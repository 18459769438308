/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import Button from '@appchoose/button';
import Icon from '@appchoose/icon';
import { Sidebar, SidebarContent } from '@appchoose/sidebar';
import { useAtomValue } from 'jotai';

import { brandState } from '../../stores/brand';
import { track } from '../../utils/analytics';
import { ShopifyPluggedIcon } from '../icons/shopify-plugged/shopify-plugged';
import { ShopifyModal } from './shopify-modal';

const SHOPIFY_SEARCH_PARAM = 'openShopifyIntegration';

export const ShopifyPlugin: React.FC = () => {
  const { t } = useTranslation();
  const brand = useAtomValue(brandState);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isOpen, setIsOpen] = useState(searchParams.has(SHOPIFY_SEARCH_PARAM));

  const hasShopify = !!brand?.shopify?.shop;

  const removeShopifySearchParam = () => {
    if (searchParams.has(SHOPIFY_SEARCH_PARAM)) {
      searchParams.delete(SHOPIFY_SEARCH_PARAM);
      setSearchParams(searchParams);
    }
  };

  return (
    <>
      <div className="w-full rounded-lg border border-gray-300 bg-white">
        {brand?.plugins?.shopify && !brand.plugins.shopify.shared_secret ? (
          <div className="error bg-red-300 p-3 text-center text-sm font-semibold text-red-600">
            {t('order.integrations.shopify.shopify_error_installation_title')}
          </div>
        ) : null}
        <div className="flex items-center justify-between p-6">
          <div className="flex space-x-4">
            <div className="flex items-center justify-center rounded-full bg-gray-100 p-3">
              <ShopifyPluggedIcon title="Shopify" className="size-8" />
            </div>
            <div className="flex flex-col justify-center space-y-2">
              <div className="font-bold">Shopify</div>
              <div className="flex items-center text-xs font-normal text-gray-500">
                {brand?.plugins?.shopify && (
                  <a
                    href={`https://${
                      (brand?.plugins.shopify?.shop as string | undefined) ?? ''
                    }/admin`}
                    className="flex items-center space-x-1 text-xs font-semibold text-green-900"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span>{brand.plugins.shopify?.shop}</span>
                    <Icon icon="externalLinkDefault" className="size-3.5" />
                  </a>
                )}
              </div>
            </div>
          </div>
          {hasShopify ? (
            <Button
              type="button"
              appearance="outline"
              onClick={() => {
                setIsOpen(true);
                track('OpenModalShopifyIntegration', {
                  from: 'Settings',
                  mode: 'View',
                });
              }}
            >
              {t('settings.integration_tabs.view_configuration')}
            </Button>
          ) : (
            <Button
              type="button"
              onClick={() => {
                setIsOpen(true);
                track('OpenModalShopifyIntegration', {
                  from: 'Settings',
                  mode: 'Edit',
                });
              }}
            >
              {t('settings.integration_tabs.integrate')}
            </Button>
          )}
        </div>
      </div>
      <Sidebar
        open={isOpen}
        onOpenChange={(isOpen) => {
          setIsOpen(isOpen);
          if (!isOpen) {
            removeShopifySearchParam();
          }
        }}
      >
        <SidebarContent className="mt-18 sm:!w-[37.5rem] lg:mt-0">
          <ShopifyModal setIsOpen={setIsOpen} />
        </SidebarContent>
      </Sidebar>
    </>
  );
};

ShopifyPlugin.displayName = 'ShopifyPlugin';
