import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { getCountryFromCountryName } from '@appchoose/address';
import Button from '@appchoose/button';
import { Form } from '@appchoose/form';
import { useAtom } from 'jotai';

import { brandState } from '../../stores/brand';
import { useUpdateSellerMutation } from '../../types/generated';
import { removeAllSpaces } from '../../utils/string';
import type { IbanFormData } from '../brand-info/iban-form-fields';
import { IbanFormFields } from '../brand-info/iban-form-fields';
import type { OnboardingStepBankingInfoForm } from '../onboarding/onboarding-step-billing-info';

export type BankingInfoHandlerProps = {
  goToNextSubStepOrStep: () => void;
};

export const BankingInfoHandler = ({
  goToNextSubStepOrStep,
}: BankingInfoHandlerProps) => {
  const { t } = useTranslation();
  const [brand, setBrand] = useAtom(brandState);

  const defaultFiscalCountry = getCountryFromCountryName(
    brand?.billing_address?.country ?? 'France'
  );

  const { mutateAsync: updateSellerMutation } = useUpdateSellerMutation();

  const form = useForm<IbanFormData>({
    mode: 'onTouched',
    defaultValues: {
      iban: removeAllSpaces(brand?.iban ?? ''),
    },
  });

  const onSubmit = (data: OnboardingStepBankingInfoForm) => {
    if (!brand) return;
    setBrand({
      ...brand,
      iban: data.iban,
    });
    updateSellerMutation({
      updateSeller: { iban: data.iban },
    });
    goToNextSubStepOrStep();
  };

  return (
    <div className="mt-8 sm:mt-20">
      <h2 className="mb-8 text-2xl font-bold sm:mb-10 sm:text-3.5xl">
        {t('onboarding.billing.sub_steps.2.title')}
      </h2>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <IbanFormFields
            countryCode={defaultFiscalCountry?.code || ''}
            autoFocus
          />
          <div className="mt-8 flex sm:mt-10">
            <Button type="submit" size="large">
              {t('continue')}
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};
