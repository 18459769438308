import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Loader from '@appchoose/loader';
import { useAuth0 } from '@auth0/auth0-react';
import { useSessionStorageValue } from '@react-hookz/web';

import { usePostRegisterMutation } from '../../types/generated-new';

export const LoginSuccessful: React.FC = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const { value: brandId } = useSessionStorageValue<string>('brandId');
  const { value: redirectUri } = useSessionStorageValue<string>('redirectUri');

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { mutateAsync: postRegisterMutation } = usePostRegisterMutation();

  useEffect(() => {
    const getAccessToken = async () => {
      await getAccessTokenSilently({ cacheMode: 'off' });
      setIsLoading(false);
    };

    getAccessToken();
  }, []);

  useEffect(() => {
    if (!isLoading) {
      const handlePostRegister = async () => {
        if (
          user &&
          user['https://appchoose.io/claims/isRegistered'] === false
        ) {
          await postRegisterMutation({
            config: {
              preferredLanguage: navigator.language,
            },
          });
        }

        if (
          user &&
          user['https://appchoose.io/claims/isRegistered'] === false &&
          user['https://appchoose.io/claims/currentConnection'] !== 'auth0' &&
          user['https://appchoose.io/claims/currentConnection'] !== 'email'
        ) {
          navigate(`/${brandId}/welcome`, { replace: true });
        } else {
          // Full redirect rules are available in full-brand-info.tsx
          if (redirectUri) {
            navigate(redirectUri, { replace: true });
          } else {
            if (brandId) {
              navigate(`/${brandId}/home`, { replace: true });
            } else {
              navigate('/', { replace: true });
            }
          }
        }
      };
      handlePostRegister();
    }
  }, [isLoading]);

  return (
    <div className="flex h-screen items-center justify-center">
      <Loader className="size-8" />
    </div>
  );
};

LoginSuccessful.displayName = 'LoginSuccessful';
