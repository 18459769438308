import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import cn from '@appchoose/cn';
import Icon from '@appchoose/icon';
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from '@appchoose/tooltip';
import { useAtomValue } from 'jotai';

import { brandState } from '../../stores/brand';
import { activeSaleState, shortActiveSaleIdState } from '../../stores/sales';
import { amDefaultContact, findContact } from '../../types/choose-contact';
import {
  OrderTagBrand,
  OrderTagClaim,
  OrderTagRefund,
  OrderTagReship,
  OrderTagReturn,
  OrderTagShipment,
  SupplierBillingStateSaleStatus,
} from '../../types/generated-new';
import { track } from '../../utils/analytics';
import { formatDate } from '../../utils/date';
import { AmContactCopyButton, AmContactImage } from './am-contact';
import { useBillingState } from './use-billing-state';

const ConditionIcon: React.FC<{
  status: 'success' | 'error' | 'disabled';
}> = ({ status }) => {
  return (
    <div
      className={cn('rounded border p-1', {
        'border-[#B8E1CB] bg-[#E7F5EE]': status === 'success',
        'border-[#EDB6B6] bg-[#F9E7E7]': status === 'error',
        'border-gray-100 bg-[#FBFBFB]': status === 'disabled',
      })}
    >
      <Icon
        icon={
          status === 'success'
            ? 'check'
            : status === 'error'
              ? 'close'
              : 'minus'
        }
        className={cn('size-[1.5rem]', {
          'text-green-600': status === 'success',
          'text-red-600': status === 'error',
          'text-[#9DA0A1]': status === 'disabled',
        })}
      />
    </div>
  );
};

type Condition = {
  key: string;
  label: string;
  status: 'success' | 'error' | 'disabled';
  help?: JSX.Element;
  info?: JSX.Element;
};

export const InvoiceLockedUnlockConditions: React.FC = () => {
  const { i18n, t } = useTranslation();
  const { data: billingState } = useBillingState();
  const brand = useAtomValue(brandState);

  const shortActiveSaleId = useAtomValue(shortActiveSaleIdState);
  const activeSale = useAtomValue(activeSaleState);

  if (!billingState) {
    return null;
  }

  const amContact = findContact(
    activeSale?.logistics_manager ?? undefined,
    amDefaultContact
  );

  const conditions: Condition[] = [
    {
      key: 'sale_is_over',
      label: t('invoice.invoice_locked.unlock_invoice.sale_is_over'),
      status:
        billingState.saleStatus === SupplierBillingStateSaleStatus.Ended
          ? 'success'
          : 'error',
    },
    {
      key: 'orders_delivered',
      label: t('invoice.invoice_locked.unlock_invoice.all_orders_delivered'),
      status:
        billingState.saleStatus === SupplierBillingStateSaleStatus.Ended
          ? !billingState.isPendingOrderDelivery
            ? 'success'
            : 'error'
          : 'disabled',
      info:
        billingState.saleStatus === SupplierBillingStateSaleStatus.Ended &&
        billingState.isPendingOrderDelivery ? (
          <NavLink
            to={`/${brand?.id}/orders?sid=${shortActiveSaleId}&view=filters&filters=${encodeURIComponent(
              JSON.stringify({
                brand: [OrderTagBrand.Available],
                refund: [OrderTagRefund.None, OrderTagRefund.PartiallyRefunded],
                reship: [OrderTagReship.None],
                shipment: [
                  OrderTagShipment.None,
                  OrderTagShipment.Pending,
                  OrderTagShipment.InfoReceived,
                  OrderTagShipment.InTransit,
                  OrderTagShipment.OutForDelivery,
                  OrderTagShipment.AttemptFail,
                  OrderTagShipment.AvailableForPickup,
                  OrderTagShipment.Exception,
                  OrderTagShipment.Expired,
                ],
              })
            )}`}
            className="flex items-center gap-2 rounded px-1.5 py-1 text-sm font-semibold text-green-900 hover:bg-green-300"
            onClick={() => {
              track('TrackInvoiceLockedConditionRedirection', {
                type: 'orders_not_delivered',
              });
            }}
          >
            <span>
              {t('invoice.invoice_locked.unlock_invoice.track_deliveries')}
            </span>
            <span className="rounded-sm bg-green-300 px-1">
              {billingState.pendingOrderDeliveryOrderIds.length}
            </span>
            <Icon icon="back" className="rotate-180" />
          </NavLink>
        ) : undefined,
    },
    {
      key: 'claims_fulfilled',
      label: t('invoice.invoice_locked.unlock_invoice.all_claims_processed'),
      status:
        billingState.saleStatus === SupplierBillingStateSaleStatus.Ended
          ? !billingState.isPendingClaimClosing
            ? 'success'
            : 'error'
          : 'disabled',
      info:
        billingState.saleStatus === SupplierBillingStateSaleStatus.Ended &&
        billingState.isPendingClaimClosing ? (
          <NavLink
            to={`/${brand?.id}/orders?sid=${shortActiveSaleId}&view=filters&filters=${encodeURIComponent(
              JSON.stringify({
                claim: [OrderTagClaim.Opened],
              })
            )}`}
            className="flex items-center gap-2 rounded px-1.5 py-1 text-sm font-semibold text-green-900 hover:bg-green-300"
            onClick={() => {
              track('TrackInvoiceLockedConditionRedirection', {
                type: 'claims_unfulfilled',
              });
            }}
          >
            <span>
              {t('invoice.invoice_locked.unlock_invoice.manage_claims')}
            </span>
            <span className="rounded-sm bg-green-300 px-1">
              {billingState.pendingClaimClosingOrderIds.length}
            </span>
            <Icon icon="back" className="rotate-180" />
          </NavLink>
        ) : undefined,
    },
    {
      key: 'returns_validated',
      label: t('invoice.invoice_locked.unlock_invoice.all_returns_refunded'),
      status:
        billingState.saleStatus === SupplierBillingStateSaleStatus.Ended
          ? !billingState.isPendingReturnValidation
            ? 'success'
            : 'error'
          : 'disabled',
      info:
        billingState.saleStatus === SupplierBillingStateSaleStatus.Ended &&
        billingState.isPendingReturnValidation ? (
          <NavLink
            to={`/${brand?.id}/orders?sid=${shortActiveSaleId}&view=filters&filters=${encodeURIComponent(
              JSON.stringify({
                return: [OrderTagReturn.InProgress, OrderTagReturn.Delivered],
              })
            )}`}
            className="flex items-center gap-2 rounded px-1.5 py-1 text-sm font-semibold text-green-900 hover:bg-green-300"
            onClick={() => {
              track('TrackInvoiceLockedConditionRedirection', {
                type: 'returns_not_validated',
              });
            }}
          >
            <span>
              {t('invoice.invoice_locked.unlock_invoice.track_returns')}
            </span>
            <span className="rounded-sm bg-green-300 px-1">
              {billingState.pendingReturnValidationOrderIds.length}
            </span>
            <Icon icon="back" className="rotate-180" />
          </NavLink>
        ) : undefined,
    },
    {
      key: 'withdrawal_period_is_over',
      label: t(
        'invoice.invoice_locked.unlock_invoice.withdrawal_period_is_over'
      ),
      status:
        billingState.saleStatus === SupplierBillingStateSaleStatus.Ended &&
        !billingState.isPendingOrderDelivery
          ? !billingState.isPendingWithdrawalPeriod
            ? 'success'
            : 'error'
          : 'disabled',
      help:
        billingState.saleStatus === SupplierBillingStateSaleStatus.Ended &&
        billingState.isPendingOrderDelivery ? (
          <Tooltip>
            <TooltipTrigger>
              <Icon
                icon="information"
                className="size-6 shrink-0 text-[#9DA0A1]"
              />
            </TooltipTrigger>
            <TooltipContent className="max-w-96 text-center">
              {t(
                'invoice.invoice_locked.unlock_invoice.withdrawal_period_tooltip'
              )}
              <TooltipArrow />
            </TooltipContent>
          </Tooltip>
        ) : undefined,
      info:
        billingState.saleStatus === SupplierBillingStateSaleStatus.Ended &&
        !billingState.isPendingOrderDelivery &&
        billingState.withdrawalPeriodEndDate ? (
          <div className="flex items-center gap-1 rounded bg-[#FBFBFB] px-2 py-1.5 text-sm font-semibold text-gray-700">
            <Icon icon="clock" />
            <span>
              {formatDate(
                billingState.withdrawalPeriodEndDate,
                'PPP',
                i18n.language
              )}
            </span>
          </div>
        ) : undefined,
    },
  ];

  return (
    <div className="flex flex-col gap-10">
      <div className="flex flex-col gap-4">
        <div className="text-2xl font-bold text-gray-900">
          {t('invoice.invoice_locked.unlock_invoice.unlocking_conditions')}
        </div>
        <ul className="flex list-inside list-disc flex-col rounded border border-gray-100">
          {conditions.map((condition) => (
            <li
              key={condition.key}
              className="flex flex-col justify-between gap-6 border-b border-gray-100 p-4 last:border-none md:flex-row md:items-center"
            >
              <div className="flex flex-1 items-center justify-between gap-4">
                <div className="flex items-center gap-4">
                  <ConditionIcon status={condition.status} />

                  <span
                    className={cn('text-sm', {
                      'font-semibold text-gray-900':
                        condition.status !== 'disabled',
                      'text-[#767A7C]': condition.status === 'disabled',
                    })}
                  >
                    {condition.label}
                  </span>
                </div>

                {condition.help ?? null}
              </div>
              {condition.info ? (
                <div className="flex justify-end">{condition.info}</div>
              ) : null}
            </li>
          ))}
        </ul>
      </div>
      {amContact ? (
        <div className="flex flex-1 flex-col gap-4">
          <div className="text-2xl font-bold text-gray-900">
            {t('invoice.invoice_locked.unlock_invoice.contact')}
          </div>
          <div className="flex w-full flex-col items-start justify-between gap-6 rounded border border-gray-100 p-6 md:flex-row md:items-center">
            <div className="flex flex-col items-start gap-4 md:flex-row md:items-center">
              <AmContactImage
                avatar={amContact.avatar}
                name={amContact.firstName}
              />
              <div className="flex flex-col gap-[0.12rem]">
                <div className="font-bold leading-5.5 text-gray-900">
                  {t('invoice.invoice_locked.am_contact.account_manager', {
                    name: amContact.firstName,
                  })}
                </div>
                <div className="text-xs leading-[1.125rem] text-gray-700">
                  {t('invoice.invoice_locked.am_contact.need_help')}
                </div>
              </div>
            </div>

            <AmContactCopyButton
              className="w-full md:w-auto"
              email={amContact.email}
              from="billing"
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};
