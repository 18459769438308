import { useTranslation } from 'react-i18next';

import { useAtomValue } from 'jotai';

import { activeSaleState } from '../../stores/sales';
import { formatPrice } from '../../utils/currency';
import { transformDateSimple } from '../../utils/date';
import { DashedLine } from './dashed-line';
import { useBilling } from './use-billing';

type BillingPaymentsProps = {
  isSubjectVat: boolean;
};

export const BillingPayments: React.FC<BillingPaymentsProps> = ({
  isSubjectVat,
}: BillingPaymentsProps) => {
  const { i18n, t } = useTranslation();
  const { data: billing } = useBilling();
  const activeSale = useAtomValue(activeSaleState);

  if (!billing?.payments.length) {
    return null;
  }

  return (
    <section className="flex flex-col gap-4">
      <h3 className="text-2xl font-bold">{t('invoice.payments.title')}</h3>
      <div className="overflow-hidden rounded-lg border border-gray-100">
        <ul className="py-2">
          <li className="flex justify-between space-x-2 px-6 py-2 text-base leading-5.5 text-gray-700 transition-colors duration-300 hover:bg-gray-50">
            <div className="shrink-0 space-x-1">
              <span className="">
                {t('invoice.payments.total_to_be_invoiced_vat')}
              </span>
            </div>
            <div className="flex w-full items-end pb-1.5">
              <DashedLine />
            </div>
            <div className="shrink-0">
              {formatPrice(
                (isSubjectVat
                  ? (billing?.total_billing_ttc ?? 0)
                  : (billing?.total_billing_ht ?? 0)) / 100,
                i18n.language === 'fr' ? 'fr' : 'en',
                activeSale?.currency.toString().toUpperCase() ?? 'EUR'
              )}
            </div>
          </li>

          {billing.payments
            .sort((a, b) => {
              if (!a.paidAt) {
                return 1;
              }
              if (!b.paidAt) {
                return -1;
              }
              return Number(new Date(b.paidAt)) - Number(new Date(a.paidAt));
            })
            .map((payment) => (
              <li
                key={payment.invoiceId}
                className="flex justify-between space-x-2 px-6 py-2 text-base leading-5.5 text-gray-700 transition-colors duration-300 hover:bg-gray-50"
              >
                <div className="shrink-0 space-x-1">
                  <span className="font-semibold text-black">
                    {payment.paidAt
                      ? t('invoice.payments.payment_made_on', {
                          date: transformDateSimple(
                            new Date(payment.paidAt ?? ''),
                            i18n.language === 'fr' ? 'fr' : 'en',
                            true,
                            'Europe/Paris'
                          ),
                          interpolation: { escapeValue: false },
                        })
                      : t('invoice.payments.payment_made')}
                  </span>
                </div>
                <div className="flex w-full items-end pb-1.5">
                  <DashedLine />
                </div>
                <div className="shrink-0 font-semibold text-black">
                  {formatPrice(
                    -(isSubjectVat
                      ? payment.amountVatIncludedInCents
                      : payment.amountVatExcludedInCents) / 100,
                    i18n.language === 'fr' ? 'fr' : 'en',
                    activeSale?.currency.toString().toUpperCase() ?? 'EUR'
                  )}
                </div>
              </li>
            ))}

          <li className="flex justify-between space-x-2 px-6 py-2 text-base leading-5.5 text-gray-700 transition-colors duration-300 hover:bg-gray-50">
            <div className="shrink-0 space-x-1">
              <span className="">
                {t('invoice.payments.remainder_to_be_invoiced')}
              </span>
            </div>
            <div className="flex w-full items-end pb-1.5">
              <DashedLine />
            </div>
            <div className="shrink-0">
              {formatPrice(
                (isSubjectVat
                  ? (billing?.total_remaining_ttc ?? 0)
                  : (billing?.total_remaining_ht ?? 0)) / 100,
                i18n.language === 'fr' ? 'fr' : 'en',
                activeSale?.currency.toString().toUpperCase() ?? 'EUR'
              )}
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
};
