import { useTranslation } from 'react-i18next';

import Button from '@appchoose/button';
import { useAtomValue } from 'jotai';

import { brandState } from '../../stores/brand';
import { StoreRegion } from '../../types/generated';
import { BillingInfoView } from '../billing-info-view/billing-info-view';

export type BillingBankingInfoSummaryProps = {
  goToNextSubStepOrStep: () => void;
  onEditBankingInfo: () => void;
  onEditBillingInfo: () => void;
  onEditIban: () => void;
};

export const BillingBankingInfoSummary = ({
  goToNextSubStepOrStep,
  onEditBankingInfo,
  onEditBillingInfo,
  onEditIban,
}: BillingBankingInfoSummaryProps) => {
  const { t } = useTranslation();
  const brand = useAtomValue(brandState);
  const fiscalCountryCode = brand?.billing_address?.countryCode ?? 'FR';

  return (
    <>
      <div className="mt-8 sm:mt-20">
        <h2 className="mb-8 text-2xl font-bold sm:mb-10 sm:text-3.5xl">
          {t('onboarding.billing.sub_steps.1.confirm_title')}
        </h2>
      </div>
      <div className="flex flex-col items-start">
        <div className="min-w-[18.375rem] rounded-lg border border-gray-500 p-6">
          {brand?.billing_address && (
            <BillingInfoView
              isSiretActive={
                fiscalCountryCode === 'FR' && brand?.store === StoreRegion.Fr
              }
              siret={brand?.siret ?? undefined}
              isSubjectVat={brand && !brand.is_autoentrepreneur}
              iban={brand?.iban ?? undefined}
              taxId={brand?.tax_id ?? undefined}
              intracommunityVat={brand?.intracommunity_vat ?? undefined}
              address={brand?.billing_address}
              onEditBankInfo={onEditBankingInfo}
              onEditBillingInfo={onEditBillingInfo}
              onEditIban={onEditIban}
            />
          )}
        </div>
        <div className="mt-8 sm:mt-10">
          <Button type="button" size="large" onClick={goToNextSubStepOrStep}>
            {t('confirm')}
          </Button>
        </div>
      </div>
    </>
  );
};
