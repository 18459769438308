/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Address } from '@appchoose/address';
import AddressView from '@appchoose/address-view';
import Icon from '@appchoose/icon';
import { useAtomValue } from 'jotai';

import { brandState } from '../../stores/brand';
import { StoreRegion } from '../../types/generated';
import { formatIbanForHuman } from '../../utils/iban';
import { isCountryNameInUE } from '../../utils/utils';

type BillingInfoViewProps = {
  isSiretActive: boolean;
  address?: Address;
  siret?: string;
  taxId?: string;
  intracommunityVat?: string;
  isSubjectVat?: boolean;
  iban?: string;
  onEditBillingInfo: () => void;
  onEditIban: () => void;
  onEditBankInfo: () => void;
};

export const BillingInfoView: React.FC<BillingInfoViewProps> = ({
  isSiretActive,
  address,
  siret,
  intracommunityVat,
  isSubjectVat,
  iban,
  taxId,
  onEditBillingInfo,
  onEditIban,
  onEditBankInfo,
}: BillingInfoViewProps) => {
  const brand = useAtomValue(brandState);
  const { i18n, t } = useTranslation();

  const isSwift = brand && !brand.routing_code;

  return (
    <dl className="divide-y divide-gray-100">
      <div className="pb-6">
        {address && Object.keys(address).length ? (
          <AddressView
            label={t('address.label')}
            address={address}
            locale={i18n.language === 'fr' ? 'FR' : 'EN'}
          />
        ) : (
          <dd className="text-sm font-semibold text-red-600">
            {t('billing.fields.billing_address.missing')}
          </dd>
        )}

        <div className="mt-4 flex space-x-12">
          {isSiretActive ? (
            <div>
              <dt className="pb-1.5 text-xs font-semibold uppercase tracking-wider text-gray-500">
                {t('billing.fields.siret.label')}
              </dt>
              {siret ? (
                <dd className="text-sm text-gray-700">{siret}</dd>
              ) : (
                <dd className="text-sm font-semibold text-red-600">
                  {t('billing.fields.siret.missing')}
                </dd>
              )}
            </div>
          ) : (
            ''
          )}

          {brand?.store === StoreRegion.Fr ? (
            <div>
              <dt className="pb-1.5 text-xs font-semibold uppercase tracking-wider text-gray-500">
                {isCountryNameInUE(address?.country ?? 'France')
                  ? t('billing.fields.intracommunity_vat.label')
                  : t('billing.fields.tax_id.label')}
              </dt>
              {isCountryNameInUE(address?.country ?? 'France') ? (
                <>
                  {isSubjectVat ? (
                    intracommunityVat ? (
                      <dd className="text-sm text-gray-700">
                        {intracommunityVat}
                      </dd>
                    ) : (
                      <dd className="text-sm font-semibold text-red-600">
                        {t('billing.fields.intracommunity_vat.missing')}
                      </dd>
                    )
                  ) : (
                    <dd className="text-xs font-semibold uppercase leading-5 tracking-wider text-orange-600">
                      <div className="flex items-center">
                        <Icon icon="information" className="mr-1" />
                        {t('billing.exempt_from_tax')}
                      </div>
                    </dd>
                  )}
                </>
              ) : taxId ? (
                <dd className="text-sm text-gray-700">{taxId}</dd>
              ) : (
                <dd className="text-sm text-gray-500">
                  {t('billing.fields.tax_id.missing')}
                </dd>
              )}
            </div>
          ) : null}
        </div>

        <button
          type="button"
          className="mt-6 text-xs font-bold text-green-900 focus-visible:ring-2 focus-visible:ring-gray-900/30"
          onClick={onEditBillingInfo}
        >
          {t('edit')}
        </button>
      </div>

      <div className="pt-6">
        {brand?.store === StoreRegion.Fr ||
        (brand?.store === StoreRegion.Us && brand?.iban) ? (
          <>
            <dt className="pb-1.5 text-xs font-semibold uppercase tracking-wider text-gray-500">
              {t('billing.fields.iban.label')}
            </dt>
            {iban ? (
              <dd className="text-sm text-gray-700">
                {formatIbanForHuman(iban)}
              </dd>
            ) : (
              <dd className="text-sm font-semibold text-red-600">
                {t('billing.fields.iban.missing')}
              </dd>
            )}

            <button
              type="button"
              className="mt-6 text-xs font-bold text-green-900 focus-visible:ring-2 focus-visible:ring-gray-900/30"
              onClick={onEditIban}
            >
              {t('edit')}
            </button>
          </>
        ) : (
          <>
            <div className="mb-2 text-base font-semibold text-gray-700">
              {t('onboarding.billing.sub_steps.3.subtitle')}
            </div>
            {!isSwift && brand?.bank_address && (
              <div className="mb-4">
                <div className="mb-2 text-sm text-gray-700">
                  {brand.bank_address.name}
                </div>
                <div className="text-sm text-gray-700">
                  {brand.bank_address.street}
                </div>
                {brand.bank_address.street2 ? (
                  <div className="text-sm text-gray-700">
                    {brand.bank_address.street2}
                  </div>
                ) : null}
                <div className="text-sm text-gray-700">{`${
                  brand.bank_address.city
                }, ${brand.bank_address.province ?? ''} ${
                  brand.bank_address.bp
                }`}</div>
                <div className="text-sm text-gray-700">
                  {brand?.bank_address.country}
                </div>
              </div>
            )}
            <div className="mb-6 flex space-x-14">
              <div className="flex flex-col space-y-1">
                <div className="text-xs font-semibold uppercase text-gray-500">
                  {t('brand_info.legal_info_form_fields.account_number.label')}
                </div>
                <div className="text-sm text-gray-700">
                  {brand?.account_number ?? ''}
                </div>
              </div>
              <div className="flex flex-col space-y-1">
                <div className="text-xs font-semibold uppercase text-gray-500">
                  {t(
                    'brand_info.legal_info_form_fields.beneficiary_name.label'
                  )}
                </div>
                <div className="text-sm text-gray-700">
                  {brand?.beneficiary_name ?? ''}
                </div>
              </div>
              {!isSwift && (
                <div className="flex flex-col space-y-1">
                  <div className="text-xs font-semibold uppercase text-gray-500">
                    {t('brand_info.legal_info_form_fields.routing_code.label')}
                  </div>
                  <div className="text-sm text-gray-700">
                    {brand?.routing_code ?? ''}
                  </div>
                </div>
              )}
              {isSwift && (
                <div className="flex flex-col space-y-1">
                  <div className="text-xs font-semibold uppercase text-gray-500">
                    {t('brand_info.legal_info_form_fields.swift_code.label')}
                  </div>
                  <div className="text-sm text-gray-700">
                    {brand?.swift_code ?? ''}
                  </div>
                </div>
              )}
            </div>
            <button
              type="button"
              className="text-xs font-bold text-green-900 focus-visible:ring-2 focus-visible:ring-gray-900/30"
              onClick={onEditBankInfo}
            >
              {t('edit')}
            </button>
          </>
        )}
      </div>
    </dl>
  );
};
