import { useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { useQueryClient } from '@tanstack/react-query';
import { useAtomValue, useSetAtom } from 'jotai';

import { newOrdersCountState } from '../components/new-orders-banner/new-orders-banner';
import { activeSaleState } from '../stores/sales';
import type { OrderCreatedSubscription } from '../types/generated';
import { OrderCreatedDocument } from '../types/generated';
import { getSubscriptionClient } from './subscription-client';

export const useOrderCreatedSubscription = () => {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();
  const activeSale = useAtomValue(activeSaleState);
  const setNewOrdersCount = useSetAtom(newOrdersCountState);

  useEffect(() => {
    const client = getSubscriptionClient(getAccessTokenSilently);
    const subscribe = () => {
      client
        .request({
          query: OrderCreatedDocument,
        })
        .subscribe({
          next(response) {
            if (!activeSale) return;

            const data = response.data as OrderCreatedSubscription;

            if (!data.orderCreated) return;

            setNewOrdersCount((count) => count + 1);

            queryClient.invalidateQueries({
              queryKey: ['ordersCounters'],
            });
          },
        });
    };
    subscribe();
    return () => {
      client.close();
    };
  }, [queryClient, activeSale]);
};
