import React from 'react';
import { useTranslation } from 'react-i18next';

import Icon from '@appchoose/icon';
import Statistics from '@appchoose/statistics';
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from '@appchoose/tooltip';
import { useAtomValue } from 'jotai';

import { activeSaleState } from '../../stores/sales';
import { StoreCurrency } from '../../types/generated';

type Product =
  | {
      image: string;
      description: string;
      options?: (string | null | undefined)[] | null | undefined;
      sum: number;
    }
  | null
  | undefined;

type Return =
  | {
      sum: number;
      code: string;
    }
  | null
  | undefined;
type ReturnProps = {
  total_orders: number;
  total_sum: number;
  percentage: number;
  returns: Return[];
  products: Product[];
};

export const Returns = ({
  total_orders,
  total_sum,
  percentage,
  returns,
  products,
}: ReturnProps) => {
  const activeSale = useAtomValue(activeSaleState);
  const { i18n, t } = useTranslation();

  if (returns && total_orders) {
    return (
      <div className="returns">
        <div className="row-stats grid grid-cols-1 gap-4 lg:grid-cols-2">
          <div className="w-full space-y-6 divide-y rounded border border-gray-300 p-6">
            <Statistics
              icon="return"
              hasBorder={false}
              title={t('analytics.returns_total_orders')}
              value={total_orders}
              locale={i18n.language === 'fr' ? 'fr' : 'en'}
              tooltipContent={
                <div className="p-1">
                  {t('analytics.return_tooltip_total_orders')}
                </div>
              }
            />
            <Statistics
              icon="percent"
              hasBorder={false}
              title={t('analytics.returns_rate')}
              sign="%"
              showSign
              value={percentage * 100}
              locale={i18n.language === 'fr' ? 'fr' : 'en'}
              className="pt-6"
              tooltipContent={
                <div className="p-1">{t('analytics.tooltip_returns_rate')}</div>
              }
            />
            <Statistics
              icon={
                activeSale?.currency === StoreCurrency.Usd
                  ? 'dollarCircle'
                  : 'euroCircle'
              }
              className="rounded-none pt-6"
              hasBorder={false}
              title={t('analytics.returns_total_refund')}
              sign={activeSale?.currency === StoreCurrency.Usd ? '$' : '€'}
              showSign
              reverseSignAndValue={i18n.language === 'en' ? true : false}
              value={total_sum / 100}
              locale={i18n.language === 'fr' ? 'fr' : 'en'}
              tooltipContent={
                <div className="p-1">{t('analytics.tooltip_total_refund')}</div>
              }
            />
          </div>
          <div className="w-full">
            <div className="rounded border border-gray-300 p-6 pb-0">
              <div>
                <div className="flex flex-row items-center justify-between">
                  <div className="flex items-center">
                    <Icon icon="tagReturn" />

                    <Tooltip>
                      <TooltipTrigger className="ml-2.25 cursor-pointer border-b border-dashed border-gray-300 text-xs font-semibold uppercase tracking-wider text-gray-700 hover:border-solid">
                        {t('analytics.returns_products')}
                      </TooltipTrigger>
                      <TooltipContent side="top">
                        <div className="p-1">
                          {t('analytics.tooltip_returns_products')}
                        </div>
                        <TooltipArrow />
                      </TooltipContent>
                    </Tooltip>
                  </div>

                  <p className="text-xs text-gray-500">
                    {t('analytics.returns_count')}
                  </p>
                </div>
                <div className="space-y-3 py-4">
                  {products.map((p, i) => (
                    <div
                      className="flex flex-row items-center space-x-2"
                      key={i}
                    >
                      <img
                        className="size-14 rounded border border-gray-300"
                        src={p?.image}
                      />
                      <div className="flex-1 space-y-1">
                        <p className="text-sm text-gray-700">
                          {p?.description}
                        </p>
                        {p?.options ? (
                          <p className="text-xs text-gray-500">
                            {p.options
                              .filter((o): o is string => !!o)
                              .join(' • ')}
                          </p>
                        ) : null}
                      </div>
                      <p className="float-right text-xs font-semibold text-gray-500">
                        {p?.sum}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {returns.length ? (
            <div className="lg:col-span-2">
              <div className="rounded border border-gray-300 p-6 pb-0">
                <div>
                  <div className="flex flex-row items-center justify-between">
                    <div className="flex items-center">
                      <Icon icon="information" />

                      <Tooltip>
                        <TooltipTrigger className="ml-2.25 cursor-pointer border-b border-dashed border-gray-300 text-xs font-semibold uppercase tracking-wider text-gray-700 hover:border-solid">
                          {t('analytics.returns_reason')}
                        </TooltipTrigger>
                        <TooltipContent side="top">
                          <div className="p-1">
                            {t('analytics.tooltip_returns_reason')}
                          </div>
                          <TooltipArrow />
                        </TooltipContent>
                      </Tooltip>
                    </div>

                    <p className="text-xs text-gray-500">
                      {t('analytics.returns_count')}
                    </p>
                  </div>
                  <div className="reasons">
                    {returns.map((r, i) => (
                      <p key={r?.code} className="text-gray-900">
                        <span className="text-gray-500">{i + 1}</span>
                        <span className="px-2 text-gray-500">—</span>
                        <span className="text-sm font-normal">
                          {t('analytics.return_code_' + r?.code)}
                        </span>

                        <span className="float-right text-xs font-semibold text-gray-500">
                          {r?.sum}
                        </span>
                      </p>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
  return (
    <div className="no-star">
      <Icon icon="return" size="large" className="mr-1" />
      <p>{t('analytics.returns_empty')}</p>
    </div>
  );
};
