import type { Address } from '@appchoose/address';
import { atom } from 'jotai';

import type { BrandQuery } from '../types/generated';

export const serverTimeState = atom<Date | undefined>(undefined);

export type Brand =
  | (Exclude<
      NonNullable<BrandQuery['brand']>,
      'address' | 'bank_address' | 'return_address'
    > & {
      // TODO: Type retrieved from the API - type the address object on the back-end
      billing_address?: Address | null;
      bank_address?: Address | null;
      return_address?: Address | null;
      nbUsers?: number;
    })
  | undefined;

export const brandState = atom<Brand>(undefined);
