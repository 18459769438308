import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@appchoose/button';
import Icon from '@appchoose/icon';
import { toast } from '@appchoose/toast';
import { useQueryClient } from '@tanstack/react-query';

import type { OrderQuery } from '../../types/generated-new';
import { useAcceptOrderClaimWithReshipMutation } from '../../types/generated-new';

type OrderConfirmReturnProps = {
  claim: OrderQuery['order']['claims'][0];
  orderId?: OrderQuery['order']['id'];
  setIsOpen: (isOpen: boolean) => void;
};

export const ModalConfirmReturn: React.FC<OrderConfirmReturnProps> = ({
  claim,
  orderId,
  setIsOpen,
}: OrderConfirmReturnProps) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const {
    mutateAsync: acceptClaimWithReshipMutation,
    isPending,
    error,
  } = useAcceptOrderClaimWithReshipMutation();

  useEffect(() => {
    if (error) {
      toast.error(t('order.modals.modal_return.return_error'));
    }
  }, [error]);

  const onConfirm = async () => {
    await acceptClaimWithReshipMutation({
      input: {
        orderId: orderId ?? '',
        claimId: claim.id,
      },
    });

    queryClient.invalidateQueries({
      queryKey: ['order', { orderId: orderId }],
    });
    queryClient.invalidateQueries({
      queryKey: ['orders'],
    });
    setIsOpen(false);
    toast.success(t('order.modals.modal_return.claim_processed'));
  };

  return (
    <div className="flex flex-col overflow-hidden">
      <div className="overflow-y-auto p-6 md:p-10">
        <h3
          id="modal-return-title"
          className="mb-4 text-2xl font-bold text-gray-900"
        >
          {t('order.actions_return_product', {
            count: claim.items.length,
          })}
        </h3>
        <p className="text-sm text-gray-700">
          {t('order.modals.modal_return.confirm_return')}
        </p>
        <div className="mb-10 mt-8 flex items-center gap-4 rounded border border-gray-300 bg-[#FBFBFB] p-6 text-gray-900">
          <Icon icon="information" className="size-6" />
          <span className="text-sm font-semibold">
            {t('order.modals.modal_return.confirm_return_info')}
          </span>
        </div>
      </div>
      <div className="flex shrink-0 justify-end space-x-6 border-t border-gray-100 p-6 md:p-10 md:pt-6">
        <Button
          type="button"
          appearance="outline"
          onClick={() => setIsOpen(false)}
        >
          {t('cancel')}
        </Button>
        <Button type="button" onClick={() => onConfirm()} disabled={isPending}>
          {t('confirm')}
        </Button>
      </div>
    </div>
  );
};
