import React from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import Button from '@appchoose/button';
import { Form } from '@appchoose/form';
import { useAtom } from 'jotai';

import type { Brand } from '../../stores/brand';
import { brandState } from '../../stores/brand';
import { StoreRegion, useUpdateSellerMutation } from '../../types/generated';
import { getPhoneCountryCode, parsePhoneNumber } from '../../utils/phone';
import { removeAllSpaces } from '../../utils/string';
import type { BrandContactFormData } from '../brand-info/contact-form-fields';
import { ContactFormFields } from '../brand-info/contact-form-fields';

export type OnBoardingContactFormProps = {
  goToNextStep: () => void;
};

export const OnBoardingContactForm: React.FC<OnBoardingContactFormProps> = ({
  goToNextStep,
}: OnBoardingContactFormProps) => {
  const { t } = useTranslation();
  const [brand, setBrand] = useAtom(brandState);

  const { mutateAsync: updateSellerMutation } = useUpdateSellerMutation();

  const parsedDefaultPhoneNumber = parsePhoneNumber(brand?.contact_phone ?? '');

  const form = useForm<BrandContactFormData>({
    mode: 'onTouched',
    defaultValues: {
      email: brand?.contact_email ?? '',
      phone: parsedDefaultPhoneNumber?.formatInternational().replace(/\s/g, ''),
      phoneCountryCode: getPhoneCountryCode(
        parsedDefaultPhoneNumber,
        brand?.store ?? StoreRegion.Fr
      ),
    },
  });

  const onSubmit = (data: BrandContactFormData) => {
    onUpdate({
      contact_phone: data.phone,
      contact_email: removeAllSpaces(data.email),
    });
  };

  const onUpdate = (
    data: Pick<NonNullable<Brand>, 'contact_phone' | 'contact_email'>
  ) => {
    if (!brand) return;
    updateSellerMutation({ updateSeller: data });
    setBrand({
      ...brand,
      contact_email: data.contact_email,
      contact_phone: data.contact_phone,
    });
    goToNextStep();
  };

  return (
    <section className="w-full">
      <div className="mt-8 flex flex-col gap-10 sm:mt-20">
        <div className="flex flex-col gap-4">
          <h2 className="text-2xl font-bold sm:text-3.5xl">
            {t('onboarding.contact.title')}
          </h2>
          <div className="flex flex-col gap-2 text-sm leading-5.5 text-gray-900">
            <p>
              <Trans
                t={t}
                i18nKey="onboarding.contact.subtitle"
                components={{ b: <b className="text-gray-900" /> }}
              />
            </p>
            <p>
              <Trans
                t={t}
                i18nKey="onboarding.contact.subtitle_emergency"
                components={{ b: <b className="text-gray-900" /> }}
              />
            </p>
          </div>
        </div>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="space-y-6">
              <ContactFormFields autoFocus />
            </div>
            <div className="mt-8 flex sm:mt-10">
              <Button type="submit" size="large">
                {t('continue')}
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </section>
  );
};
