import { useTranslation } from 'react-i18next';

import Alert from '@appchoose/alert';
import Button from '@appchoose/button';
import Illustration from '@appchoose/illustration';
import { useAtomValue } from 'jotai';

import { brandState } from '../../stores/brand';
import { activeSaleState } from '../../stores/sales';
import { useOrdersCountersQuery } from '../../types/generated-new';
import { TabEntry, useTabs } from '../../views/orders-page/use-tabs';
import { useResetList } from './use-reset-list';

export const OrderTableNoOrder: React.FC = () => {
  const { selectedTab } = useTabs();
  const { t } = useTranslation();
  const { resetList } = useResetList();

  const activeSale = useAtomValue(activeSaleState);
  const brand = useAtomValue(brandState);

  const { data: ordersCountersData } = useOrdersCountersQuery(
    {
      sellerId: brand?.id ?? '',
      saleId: activeSale?.id ?? '',
    },
    {
      enabled: !!brand?.id && !!activeSale?.id,
    }
  );

  if (ordersCountersData?.ordersCounters.tagging.brand.available === 0) {
    return (
      <div className="mt-20 flex w-full flex-col items-center space-y-4">
        <Illustration illustration="boxEmpty" className="size-20" />
        <div className="space-y-1 text-center">
          <h4 className="text-2xl font-bold text-gray-900">
            {t('orders.table.empty_state.no_order.no_order_for_now')}
          </h4>
          <p className="text-sm">
            {t('orders.table.empty_state.no_order.no_order_expect_the_magic')}
          </p>
        </div>
      </div>
    );
  }

  if (
    ordersCountersData?.ordersCounters.tagging.brand.actionRequired === 0 &&
    selectedTab === TabEntry.RequiredAction
  ) {
    return (
      <div className="mt-20 flex w-full flex-col items-center space-y-12">
        <div className="flex flex-col items-center space-y-4">
          <Illustration illustration="boxEmpty" className="size-20" />
          <div className="space-y-4 text-center">
            <h4 className="text-2xl font-bold text-gray-900">
              {t('orders.table.empty_state.no_order.no_required_action_order')}
            </h4>
            <Button type="button" onClick={() => resetList(TabEntry.Available)}>
              {t('orders.table.empty_state.no_order.view_all_orders')}
            </Button>
          </div>
        </div>
        <Alert
          size="small"
          title={t(
            'orders.table.empty_state.no_order.tips_refine_your_results'
          )}
          icon="information"
          className="max-w-[33rem]"
        >
          <p className="text-sm text-gray-700">
            {t('orders.table.empty_state.no_order.tips_filter_content')}
          </p>
        </Alert>
      </div>
    );
  }

  if (
    ordersCountersData?.ordersCounters.tagging.brand
      .actionRequiredUnfulfilled === 0 &&
    selectedTab === TabEntry.Unfulfilled
  ) {
    return (
      <div className="mt-20 flex w-full flex-col items-center space-y-12">
        <div className="flex flex-col items-center space-y-4">
          <Illustration illustration="boxEmpty" className="size-20" />
          <div className="space-y-4 text-center">
            <h4 className="text-2xl font-bold text-gray-900">
              {t('orders.table.empty_state.no_order.no_unfulfilled_order')}
            </h4>
            <Button type="button" onClick={() => resetList(TabEntry.Available)}>
              {t('orders.table.empty_state.no_order.view_all_orders')}
            </Button>
          </div>
        </div>
        <Alert
          size="small"
          title={t(
            'orders.table.empty_state.no_order.tips_view_fulfillent_results'
          )}
          icon="information"
          className="max-w-[33rem]"
        >
          <p className="text-sm text-gray-700">
            {t('orders.table.empty_state.no_order.tips_filter_content')}
          </p>
        </Alert>
      </div>
    );
  }

  if (
    ordersCountersData?.ordersCounters.tagging.brand.actionRequiredClaim ===
      0 &&
    selectedTab === TabEntry.Claim
  ) {
    return (
      <div className="mt-20 flex w-full flex-col items-center space-y-12">
        <div className="flex flex-col items-center space-y-4">
          <Illustration illustration="boxEmpty" className="size-20" />
          <div className="space-y-4 text-center">
            <h4 className="text-2xl font-bold text-gray-900">
              {t('orders.table.empty_state.no_order.no_claim_order')}
            </h4>
            <Button type="button" onClick={() => resetList(TabEntry.Available)}>
              {t('orders.table.empty_state.no_order.view_all_orders')}
            </Button>
          </div>
        </div>
        <Alert
          size="small"
          title={t('orders.table.empty_state.no_order.tips_view_closed_claims')}
          icon="information"
          className="max-w-[33rem]"
        >
          <p className="text-sm text-gray-700">
            {t('orders.table.empty_state.no_order.tips_filter_content')}
          </p>
        </Alert>
      </div>
    );
  }

  if (
    ordersCountersData?.ordersCounters.tagging.brand
      .actionRequiredShipmentIncident === 0 &&
    selectedTab === TabEntry.DeliveryIncident
  ) {
    return (
      <div className="mt-20 flex w-full flex-col items-center space-y-12">
        <div className="flex flex-col items-center space-y-4">
          <Illustration illustration="boxEmpty" className="size-20" />
          <div className="space-y-4 text-center">
            <h4 className="text-2xl font-bold text-gray-900">
              {t(
                'orders.table.empty_state.no_order.no_delivery_incident_order'
              )}
            </h4>
            <Button type="button" onClick={() => resetList(TabEntry.Available)}>
              {t('orders.table.empty_state.no_order.view_all_orders')}
            </Button>
          </div>
        </div>
        <Alert
          size="small"
          title={t(
            'orders.table.empty_state.no_order.tips_refine_your_results'
          )}
          icon="information"
          className="max-w-[33rem]"
        >
          <p className="text-sm text-gray-700">
            {t('orders.table.empty_state.no_order.tips_filter_content')}
          </p>
        </Alert>
      </div>
    );
  }

  if (
    ordersCountersData?.ordersCounters.tagging.brand.actionRequiredReturn ===
      0 &&
    selectedTab === TabEntry.Return
  ) {
    return (
      <div className="mt-20 flex w-full flex-col items-center space-y-12">
        <div className="flex flex-col items-center space-y-4">
          <Illustration illustration="boxEmpty" className="size-20" />
          <div className="space-y-4 text-center">
            <h4 className="text-2xl font-bold text-gray-900">
              {t('orders.table.empty_state.no_order.no_return_order')}
            </h4>
            <Button type="button" onClick={() => resetList(TabEntry.Available)}>
              {t('orders.table.empty_state.no_order.view_all_orders')}
            </Button>
          </div>
        </div>
        <Alert
          size="small"
          title={t('orders.table.empty_state.no_order.tips_view_all_returns')}
          icon="information"
          className="max-w-[33rem]"
        >
          <p className="text-sm text-gray-700">
            {t('orders.table.empty_state.no_order.tips_filter_content')}
          </p>
        </Alert>
      </div>
    );
  }

  return (
    <div className="mt-20 flex w-full flex-col items-center space-y-4">
      <Illustration illustration="boxEmpty" className="size-20" />
      <div className="space-y-1 text-center">
        <h4 className="text-2xl font-bold text-gray-900">
          {t('orders.table.empty_state.no_order.no_order')}
        </h4>
      </div>
    </div>
  );
};
