type PdfProps = React.ComponentPropsWithoutRef<'svg'>;

export const Pdf: React.FC<PdfProps> = ({ ...props }: PdfProps) => {
  return (
    <svg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_2188_17571)">
        <rect
          width="40"
          height="40"
          transform="translate(0 0.5)"
          fill="white"
        />
        <path
          d="M7 7.5C7 5.84315 8.34315 4.5 10 4.5H26L33 11.5V33.5C33 35.1569 31.6569 36.5 30 36.5H10C8.34315 36.5 7 35.1569 7 33.5V7.5Z"
          fill="#D8D9DA"
        />
        <path
          d="M26 8.5V4.5L33 11.5H29C27.3431 11.5 26 10.1569 26 8.5Z"
          fill="#8A8D8F"
        />
        <path
          d="M11.6898 23.5V16.5H14.4798C15.8798 16.5 16.8698 17.32 16.8698 18.71C16.8698 20.07 15.9598 20.9 14.4898 20.9H12.8698V23.5H11.6898ZM12.8698 19.81H14.4598C15.1198 19.81 15.6598 19.42 15.6598 18.71C15.6598 17.88 15.0598 17.59 14.4498 17.59H12.8698V19.81ZM19.0027 22.41H20.0327C21.0827 22.41 21.8827 21.94 21.8827 19.99C21.8827 18.29 21.1227 17.59 20.0927 17.59H19.0027V22.41ZM17.8227 23.5V16.5H20.1127C21.6627 16.5 23.1427 17.57 23.1427 19.99C23.1427 22.67 21.6027 23.5 20.0527 23.5H17.8227ZM24.3949 23.5V16.5H28.7649V17.59H25.5749V19.66H28.4749V20.7H25.5749V23.5H24.3949Z"
          fill="#434647"
        />
      </g>
      <defs>
        <clipPath id="clip0_2188_17571">
          <rect
            width="40"
            height="40"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
