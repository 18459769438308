import { useTranslation } from 'react-i18next';

import { useAtomValue } from 'jotai';

import { activeSaleState } from '../../stores/sales';
import type { BillingByVatV2 } from '../../types/generated-new';
import { InvoiceStatus, InvoiceType } from '../../types/generated-new';
import { formatPrice } from '../../utils/currency';
import { formatPercentage } from '../../utils/number';
import { BillingProductSummary } from './billing-product-summary';
import { DashedLine } from './dashed-line';
import { useBilling } from './use-billing';
import { useInvoices } from './use-invoices';

type DownPaymentSummaryProps = {
  isSubjectVat: boolean;
};

export const DownPaymentSummary: React.FC<DownPaymentSummaryProps> = ({
  isSubjectVat,
}) => {
  const { data: billing } = useBilling();
  const { data: invoices } = useInvoices();
  const { i18n, t } = useTranslation();

  const activeSale = useAtomValue(activeSaleState);

  const locale = i18n.language === 'fr' ? 'fr' : 'en';

  if (
    !billing ||
    billing.down_payment?.__typename !== 'DownPaymentClassic' ||
    (billing.down_payment?.__typename === 'DownPaymentClassic' &&
      billing.down_payment.rules.some((rule) => !rule.isValidated)) ||
    invoices
      ?.filter((invoice) => invoice.type === InvoiceType.DownPayment)
      .some(
        (invoice) =>
          invoice.status === InvoiceStatus.Validated ||
          invoice.status === InvoiceStatus.ToBePaid ||
          invoice.status === InvoiceStatus.Paid
      )
  ) {
    return null;
  }

  return (
    <div className="flex flex-col gap-4">
      <h3 className="text-xl font-bold">
        {t('invoice.down_payment_summary.down_payment_details')}
      </h3>
      <div className="relative overflow-hidden rounded-lg border border-gray-100">
        <div className="space-y-8 py-6">
          {billing.down_payment.financialDetail.agg_by_vat_rate
            .filter((vat_rate): vat_rate is BillingByVatV2 => !!vat_rate)
            .map((billingByVat) => (
              <div key={billingByVat.vat_rate}>
                <div className="px-6 pb-2 text-xs font-semibold uppercase tracking-wider text-gray-500">
                  {t('invoice.products')}{' '}
                  {billingByVat.vat_rate !== '0' ? (
                    <>
                      {t('invoice.tva')} (
                      {formatPercentage(
                        parseFloat(billingByVat.vat_rate) / 100,
                        locale
                      )}
                      )
                    </>
                  ) : null}
                </div>

                <BillingProductSummary
                  product_stats={billingByVat}
                  isInternalUser={false}
                  isSubjectVat={isSubjectVat}
                  locale={locale}
                />
              </div>
            ))}

          <div>
            <div className="px-6 pb-2 text-xs font-semibold uppercase tracking-wider text-gray-500">
              {t('invoice.delivery')}{' '}
              {isSubjectVat ? formatPercentage(20 / 100, locale) : null}
            </div>
            <div className="flex justify-between space-x-2 px-6 py-2 text-base leading-5.5 text-gray-700 transition-colors duration-300 hover:bg-gray-50">
              <div className="flex shrink-0 items-center">
                <span>{t('invoice.amount_billing_ht')}</span>
              </div>
              <div className="flex w-full items-end pb-1.5">
                <DashedLine />
              </div>
              <div className="shrink-0">
                {formatPrice(
                  billing.down_payment.financialDetail.total_delivery_ht / 100,
                  locale,
                  activeSale?.currency.toString().toUpperCase() ?? 'EUR'
                )}
              </div>
            </div>
            {isSubjectVat ? (
              <div className="flex justify-between space-x-2 px-6 py-2 text-base leading-5.5 text-gray-700 transition-colors duration-300 hover:bg-gray-50">
                <div className="flex shrink-0 items-center">
                  <span>
                    {t('invoice.delivery_vat')} (
                    {formatPercentage(20 / 100, locale)})
                  </span>
                </div>
                <div className="flex w-full items-end pb-1.5">
                  <DashedLine />
                </div>
                <div className="shrink-0">
                  {formatPrice(
                    (billing.down_payment.financialDetail.total_delivery_ttc -
                      billing.down_payment.financialDetail.total_delivery_ht) /
                      100,
                    locale,
                    activeSale?.currency.toString().toUpperCase() ?? 'EUR'
                  )}
                </div>
              </div>
            ) : null}
            {isSubjectVat ? (
              <div className="flex justify-between space-x-2 px-6 py-2 text-base leading-5.5 text-gray-700 transition-colors duration-300 hover:bg-gray-50">
                <div className="flex shrink-0 items-center">
                  <span>{t('invoice.amount_billing_ttc')}</span>
                </div>
                <div className="flex w-full items-end pb-1.5">
                  <DashedLine />
                </div>
                <div className="shrink-0">
                  {formatPrice(
                    billing.down_payment.financialDetail.total_delivery_ttc /
                      100,
                    locale,
                    activeSale?.currency.toString().toUpperCase() ?? 'EUR'
                  )}
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="space-y-2 border-t border-gray-100 bg-[#FBFBFB] py-6">
          {isSubjectVat ? (
            <div className="flex justify-between space-x-2 px-6 text-gray-700">
              <div>{t('invoice.total_billing_ht')}</div>
              <div>
                {formatPrice(
                  billing.down_payment.financialDetail.total_billing_ht / 100,
                  locale,
                  activeSale?.currency.toString().toUpperCase() ?? 'EUR'
                )}
              </div>
            </div>
          ) : null}
          {isSubjectVat ? (
            <div className="flex justify-between space-x-2 px-6 text-gray-700">
              <div>{t('invoice.total_vat')}</div>
              <div>
                {formatPrice(
                  billing.down_payment.financialDetail.agg_by_vat_rate.reduce(
                    (acc, value) => acc + value.total_vat,
                    0
                  ) /
                    100 +
                    (billing.down_payment.financialDetail.total_delivery_ttc -
                      billing.down_payment.financialDetail.total_delivery_ht) /
                      100,
                  locale,
                  activeSale?.currency.toString().toUpperCase() ?? 'EUR'
                )}
              </div>
            </div>
          ) : null}
          <div className="flex justify-between space-x-2 px-6 text-2xl font-bold text-gray-900">
            <div>
              {isSubjectVat
                ? t('invoice.total_down_payment_ttc')
                : t('invoice.total_down_payment_ht')}
            </div>
            <div className="shrink-0">
              {formatPrice(
                (isSubjectVat
                  ? billing.down_payment.financialDetail.total_billing_ttc
                  : billing.down_payment.financialDetail.total_billing_ht) /
                  100,
                locale,
                activeSale?.currency.toString().toUpperCase() ?? 'EUR'
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
