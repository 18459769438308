import { useTranslation } from 'react-i18next';

import { useAtomValue } from 'jotai';

import { activeSaleState } from '../../stores/sales';
import type { BillingByVatV2 } from '../../types/generated-new';
import { formatPrice } from '../../utils/currency';
import { formatPercentage } from '../../utils/number';
import { hasBillingErrors } from '../../utils/sales';
import { AdminWithTooltip } from './admin-with-tooltip';
import { BillingProductSummary } from './billing-product-summary';
import { DashedLine } from './dashed-line';
import { useBilling } from './use-billing';

type BillingSummaryProps = {
  isInternalUser: boolean;
  isSubjectVat: boolean;
};

export const BillingSummary: React.FC<BillingSummaryProps> = ({
  isInternalUser,
  isSubjectVat,
}: BillingSummaryProps) => {
  const { data: billing, error: billingError } = useBilling();
  const { i18n, t } = useTranslation();

  const activeSale = useAtomValue(activeSaleState);

  const locale = i18n.language === 'fr' ? 'fr' : 'en';

  const hasBillingError = hasBillingErrors(billingError, billing);

  if (!billing || (hasBillingError && !(billing.has_error && isInternalUser))) {
    return null;
  }

  return (
    <div className="relative overflow-hidden rounded-lg border border-gray-100">
      <div className="space-y-8 py-6">
        {billing.agg_by_vat_rate
          .filter((vat_rate): vat_rate is BillingByVatV2 => !!vat_rate)
          .map((billingByVat) => (
            <div key={billingByVat.vat_rate}>
              <div className="px-6 pb-2 text-xs font-semibold uppercase tracking-wider text-gray-500">
                {t('invoice.products')}{' '}
                {billingByVat.vat_rate !== '0' ? (
                  <>
                    {t('invoice.tva')} (
                    {formatPercentage(
                      parseFloat(billingByVat.vat_rate) / 100,
                      locale
                    )}
                    )
                  </>
                ) : null}
              </div>

              <BillingProductSummary
                product_stats={billingByVat}
                isInternalUser={isInternalUser}
                isSubjectVat={isSubjectVat}
                locale={locale}
              />
            </div>
          ))}

        <div>
          <div className="px-6 pb-2 text-xs font-semibold uppercase tracking-wider text-gray-500">
            {t('invoice.delivery')}{' '}
            {isSubjectVat ? formatPercentage(20 / 100, locale) : null}
          </div>
          <div className="flex justify-between space-x-2 px-6 py-2 text-base leading-5.5 text-gray-700 transition-colors duration-300 hover:bg-gray-50">
            <div className="flex shrink-0 items-center">
              <span>{t('invoice.amount_billing_ht')}</span>
            </div>
            <div className="flex w-full items-end pb-1.5">
              <DashedLine />
            </div>
            <div className="shrink-0">
              {formatPrice(
                billing.total_delivery_ht / 100,
                locale,
                activeSale?.currency.toString().toUpperCase() ?? 'EUR'
              )}
            </div>
          </div>
          {isSubjectVat ? (
            <div className="flex justify-between space-x-2 px-6 py-2 text-base leading-5.5 text-gray-700 transition-colors duration-300 hover:bg-gray-50">
              <div className="flex shrink-0 items-center">
                <span>
                  {t('invoice.delivery_vat')} (
                  {formatPercentage(20 / 100, locale)})
                </span>
              </div>
              <div className="flex w-full items-end pb-1.5">
                <DashedLine />
              </div>
              <div className="shrink-0">
                {formatPrice(
                  (billing.total_delivery_ttc - billing.total_delivery_ht) /
                    100,
                  locale,
                  activeSale?.currency.toString().toUpperCase() ?? 'EUR'
                )}
              </div>
            </div>
          ) : null}
          {isSubjectVat ? (
            <div className="flex justify-between space-x-2 px-6 py-2 text-base leading-5.5 text-gray-700 transition-colors duration-300 hover:bg-gray-50">
              <div className="flex shrink-0 items-center">
                <span>{t('invoice.amount_billing_ttc')}</span>
              </div>
              <div className="flex w-full items-end pb-1.5">
                <DashedLine />
              </div>
              <div className="shrink-0">
                {formatPrice(
                  billing.total_delivery_ttc / 100,
                  locale,
                  activeSale?.currency.toString().toUpperCase() ?? 'EUR'
                )}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="space-y-2 border-t border-gray-100 bg-[#FBFBFB] py-6">
        {isInternalUser ? (
          <div className="flex justify-between space-x-2 px-6 text-gray-700">
            <div className="flex items-center space-x-2">
              <span>{t('invoice.total_operation_ht')}</span>
              <AdminWithTooltip />
            </div>
            <div>
              {formatPrice(
                billing.total_operation_ht / 100,
                locale,
                activeSale?.currency.toString().toUpperCase() ?? 'EUR'
              )}
            </div>
          </div>
        ) : null}
        {isInternalUser ? (
          <div className="flex justify-between space-x-2 px-6 text-gray-700">
            <div className="flex items-center space-x-2">
              <span>{t('invoice.total_refunded_ht')}</span>
              <AdminWithTooltip />
            </div>
            <div>
              {formatPrice(
                -billing.total_refund_ht / 100,
                locale,
                activeSale?.currency.toString().toUpperCase() ?? 'EUR'
              )}
            </div>
          </div>
        ) : null}
        {isInternalUser ? (
          <div className="flex justify-between space-x-2 px-6 text-gray-700">
            <div className="flex items-center space-x-2">
              <span>{t('invoice.total_choose_margin_ht')}</span>
              <AdminWithTooltip />
            </div>
            <div>
              {formatPrice(
                -billing.total_commission_ht / 100,
                locale,
                activeSale?.currency.toString().toUpperCase() ?? 'EUR'
              )}
            </div>
          </div>
        ) : null}
        {isSubjectVat ? (
          <div className="flex justify-between space-x-2 px-6 font-semibold text-gray-900">
            <div>{t('invoice.total_billing_ht')}</div>
            <div>
              {formatPrice(
                billing.total_billing_ht / 100,
                locale,
                activeSale?.currency.toString().toUpperCase() ?? 'EUR'
              )}
            </div>
          </div>
        ) : null}
        {isSubjectVat ? (
          <div className="flex justify-between space-x-2 px-6 text-gray-700">
            <div>{t('invoice.total_vat')}</div>
            <div>
              {formatPrice(
                billing.agg_by_vat_rate.reduce(
                  (acc, value) => acc + value.total_vat,
                  0
                ) /
                  100 +
                  (billing.total_delivery_ttc - billing.total_delivery_ht) /
                    100,
                locale,
                activeSale?.currency.toString().toUpperCase() ?? 'EUR'
              )}
            </div>
          </div>
        ) : null}
        <div className="flex justify-between space-x-2 px-6 text-2xl font-bold text-gray-900">
          <div>
            {isSubjectVat ? t('invoice.total_ttc') : t('invoice.total')}
          </div>
          <div className="shrink-0">
            {formatPrice(
              (isSubjectVat
                ? billing.total_billing_ttc
                : billing.total_billing_ht) / 100,
              locale,
              activeSale?.currency.toString().toUpperCase() ?? 'EUR'
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
