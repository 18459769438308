import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  NavLink,
  useLocation,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import Flag from '@appchoose/flag';
import Icon from '@appchoose/icon';
import { useAuth0 } from '@auth0/auth0-react';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { useSessionStorageValue } from '@react-hookz/web';
import { useAtomValue, useSetAtom } from 'jotai';

import { useBrandsAccess } from '../../hooks/use-brands-access';
import { useSupportChat } from '../../hooks/use-support-chat';
import { brandState } from '../../stores/brand';
import {
  activeSaleIdState,
  activeSaleState,
  sortedSalesState,
} from '../../stores/sales';
import { Store } from '../../types/generated-new';
import type { BrandMatch } from '../../types/navigation';
import { SalesSelectorCurrentSale } from './sales-selector-current-sale';
import { SalesSelectorItem } from './sales-selector-item';

export const SalesSelector: React.FC = () => {
  const { user, logout } = useAuth0();
  const [, setSearchParams] = useSearchParams();
  const location = useLocation();
  const { setSupportPlatform } = useSupportChat();
  const brand = useAtomValue(brandState);
  const { t } = useTranslation();

  const { brandId } = useParams<BrandMatch>();

  const { set: setRedirectUri } = useSessionStorageValue<string>('redirectUri');

  const { data: brandAccessData } = useBrandsAccess();

  const sortedSales = useAtomValue(sortedSalesState);
  const activeSale = useAtomValue(activeSaleState);
  const setActiveSaleId = useSetAtom(activeSaleIdState);

  useEffect(() => {
    if (brandAccessData) {
      if (
        user &&
        user?.['https://appchoose.io/claims/isInternalUser'] === false &&
        !brandAccessData.find((brand) => brand.brandId === brandId)
      ) {
        setRedirectUri(
          `${location.pathname}/${location.search}${location.hash}`
        );
        logout({
          logoutParams: {
            returnTo: `${window.location.origin}/logout-successful`,
          },
        });
      }
    }
  }, [brandAccessData]);

  const onSelect = (saleId: string) => {
    // On change de vente : on reset les search params et on set le sid
    setSearchParams(
      new URLSearchParams({
        sid: saleId.slice(0, 5),
      })
    );

    setActiveSaleId(saleId);
    const activeSale = sortedSales?.find((sale) => sale.id === saleId);
    setSupportPlatform(activeSale);
  };

  if (sortedSales?.length === 0) {
    return null;
  }

  const otherDashboards =
    brandAccessData?.filter((brandAccess) => brandAccess.brandId !== brandId) ??
    [];
  const hasDifferentSaleStores =
    new Set(sortedSales?.map((sale) => sale.store)).size > 1;
  const hasDifferentDashboardStores =
    new Set(brandAccessData?.map((brand) => brand.store)).size > 1;

  return (
    <DropdownMenuPrimitive.Root>
      <DropdownMenuPrimitive.Trigger className="w-full">
        <SalesSelectorCurrentSale
          sale={activeSale}
          showFlag={hasDifferentSaleStores}
        />
      </DropdownMenuPrimitive.Trigger>
      <DropdownMenuPrimitive.Content
        align="start"
        alignOffset={-16}
        sideOffset={16}
        className="z-20 max-h-96 min-w-[17rem] overflow-y-auto rounded-lg border border-gray-300 bg-white data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2"
      >
        {otherDashboards.length > 0
          ? otherDashboards.map((brand) => (
              <DropdownMenuPrimitive.Item
                key={brand.brandId}
                className="border-b border-gray-300 outline-none hover:bg-gray-100 focus:bg-gray-100"
                asChild
              >
                <NavLink
                  className="flex items-center justify-between gap-12 px-4 py-6"
                  to={`/${brand.brandId}/settings/account`}
                  target="_blank"
                >
                  <div className="items-center space-x-2 truncate text-base font-semibold text-gray-700">
                    <span>{brand.brandName}</span>
                    {hasDifferentDashboardStores ? (
                      <Flag
                        flag={brand.store === Store.Us ? 'US' : 'EU'}
                        viewBox="0 0 24 24"
                        className="inline h-[22px] w-[18px]"
                      />
                    ) : null}
                  </div>
                  <div>
                    <div className="flex cursor-pointer items-center gap-2">
                      <div className="text-sm font-semibold text-green-900">
                        {t('settings.account_tabs.change_dashboard')}
                      </div>
                      <Icon
                        className="shrink-0 text-green-900"
                        icon="externalLinkSimple"
                        size="large"
                      />
                    </div>
                  </div>
                </NavLink>
              </DropdownMenuPrimitive.Item>
            ))
          : null}

        <DropdownMenuPrimitive.Group>
          {otherDashboards.length > 0 ? (
            <DropdownMenuPrimitive.Label className="px-4 pt-6 font-semibold text-gray-700">
              {brand?.name}
            </DropdownMenuPrimitive.Label>
          ) : null}
          {sortedSales?.map((sale) => (
            <DropdownMenuPrimitive.Item
              key={sale.id}
              onSelect={() => onSelect(sale.id)}
              className="flex cursor-pointer items-center gap-4 border-b border-gray-300 p-4 outline-none last:border-none hover:bg-gray-100 focus:bg-gray-100"
            >
              <SalesSelectorItem
                sale={sale}
                showFlag={hasDifferentSaleStores}
              />
            </DropdownMenuPrimitive.Item>
          ))}
        </DropdownMenuPrimitive.Group>
      </DropdownMenuPrimitive.Content>
    </DropdownMenuPrimitive.Root>
  );
};
