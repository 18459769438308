import { isAfter, isBefore } from 'date-fns';
import { atom } from 'jotai';

import type { Sale } from '../types/generated';
import { serverTimeState } from './brand';

export const salesState = atom<Sale[] | undefined>(undefined);

export const activeSaleIdState = atom<string | undefined>(undefined);

export const shortActiveSaleIdState = atom((get) => {
  const activeSaleId = get(activeSaleIdState);

  return activeSaleId?.slice(0, 5) ?? '';
});

export const sortedSalesState = atom((get) => {
  const list = get(salesState);

  return list
    ?.slice()
    .sort((a, b) =>
      new Date(a.start_at ?? '').getTime() >
      new Date(b.start_at ?? '').getTime()
        ? -1
        : 1
    );
});

export const activeSaleState = atom((get) => {
  const list = get(salesState);
  const activeSaleId = get(activeSaleIdState);

  return list?.find((sale) => sale.id === activeSaleId);
});

export const getInitialActiveSaleId = (
  queryParamSaleId: string | undefined,
  sales: Sale[] | undefined,
  serverTime: Date
) => {
  if (queryParamSaleId)
    return sales?.find((sale) => sale.id.startsWith(queryParamSaleId))?.id;

  const startedSales =
    sales
      ?.filter((sale) => isAfter(serverTime, new Date(sale.start_at ?? '')))
      .sort((a, b) =>
        new Date(a.start_at ?? '').getTime() >
        new Date(b.start_at ?? '').getTime()
          ? -1
          : 1
      ) ?? [];
  if (startedSales.length > 0 && startedSales[0]) {
    return startedSales[0].id;
  }

  if (sales && sales.length > 0 && sales[0]) return sales[0].id;
};

export const isWaitingForSaleState = atom((get) => {
  const activeSale = get(activeSaleState);
  const serverTime = get(serverTimeState);

  return !!activeSale && isFuture(activeSale, serverTime);
});

export const activeSaleWidgetsState = atom((get) => {
  const activeSale = get(activeSaleState);
  const serverTime = get(serverTimeState);

  if (
    activeSale &&
    isCurrent(activeSale, serverTime) &&
    !isEnded(activeSale, serverTime)
  ) {
    if (Array.isArray(activeSale.widgets) && activeSale.widgets.length) {
      return activeSale.widgets
        .filter((widget) => !!widget)
        .filter((c) => !c.state);
    }
  }
  return undefined;
});

export const isFuture = (sale: Sale, currentDate: Date | undefined) => {
  return isBefore(currentDate ?? new Date(), new Date(sale.start_at ?? ''));
};

export const isCurrent = (sale: Sale, currentDate: Date | undefined) => {
  return (
    isAfter(currentDate ?? new Date(), new Date(sale.start_at ?? '')) &&
    isBefore(currentDate ?? new Date(), new Date(sale.end_at ?? ''))
  );
};

export const isEnded = (sale: Sale, currentDate: Date | undefined) => {
  return isAfter(currentDate ?? new Date(), new Date(sale.end_at ?? ''));
};
