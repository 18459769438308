import { useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { useQueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';

import { activeSaleState } from '../stores/sales';
import type { InvoiceUpdatedSubscription } from '../types/generated';
import { InvoiceUpdatedDocument } from '../types/generated';
import { getSubscriptionClient } from './subscription-client';

export const useInvoiceUpdatedSubscription = () => {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();
  const activeSale = useAtomValue(activeSaleState);

  useEffect(() => {
    const client = getSubscriptionClient(getAccessTokenSilently);
    const subscribe = () => {
      client
        .request({
          query: InvoiceUpdatedDocument,
        })
        .subscribe({
          next(response) {
            if (!activeSale) return;

            const data = response.data as InvoiceUpdatedSubscription;

            if (!data.invoiceUpdated) return;

            queryClient.invalidateQueries({
              queryKey: ['invoices'],
            });
          },
        });
    };
    subscribe();
    return () => {
      client.close();
    };
  }, [queryClient, activeSale]);
};
