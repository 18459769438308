import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@appchoose/form';
import Input from '@appchoose/input';
import Select, {
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@appchoose/select';

export const getDefaultDeliveryDelay = ({
  min,
  max,
}: {
  min: number | null | undefined;
  max: number | null | undefined;
}) => {
  if (
    (min === null || min === undefined) &&
    (max === null || max === undefined)
  ) {
    return undefined;
  }
  if (min === 3 && max === 5) return '3-5';
  if (min === 5 && max === 7) return '5-7';
  if (min === 8 && max === 10) return '8-10';
  if (min === 10 && max === 12) return '10-12';
  return 'custom';
};

type ShippingDelayFormFieldsProps = {
  autoFocus?: boolean;
};

export type ShippingDelayFormData = {
  deliveryDelays: string;
  deliveryDelaysMin: number;
  deliveryDelaysMax: number;
  haveDifferentDeliveryTimeProducts: boolean;
  havePreOrderProducts: boolean;
};

export const ShippingDelayFormFields: React.FC<
  ShippingDelayFormFieldsProps
> = ({ autoFocus }) => {
  const { t } = useTranslation();

  const form = useFormContext<ShippingDelayFormData>();

  const deliveryDelayOptions = [
    {
      value: '3-5',
      min: 3,
      max: 5,
      label: t('onboarding.shipping.delivery_delays.x_days', {
        interval: '3-5',
      }),
    },
    {
      value: '5-7',
      min: 5,
      max: 7,
      label: t('onboarding.shipping.delivery_delays.x_days', {
        interval: '5-7',
      }),
    },
    {
      value: '8-10',
      min: 8,
      max: 10,
      label: t('onboarding.shipping.delivery_delays.x_days', {
        interval: '8-10',
      }),
    },
    {
      value: '10-12',
      min: 10,
      max: 12,
      label: t('onboarding.shipping.delivery_delays.x_days', {
        interval: '10-12',
      }),
    },
    {
      value: 'custom',
      min: 0,
      max: 0,
      label: t('onboarding.shipping.delivery_delays.custom_interval'),
    },
  ];

  const DeliveryDelaysMinErrorMessage =
    (form.formState.errors.deliveryDelaysMin?.type === 'required' &&
      `${t(
        'onboarding.shipping.delivery_delays_min.validation_errors.required'
      )}`) ||
    (form.formState.errors.deliveryDelaysMin?.type === 'min' &&
      `${t('onboarding.shipping.delivery_delays_min.validation_errors.min', {
        min: '0',
      })}`) ||
    (form.formState.errors.deliveryDelaysMin?.type === 'minLowerThanMax' &&
      `${t(
        'onboarding.shipping.delivery_delays_min.validation_errors.min_lower_than_max'
      )}`);

  const DeliveryDelaysMaxErrorMessage =
    (form.formState.errors.deliveryDelaysMax?.type === 'required' &&
      `${t(
        'onboarding.shipping.delivery_delays_max.validation_errors.required'
      )}`) ||
    (form.formState.errors.deliveryDelaysMax?.type === 'min' &&
      `${t('onboarding.shipping.delivery_delays_max.validation_errors.min', {
        min: '0',
      })}`);

  form.watch('deliveryDelays');

  return (
    <>
      <FormField
        control={form.control}
        name="deliveryDelays"
        rules={{
          required: true,
        }}
        render={({ field }) => (
          <FormItem>
            <Select
              value={field.value}
              onValueChange={(value) => {
                field.onChange(value);
                const selectedOption = deliveryDelayOptions.find(
                  (option) => option.value === value
                );
                if (selectedOption && selectedOption.value !== 'custom') {
                  form.setValue('deliveryDelaysMin', selectedOption.min);
                  form.setValue('deliveryDelaysMax', selectedOption.max);
                }
              }}
            >
              <FormControl>
                <SelectTrigger autoFocus>
                  <SelectValue
                    placeholder={t(
                      'onboarding.shipping.delivery_delays.placeholder'
                    )}
                  />
                </SelectTrigger>
              </FormControl>
              <SelectContent
                ref={(ref) => {
                  if (!ref) return;
                  ref.addEventListener('touchstart', (e: TouchEvent) =>
                    e.preventDefault()
                  );
                }}
              >
                {deliveryDelayOptions.map((option) => (
                  <SelectItem key={option.value} value={option.value}>
                    {option.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <FormMessage match="required">
              {t(
                'onboarding.shipping.delivery_delays.validation_errors.required'
              )}
            </FormMessage>
          </FormItem>
        )}
      />
      {form.getValues('deliveryDelays') === 'custom' ? (
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2 text-gray-900">
            <div className="w-14">
              <FormField
                control={form.control}
                name="deliveryDelaysMin"
                rules={{
                  required: true,
                  min: 0,
                  validate: {
                    minLowerThanMax: (value) => {
                      // Delay minLowerThanMax validation until deliveryDelaysMin and deliveryDelaysMax are filled
                      if (!value || !form.getValues('deliveryDelaysMax'))
                        return true;
                      return value < form.getValues('deliveryDelaysMax');
                    },
                  },
                  deps: ['deliveryDelaysMax'],
                }}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="sr-only">
                      {t('onboarding.shipping.delivery_delays_min.label')}
                    </FormLabel>
                    <FormControl className="-mt-2">
                      <Input
                        type="number"
                        step={1}
                        min={0}
                        inputMode="numeric"
                        autoFocus={autoFocus}
                        {...field}
                        onChange={(event) => {
                          field.onChange(
                            !isNaN(event.target.valueAsNumber)
                              ? event.target.valueAsNumber
                              : null
                          );
                        }}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>
            <span>{t('to')}</span>
            <div className="w-14">
              <FormField
                control={form.control}
                name="deliveryDelaysMax"
                rules={{
                  required: true,
                  min: 1,
                  deps: ['deliveryDelaysMin'],
                }}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="sr-only">
                      {t('onboarding.shipping.delivery_delays_max.label')}
                    </FormLabel>
                    <FormControl className="-mt-2">
                      <Input
                        type="number"
                        step={1}
                        min={0}
                        inputMode="numeric"
                        {...field}
                        onChange={(event) => {
                          field.onChange(
                            !isNaN(event.target.valueAsNumber)
                              ? event.target.valueAsNumber
                              : null
                          );
                        }}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>
            <span>{t('calendar_days')}.</span>
          </div>
          <div className="text-xs text-red-600">
            <p>{DeliveryDelaysMinErrorMessage}</p>
            <p>{DeliveryDelaysMaxErrorMessage}</p>
          </div>
        </div>
      ) : null}
    </>
  );
};
