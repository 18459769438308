/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import type { Address } from '@appchoose/address';
import { getCountryFromCountryCode } from '@appchoose/address';
import AddressFormFields from '@appchoose/address-form-fields';
import Button from '@appchoose/button';
import cn from '@appchoose/cn';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@appchoose/command';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from '@appchoose/form';
import Icon from '@appchoose/icon';
import { Popover, PopoverContent, PopoverTrigger } from '@appchoose/popover';
import { useQueryClient } from '@tanstack/react-query';
import i18n from 'i18next';
import { useAtom } from 'jotai';
import { matchSorter } from 'match-sorter';

import { brandState } from '../../stores/brand';
import { StoreRegion, useUpdateSellerMutation } from '../../types/generated';
import { getCountryOptions } from '../../utils/address-options';
import type { SelectedCourier } from '../onboarding/onboarding-step-shipping/onboarding-step-shipping-delivery-service';
import { useVisibleCouriers } from '../onboarding/onboarding-step-shipping/use-default-couriers';
import { ShippingDelayAdditionalInfoFormFields } from '../shipping-delay-additional-info-form-fields/shipping-delay-additonal-info-form-fields';
import {
  ShippingDelayFormFields,
  getDefaultDeliveryDelay,
} from '../shipping-delay-form-fields/shipping-delay-form-fields';
import { ShippingServiceFields } from '../shipping-service-fields/shipping-service-fields';

type SettingsDeliveryProps = {
  onPostSave: () => void;
};

export type SettingsDeliveryFormData = {
  services: SelectedCourier[];
  shipping_country: string;
  deliveryDelaysMin: number | undefined;
  deliveryDelaysMax: number | undefined;
  haveDifferentDeliveryTimeProducts: boolean;
  havePreOrderProducts: boolean;
} & Address;

export const SettingsDelivery: React.FC<SettingsDeliveryProps> = ({
  onPostSave,
}) => {
  const [brand, setBrand] = useAtom(brandState);
  const { t } = useTranslation();
  const { getVisibleCouriers } = useVisibleCouriers();
  const queryClient = useQueryClient();

  const { mutateAsync: updateSellerMutation } = useUpdateSellerMutation();

  const countryOptions = getCountryOptions();

  const [open, setOpen] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');
  const listRef = useRef<HTMLDivElement>(null);
  const scrollId = useRef<number>();

  const countryMatches = useMemo(
    () => matchSorter(countryOptions, searchQuery, { keys: ['label'] }),
    [countryOptions, searchQuery]
  );

  const form = useForm<SettingsDeliveryFormData>({
    mode: 'onTouched',
    defaultValues: async () => ({
      shipping_country: brand?.shipping_country ?? '',
      services: await getVisibleCouriers(),
      name: brand?.return_address?.name ?? '',
      street: brand?.return_address?.street ?? '',
      street2: brand?.return_address?.street2 ?? '',
      bp: brand?.return_address?.bp ?? '',
      city: brand?.return_address?.city ?? '',
      province: brand?.return_address?.province ?? '',
      country:
        brand?.return_address?.country ??
        getCountryFromCountryCode(
          (brand?.return_address?.countryCode as string | null) ?? ''
        )?.fr ??
        (brand?.store === StoreRegion.Us
          ? getCountryFromCountryCode('US').en
          : getCountryFromCountryCode('FR').fr),
      countryCode:
        brand?.return_address?.countryCode ??
        (brand?.store === StoreRegion.Us ? 'US' : 'FR'),
      deliveryDelays: getDefaultDeliveryDelay({
        min: brand?.min_delivery_delay,
        max: brand?.max_delivery_delay,
      }),
      deliveryDelaysMin: brand?.min_delivery_delay ?? undefined,
      deliveryDelaysMax: brand?.max_delivery_delay ?? undefined,
      haveDifferentDeliveryTimeProducts:
        brand?.has_mixed_delivery_delay ?? false,
      havePreOrderProducts: brand?.has_pre_order_delivery ?? false,
    }),
  });

  const onSubmit = (data: SettingsDeliveryFormData) => {
    if (!brand) return;
    const {
      name,
      street,
      street2,
      bp,
      city,
      country,
      countryCode,
      province,
      shipping_country,
      deliveryDelaysMax,
      deliveryDelaysMin,
    } = data;
    const returnAddress = {
      name,
      street,
      street2,
      bp,
      city,
      country,
      countryCode,
      province,
    };
    setBrand({
      ...brand,
      min_delivery_delay: deliveryDelaysMin,
      max_delivery_delay: deliveryDelaysMax,
      has_mixed_delivery_delay: data.haveDifferentDeliveryTimeProducts,
      has_pre_order_delivery: data.havePreOrderProducts,
      shipping_country: shipping_country,
      return_address: returnAddress,
    });
    updateSellerMutation({
      updateSeller: {
        return_address: returnAddress,
        shipping_country: shipping_country,
        min_delivery_delay: deliveryDelaysMin,
        max_delivery_delay: deliveryDelaysMax,
        has_mixed_delivery_delay: data.haveDifferentDeliveryTimeProducts,
        has_pre_order_delivery: data.havePreOrderProducts,
        used_couriers: data.services
          .filter((service) => service.selected === true)
          .map((service) => service.slug),
      },
    });
    queryClient.invalidateQueries({
      queryKey: ['usedCouriers'],
    });
    onPostSave();
  };

  form.watch('shipping_country');

  return (
    <div className="max-w-2.5xl pb-6">
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-col gap-10"
        >
          <section className="flex flex-col gap-6">
            <h3 className="text-xl font-bold leading-[1.875rem] text-gray-900">
              {t('settings.delivery_tabs.carriers')}
            </h3>

            <FormField
              control={form.control}
              name="shipping_country"
              render={({ field }) => {
                const selectedCountryOption = countryOptions.find(
                  (country) => country.value === field.value
                );
                return (
                  <FormItem>
                    <FormLabel>{t('settings.delivery_tabs.country')}</FormLabel>
                    <Popover open={open} onOpenChange={setOpen}>
                      <PopoverTrigger asChild>
                        <FormControl>
                          <button
                            type="button"
                            role="combobox"
                            aria-expanded={open}
                            className='form-input flex items-center justify-between rounded border-gray-500 text-sm leading-5.5 text-gray-900 transition duration-300 hover:border-gray-700 focus:border-gray-700 focus:ring-1 focus:ring-gray-700 aria-[invalid="true"]:border-red-600 aria-[invalid="true"]:ring-red-600'
                          >
                            <div className="flex items-center truncate">
                              {field.value
                                ? selectedCountryOption?.leftSection
                                : null}
                              <span className="truncate">
                                {field.value
                                  ? selectedCountryOption?.label
                                  : t('address.fields.country.empty_field')}
                              </span>
                            </div>
                            <Icon
                              icon={open ? 'close' : 'arrowDown'}
                              className="ml-2 size-4 shrink-0 opacity-50"
                            />
                          </button>
                        </FormControl>
                      </PopoverTrigger>
                      <PopoverContent className="p-0" align="start">
                        <Command shouldFilter={false}>
                          <CommandInput
                            placeholder={t(
                              'address.fields.country.placeholder_search'
                            )}
                            onValueChange={(value) => {
                              setSearchQuery(value);

                              //#region scroll list to top when typing
                              // https://github.com/pacocoursey/cmdk/issues/234
                              // https://github.com/pacocoursey/cmdk/issues/233

                              // clear pending scroll
                              if (scrollId.current)
                                cancelAnimationFrame(scrollId.current);

                              // the setTimeout is used to create a new task
                              // this is to make sure that we don't scroll until the user is done typing
                              // you can tweak the timeout duration ofc
                              scrollId.current = requestAnimationFrame(() => {
                                // inside your list select the first group and scroll to the top
                                listRef.current?.scrollTo({ top: 0 });
                              });

                              //#endregion
                            }}
                            value={searchQuery}
                          />
                          <CommandList ref={listRef}>
                            <CommandEmpty>
                              {t('address.fields.country.no_results')}
                            </CommandEmpty>

                            <CommandGroup>
                              {countryMatches.map((country) => (
                                <CommandItem
                                  key={country.value}
                                  value={country.value}
                                  keywords={[country.label]}
                                  onSelect={(currentValue) => {
                                    field.onChange(
                                      currentValue === field.value
                                        ? ''
                                        : currentValue
                                    );

                                    setOpen(false);
                                  }}
                                  className={cn(
                                    'flex items-center justify-between',
                                    {
                                      'font-semibold text-green-900':
                                        field.value === country.value,
                                    }
                                  )}
                                >
                                  <div className="flex items-center truncate">
                                    {country.leftSection}

                                    <span className="truncate">
                                      {country.label}
                                    </span>
                                  </div>
                                  <Icon
                                    icon="check"
                                    className={cn(
                                      'ml-2 size-4 shrink-0',
                                      field.value === country.value
                                        ? 'opacity-100'
                                        : 'opacity-0'
                                    )}
                                  />
                                </CommandItem>
                              ))}
                            </CommandGroup>
                          </CommandList>
                        </Command>
                      </PopoverContent>
                    </Popover>
                  </FormItem>
                );
              }}
            />

            <div className="flex flex-col gap-6">
              <ShippingServiceFields
                shippingCountryCode={form.getValues('shipping_country') ?? 'FR'}
              />
            </div>
          </section>
          <section className="flex flex-col gap-8">
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-2">
                <p className="text-xl font-bold leading-[1.875rem] text-gray-900">
                  {t(
                    'onboarding.shipping.sub_steps.3.preparation_delivery_time'
                  )}
                </p>
                <p className="text-sm leading-5.5 text-gray-700">
                  {t('onboarding.shipping.sub_steps.3.order_processing_time')}
                </p>
              </div>
              <ShippingDelayFormFields />
            </div>
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-2">
                <p className="font-bold leading-5.5 text-gray-900">
                  {t('onboarding.shipping.sub_steps.3.additional_information')}
                </p>
              </div>
              <ShippingDelayAdditionalInfoFormFields />
            </div>
          </section>
          <section className="flex flex-col gap-6">
            <h3 className="text-xl font-bold leading-[1.875rem] text-gray-900">
              {t('settings.delivery_tabs.return')}
            </h3>
            <AddressFormFields
              googleMapsApiKey={
                import.meta.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? ''
              }
              locale={i18n.language}
              showCompany={true}
              showName={false}
              showPhone={false}
              translations={{
                suggestions: t('suggestions'),
                fieldsNameValidationErrorsRequired: t(
                  'address.fields.name.validation_errors.required'
                ),
                fieldsNameValidationErrorsMaxLength: t(
                  'address.fields.name.validation_errors.maxLength',
                  {
                    maxLength: '200',
                  }
                ),
                fieldsFirstnameValidationErrorsRequired: t(
                  'address.fields.firstname.validation_errors.required'
                ),
                fieldsFirstnameValidationErrorsMaxLength: t(
                  'address.fields.firstname.validation_errors.maxLength',
                  {
                    maxLength: '200',
                  }
                ),
                fieldsLastnameValidationErrorsRequired: t(
                  'address.fields.lastname.validation_errors.required'
                ),
                fieldsLastnameValidationErrorsMaxLength: t(
                  'address.fields.lastname.validation_errors.maxLength',
                  {
                    maxLength: '200',
                  }
                ),
                fieldsPhoneValidationErrorsRequired: t(
                  'address.fields.phone.validation_errors.required'
                ),
                fieldsPhoneValidationErrorsMaxLength: t(
                  'address.fields.phone.validation_errors.maxLength',
                  {
                    maxLength: '200',
                  }
                ),
                fieldsStreetValidationErrorsRequired: t(
                  'address.fields.street.validation_errors.required'
                ),
                fieldsStreetValidationErrorsMaxLength: t(
                  'address.fields.street.validation_errors.maxLength',
                  {
                    maxLength: '200',
                  }
                ),
                fieldsStreet2ValidationErrorsMaxLength: t(
                  'address.fields.street2.validation_errors.maxLength',
                  {
                    maxLength: '200',
                  }
                ),
                fieldsBpValidationErrorsRequired: t(
                  'address.fields.bp.validation_errors.required'
                ),
                fieldsBpValidationErrorsMaxLength: t(
                  'address.fields.bp.validation_errors.maxLength',
                  {
                    maxLength: '200',
                  }
                ),
                fieldsCityValidationErrorsRequired: t(
                  'address.fields.city.validation_errors.required'
                ),
                fieldsCityValidationErrorsMaxLength: t(
                  'address.fields.city.validation_errors.maxLength',
                  {
                    maxLength: '200',
                  }
                ),
                fieldsProvinceValidationErrorsRequired: t(
                  'address.fields.province.validation_errors.required'
                ),
                fieldsProvinceValidationErrorsMaxLength: t(
                  'address.fields.province.validation_errors.maxLength',
                  {
                    maxLength: '200',
                  }
                ),
                fieldsCountryValidationErrorsRequired: t(
                  'address.fields.country.validation_errors.required'
                ),
                fieldsNameLabel: t('address.fields.name.label_return'),
                fieldsNamePlaceholder: t('address.fields.name.placeholder'),
                fieldsFirstnameLabel: t('address.fields.firstname.label'),
                fieldsFirstnamePlaceholder: t(
                  'address.fields.firstname.placeholder'
                ),
                fieldsLastnameLabel: t('address.fields.lastname.label'),
                fieldsLastnamePlaceholder: t(
                  'address.fields.lastname.placeholder'
                ),
                fieldsPhoneLabel: t('address.fields.phone.label'),
                fieldsPhonePlaceholder: t('address.fields.phone.placeholder'),
                fieldsStreetLabel: t('address.fields.street.label'),
                fieldsStreetPlaceholder: t('address.fields.street.placeholder'),
                fieldsStreet2Label: t('address.fields.street2.label'),
                fieldsStreet2Placeholder: t(
                  'address.fields.street2.placeholder'
                ),
                fieldsBpLabel: t('address.fields.bp.label'),
                fieldsBpPlaceholder: '',
                fieldsCityLabel: t('address.fields.city.label'),
                fieldsCityPlaceholder: '',
                fieldsProvinceLabel: t('address.fields.province.label'),
                fieldsProvincePlaceholder: '',
                fieldsProvinceEmptyField: t(
                  'address.fields.province.empty_field'
                ),
                fieldsCountryLabel: t('address.fields.country.label'),
                fieldsCountryPlaceholder: t(
                  'address.fields.country.placeholder'
                ),
                fieldsCountryPlaceholderSearch: t(
                  'address.fields.country.placeholder_search'
                ),
                fieldsCountryEmptyField: t(
                  'address.fields.country.empty_field'
                ),
                fieldsCountryNoResults: t('address.fields.country.no_results'),
              }}
            />
          </section>
          <Button type="submit" className="self-start">
            {t('save')}
          </Button>
        </form>
      </Form>
    </div>
  );
};

SettingsDelivery.displayName = 'SettingsDelivery';
