import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Button from '@appchoose/button';
import Icon from '@appchoose/icon';
import Loader from '@appchoose/loader';
import { toast } from '@appchoose/toast';
import * as Sentry from '@sentry/react';

import { useCopyToClipboard } from '../../hooks/use-copy-to-clipboard';
import {
  useInviteCodeQuery,
  useUpdateInviteCodeMutation,
} from '../../types/generated-new';
import type { BrandMatch } from '../../types/navigation';

export const InviteLink = () => {
  const { brandId } = useParams<BrandMatch>();
  const { t } = useTranslation();

  const {
    data: inviteCode,
    isLoading: loadingInviteCode,
    error: errorInviteCode,
    refetch: refetchInviteCode,
  } = useInviteCodeQuery(undefined, {
    refetchOnWindowFocus: false,
  });
  const { mutateAsync: mutateUpdateInviteCode } = useUpdateInviteCodeMutation();
  const [clipboardState, copyToClipboard] = useCopyToClipboard();

  const inviteLink = `${window.location.origin}/${brandId}?invite-code=${inviteCode?.getInviteCode}`;

  useEffect(() => {
    const { value, error } = clipboardState;
    if (value) {
      toast.success(t('settings.member_tabs.invite_link.copy_feedback'));
    }
    if (error) {
      Sentry.captureException(error);
    }
  }, [clipboardState]);

  const handleGenerateNewLink = async () => {
    try {
      await mutateUpdateInviteCode({});
      refetchInviteCode();
      toast.success(
        t('settings.member_tabs.invite_link.generation_link_success')
      );
    } catch {
      toast.error(t('settings.member_tabs.invite_link.generation_link_error'));
    }
  };

  const handleCopyLink = () => {
    if (inviteLink) {
      copyToClipboard(inviteLink);
    }
  };

  return errorInviteCode ? (
    <div className="flex flex-col items-center p-10">
      <div className="mb-6 max-w-md text-center text-sm text-gray-700">
        {t('settings.member_tabs.invite_link.error_loading_invite')}
      </div>
      <Button
        appearance="primary"
        type="button"
        onClick={() => refetchInviteCode()}
      >
        {t('settings.member_tabs.retry')}
      </Button>
    </div>
  ) : (
    <div>
      <h3 className="mb-2 text-2xl font-bold">
        {t('settings.member_tabs.invite_link.title')}
      </h3>
      <div className="text-base text-gray-700">
        {t('settings.member_tabs.invite_link.main_info')}
      </div>
      <div className="text-base text-gray-700">
        {t('settings.member_tabs.invite_link.main_info_2')}
      </div>
      <div className="mb-8 text-base text-gray-700">
        {t('settings.member_tabs.invite_link.generate_link_1')}
        <span
          onClick={handleGenerateNewLink}
          className="cursor-pointer font-semibold text-green-900"
        >
          {t('settings.member_tabs.invite_link.generate_link_2')}
        </span>
        {t('settings.member_tabs.invite_link.generate_link_3')}
      </div>
      <div
        onClick={handleCopyLink}
        className="mb-12 flex cursor-pointer items-center justify-between rounded border border-gray-700 px-2 py-2.5"
      >
        <div className="truncate text-sm text-gray-900">{inviteLink}</div>
        <div className="flex shrink-0 items-center space-x-1">
          {loadingInviteCode ? (
            <Loader className="size-6" />
          ) : (
            <>
              <Icon icon="copy" className="text-green-900" />
              <div className="text-sm font-semibold text-green-900">
                {t('settings.member_tabs.invite_link.copy_link')}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
