import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useAtomValue } from 'jotai';

import Marion from '../../assets/img/Marion.png';
import {
  FaqAccordion,
  FaqAccordionContent,
  FaqAccordionItem,
  FaqAccordionTrigger,
} from '../../components/faq-accordion/faq-accordion';
import useTitle from '../../hooks/use-title';
import { brandState } from '../../stores/brand';
import { StoreRegion } from '../../types/generated';
import { track } from '../../utils/analytics';

import './faq-screen.scss';

export const FAQScreen: React.FC = () => {
  const brand = useAtomValue(brandState);
  const { t } = useTranslation();

  useTitle(`${brand?.name ?? ''} ~ FAQ`);

  const [ordersAccordion, setOrdersAccordion] = useState<string[]>([]);
  const [customerAccordion, setCustomerAccordion] = useState<string[]>([]);
  const [invoiceAccordion, setInvoiceAccordion] = useState<string[]>([]);

  return (
    <main className="faq content relative flex-1 overflow-y-auto focus:outline-none">
      <div className="m-4 md:m-10">
        <header>
          <h2 className="text-4xl font-bold leading-[3.75rem] text-gray-900">
            {t('sidebar.faq_full')}
          </h2>
        </header>
        <section className="flex flex-col space-y-4 py-6 sm:flex-row sm:space-x-12 sm:space-y-0">
          <div className="flex-1 space-y-16">
            <div>
              <h3 className="mb-6 text-2xl font-bold text-gray-900">
                {t('faq.orders.title')}
              </h3>

              <FaqAccordion
                type="multiple"
                value={ordersAccordion}
                onValueChange={(value) => {
                  const openedValues = value.filter(
                    (x) => !ordersAccordion.includes(x)
                  );
                  openedValues.forEach((openedValue) => {
                    track('OpenFAQ', {
                      questionTitle: openedValue,
                    });
                  });
                  setOrdersAccordion(value);
                }}
              >
                <FaqAccordionItem value="when_ship_orders">
                  <FaqAccordionTrigger>
                    {t('faq.orders.when_ship_orders_question')}
                  </FaqAccordionTrigger>
                  <FaqAccordionContent>
                    <p className="max-w-screen-sm whitespace-pre-wrap py-3.5 text-sm text-gray-700">
                      {t('faq.orders.when_ship_orders_answer')}
                    </p>
                  </FaqAccordionContent>
                </FaqAccordionItem>
                <FaqAccordionItem value="late_processing_orders">
                  <FaqAccordionTrigger>
                    {t('faq.orders.late_processing_orders_question')}
                  </FaqAccordionTrigger>
                  <FaqAccordionContent>
                    <p className="max-w-screen-sm whitespace-pre-wrap py-3.5 text-sm text-gray-700">
                      {t('faq.orders.late_processing_orders_answer')}
                    </p>
                  </FaqAccordionContent>
                </FaqAccordionItem>
                <FaqAccordionItem value="put_invoice_in_package">
                  <FaqAccordionTrigger>
                    {t('faq.orders.put_invoice_in_package_question')}
                  </FaqAccordionTrigger>
                  <FaqAccordionContent>
                    <p className="max-w-screen-sm whitespace-pre-wrap py-3.5 text-sm text-gray-700">
                      {t('faq.orders.put_invoice_in_package_answer')}
                    </p>
                  </FaqAccordionContent>
                </FaqAccordionItem>
                <FaqAccordionItem value="import_tracking_numbers">
                  <FaqAccordionTrigger>
                    {t('faq.orders.import_tracking_numbers_question')}
                  </FaqAccordionTrigger>
                  <FaqAccordionContent>
                    <p className="max-w-screen-sm whitespace-pre-wrap py-3.5 text-sm text-gray-700">
                      {t('faq.orders.import_tracking_numbers_answer')}(
                      <a
                        className="text-sm font-semibold text-green-900"
                        href="https://storage.cloud.google.com/public.appchoose.io/csv/import_tracking_template.csv"
                      >
                        https://storage.cloud.google.com/public.appchoose.io/csv/import_tracking_template.csv
                      </a>
                      ).
                      {t('faq.orders.import_tracking_numbers_answer_2')}
                    </p>
                  </FaqAccordionContent>
                </FaqAccordionItem>
                <FaqAccordionItem value="import_stock_from_eshop">
                  <FaqAccordionTrigger>
                    {t('faq.orders.import_stock_from_eshop_question')}
                  </FaqAccordionTrigger>
                  <FaqAccordionContent>
                    <p className="max-w-screen-sm whitespace-pre-wrap py-3.5 text-sm text-gray-700">
                      {t('faq.orders.import_stock_from_eshop_answer')}
                    </p>
                  </FaqAccordionContent>
                </FaqAccordionItem>
                <FaqAccordionItem value="import_orders_into_eshop">
                  <FaqAccordionTrigger>
                    {t('faq.orders.import_orders_into_eshop_question')}
                  </FaqAccordionTrigger>
                  <FaqAccordionContent>
                    <p className="max-w-screen-sm whitespace-pre-wrap py-3.5 text-sm text-gray-700">
                      {t('faq.orders.import_orders_into_eshop_answer')}
                    </p>
                  </FaqAccordionContent>
                </FaqAccordionItem>
                <FaqAccordionItem value="two_orders_one_package">
                  <FaqAccordionTrigger>
                    {t('faq.orders.two_orders_one_package_question')}
                  </FaqAccordionTrigger>
                  <FaqAccordionContent>
                    <p className="max-w-screen-sm whitespace-pre-wrap py-3.5 text-sm text-gray-700">
                      {t('faq.orders.two_orders_one_package_answer')}
                    </p>
                  </FaqAccordionContent>
                </FaqAccordionItem>
                <FaqAccordionItem value="same_tracking_number_twice">
                  <FaqAccordionTrigger>
                    {t('faq.orders.same_tracking_number_twice_question')}
                  </FaqAccordionTrigger>
                  <FaqAccordionContent>
                    <p className="max-w-screen-sm whitespace-pre-wrap py-3.5 text-sm text-gray-700">
                      {t('faq.orders.same_tracking_number_twice_answer')}
                    </p>
                  </FaqAccordionContent>
                </FaqAccordionItem>
                <FaqAccordionItem value="article_out_of_stock">
                  <FaqAccordionTrigger>
                    {t('faq.orders.article_out_of_stock_question')}
                  </FaqAccordionTrigger>
                  <FaqAccordionContent>
                    <p className="max-w-screen-sm whitespace-pre-wrap py-3.5 text-sm text-gray-700">
                      {t('faq.orders.article_out_of_stock_answer')}
                    </p>
                  </FaqAccordionContent>
                </FaqAccordionItem>
                <FaqAccordionItem value="platform_statuses">
                  <FaqAccordionTrigger>
                    {t('faq.orders.platform_statuses_question')}
                  </FaqAccordionTrigger>
                  <FaqAccordionContent>
                    <p className="max-w-screen-sm whitespace-pre-wrap py-3.5 text-sm text-gray-700">
                      {t('faq.orders.platform_statuses_answer')}
                    </p>
                  </FaqAccordionContent>
                </FaqAccordionItem>
              </FaqAccordion>
            </div>

            <div>
              <h3 className="mb-6 text-2xl font-bold text-gray-900">
                {t('faq.sav.title')}
              </h3>

              <FaqAccordion
                type="multiple"
                value={customerAccordion}
                onValueChange={(value) => {
                  const openedValues = value.filter(
                    (x) => !customerAccordion.includes(x)
                  );
                  openedValues.forEach((openedValue) => {
                    track('OpenFAQ', {
                      questionTitle: openedValue,
                    });
                  });
                  setCustomerAccordion(value);
                }}
              >
                <FaqAccordionItem value="handling_customer_disputes">
                  <FaqAccordionTrigger>
                    {t('faq.sav.handling_customer_disputes_question')}
                  </FaqAccordionTrigger>
                  <FaqAccordionContent>
                    <p className="max-w-screen-sm whitespace-pre-wrap py-3.5 text-sm text-gray-700">
                      {t('faq.sav.handling_customer_disputes_answer')}
                    </p>
                  </FaqAccordionContent>
                </FaqAccordionItem>
                <FaqAccordionItem value="kinds_of_claim_encountered">
                  <FaqAccordionTrigger>
                    {t('faq.sav.kinds_of_claim_encountered_question')}
                  </FaqAccordionTrigger>
                  <FaqAccordionContent>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: t('faq.sav.kinds_of_claim_encountered_answer'),
                      }}
                      className="max-w-screen-sm whitespace-pre-wrap py-3.5 text-sm text-gray-700"
                    />
                  </FaqAccordionContent>
                </FaqAccordionItem>
                <FaqAccordionItem value="access_customer_email">
                  <FaqAccordionTrigger>
                    {t('faq.sav.access_customer_email_question')}
                  </FaqAccordionTrigger>
                  <FaqAccordionContent>
                    <p className="max-w-screen-sm whitespace-pre-wrap py-3.5 text-sm text-gray-700">
                      {t('faq.sav.access_customer_email_answer')}
                    </p>
                  </FaqAccordionContent>
                </FaqAccordionItem>
                <FaqAccordionItem value="how_returns_work">
                  <FaqAccordionTrigger>
                    {t('faq.sav.how_returns_work_question')}
                  </FaqAccordionTrigger>
                  <FaqAccordionContent>
                    <p className="max-w-screen-sm whitespace-pre-wrap py-3.5 text-sm text-gray-700">
                      {brand?.store === StoreRegion.Fr
                        ? t('faq.sav.how_returns_work_answer_fr_christmas')
                        : t('faq.sav.how_returns_work_answer_us')}
                    </p>
                  </FaqAccordionContent>
                </FaqAccordionItem>
                <FaqAccordionItem value="exchanges_possible">
                  <FaqAccordionTrigger>
                    {t('faq.sav.exchanges_possible_question')}
                  </FaqAccordionTrigger>
                  <FaqAccordionContent>
                    <p className="max-w-screen-sm whitespace-pre-wrap py-3.5 text-sm text-gray-700">
                      {t('faq.sav.exchanges_possible_answer')}
                    </p>
                  </FaqAccordionContent>
                </FaqAccordionItem>
              </FaqAccordion>
            </div>

            <div>
              <h3 className="mb-6 text-2xl font-bold text-gray-900">
                {t('faq.billing.title')}
              </h3>

              <FaqAccordion
                type="multiple"
                value={invoiceAccordion}
                onValueChange={(value) => {
                  const openedValues = value.filter(
                    (x) => !invoiceAccordion.includes(x)
                  );
                  openedValues.forEach((openedValue) => {
                    track('OpenFAQ', {
                      questionTitle: openedValue,
                    });
                  });
                  setInvoiceAccordion(value);
                }}
              >
                <FaqAccordionItem value="billing_process">
                  <FaqAccordionTrigger>
                    {t('faq.billing.billing_process_question')}
                  </FaqAccordionTrigger>
                  <FaqAccordionContent>
                    <p className="max-w-screen-sm whitespace-pre-wrap py-3.5 text-sm text-gray-700">
                      {
                        <Trans
                          t={t}
                          i18nKey={
                            brand?.store === StoreRegion.Fr
                              ? 'faq.billing.billing_process_answer_fr_christmas'
                              : 'faq.billing.billing_process_answer_us'
                          }
                          components={{
                            a: (
                              <a
                                className="text-green-900"
                                target="_blank"
                                rel="noreferrer"
                              />
                            ),
                          }}
                        />
                      }
                    </p>
                  </FaqAccordionContent>
                </FaqAccordionItem>
              </FaqAccordion>
            </div>
          </div>
          <div className="sticky top-6 self-start rounded-lg bg-beige-300 p-6 sm:w-[246px]">
            <img src={Marion} alt="" className="mx-auto mb-2" />
            <h5 className="text-center text-xs font-semibold uppercase tracking-wider text-gray-700">
              Marion
            </h5>
            <h6 className="mb-2 mt-6 font-semibold text-gray-900">
              {t('faq.help_title')}
            </h6>
            <p className="whitespace-pre-line text-xs text-gray-700">
              {t('faq.help_text')}
            </p>
          </div>
        </section>
      </div>
    </main>
  );
};
