import { forwardRef, useEffect } from 'react';

import Icon from '@appchoose/icon';
import { toast } from '@appchoose/toast';
import * as Sentry from '@sentry/react';

import { useCopyToClipboard } from '../../hooks/use-copy-to-clipboard';
import { track } from '../../utils/analytics';

type CopyProps = {
  value: string;
  successMessage: string;
  trackLabel: string;
} & React.HTMLAttributes<HTMLButtonElement>;

export const Copy: React.FC<CopyProps> = forwardRef<
  HTMLButtonElement,
  CopyProps
>(({ successMessage, trackLabel, value, ...props }: CopyProps, ref) => {
  const [clipboardState, clipboardStateToClipboard] = useCopyToClipboard();

  useEffect(() => {
    const { value, error } = clipboardState;
    if (value) {
      toast.success(successMessage);
    }
    track('CopyToClipboard', {
      label: trackLabel,
    });
    if (error) {
      Sentry.captureException(error);
    }
  }, [clipboardState]);

  return (
    <button
      ref={ref}
      type="button"
      onClick={() => clipboardStateToClipboard(value)}
      {...props}
    >
      <Icon icon="copy" />
    </button>
  );
});

Copy.displayName = 'Copy';
