/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { atom } from 'jotai';
import MiniSearch from 'minisearch';

import type { StockQuery, Variant } from '../types/generated';
import type { ProductIndex } from '../utils/product';
import { SEARCH_PRODUCT_CONFIG, getSearchQuery } from '../utils/product';
import { debouncedSearchTermState } from '../views/stock-screen/stock-search';

export type StockProduct = NonNullable<
  NonNullable<StockQuery['products']>[number]
>;

export const productsState = atom<StockProduct[] | undefined>(undefined);

export enum ProductSort {
  name_asc,
  name_desc,
  stock_asc,
  stock_desc,
  sku_asc,
  sku_desc,
}

export const productSortState = atom<ProductSort>(ProductSort.stock_asc);

export type SortedVariant = Omit<Variant, 'stock'> & {
  productName: string;
  productImage: string | undefined;
  productType: string;
  stock: number;
};

export const sortedVariantsState = atom((get) => {
  const list = get(productsState);

  if (!list) return undefined;

  const sort = get(productSortState);
  let foundVariantIds: string[] | undefined = undefined;
  const debouncedSearchTerm = get(debouncedSearchTermState);

  const minisearch = new MiniSearch<ProductIndex>(SEARCH_PRODUCT_CONFIG);
  minisearch.addAll(
    list.flatMap(
      (product) =>
        product.variants
          ?.filter((variant): variant is Variant => !!variant)
          .flatMap((variant) => {
            return {
              id: variant.id,
              name: product.name ?? '',
              sku: variant.sku ?? '',
              options: variant.options.join(' - ').toLowerCase(),
            };
          }) ?? []
    )
  );

  if (debouncedSearchTerm) {
    foundVariantIds =
      minisearch
        .search({
          prefix: true,
          queries: getSearchQuery(debouncedSearchTerm),
        })
        .map((searchResult) => searchResult.id as string) ?? [];
  }

  const flattenedVariants: SortedVariant[] = [];

  list.forEach((product) => {
    if (!Array.isArray(product.variants)) {
      return;
    }

    product.variants
      .filter((variant): variant is Variant => !!variant)
      .filter((variant) =>
        foundVariantIds ? foundVariantIds.includes(variant.id) : true
      )
      .forEach((variant) => {
        const sanitizedProductImages = product.images?.filter(
          (image): image is string => !!image
        );
        const productImage =
          Array.isArray(sanitizedProductImages) &&
          sanitizedProductImages.length > 0
            ? sanitizedProductImages[0]
            : undefined;

        const sanitizedVariantImages = variant.images?.filter(
          (image): image is string => !!image
        );
        const variantOrProductImages =
          Array.isArray(sanitizedVariantImages) &&
          sanitizedVariantImages.length > 0
            ? sanitizedVariantImages
            : productImage
              ? [productImage]
              : [];

        flattenedVariants.push({
          ...variant,
          productName: product.name ?? '',
          images: variantOrProductImages,
          productImage,
          productType: product.type ?? '',
          stock: typeof variant.stock === 'number' ? variant.stock : 1000,
        });
      });
  });

  if (sort === ProductSort.stock_asc || sort === ProductSort.stock_desc) {
    const sortDirection = sort === ProductSort.stock_asc ? -1 : 1;
    return flattenedVariants.sort((a, b) => {
      return a.stock > b.stock ? -1 * sortDirection : 1 * sortDirection;
    });
  }

  if (sort === ProductSort.name_asc || sort === ProductSort.name_desc) {
    const sortDirection = sort === ProductSort.name_asc ? 1 : -1;
    return flattenedVariants.sort((a, b) => {
      return (
        a.productName.toLowerCase().localeCompare(b.productName.toLowerCase()) *
        sortDirection
      );
    });
  }

  if (sort === ProductSort.sku_asc || sort === ProductSort.sku_desc) {
    const sortDirection = sort === ProductSort.sku_asc ? 1 : -1;
    return flattenedVariants.sort((a, b) => {
      return (
        (a.sku ?? '')
          .toLowerCase()
          .localeCompare((b.sku ?? '').toLowerCase(), 'en', {
            numeric: true,
          }) * sortDirection
      );
    });
  }
});
