import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAtom, useAtomValue } from 'jotai';
import { atomWithReset } from 'jotai/utils';

import { useFilters } from '../../components/order-table/use-filters';
import type { TabProps } from '../../components/order-tabs/types';
import { brandState } from '../../stores/brand';
import { activeSaleState } from '../../stores/sales';
import { useOrdersCountersQuery } from '../../types/generated-new';
import { track } from '../../utils/analytics';
import { getSearchParam } from '../../utils/url';

export enum TabEntry {
  RequiredAction = 'required-action',
  Unfulfilled = 'unfulfilled',
  Claim = 'claim',
  DeliveryIncident = 'delivery-incident',
  Return = 'return',
  Available = 'available',
  Filters = 'filters',
}

export const getTrackingNameFromTabEntry = (tab: TabEntry) => {
  switch (tab) {
    case TabEntry.RequiredAction:
      return 'Required Action';
    case TabEntry.Unfulfilled:
      return 'Unfulfilled';
    case TabEntry.Claim:
      return 'Claims';
    case TabEntry.DeliveryIncident:
      return 'Delivery incidents';
    case TabEntry.Return:
      return 'Returns';
    case TabEntry.Filters:
      return 'Filters';
    default:
      return 'All';
  }
};

export const sanitizeTab = (tab: string) => {
  if (Object.values(TabEntry).includes(tab as TabEntry)) {
    return tab as TabEntry;
  }
  return TabEntry.RequiredAction;
};

const selectedTabState = atomWithReset<TabEntry>(
  sanitizeTab(getSearchParam('view') ?? '')
);

export const useTabs = () => {
  const { hasActiveFilters } = useFilters();
  const { t } = useTranslation();

  const activeSale = useAtomValue(activeSaleState);
  const brand = useAtomValue(brandState);
  const [selectedTab, setSelectedTab] = useAtom(selectedTabState);
  const resetTabFromUrl = () => {
    setSelectedTab(sanitizeTab(getSearchParam('view') ?? ''));
  };

  const { data: ordersCountersData } = useOrdersCountersQuery(
    {
      sellerId: brand?.id ?? '',
      saleId: activeSale?.id ?? '',
    },
    {
      enabled: !!brand?.id && !!activeSale?.id,
      refetchOnWindowFocus: false,
    }
  );

  const tabs = useMemo(() => {
    const displayedTabs: TabProps[] = [
      {
        id: TabEntry.RequiredAction,
        value: TabEntry.RequiredAction,
        label: t('orders.tabs.required_action'),
        counter:
          ordersCountersData?.ordersCounters.tagging.brand.actionRequired ?? 0,
      },
      {
        id: TabEntry.Unfulfilled,
        value: TabEntry.Unfulfilled,
        label: t('orders.tabs.unfulfilled'),
        counter:
          ordersCountersData?.ordersCounters.tagging.brand
            .actionRequiredUnfulfilled ?? 0,
      },
      {
        id: TabEntry.Claim,
        value: TabEntry.Claim,
        label: t('orders.tabs.claim'),
        counter:
          ordersCountersData?.ordersCounters.tagging.brand
            .actionRequiredClaim ?? 0,
      },
      {
        id: TabEntry.DeliveryIncident,
        value: TabEntry.DeliveryIncident,
        label: t('orders.tabs.delivery_incident'),
        counter:
          ordersCountersData?.ordersCounters.tagging.brand
            .actionRequiredShipmentIncident ?? 0,
      },
      {
        id: TabEntry.Return,
        value: TabEntry.Return,
        label: t('orders.tabs.return'),
        counter:
          ordersCountersData?.ordersCounters.tagging.brand
            .actionRequiredReturn ?? 0,
      },
      {
        id: TabEntry.Available,
        value: TabEntry.Available,
        label: t('orders.tabs.available'),
      },
    ];
    if (hasActiveFilters)
      displayedTabs.push({
        id: TabEntry.Filters,
        value: TabEntry.Filters,
        label: t('orders.tabs.filters'),
      });

    return displayedTabs;
  }, [hasActiveFilters, ordersCountersData, t]);

  const trackTab = (value: TabEntry) => {
    track('GoToTableViewOrders', {
      tableViewName: getTrackingNameFromTabEntry(value),
    });
  };

  return {
    tabs,
    trackTab,
    resetTabFromUrl,
    selectedTab,
    setSelectedTab,
  } as const;
};
