import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Icon from '@appchoose/icon';
import { toast } from '@appchoose/toast';
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from '@appchoose/tooltip';
import * as Sentry from '@sentry/react';

import { useCopyToClipboard } from '../../hooks/use-copy-to-clipboard';
import type { OrderQuery } from '../../types/generated-new';
import { track } from '../../utils/analytics';
import { formatInternationalPhoneNumber } from '../../utils/phone';

type ModalOrderRecipientDetailsProps = {
  order: OrderQuery['order'] | undefined;
};

export const ModalOrderRecipientDetails: React.FC<
  ModalOrderRecipientDetailsProps
> = ({ order }: ModalOrderRecipientDetailsProps) => {
  const { t } = useTranslation();

  const [phoneClipboardState, copyPhoneToClipboard] = useCopyToClipboard();
  const [emailClipboardState, copyEmailToClipboard] = useCopyToClipboard();

  useEffect(() => {
    const { value, error } = phoneClipboardState;
    if (value) {
      toast.success(t('order.copy_phone_feedback'));
    }
    if (error) {
      Sentry.captureException(error);
    }
  }, [phoneClipboardState, t]);

  useEffect(() => {
    const { value, error } = emailClipboardState;
    if (value) {
      toast.success(t('clipboard.email_copied'));
    }
    if (error) {
      Sentry.captureException(error);
    }
  }, [emailClipboardState, t]);

  const onCopyPhone = (phoneNumber: string, countryCode: string) => {
    copyPhoneToClipboard(
      formatInternationalPhoneNumber(phoneNumber, countryCode)
    );
    track('CopyToClipboard', {
      label: 'phone',
    });
  };

  const onCopyEmail = (email: string) => {
    copyEmailToClipboard(email);
    track('CopyToClipboard', {
      label: 'email',
    });
  };

  if (!order?.recipient.phoneNumber && !order?.recipient.email) return null;

  return (
    <section className="space-y-2">
      <h4 className="text-xs font-semibold uppercase tracking-wider text-gray-500">
        {t('order.recipient_details')}
      </h4>
      {order.recipient.phoneNumber ? (
        <div className="phone flex items-center justify-between">
          <p className="flex items-center gap-x-2 text-sm leading-5.5 text-gray-700">
            <Icon icon="phone" />
            {formatInternationalPhoneNumber(
              order.recipient.phoneNumber.replaceAll(' ', ''),
              order.recipient.address.countryCode || 'FR'
            )}
          </p>
          <Tooltip>
            <TooltipTrigger
              onClick={() =>
                onCopyPhone(
                  order.recipient.phoneNumber,
                  order.recipient.address.countryCode || 'FR'
                )
              }
              type="button"
              className="rounded transition-colors hover:text-gray-700 focus-visible:ring-2 focus-visible:ring-gray-900/30"
            >
              <Icon icon="copy" />
            </TooltipTrigger>
            <TooltipContent side="left">
              <div className="px-1.5 py-3">{t('order.copy_phone_tooltip')}</div>
              <TooltipArrow />
            </TooltipContent>
          </Tooltip>
        </div>
      ) : null}
      {order.recipient.email ? (
        <div className="flex items-center justify-between">
          <Tooltip>
            <TooltipTrigger asChild>
              <a
                className="email flex items-center gap-x-2 leading-5.5"
                href={'mailto:' + order.recipient.email}
              >
                <Icon icon="mail" />
                <span className="underline-dotted text-sm text-gray-700">
                  {order.recipient.email}
                </span>
              </a>
            </TooltipTrigger>
            <TooltipContent>
              <div
                className="px-1.5 py-3"
                dangerouslySetInnerHTML={{
                  __html: t('order.email'),
                }}
              />
              <TooltipArrow />
            </TooltipContent>
          </Tooltip>
          <Tooltip>
            <TooltipTrigger
              onClick={() => onCopyEmail(order.recipient.email || '')}
              type="button"
              className="rounded-lg transition-colors hover:text-gray-700 focus-visible:ring-2 focus-visible:ring-gray-900/30"
            >
              <Icon icon="copy" />
            </TooltipTrigger>
            <TooltipContent side="left">
              <div className="px-1.5 py-3">{t('order.copy_mail_tooltip')}</div>
              <TooltipArrow />
            </TooltipContent>
          </Tooltip>
        </div>
      ) : null}
    </section>
  );
};
