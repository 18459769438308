import { useTranslation } from 'react-i18next';
import { NavLink, useParams } from 'react-router-dom';

import Icon from '@appchoose/icon';
import { useAuth0 } from '@auth0/auth0-react';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { useSessionStorageValue } from '@react-hookz/web';

import { AvatarProfile } from '../../components/avatar-profile/avatar-profile';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '../../components/dropdown-menu';
import type { BrandMatch } from '../../types/navigation';

export const ProfileMenu: React.FC = () => {
  const { brandId = '' } = useParams<BrandMatch>();
  const { t } = useTranslation();

  const { set: setRedirectUri } = useSessionStorageValue<string>('redirectUri');
  const { user, logout } = useAuth0();

  const handleLogout = () => {
    setRedirectUri(`/${brandId}/home`);
    logout({
      logoutParams: {
        returnTo: `${window.location.origin}/logout-successful`,
      },
    });
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="inline-flex min-w-[15rem] items-center justify-between space-x-4 rounded-lg border border-gray-500 p-2 text-sm font-medium text-gray-900 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 [&[data-state=open]>svg]:rotate-180">
        <div className="flex items-center space-x-2">
          <AvatarProfile pictureUrl={user?.picture} className="size-12" />
          <div className="flex flex-col justify-start">
            <span className="text-left text-sm font-semibold text-gray-700">
              {user?.['https://appchoose.io/claims/firstname']}{' '}
              {user?.['https://appchoose.io/claims/lastname']}
            </span>
            <span className="text-left text-xs text-gray-700">
              {user?.email}
            </span>
          </div>
        </div>
        <Icon icon="arrowDown" />
      </DropdownMenuTrigger>

      <DropdownMenuContent
        align="end"
        className="z-10 mt-2 rounded-md bg-white p-0 py-2 shadow-lg ring-1 ring-black/5 focus:outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2"
        style={{
          minWidth: 'var(--radix-dropdown-menu-trigger-width)',
        }}
      >
        <DropdownMenuPrimitive.Item className="outline-none">
          <NavLink to={`/${brandId}/limited-settings`}>
            <button
              type="button"
              className="flex w-full items-center space-x-2 px-4 py-2 text-sm font-bold text-gray-900 hover:bg-gray-50"
            >
              <Icon icon="settings" size="large" />
              <span>{t('auth.profile_menu.settings')}</span>
            </button>
          </NavLink>
        </DropdownMenuPrimitive.Item>
        <DropdownMenuPrimitive.Item
          className="flex w-full items-center space-x-2 px-4 py-2 text-sm font-bold text-gray-900 outline-none hover:bg-gray-50"
          onSelect={handleLogout}
        >
          <Icon icon="power" size="large" />
          <span>{t('auth.profile_menu.logout')}</span>
        </DropdownMenuPrimitive.Item>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
