import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Icon from '@appchoose/icon';
import Loader from '@appchoose/loader';
import { toast } from '@appchoose/toast';

import { useGeneratePurchaseOrderMutation } from '../../types/generated-new';

type ModalOrderPurchaseOrderProps = {
  orderId: string;
};

const downloadURI = (uri: string) => {
  const link = document.createElement('a');
  // If you don't know the name or want to use
  // the webserver default set name = ''
  link.setAttribute('download', 'download');
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const ModalOrderPurchaseOrder: React.FC<
  ModalOrderPurchaseOrderProps
> = ({ orderId }) => {
  const { t } = useTranslation();

  const { mutateAsync, isPending, error } = useGeneratePurchaseOrderMutation();

  useEffect(() => {
    if (error) {
      toast.error(t('order.purchase_order.generate_purchase_order_error'));
    }
  }, [error]);

  return (
    <div className="space-y-4">
      {isPending ? (
        <Loader className="size-4" />
      ) : (
        <button
          type="button"
          className="flex gap-x-1 text-xs font-semibold text-green-900"
          onClick={async () => {
            const { generatePurchaseOrder } = await mutateAsync({
              orderId,
            });
            downloadURI(generatePurchaseOrder.url);
          }}
        >
          <Icon icon="download" />
          {t('order.purchase_order.download_purchase_order')}
        </button>
      )}
    </div>
  );
};
