import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Icon from '@appchoose/icon';
import Loader from '@appchoose/loader';
import { toast } from '@appchoose/toast';

import { useGenerateInvoiceCustomsMutation } from '../../types/generated-new';

type ModalOrderInvoiceCustomsProps = {
  orderId: string;
};

const downloadURI = (uri: string) => {
  const link = document.createElement('a');
  // If you don't know the name or want to use
  // the webserver default set name = ''
  link.setAttribute('download', 'download');
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const ModalOrderInvoiceCustoms: React.FC<
  ModalOrderInvoiceCustomsProps
> = ({ orderId }) => {
  const { t } = useTranslation();

  const { mutateAsync, isPending, error } = useGenerateInvoiceCustomsMutation();

  useEffect(() => {
    if (error) {
      toast.error(t('order.invoice_customs.generate_invoice_error'));
    }
  }, [error]);

  return (
    <div className="space-y-4">
      <p className="text-xs text-gray-700">
        {t('order.invoice_customs.us_customs_procedures_only')}
      </p>
      {isPending ? (
        <Loader className="size-4" />
      ) : (
        <button
          type="button"
          className="flex gap-x-1 text-xs font-semibold text-green-900"
          onClick={async () => {
            const { generateInvoiceCustoms } = await mutateAsync({
              orderId,
            });
            downloadURI(generateInvoiceCustoms.url);
          }}
        >
          <Icon icon="download" />
          {t('order.invoice_customs.download_invoice')}
        </button>
      )}
    </div>
  );
};
