import type { ChangeEvent } from 'react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Button from '@appchoose/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@appchoose/form';
import Input from '@appchoose/input';
import { useUpdateEffect } from '@react-hookz/web';
import type { Auth0Error } from 'auth0-js';
import Mailchecker from 'mailchecker';

import { XChooseHeader } from '../../components/x-choose-header/x-choose-header';
import type { BrandMatch } from '../../types/navigation';
import { track } from '../../utils/analytics';
import { changePassword } from '../../utils/auth';

export type ForgotPasswordForm = {
  email: string;
};

export const ForgotPasswordEmail: React.FC = () => {
  const { search } = useLocation();
  const { t } = useTranslation();

  const form = useForm<ForgotPasswordForm>({
    mode: 'onTouched',
    defaultValues: {
      email: decodeURIComponent(new URLSearchParams(search).get('email') ?? ''),
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState<string | undefined>(undefined);

  useEffect(() => {
    track('ClickForgotPassword', {});
  }, []);

  useUpdateEffect(() => {
    form.trigger();
  }, [serverError]);

  const { brandId = '' } = useParams<BrandMatch>();
  const location = useLocation();
  const navigate = useNavigate();

  const onSubmit = async (data: ForgotPasswordForm) => {
    setIsLoading(true);

    try {
      await changePassword({
        email: data.email,
      });

      const queryParams = new URLSearchParams(location.search);
      queryParams.set('email', data.email);

      navigate(
        `/${brandId}/forgot-password-confirmation?${queryParams.toString()}`
      );
    } catch (error) {
      setServerError((error as Auth0Error)?.description);
    }

    setIsLoading(false);
  };

  return (
    <div className="mx-auto w-full px-4 py-10 sm:max-w-4.5xl sm:px-6">
      <XChooseHeader />
      <main className="mx-2 mt-10 sm:mx-8 sm:mt-20 md:mx-24">
        <h1 className="mb-4 text-3.5xl font-bold">
          {t('auth.change_password.create_new_password')}
        </h1>
        <div className="space-y-10">
          <p className="m-0 text-base leading-5.5 text-gray-700">
            {t('auth.change_password.reinitialize_your_password_info')}
          </p>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-10">
              <FormField
                control={form.control}
                name="email"
                rules={{
                  required: true,
                  maxLength: 50,
                  validate: {
                    email: (value) => Mailchecker.isValid(value),
                    server: () => serverError === undefined,
                  },
                }}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('auth.fields.email.label')}</FormLabel>
                    <FormControl>
                      <Input
                        type="email"
                        placeholder="example@gmail.com"
                        autoComplete="email"
                        inputMode="email"
                        {...field}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          if (serverError !== undefined) {
                            setServerError(undefined);
                          }
                          const { value } = event.target;
                          event.target.value = value.trim();
                          field.onChange(event);
                        }}
                      />
                    </FormControl>
                    <FormMessage match="required">
                      {t(
                        'brand_info.contact_form_fields.email.validation_errors.required'
                      )}
                    </FormMessage>
                    <FormMessage match="maxLength">
                      {t(
                        'brand_info.contact_form_fields.email.validation_errors.maxLength',
                        {
                          maxLength: '50',
                        }
                      )}
                    </FormMessage>
                    <FormMessage match="email">
                      {t(
                        'brand_info.contact_form_fields.email.validation_errors.email'
                      )}
                    </FormMessage>
                    <FormMessage match="server">{serverError}</FormMessage>
                  </FormItem>
                )}
              />
              <Button
                type="submit"
                appearance="primary"
                size="large"
                disabled={isLoading}
              >
                {t('auth.change_password.send_email_to_reset')}
              </Button>
            </form>
          </Form>
        </div>
      </main>
    </div>
  );
};

ForgotPasswordEmail.displayName = 'ForgotPasswordEmail';
