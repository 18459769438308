import { useState } from 'react';

import { useAtomValue } from 'jotai';

import { DurationWidgetEnded } from '../../components/duration-widget-ended/duration-widget-ended';
import { DurationWidget } from '../../components/duration-widget/duration-widget';
import { activeSaleState, activeSaleWidgetsState } from '../../stores/sales';
import {
  WidgetState,
  WidgetType,
  useUpdateWidgetMutation,
} from '../../types/generated';

export const HomeSaleWidgets: React.FC = () => {
  const activeSale = useAtomValue(activeSaleState);
  const activeSaleWidgets = useAtomValue(activeSaleWidgetsState);

  const [saleWidgetsDurationRecap, setSaleWidgetsDurationRecap] = useState<
    {
      id: string;
      additionnalDays?: number;
    }[]
  >([]);

  const { mutateAsync: updateWidgetMutation } = useUpdateWidgetMutation();

  if (!activeSale) {
    return null;
  }

  const activeSaleDurationWidgets = activeSaleWidgets?.filter(
    (widget) => widget.type === WidgetType.Duration
  );

  const onRefuse = async (
    id: string,
    reason: string,
    reasonComment?: string
  ) => {
    const { updateWidget } = await updateWidgetMutation({
      id: id,
      data: {
        state: WidgetState.Refused,
        results: {
          reason,
          reasonComment,
        },
      },
    });
    if (updateWidget) {
      setSaleWidgetsDurationRecap([
        ...saleWidgetsDurationRecap,
        {
          id,
        },
      ]);
      return true;
    }
    return false;
  };

  const onConfirm = async (id: string, additionnalDays: number) => {
    const { updateWidget } = await updateWidgetMutation({
      id: id,
      data: {
        state: WidgetState.Accepted,
        results: {
          additionnalDays,
        },
      },
    });
    if (updateWidget) {
      setSaleWidgetsDurationRecap([
        ...saleWidgetsDurationRecap,
        {
          id,
          additionnalDays,
        },
      ]);
      return true;
    }
    return false;
  };

  const onClose = (widget_id: string) => {
    const idx = saleWidgetsDurationRecap.findIndex((c) => c.id === widget_id);
    if (idx > -1) {
      const newWidgetsEnded = [...saleWidgetsDurationRecap];
      newWidgetsEnded.splice(idx, 1);
      setSaleWidgetsDurationRecap(newWidgetsEnded);
    }
  };

  return (
    <>
      {activeSaleDurationWidgets &&
        activeSaleDurationWidgets.length > 0 &&
        activeSaleDurationWidgets.map((widget) => (
          <DurationWidget
            key={widget.id}
            onConfirm={onConfirm}
            onRefuse={onRefuse}
            endTime={activeSale.end_at ?? ''}
            widget={widget}
          />
        ))}
      {saleWidgetsDurationRecap.map((widget) => (
        <DurationWidgetEnded
          key={widget.id}
          onClose={onClose}
          endTime={activeSale.end_at ?? ''}
          additionnalDays={widget.additionnalDays}
          widget_id={widget.id}
        />
      ))}
    </>
  );
};

HomeSaleWidgets.displayName = 'HomeSaleWidgets';
