import { useTranslation } from 'react-i18next';

import Icon from '@appchoose/icon';

import type { OrderQuery } from '../../types/generated-new';
import { ModalOrderPurchaseOrder } from './modal-order-purchase-order';

export type OrderAttachmentsProps = {
  claim?: OrderQuery['order']['claims'][0];
  orderId: string;
};

export const OrderAttachments: React.FC<OrderAttachmentsProps> = ({
  claim,
  orderId,
}: OrderAttachmentsProps) => {
  const { t } = useTranslation();

  if (!claim) return null;

  return (
    <div className="mt-4 flex flex-col gap-4 border-t border-gray-300 pt-4">
      <h5 className="text-sm font-semibold text-gray-900">
        {t('order.attachments')}
      </h5>
      <ul className="flex flex-col gap-4">
        <li>
          <ModalOrderPurchaseOrder orderId={orderId} />
        </li>
        {claim.swornStatementUrls.map((url) => (
          <li key={url}>
            <a
              href={url}
              className="flex gap-x-1 text-xs font-semibold text-green-900"
              target="_blank"
              rel="noreferrer"
            >
              <Icon icon="download" />
              {t('order.claim.sworn_attachment')}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};
