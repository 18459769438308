import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from '@appchoose/button';
import cn from '@appchoose/cn';
import { Form, FormControl, FormField, FormItem } from '@appchoose/form';
import Icon from '@appchoose/icon';
import Loader from '@appchoose/loader';
import { Modal, ModalContent, ModalTrigger } from '@appchoose/modal';
import { Popover, PopoverContent, PopoverTrigger } from '@appchoose/popover';
import { toast } from '@appchoose/toast';
import { useAuth0 } from '@auth0/auth0-react';
import { PopoverClose } from '@radix-ui/react-popover';
import * as Sentry from '@sentry/react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

import { ModalStockUpdateImpossibleToChange } from '../../components/modal-stock-update-impossible-to-change/modal-stock-update-impossible-to-change';
import { activeSaleIdState } from '../../stores/sales';
import { useUpdateStockMutation } from '../../types/generated';
import { track } from '../../utils/analytics';
import { isScopeAuthorized } from '../../utils/auth';
import { newStockState, stockUpdateOpenIdState } from './stock-state';

type StockUpdateProps = {
  stock: number | null | undefined;
  stock_id: string;
  canEdit: boolean;
};

export const StockUpdate: React.FC<StockUpdateProps> = ({
  stock,
  stock_id,
  canEdit,
}: StockUpdateProps) => {
  const { t } = useTranslation();

  const [stockUpdateOpenId, setStockUpdateOpenId] = useAtom(
    stockUpdateOpenIdState
  );
  const newStock = useAtomValue(newStockState);

  const { isPending } = useUpdateStockMutation();

  function transformStock() {
    if (stock === null) {
      return 1000;
    }
    return stock;
  }

  return (
    <>
      <div
        data-column="stock"
        className="flex flex-col space-y-4 min-[960px]:flex-row min-[960px]:items-center min-[960px]:justify-end"
      >
        <div className="text-xs font-semibold uppercase tracking-wider text-gray-500 min-[960px]:hidden">
          {t('stock.headers.stock')}
        </div>
        {isPending ? (
          <Loader className="size-3" />
        ) : (
          <div className="flex flex-row items-center">
            <div
              className={cn('px-2 py-1 text-sm text-gray-900', {
                'rounded font-semibold': typeof stock === 'number' && stock < 6,
                'bg-red-300 text-red-600': stock === 0,
                'bg-beige-300 text-orange-600':
                  typeof stock === 'number' && stock > 0 && stock < 6,
              })}
            >
              {transformStock()}
            </div>
            {stockUpdateOpenId === stock_id ? (
              <>
                <Icon
                  icon="arrowUp"
                  className="shrink-0 rotate-90 text-gray-500"
                />
                <div className="text-sm font-bold text-green-900">
                  {newStock}
                </div>
              </>
            ) : null}
          </div>
        )}
      </div>

      <div
        data-column="stock-update"
        className="flex flex-col space-y-4 min-[960px]:flex-row min-[960px]:items-center min-[960px]:justify-end min-[960px]:text-right"
      >
        <div className="text-xs font-semibold uppercase tracking-wider text-gray-500 min-[960px]:hidden">
          {t('stock.headers.change_stock')}
        </div>
        {canEdit ? (
          <Popover
            open={stockUpdateOpenId === stock_id}
            onOpenChange={(open) => {
              if (open === true) setStockUpdateOpenId(stock_id);
              if (open === false) setStockUpdateOpenId(undefined);
            }}
          >
            <PopoverTrigger
              type="button"
              className="h-10 w-20 cursor-pointer rounded-lg border border-gray-300 p-2 text-right text-sm text-gray-500 transition-colors duration-300 hover:border-gray-100 hover:bg-gray-100"
              onClick={() => track('OpenModalStockUpdate', {})}
            >
              0
            </PopoverTrigger>
            <PopoverContent sideOffset={-54} className="w-[112px]">
              <StockUpdateModal previousStock={stock} stockId={stock_id} />
            </PopoverContent>
          </Popover>
        ) : (
          <Modal>
            <ModalTrigger
              onClick={() =>
                track('OpenModalStockUpdateImpossibleToChange', {})
              }
            >
              <p className="cursor-help border-b border-dashed border-gray-300 text-xs text-gray-500">
                {t('not_modifiable')}
              </p>
            </ModalTrigger>
            <ModalContent scrollable>
              <ModalStockUpdateImpossibleToChange />
            </ModalContent>
          </Modal>
        )}
      </div>
    </>
  );
};

type StockUpdateModalProps = {
  previousStock: number | null | undefined;
  stockId: string;
};

type StockUpdateForm = {
  stock: number;
};

const StockUpdateModal: React.FC<StockUpdateModalProps> = ({
  previousStock,
  stockId,
}) => {
  const { user } = useAuth0();
  const { t } = useTranslation();

  const activeSaleId = useAtomValue(activeSaleIdState);
  const setNewStock = useSetAtom(newStockState);
  const setStockUpdateOpenId = useSetAtom(stockUpdateOpenIdState);

  const { mutateAsync: updateStockMutation, isPending } =
    useUpdateStockMutation();

  const form = useForm<StockUpdateForm>({
    mode: 'onTouched',
    disabled: !isScopeAuthorized(user, 'scope.brand.stock.write'),
    defaultValues: {
      stock: 0,
    },
  });

  const onSubmit = async (data: StockUpdateForm) => {
    try {
      await updateStockMutation({
        saleId: activeSaleId ?? '',
        stockId: stockId,
        stock: data.stock,
      });
      toast.success(t('stock.updated_stock'));
    } catch (error) {
      if ((error as Error)?.message === 'Stock is live. Update is impossible') {
        toast.error(t('stock.stock_shopify_error'));
      } else {
        toast.error(t('stock.stock_generic_error'));
      }
      Sentry.captureException(error);
    }
    setStockUpdateOpenId(undefined);
  };

  useEffect(() => {
    setNewStock(0);

    return () => {
      setNewStock(undefined);
    };
  }, []);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      form.handleSubmit(onSubmit)();
    }
  };

  return (
    <Form {...form}>
      <form className="space-y-2" onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          name="stock"
          rules={{
            required: true,
            min: 0,
            validate: {
              different: (value: number) => value !== previousStock,
            },
          }}
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <input
                  type="number"
                  onKeyDown={handleKeyDown}
                  autoFocus={true}
                  {...field}
                  onChange={(event) => {
                    field.onChange(event.target.valueAsNumber);
                    let newStock = event.target.valueAsNumber;
                    if (isNaN(newStock) || newStock < 0) {
                      newStock = 0;
                    }
                    setNewStock(newStock);
                  }}
                  className={
                    'hide-arrows form-input block w-full rounded-lg border border-gray-500 p-2 text-right text-sm leading-5.5 text-gray-900 outline-none transition duration-300 placeholder:text-gray-500 hover:border-gray-700 focus:border-gray-700 focus:ring-gray-700 disabled:cursor-not-allowed disabled:border-gray-100 disabled:bg-gray-50 disabled:text-gray-500 disabled:placeholder:text-gray-300 aria-[invalid="true"]:border-red-600 aria-[invalid="true"]:ring-red-600'
                  }
                />
              </FormControl>
            </FormItem>
          )}
        />
        <div className="flex w-full flex-row justify-between">
          <PopoverClose>
            <Button
              type="button"
              appearance="outline"
              className="h-8 w-[36px] justify-center p-0"
            >
              <Icon icon="close" size="large" />
            </Button>
          </PopoverClose>
          <Button
            type="submit"
            appearance="primary"
            className="h-8 w-[36px] justify-center p-0"
            disabled={
              !form.formState.isValid || form.formState.disabled || isPending
            }
          >
            <Icon icon="check" size="large" />
          </Button>
        </div>
      </form>
    </Form>
  );
};
