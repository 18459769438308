/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { atom, useAtom } from 'jotai';

import {
  OrderTagClaim,
  OrderTagClaimReason,
  OrderTagFulfillment,
  OrderTagRefund,
  OrderTagReship,
  OrderTagReturn,
  OrderTagShipment,
  OrderTagShopify,
} from '../../types/generated-new';
import { track } from '../../utils/analytics';
import { getComplexSearchParam } from '../../utils/url';

export type OrderFiltering = {
  creationDate?: {
    from?: string;
    to?: string;
  };
  claim: OrderTagClaim[];
  claimReason: OrderTagClaimReason[];
  fulfillment: OrderTagFulfillment[];
  refund: OrderTagRefund[];
  reship: OrderTagReship[];
  return: OrderTagReturn[];
  shipment: OrderTagShipment[];
  shopify: OrderTagShopify[];
};

enum OrderFilters {
  claim = 'claim',
  claimReason = 'claimReason',
  fulfillment = 'fulfillment',
  refund = 'refund',
  reship = 'reship',
  return = 'return',
  shipment = 'shipment',
  shopify = 'shopify',
}

export const getTrackingNameFromFilters = (filters: OrderFiltering) => {
  const trackingNames: string[] = [];
  if (filters.creationDate?.from) {
    trackingNames.push('creationDateFrom');
  }
  if (filters.creationDate?.to) {
    trackingNames.push('creationDateTo');
  }
  if (filters.fulfillment.includes(OrderTagFulfillment.Unfulfilled)) {
    trackingNames.push('fulfillmentUnfulfilled');
  }
  if (filters.fulfillment.includes(OrderTagFulfillment.Fulfilled)) {
    trackingNames.push('fulfillmentFulfilled');
  }
  if (filters.shopify.includes(OrderTagShopify.Synchronized)) {
    trackingNames.push('shopifySynchronized');
  }
  if (filters.shopify.includes(OrderTagShopify.Unsynchronized)) {
    trackingNames.push('shopifyUnsynchronized');
  }
  if (filters.shipment.includes(OrderTagShipment.Pending)) {
    trackingNames.push('shipmentPending');
  }
  if (filters.shipment.includes(OrderTagShipment.InfoReceived)) {
    trackingNames.push('shipmentInfoReceived');
  }
  if (filters.shipment.includes(OrderTagShipment.InTransit)) {
    trackingNames.push('shipmentInTransit');
  }
  if (filters.shipment.includes(OrderTagShipment.OutForDelivery)) {
    trackingNames.push('shipmentOutForDelivery');
  }
  if (filters.shipment.includes(OrderTagShipment.AvailableForPickup)) {
    trackingNames.push('shipmentAvailableForPickup');
  }
  if (filters.shipment.includes(OrderTagShipment.Delivered)) {
    trackingNames.push('shipmentDelivered');
  }
  if (filters.shipment.includes(OrderTagShipment.AttemptFail)) {
    trackingNames.push('shipmentAttemptFail');
  }
  if (filters.shipment.includes(OrderTagShipment.Exception)) {
    trackingNames.push('shipmentException');
  }
  if (filters.shipment.includes(OrderTagShipment.Expired)) {
    trackingNames.push('shipmentExpired');
  }
  if (filters.claim.includes(OrderTagClaim.Opened)) {
    trackingNames.push('claimOpened');
  }
  if (filters.claim.includes(OrderTagClaim.Closed)) {
    trackingNames.push('claimClosed');
  }
  if (filters.claimReason.includes(OrderTagClaimReason.DeliveredButDamaged)) {
    trackingNames.push('claimReasonDeliveredButDamaged');
  }
  if (
    filters.claimReason.includes(OrderTagClaimReason.DeliveredButIncomplete)
  ) {
    trackingNames.push('claimReasonDeliveredButIncomplete');
  }
  if (filters.claimReason.includes(OrderTagClaimReason.DeliveredButWrong)) {
    trackingNames.push('claimReasonDeliveredButWrong');
  }
  if (filters.claimReason.includes(OrderTagClaimReason.None)) {
    trackingNames.push('claimReasonNone');
  }
  if (
    filters.claimReason.includes(
      OrderTagClaimReason.NotDeliveredAndReturnedToSender
    )
  ) {
    trackingNames.push('claimReasonNotDeliveredAndReturnedToSender');
  }
  if (
    filters.claimReason.includes(
      OrderTagClaimReason.NotDeliveredAndTrackingBlocked
    )
  ) {
    trackingNames.push('claimReasonNotDeliveredAndTrackingBlocked');
  }
  if (
    filters.claimReason.includes(
      OrderTagClaimReason.NotDeliveredAndTrackingDelivered
    )
  ) {
    trackingNames.push('claimReasonNotDeliveredAndTrackingDelivered');
  }
  if (filters.claimReason.includes(OrderTagClaimReason.Other)) {
    trackingNames.push('claimReasonOther');
  }
  if (filters.claimReason.includes(OrderTagClaimReason.VoucherNotWorking)) {
    trackingNames.push('claimReasonVoucherNotWorking');
  }
  if (filters.return.includes(OrderTagReturn.Validated)) {
    trackingNames.push('returnAccepted');
  }
  if (filters.return.includes(OrderTagReturn.InProgress)) {
    trackingNames.push('returnInProgress');
  }
  if (filters.return.includes(OrderTagReturn.Delivered)) {
    trackingNames.push('returnDelivered');
  }
  if (filters.return.includes(OrderTagReturn.Canceled)) {
    trackingNames.push('returnCanceled');
  }
  if (filters.return.includes(OrderTagReturn.Requested)) {
    trackingNames.push('returnRequested');
  }
  if (filters.refund.includes(OrderTagRefund.PartiallyRefunded)) {
    trackingNames.push('refundPartiallyRefunded');
  }
  if (filters.refund.includes(OrderTagRefund.TotallyRefunded)) {
    trackingNames.push('refundTotallyRefunded');
  }
  if (filters.reship.includes(OrderTagReship.Reshipped)) {
    trackingNames.push('reship');
  }

  return trackingNames;
};

const getHasActiveFilters = (filters: OrderFiltering) => {
  if (!filters) {
    return false;
  }

  if (filters.creationDate?.from || filters.creationDate?.to) {
    return true;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { creationDate, ...filtersWithoutCreationDate } = filters;

  return Object.values(filtersWithoutCreationDate).some(
    (filter) => filter.length > 0
  );
};

const sanitizeFilters = (filters: unknown) => {
  const sanitizedFilters = {} as OrderFiltering;

  // eslint-disable-next-line @typescript-eslint/dot-notation
  if (filters && typeof filters['creationDate'] === 'object') {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    const creationDate = filters['creationDate'] as {
      from?: string;
      to?: string;
    };

    if (
      typeof creationDate.from === 'string' &&
      new Date(creationDate.from).toString() !== 'Invalid Date'
    ) {
      sanitizedFilters.creationDate = {
        from: creationDate.from,
      };
    }
    if (
      typeof creationDate.to === 'string' &&
      new Date(creationDate.to).toString() !== 'Invalid Date'
    ) {
      sanitizedFilters.creationDate = {
        from: sanitizedFilters.creationDate?.from,
        to: creationDate.to,
      };
    }
  }
  Object.keys(OrderFilters).forEach((key) => {
    if (
      filters &&
      Object.keys(filters as OrderFiltering).includes(key) &&
      Array.isArray((filters as OrderFiltering)[key])
    ) {
      if (key === OrderFilters.claim.toString()) {
        sanitizedFilters[key] = (filters as OrderFiltering)[key].filter(
          (claim) =>
            Object.values(OrderTagClaim).includes(claim as OrderTagClaim)
        );
      }
      if (key === OrderFilters.claimReason.toString()) {
        sanitizedFilters[key] = (filters as OrderFiltering)[key].filter(
          (claimReason) =>
            Object.values(OrderTagClaimReason).includes(
              claimReason as OrderTagClaimReason
            )
        );
      }
      if (key === OrderFilters.fulfillment.toString()) {
        sanitizedFilters[key] = (filters as OrderFiltering)[key].filter(
          (fulfillment) =>
            Object.values(OrderTagFulfillment).includes(
              fulfillment as OrderTagFulfillment
            )
        );
      }
      if (key === OrderFilters.refund.toString()) {
        sanitizedFilters[key] = (filters as OrderFiltering)[key].filter(
          (refund) =>
            Object.values(OrderTagRefund).includes(refund as OrderTagRefund)
        );
      }
      if (key === OrderFilters.reship.toString()) {
        sanitizedFilters[key] = (filters as OrderFiltering)[key].filter(
          (reship) =>
            Object.values(OrderTagReship).includes(reship as OrderTagReship)
        );
      }
      if (key === OrderFilters.return.toString()) {
        sanitizedFilters[key] = (filters as OrderFiltering)[key].filter(
          (_return) =>
            Object.values(OrderTagReturn).includes(_return as OrderTagReturn)
        );
      }
      if (key === OrderFilters.shipment.toString()) {
        sanitizedFilters[key] = (filters as OrderFiltering)[key].filter(
          (shipment) =>
            Object.values(OrderTagShipment).includes(
              shipment as OrderTagShipment
            )
        );
      }
      if (key === OrderFilters.shopify.toString()) {
        sanitizedFilters[key] = (filters as OrderFiltering)[key].filter(
          (shopify) =>
            Object.values(OrderTagShopify).includes(shopify as OrderTagShopify)
        );
      }
    } else {
      sanitizedFilters[key] = [];
    }
  });
  return sanitizedFilters;
};

const orderFilteringState = atom<OrderFiltering>(
  sanitizeFilters(getComplexSearchParam('filters'))
);

export const useFilters = () => {
  const [filters, setFilters] = useAtom(orderFilteringState);
  const resetFiltersFromUrl = () => {
    setFilters(sanitizeFilters(getComplexSearchParam('filters')));
  };

  const hasActiveFilters = getHasActiveFilters(filters);

  const resetFilters = () => {
    setFilters({
      ...(Object.keys(OrderFilters).reduce(
        (acc, key) => ({ ...acc, [key]: [] }),
        {}
      ) as OrderFiltering),
      creationDate: {
        from: undefined,
        to: undefined,
      },
    });
  };

  const trackFilters = (filters: OrderFiltering) => {
    track('FilterTableOrders', {
      filterList: getTrackingNameFromFilters(filters),
    });
  };

  return {
    filters,
    hasActiveFilters,
    resetFilters,
    resetFiltersFromUrl,
    setFilters,
    trackFilters,
  } as const;
};
