import React from 'react';
import { useTranslation } from 'react-i18next';

import { addDays, differenceInCalendarDays } from 'date-fns';
import i18n from 'i18next';
import { useAtomValue } from 'jotai';
import type { DotProps } from 'recharts';
import {
  Area,
  AreaChart,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { brandState } from '../../stores/brand';
import { activeSaleState } from '../../stores/sales';
import { StoreCurrency } from '../../types/generated';
import { transformPrice } from '../../utils/currency';
import { formatDayAndMonth, formatFullDate } from '../../utils/date';

type PayloadData = { date: string; amount: number; orders: number };

type TickData = {
  d: number;
  text: string;
};

type CustomTooltipProps = {
  active?: boolean;
  payload?: {
    color: string;
    dataKey: string;
    fill: string;
    fillOpacity: string;
    formatter: undefined;
    name: string;
    payload: PayloadData;
    points: [];
    stroke: string;
    strokeWidth: string;
    type: undefined;
    unit: undefined;
    value: number;
  }[];
  currency: string;
  label?: string;
};

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  active,
  currency,
  payload,
  label,
}: CustomTooltipProps) => {
  const brand = useAtomValue(brandState);
  const { t } = useTranslation();

  if (active) {
    return Array.isArray(payload) && payload.length && payload[0] ? (
      <div
        className="area-chart-tooltip"
        style={{
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.16)',
          borderRadius: 8,
          padding: 16,
          border: 0,
          background: '#0B1115',
        }}
      >
        <p
          style={{
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: 12,
            letterSpacing: 0.8,
            margin: 0,
            marginBottom: 8,
            textTransform: 'uppercase',
            color: '#ECECEC',
          }}
        >
          {formatFullDate(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            new Date(label!),
            i18n.language === 'fr' ? 'fr' : 'en',
            brand?.timezone ?? undefined
          )}
        </p>
        <p
          style={{
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: 12,
            letterSpacing: 0,
            margin: 0,
            marginBottom: 8,
            textTransform: 'capitalize',
            color: '#ECECEC',
          }}
        >
          {t('rating.chart_ca')} :{' '}
          <b>
            {transformPrice(
              payload[0].payload.amount * 100,
              i18n.language === 'fr' ? 'fr' : 'en',
              currency
            )}
          </b>
        </p>
        <p
          style={{
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: 12,
            letterSpacing: 0,
            margin: 0,
            marginBottom: 0,
            textTransform: 'capitalize',
            color: '#ECECEC',
          }}
        >
          {t('rating.chart_orders')} : <b>{payload[0].payload.orders}</b>
        </p>
      </div>
    ) : null;
  }

  return null;
};

type CustomCursorProps = {
  height?: number;
  points?: {
    x: number;
    y: number;
  }[];
};

const CustomCursor: React.FC<CustomCursorProps> = ({
  points,
  height,
}: CustomCursorProps) => {
  return Array.isArray(points) && points.length && points[0] ? (
    <Rectangle
      radius={8}
      x={points[0].x - 1}
      y={4}
      fill="#13482B"
      width={2}
      height={(height ?? 0) + 12}
    />
  ) : null;
};

function CustomActiveDot({ cx, cy }: DotProps) {
  return (
    <svg
      x={(cx ?? 0) - 8.5}
      y={(cy ?? 0) - 8.5}
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8.5" cy="8.5" r="5" fill="#13482B" stroke="#13482B" />
      <circle cx="8.5" cy="8.5" r="5.5" stroke="white" strokeWidth={2} />
      <circle cx="8.5" cy="8.5" r="7.5" stroke="#13482B" strokeWidth={2} />
    </svg>
  );
}

function CustomDot({ cx, cy }: DotProps) {
  return (
    <svg
      x={(cx ?? 0) - 4.5}
      y={(cy ?? 0) - 4.5}
      width="9"
      height="9"
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="4.5" cy="4.5" r="4.5" fill="#13482B" />
    </svg>
  );
}

type CustomYAxisTickProps = {
  x?: number;
  y?: number;
  payload?: {
    value: number;
  };
  currency?: string;
};

function CustomYAxisTick({ x, y, payload, currency }: CustomYAxisTickProps) {
  const formatter = Intl.NumberFormat(i18n.language === 'fr' ? 'fr' : 'en', {
    style: 'currency',
    notation: 'compact',
    currency: currency,
  });
  return (
    <g>
      <g transform={`translate(${x},${y})`}>
        <text fontSize={14} fill="#8A8D8F" textAnchor="end">
          {formatter.format(payload?.value ?? 0)}
        </text>
      </g>
    </g>
  );
}

type CustomXAxisTickProps = {
  x?: number;
  y?: number;
  payload?: {
    value: number;
  };
  dateFormatter: (date: string | number) => string;
};

function CustomXAxisTick({
  x,
  y,
  payload,
  dateFormatter,
}: CustomXAxisTickProps) {
  return (
    <g>
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={-24} fontSize={14} fill="#8A8D8F">
          {dateFormatter(payload?.value ?? 0)}
        </text>
      </g>
    </g>
  );
}

const getTicks = (
  startDate: Date,
  endDate: Date,
  num: number,
  timezone?: string
) => {
  const ticks = [
    {
      d: startDate.getTime(),
      text: formatDayAndMonth(
        startDate,
        i18n.language === 'fr' ? 'fr' : 'en',
        timezone
      ),
    },
  ];
  for (let i = 1; i < num; i++) {
    ticks.push({
      d: addDays(startDate, i).getTime(),
      text: formatDayAndMonth(
        addDays(startDate, i),
        i18n.language === 'fr' ? 'fr' : 'en',
        timezone
      ),
    });
  }

  ticks.push({
    d: endDate.getTime(),
    text: formatDayAndMonth(
      endDate,
      i18n.language === 'fr' ? 'fr' : 'en',
      timezone
    ),
  });
  return ticks;
};

const fillTicksData = (
  _ticks: TickData[],
  data: SalesAreaChartData[],
  timezone?: string
): (Omit<PayloadData, 'date'> & { date: number; index: number })[] => {
  const shallowTick = JSON.parse(JSON.stringify(_ticks)) as TickData[];
  return shallowTick.map((tick, index) => {
    const item = data.find(
      (c) =>
        formatDayAndMonth(
          new Date(c.date),
          i18n.language === 'fr' ? 'fr' : 'en',
          timezone
        ) === tick.text
    );
    if (item) {
      return {
        amount: item.amount,
        orders: item.sum,
        date: new Date(item.date).getTime(),
        index,
      };
    }
    return { amount: 0, orders: 0, date: tick.d, index };
  });
};

const processData = (
  data: SalesAreaChartData[],
  startAt?: string,
  timezone?: string
) => {
  const dates = data.map((c) => new Date(c.date).getTime());
  const min = startAt
    ? Math.min(...dates, new Date(startAt).setUTCHours(0))
    : Math.min(...dates);
  const max = Math.max(...dates);

  const domain = [min, max];
  const ticks = getTicks(
    new Date(min),
    new Date(max),
    differenceInCalendarDays(new Date(max), new Date(min)),
    timezone
  );
  const filledData = fillTicksData(ticks, data, timezone);
  return { filledData, domain, ticks: ticks.map((c) => c.d) };
};

export type SalesAreaChartData = {
  date: Date;
  sum: number;
  amount: number;
};

export type SalesAreaChartProps = {
  data: SalesAreaChartData[];
  startAt?: string;
};

const SalesAreaChart: React.FC<SalesAreaChartProps> = ({
  data,
  startAt,
}: SalesAreaChartProps) => {
  const brand = useAtomValue(brandState);
  const activeSale = useAtomValue(activeSaleState);

  const { filledData, domain, ticks } = processData(
    data,
    startAt,
    brand?.timezone ?? undefined
  );

  const dateFormatter = (date: string | number) => {
    return formatDayAndMonth(
      new Date(date),
      i18n.language === 'fr' ? 'fr' : 'en'
    );
  };

  return (
    <ResponsiveContainer>
      <AreaChart data={filledData}>
        <defs>
          <linearGradient id="MyGradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="rgba(19, 72, 43, 0.8)" />
            <stop offset="95%" stopColor="rgba(19, 72, 43, 0.1)" />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="date"
          scale="time"
          tickFormatter={dateFormatter}
          type="number"
          domain={domain}
          ticks={ticks}
          tickMargin={48}
          padding={{ right: 48 }}
          stroke="#D8D9DA"
          tick={<CustomXAxisTick dateFormatter={dateFormatter} />}
        />
        <YAxis
          padding={{ top: 32 }}
          stroke="#D8D9DA"
          type="number"
          dataKey="amount"
          unit={activeSale?.currency === StoreCurrency.Usd ? '$' : '€'}
          tickMargin={8}
          tick={
            <CustomYAxisTick
              currency={activeSale?.currency.toString().toUpperCase() ?? 'EUR'}
            />
          }
        />
        <Tooltip
          content={
            <CustomTooltip
              currency={activeSale?.currency.toString().toUpperCase() ?? 'EUR'}
            />
          }
          cursor={<CustomCursor />}
        />
        <Area
          connectNulls={true}
          type="linear"
          dataKey="amount"
          stroke="#13482B"
          strokeWidth="2"
          activeDot={<CustomActiveDot />}
          fillOpacity="1"
          fill="url(#MyGradient)"
          dot={<CustomDot />}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export { SalesAreaChart };
