import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from '@appchoose/button';
import Checkbox from '@appchoose/checkbox';
import cn from '@appchoose/cn';
import { Form, FormControl, FormField, FormItem } from '@appchoose/form';
import Icon from '@appchoose/icon';
import { ModalFooter, ModalHeader, ModalTitle } from '@appchoose/modal';
import { toast } from '@appchoose/toast';
import { useQueryClient } from '@tanstack/react-query';
import { useSetAtom } from 'jotai';

import { useReturnSlipUploadMutation } from '../../api/graphql';
import {
  type OrderQuery,
  useJoinOrderReturnLabelForClaimMutation,
  useUsedCouriersQuery,
} from '../../types/generated-new';
import { ReturnSlipFileUpload } from './return-slip-file-upload';
import type { TrackingInfoFormData } from './tracking-info-form';
import {
  TrackingNumberFormFields,
  searchCourierState,
  selectedCourierState,
} from './tracking-number-form-fields';

type ReturnSlipItem = {
  id: string;
  imageUrl: string;
  name: string;
  size?: string;
  sku?: string;
  gtin?: string;
  isReturnable: boolean;
  selected: boolean;
};

type AddReturnSlipForm = {
  file: File;
  products: ReturnSlipItem[];
} & TrackingInfoFormData;

type OrderAddReturnSlipProps = {
  claim: OrderQuery['order']['claims'][0];
  orderId?: OrderQuery['order']['id'];
  setIsOpen: (isOpen: boolean) => void;
};

export const ModalAddReturnSlip: React.FC<OrderAddReturnSlipProps> = ({
  claim,
  orderId,
  setIsOpen,
}) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const setSearchCourier = useSetAtom(searchCourierState);
  const setSelectedCourier = useSetAtom(selectedCourierState);

  const { refetch: refetchUsedCouriers } = useUsedCouriersQuery(
    {},
    {
      select: (data) => data.usedCouriers,
      enabled: false,
    }
  );

  const [returnSlipUploadMutation, { loading: uploadLoading }] =
    useReturnSlipUploadMutation({
      orderId: orderId ?? '',
    });
  const { mutateAsync: joinOrderReturnLabelForClaim, isPending: joinLoading } =
    useJoinOrderReturnLabelForClaimMutation();

  const form = useForm<AddReturnSlipForm>({
    mode: 'onTouched',
    defaultValues: async () => {
      const usedCouriers = await refetchUsedCouriers({});
      const firstUsedCourier = usedCouriers.data?.[0];
      if (firstUsedCourier) {
        setSearchCourier(firstUsedCourier?.name ?? '');
        setSelectedCourier(firstUsedCourier);
      }
      return {
        trackingCourierSlug: firstUsedCourier?.slug ?? '',
        trackingNumber: '',
        file: undefined as unknown as File,
        products: claim.items.map((item) => ({
          id: item.id,
          imageUrl: item.imageUrl,
          name: item.name,
          size: item.size ?? undefined,
          sku: item.sku ?? undefined,
          gtin: item.gtin ?? undefined,
          isReturnable: item.isReturnable,
          selected: item.isReturnable ? true : false,
        })),
      };
    },
  });

  const { fields } = useFieldArray({
    control: form.control,
    name: 'products',
    rules: {
      validate: {
        atLeastOne: (items) => items.some((s) => s.selected),
      },
    },
  });

  const onSubmit = async (data: AddReturnSlipForm) => {
    try {
      const response = await returnSlipUploadMutation({
        file: data.file,
      });

      if (response === undefined) {
        throw new Error('Failed to upload return slip');
      }

      await joinOrderReturnLabelForClaim({
        input: {
          orderId: orderId ?? '',
          claimId: claim.id,
          labelFileId: response.uploadedKey,
          itemIds: data.products
            .filter((item) => item.selected)
            .map((item) => item.id),
          trackingNumber: data.trackingNumber,
          trackingCarrierSlug: data.trackingCourierSlug,
        },
      });

      queryClient.invalidateQueries({
        queryKey: ['order', { orderId: orderId }],
      });
      queryClient.invalidateQueries({
        queryKey: ['orders'],
      });
      setIsOpen(false);
      toast.success(t('order.modals.modal_add_return_slip.return_slip_sent'));
    } catch {
      toast.error(t('order.modals.modal_add_return_slip.return_slip_error'));
    }
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex h-full flex-col overflow-hidden"
      >
        <div className="flex-auto overflow-y-auto p-6 md:p-10">
          <ModalHeader>
            <ModalTitle>
              {t(
                'order.modals.modal_add_return_slip.request_return_on_affected_item',
                {
                  count: claim.items.length,
                }
              )}
            </ModalTitle>
          </ModalHeader>

          <div className="flex flex-col gap-6">
            <p className="text-sm text-gray-700">
              {t(
                'order.modals.modal_add_return_slip.you_can_choose_to_return_or_refund_at_any_point'
              )}
            </p>
            <div className="flex items-center gap-4 rounded border border-gray-300 bg-[#FBFBFB] p-6 text-gray-900">
              <Icon icon="information" className="size-6" />
              <span className="text-sm font-semibold">
                {t('order.modals.modal_add_return_slip.confirm_return_info')}
              </span>
            </div>
            <div className="flex flex-col gap-2">
              <h5 className="font-semibold">
                {t('order.modals.modal_add_return_slip.affected_item', {
                  count: claim.items.length,
                })}
              </h5>
              <div className="flex flex-col gap-2">
                <div className="grid grid-cols-2 gap-4">
                  {fields.map((field, index) => (
                    <label key={field.id} className="w-full">
                      <div
                        className={cn(
                          'relative flex flex-col justify-center rounded p-4 transition duration-300 focus-within:ring-2 focus-within:ring-opacity-30',
                          {
                            'border-2 border-green-900 focus-within:ring-green-900':
                              field.selected && field.isReturnable,
                            'border border-gray-900 focus-within:ring-gray-900 hover:border-gray-900':
                              !field.selected && field.isReturnable,
                            'cursor-not-allowed border border-gray-300':
                              !field.isReturnable,
                            'cursor-pointer': field.isReturnable,
                          }
                        )}
                      >
                        <div className="flex items-start justify-between space-x-3">
                          <ProductViewProductDetails item={field} />
                          <div className="flex h-14 items-center">
                            <FormField
                              control={form.control}
                              name={`products.${index}.selected`}
                              disabled={!field.isReturnable}
                              render={({ field }) => (
                                <FormItem>
                                  <FormControl>
                                    <Checkbox
                                      {...field}
                                      value=""
                                      checked={field.value}
                                      onCheckedChange={(checked) => {
                                        field.onChange(checked);
                                        form.trigger('products');
                                      }}
                                    />
                                  </FormControl>
                                </FormItem>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </label>
                  ))}
                </div>
                {form.formState.errors.products?.root?.type === 'atLeastOne' ? (
                  <p className="text-xs text-red-600">
                    {t(
                      'order.modals.modal_add_return_slip.products.validation_errors.at_least_one'
                    )}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <h5 className="font-semibold">
                {t('order.modals.modal_add_return_slip.tracking_information')}
              </h5>
              <TrackingNumberFormFields
                appearance={undefined}
                detectedCouriers={[]}
              />
              <FormField
                control={form.control}
                name="file"
                rules={{
                  required: true,
                }}
                render={({ field, fieldState: { error } }) => (
                  <FormItem>
                    <FormControl>
                      <ReturnSlipFileUpload
                        value={field.value}
                        onValueChange={(file) => field.onChange(file)}
                        fieldError={error}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>
          </div>
        </div>
        <ModalFooter>
          <Button
            type="button"
            appearance="outline"
            onClick={() => setIsOpen(false)}
          >
            {t('cancel')}
          </Button>
          <Button type="submit" disabled={uploadLoading || joinLoading}>
            {t('order.modals.modal_add_return_slip.send_return_slip')}
          </Button>
        </ModalFooter>
      </form>
    </Form>
  );
};

export type ProductViewProductDetailsProps = {
  item: ReturnSlipItem;
};

export const ProductViewProductDetails: React.FC<
  ProductViewProductDetailsProps
> = ({ item }) => {
  const { t } = useTranslation();

  return (
    <div className="flex items-start space-x-3">
      <img
        src={item.imageUrl}
        alt=""
        className="size-14 rounded-lg border-2 border-white object-contain"
      />
      <div className="flex min-h-14 flex-1 flex-col justify-center space-y-1">
        <p className="text-sm font-semibold text-gray-900">{item.name}</p>
        {item.size ? (
          <p className="text-xs text-gray-700">
            <span>{item.size}</span>
          </p>
        ) : null}
        {item.sku ? (
          <p className="text-xs text-gray-700">
            {t('order.sku')} <span>{item.sku}</span>
          </p>
        ) : null}
        {!item.isReturnable ? (
          <p className="text-xs text-orange-600">
            {t('order.product_not_eligible_for_return')}
          </p>
        ) : null}
      </div>
    </div>
  );
};
