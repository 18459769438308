import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Loader from '@appchoose/loader';
import { useSessionStorageValue } from '@react-hookz/web';
import { useSetAtom } from 'jotai';

import { brandState } from '../../stores/brand';
import { StoreRegion } from '../../types/generated';
import { useBrandInfoQuery } from '../../types/generated-new';
import type { BrandMatch } from '../../types/navigation';

type BasicBrandInfoProps = {
  children: React.ReactNode;
};

export const BasicBrandInfo: React.FC<BasicBrandInfoProps> = ({
  children,
}: BasicBrandInfoProps) => {
  const { brandId } = useParams<BrandMatch>();
  const { set: setBrandId } = useSessionStorageValue<string>('brandId');

  const setBrand = useSetAtom(brandState);

  useEffect(() => {
    setBrandId(brandId ?? '');
  }, []);

  const { data: brandInfoData, isLoading } = useBrandInfoQuery(
    {
      brandId: brandId ?? '',
    },
    {
      enabled: !!brandId && brandId !== 'undefined',
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (brandInfoData?.brandInfo) {
      setBrand({
        id: brandId ?? '',
        name: brandInfoData.brandInfo.name,
        accept_contract: brandInfoData.brandInfo.acceptContract,
        nbUsers: brandInfoData.brandInfo.nbUsers,
        store: StoreRegion.Fr,
        need_refresh_info: false,
      });
    }
  }, [brandInfoData]);

  if (isLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <Loader className="size-8" />
      </div>
    );
  }

  return children;
};

BasicBrandInfo.displayName = 'BasicBrandInfo';
