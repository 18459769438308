import { useTranslation } from 'react-i18next';

import Flag from '@appchoose/flag';
import Icon from '@appchoose/icon';
import { useAtomValue } from 'jotai';

import { brandState, serverTimeState } from '../../stores/brand';
import { activeSaleIdState, isCurrent, isFuture } from '../../stores/sales';
import type { Sale } from '../../types/generated';
import { StoreRegion } from '../../types/generated';
import { transformDateSale, transformDateSimple } from '../../utils/date';

type SalesSelectorItemProps = {
  sale: Sale;
  showFlag: boolean;
};

export const SalesSelectorItem: React.FC<SalesSelectorItemProps> = ({
  sale,
  showFlag,
}: SalesSelectorItemProps) => {
  const brand = useAtomValue(brandState);
  const { i18n, t } = useTranslation();

  const serverTime = useAtomValue(serverTimeState);
  const activeSaleId = useAtomValue(activeSaleIdState);

  return (
    <>
      <div className="flex flex-1 flex-col justify-center gap-1">
        <p className="flex items-center gap-2 text-base font-semibold text-gray-900">
          <span>
            {sale.selection_name ??
              transformDateSale(
                new Date(sale.start_at ?? ''),
                i18n.language === 'fr' ? 'fr' : 'en',
                brand?.timezone ?? undefined
              )}
          </span>

          {showFlag ? (
            <Flag
              flag={sale.store === StoreRegion.Us ? 'US' : 'EU'}
              viewBox="0 0 24 24"
              className="w-[18px]"
            />
          ) : null}
          {isFuture(sale, serverTime) ? (
            <span className="text-xs font-semibold text-green-900">
              {t('sale.next')}
            </span>
          ) : null}
          {isCurrent(sale, serverTime) ? (
            <span className="text-xs font-semibold text-green-900">
              {t('sale.current')}
            </span>
          ) : null}
        </p>
        <p className="flex items-center gap-2 text-sm text-gray-700">
          {transformDateSimple(
            new Date(sale.start_at ?? ''),
            i18n.language === 'fr' ? 'fr' : 'en',
            true,
            brand?.timezone ?? undefined
          )}
          <Icon icon="back" className="rotate-180" />
          {sale.end_at
            ? transformDateSimple(
                new Date(sale.end_at),
                i18n.language === 'fr' ? 'fr' : 'en',
                true,
                brand?.timezone ?? undefined
              )
            : ''}
        </p>
      </div>
      {sale.id === activeSaleId ? (
        <div className="peer flex aspect-square size-[18px] items-center justify-center rounded-full border border-gray-700 text-gray-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-900/30 data-[state=checked]:text-green-900">
          <span className="flex items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="size-3 fill-current text-current transition duration-300"
            >
              <circle cx="12" cy="12" r="9" />
            </svg>
          </span>
        </div>
      ) : null}
    </>
  );
};
