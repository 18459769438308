import { RudderAnalytics } from '@rudderstack/analytics-js/bundled';

import { capitalizeFirstLetter } from './string';

const analytics = new RudderAnalytics();

export function rudderInitialize() {
  analytics.load(
    import.meta.env.REACT_APP_RUDDERSTACK_API_KEY as string,
    import.meta.env.REACT_APP_RUDDERSTACK_DATAPLANE_URL as string,
    {
      integrations: { All: true },
    }
  );
}

export const setUserId = (userId: string) => {
  analytics.identify(userId);
};

export const pageView = (pageName: string) => {
  const pathnameParts = location.pathname.split('/');
  if (
    pathnameParts.length > 1 &&
    pathnameParts[1] &&
    (pathnameParts[1].length === 25 || pathnameParts[1] === 'demo')
  ) {
    pathnameParts.splice(1, 1);
  }

  // Custom page names for onboarding
  let sentPageName = capitalizeFirstLetter(pageName);
  switch (pageName) {
    case 'onboarding_0':
      sentPageName = '_onboarding_step_introduction_';
      break;
    case 'onboarding_1':
      sentPageName = '_onboarding_step_create_account_';
      break;
    case 'onboarding_2':
      sentPageName = '_onboarding_step_shipping_country_';
      break;
    case 'onboarding_2_1':
      sentPageName = '_onboarding_step_shipping_carrier_';
      break;
    case 'onboarding_2_2':
      sentPageName = '_onboarding_step_shipping_delay_';
      break;
    case 'onboarding_3':
      sentPageName = '_onboarding_step_contact_info_';
      break;
    case 'onboarding_4':
      sentPageName = '_onboarding_step_return_address_';
      break;
    case 'onboarding_5':
      sentPageName = '_onboarding_step_billing_info_';
      break;
    case 'onboarding_5_1':
      sentPageName = '_onboarding_step_billing_bank_';
      break;
    case 'onboarding_5_2':
      sentPageName = '_onboarding_step_billing_confirmation_';
      break;
    case 'onboarding_6':
      sentPageName = '_onboarding_step_commitment_';
      break;
  }

  analytics.track(`View${sentPageName}Page`, {
    path: pathnameParts.join('/'),
  });
};

export const track = (
  eventType: string,
  eventProperties: Record<string, string | number | string[]>
) => {
  analytics.track(eventType, eventProperties);
};
