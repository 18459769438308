import React from 'react';
import { useTranslation } from 'react-i18next';

import Avatar from '@appchoose/avatar';
import Icon from '@appchoose/icon';
import Illustration from '@appchoose/illustration';
import { useAtomValue } from 'jotai';

import { activeSaleState } from '../../stores/sales';
import {
  findContact,
  logisticsManagerDefaultContact,
} from '../../types/choose-contact';
import { formatInternationalPhoneNumber } from '../../utils/phone';

export const ModalStockUpdateImpossibleToChange: React.FC = () => {
  const { t } = useTranslation();
  const activeSale = useAtomValue(activeSaleState);

  const logisticsManagerContact = findContact(
    activeSale?.logistics_manager ?? '',
    logisticsManagerDefaultContact
  );

  return (
    <div className="flex flex-col overflow-hidden">
      <div className="relative flex flex-auto flex-col items-center space-y-6 overflow-y-auto p-6 md:p-10">
        <Illustration
          illustration="desktopUnavailable"
          className="mt-4 shrink-0 md:mt-8"
        />
        <h3
          id="modal-stock-title"
          className="text-center text-2xl font-bold text-gray-900"
        >
          {t('stock.coupon_title')}
        </h3>
        <p className="text-center text-sm text-gray-700">
          {t('stock.coupon_p')}
        </p>
        <div className="flex w-full max-w-80 flex-row justify-between space-x-8 rounded-lg bg-beige-300 p-4">
          <div className="space-y-1">
            <h4 className="font-bold text-gray-900">{`${logisticsManagerContact.firstName} ${logisticsManagerContact.lastName}`}</h4>
            <div className="space-y-2 text-sm text-gray-700">
              {logisticsManagerContact.phoneNumber ? (
                <p className="flex items-center">
                  <Icon icon="phone" className="mr-2" />
                  {formatInternationalPhoneNumber(
                    logisticsManagerContact.phoneNumber,
                    'FR'
                  )}
                </p>
              ) : null}
              <p className="flex items-center">
                <Icon icon="mail" className="mr-2" />
                {logisticsManagerContact.email}
              </p>
            </div>
          </div>
          <Avatar
            src={`https://public.choose.app/avatars/80x80/${logisticsManagerContact.avatar}-80x80.png`}
            srcSet={`https://public.choose.app/avatars/80x80/${logisticsManagerContact.avatar}-80x80@2x.png 2x,
                          https://public.choose.app/avatars/80x80/${logisticsManagerContact.avatar}-80x80@3x.png 3x`}
            className="h-14 w-14"
          />
        </div>
      </div>
    </div>
  );
};

ModalStockUpdateImpossibleToChange.displayName = 'ModalStock';
