import { useNavigate, useParams } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';
import { useSessionStorageValue } from '@react-hookz/web';

import type { BrandMatch } from '../../types/navigation';

export const RedirectIfLogged = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const navigate = useNavigate();
  const { brandId = '' } = useParams<BrandMatch>();
  const { value: redirectUri } = useSessionStorageValue<string>('redirectUri');

  const { isAuthenticated } = useAuth0();

  if (isAuthenticated) {
    if (redirectUri) {
      navigate(redirectUri, { replace: true });
    } else {
      navigate(`/${brandId}/home`, { replace: true });
    }
  }

  return children;
};
