import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import cn from '@appchoose/cn';
import Icon from '@appchoose/icon';
import { toast } from '@appchoose/toast';
import * as Sentry from '@sentry/react';

import { useCopyToClipboard } from '../../hooks/use-copy-to-clipboard';
import type { ChooseContact } from '../../types/choose-contact';
import { track } from '../../utils/analytics';

type AmContactProps = {
  className?: string;
  from: string;
  contact: ChooseContact;
};

export const AmContact: React.FC<AmContactProps> = ({
  className,
  contact,
  from,
}) => {
  const { t } = useTranslation();

  return (
    <div className={cn('flex flex-col justify-center gap-6', className)}>
      <div className="flex items-center gap-4">
        <AmContactImage avatar={contact.avatar} name={contact.firstName} />
        <div className="flex flex-col gap-[0.12rem]">
          <div className="font-bold leading-5.5 text-gray-900">
            {t('invoice.invoice_locked.am_contact.account_manager', {
              name: contact.firstName,
            })}
          </div>
          <div className="max-w-[13.875rem] text-xs leading-[1.125rem] text-gray-700">
            {t('invoice.invoice_locked.am_contact.need_help')}
          </div>
        </div>
      </div>

      <AmContactCopyButton email={contact.email} from={from} />
    </div>
  );
};

type AmContactImageProps = {
  avatar: string;
  name: string;
};

export const AmContactImage: React.FC<AmContactImageProps> = ({
  avatar,
  name,
}) => {
  const { t } = useTranslation();

  return (
    <img
      src={`https://public.choose.app/avatars/80x80/${avatar}-80x80.png`}
      srcSet={`https://public.choose.app/avatars/80x80/${avatar}-80x80@2x.png 2x,
       https://public.choose.app/avatars/80x80/${avatar}-80x80@3x.png 3x`}
      className="inline-block size-14 rounded-full ring-1 ring-gray-100"
      alt={t('invoice.invoice_locked.am_contact.profile_picture', {
        name: name,
      })}
    />
  );
};

type AmContactCopyButtonProps = {
  className?: string;
  email: string;
  from: string;
};

export const AmContactCopyButton: React.FC<AmContactCopyButtonProps> = ({
  className,
  email,
  from,
}) => {
  const { t } = useTranslation();
  const [emailClipboardState, copyEmailToClipboard] = useCopyToClipboard();

  useEffect(() => {
    const { value, error } = emailClipboardState;
    if (value) {
      toast.success(t('clipboard.email_copied'));
    }
    if (error) {
      Sentry.captureException(error);
    }
  }, [emailClipboardState, t]);

  return (
    <button
      onClick={() => {
        copyEmailToClipboard(email);
        track('CopyToClipboard', {
          label: 'am_contact_email',
          from: from,
        });
      }}
      type="button"
      className={cn(
        'flex place-content-center items-center gap-1 rounded border border-gray-100 bg-[#FBFBFB] p-2 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus-visible:ring-2 focus-visible:ring-gray-100 disabled:bg-gray-100 disabled:text-[#9DA0A1]',
        className
      )}
    >
      <span>{email}</span>

      <Icon icon="copy" className="size-3.5" />
    </button>
  );
};
