'use client';

import * as React from 'react';
import { DayPicker } from 'react-day-picker';

import cn from '@appchoose/cn';
import Icon from '@appchoose/icon';

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn('p-3', className)}
      classNames={{
        months: 'flex flex-col space-y-4 sm:space-y-0 relative',
        month: 'space-y-4',
        month_caption: 'inline-flex pt-1.5 relative items-center',
        caption_label:
          'inline-flex items-center text-xl leading-5 font-semibold gap-x-1 rounded-sm peer-focus-visible:outline outline-2 outline-gray-900',
        nav: 'space-x-1 flex items-center absolute right-0 h-8',
        button_previous:
          'size-7 flex justify-center items-center bg-transparent p-0 text-gray-900 disabled:opacity-50',
        button_next:
          'size-7 flex justify-center items-center bg-transparent p-0 text-gray-900 disabled:opacity-50',
        month_grid: 'w-full border-collapse space-y-1',
        weekdays: 'flex',
        weekday: 'text-gray-500 uppercase rounded-md w-9 font-semibold text-xs',
        week: 'flex w-full mt-2',
        day: 'rounded size-9 text-center text-sm p-0 relative hover:bg-gray-900/[0.06] data-[disabled]:hover:bg-transparent [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected])]:bg-green-900/50 [&:has([aria-selected])]:bg-green-900 first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20',
        day_button:
          'size-9 p-0 font-normal aria-selected:opacity-100 rounded disabled:text-[#5F6365]',
        range_end: 'day-range-end',
        selected: 'bg-green-900 hover:bg-green-900 text-white rounded',
        today: 'bg-green-300 hover:bg-green-300',
        outside: 'text-gray-300 hover:bg-gray-900/[0.06] rounded',
        disabled: 'text-gray-300 opacity-50',
        range_middle:
          'aria-selected:bg-accent aria-selected:text-accent-foreground',
        hidden: 'invisible',
        dropdowns: 'relative inline-flex items-center relative gap-x-2 ml-2',
        dropdown_root: 'relative inline-flex items-center',
        dropdown:
          'opacity-0 w-full appearance-none absolute peer left-0 right-0 inline-flex items-center',
        ...classNames,
      }}
      formatters={{
        formatMonthDropdown: (date) =>
          date.toLocaleDateString(props.locale?.code ?? 'en-US', {
            month: 'short',
          }),
      }}
      components={{
        Chevron: (props) => {
          if (props.orientation === 'left') {
            return (
              <Icon
                icon="arrowDown"
                {...props}
                className="size-5 rotate-90"
                size={undefined}
              />
            );
          }
          if (props.orientation === 'right') {
            return (
              <Icon
                icon="arrowDown"
                {...props}
                className="size-5 -rotate-90"
                size={undefined}
              />
            );
          }

          return (
            <Icon
              icon="arrowDown"
              {...props}
              className="size-4"
              size={undefined}
            />
          );
        },
      }}
      {...props}
    />
  );
}
Calendar.displayName = 'Calendar';

export { Calendar };
