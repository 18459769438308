import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from '@appchoose/button';
import { Form } from '@appchoose/form';
import Loader from '@appchoose/loader';
import { Modal, ModalContent } from '@appchoose/modal';
import { toast } from '@appchoose/toast';
import { useAuth0 } from '@auth0/auth0-react';
import { useQueryClient } from '@tanstack/react-query';
import type { TFunction } from 'i18next';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

import { activeSaleState } from '../../stores/sales';
import type {
  DetectCouriersQuery,
  OrderQuery,
} from '../../types/generated-new';
import {
  useCreateOrderParcelMutation,
  useDetectCouriersQuery,
  useGroupOrderParcelMutation,
  useOrderQuery,
  useOrdersByTrackingQuery,
  useUsedCouriersQuery,
} from '../../types/generated-new';
import { isScopeAuthorized } from '../../utils/auth';
import { EstimatedDeliveryDates } from './estimated-delivery-dates';
import { ModalAddTrackingAlreadyAssignedDifferentRecipient } from './modal-add-tracking-already-assigned-different-recipient';
import { ModalAddTrackingAlreadyAssignedSameRecipient } from './modal-add-tracking-already-assigned-same-recipient';
import {
  TrackingNumberFormFields,
  searchCourierState,
  selectedCourierState,
} from './tracking-number-form-fields';

type TrackingInfoFormProps = {
  orderId: string;
  parcel?:
    | OrderQuery['order']['parcels'][0]
    | OrderQuery['order']['returnParcels'][0];
  shipping?: OrderQuery['order']['shipping'];
};

export type TrackingInfoFormData = {
  trackingCourierSlug: string;
  trackingNumber: string;
};

export enum DetectCourrierError {
  NO_COURIER_FOUND = 'NO_COURIER_FOUND',
  INVALID_COURIER = 'INVALID_COURIER',
}

export const getDetectCourierError = (
  courier: string,
  detectCouriersData?: DetectCouriersQuery['detectCouriers']
) => {
  if (detectCouriersData === undefined) return;

  if (detectCouriersData.length === 0) {
    return DetectCourrierError.NO_COURIER_FOUND;
  }
  if (
    detectCouriersData.length > 0 &&
    !detectCouriersData.map((courier) => courier.slug).includes(courier)
  )
    return DetectCourrierError.INVALID_COURIER;
};

const getTrackingErrorMessage = (errorMessage: string, t: TFunction) => {
  switch (errorMessage) {
    case 'an_other_order_already_exist_with_the_tracking_info':
      return t('tracking.order_already_exist_with_the_tracking_info');
    case 'order_already_fulfilled':
      return t('tracking.order_already_fulfilled');
    default:
      return t('error');
  }
};

const getTrackingMessageFromError = (e: unknown, t: TFunction) => {
  if (Array.isArray(e)) {
    e.forEach((error: Error) => {
      return getTrackingErrorMessage(error.message, t);
    });
  } else if (e instanceof Error) {
    return getTrackingErrorMessage(e.message, t);
  } else {
    return t('error');
  }

  return '';
};

export const TrackingInfoForm: React.FC<TrackingInfoFormProps> = ({
  orderId,
  shipping,
}: TrackingInfoFormProps) => {
  const { user } = useAuth0();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const activeSale = useAtomValue(activeSaleState);
  const setSearchCourier = useSetAtom(searchCourierState);
  const [selectedCourier, setSelectedCourier] = useAtom(selectedCourierState);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const { data: order } = useOrderQuery(
    {
      orderId: orderId,
    },
    {
      enabled: !!orderId,
      refetchOnWindowFocus: false,
      select: (result) => result.order,
    }
  );

  const { refetch: refetchUsedCouriers } = useUsedCouriersQuery(
    {},
    {
      select: (data) => data.usedCouriers,
      enabled: false,
    }
  );

  const form = useForm<TrackingInfoFormData>({
    mode: 'onTouched',
    defaultValues: async () => {
      const usedCouriers = await refetchUsedCouriers({});
      const firstUsedCourier = usedCouriers?.data?.[0];
      if (firstUsedCourier) {
        setSearchCourier(firstUsedCourier?.name ?? '');
        setSelectedCourier(firstUsedCourier);
      }
      return {
        trackingCourierSlug: firstUsedCourier?.slug ?? '',
        trackingNumber: '',
      };
    },
  });

  const {
    data: detectCouriersData,
    isFetching: isDetectCouriersFetching,
    refetch: refetchDetectCouriers,
  } = useDetectCouriersQuery(
    {
      input: {
        orderId: orderId,
        trackingNumber: form.getValues('trackingNumber') ?? '',
      },
    },
    {
      select: (data) => data.detectCouriers,
      enabled: false,
    }
  );
  const {
    data: ordersByTrackingData,
    isFetching: isOrdersByTrackingFetching,
    refetch: refetchOrdersByTracking,
  } = useOrdersByTrackingQuery(
    {
      input: {
        saleId: activeSale?.id ?? '',
        trackingCourierSlug: form.getValues('trackingCourierSlug') ?? '',
        trackingNumber: form.getValues('trackingNumber') ?? '',
      },
    },
    {
      select: (data) => data.ordersByTracking,
      enabled: false,
    }
  );

  const {
    mutateAsync: createOrderParcelMutation,
    isPending: createOrderParcelLoading,
    error: createOrderParcelError,
    reset: resetCreateOrderParcel,
  } = useCreateOrderParcelMutation();
  const {
    mutateAsync: groupOrderParcelMutation,
    isPending: groupOrderParcelLoading,
    reset: resetGroupOrderParcel,
  } = useGroupOrderParcelMutation();

  const onSubmit = async (data: TrackingInfoFormData) => {
    const detectCouriersResult = await refetchDetectCouriers();

    if (detectCouriersResult.data === undefined) return;
    const detectCourierError = getDetectCourierError(
      data.trackingCourierSlug,
      detectCouriersResult.data
    );
    if (!detectCourierError) {
      await checkOrdersByTracking();
    }
  };

  const checkOrdersByTracking = async () => {
    const ordersByTrackingResult = await refetchOrdersByTracking();

    if (ordersByTrackingResult.data === undefined) return;
    if (ordersByTrackingResult.data.length > 0) {
      setModalOpen(true);
    } else {
      createParcel({
        trackingCourierSlug: form.getValues('trackingCourierSlug'),
        trackingNumber: form.getValues('trackingNumber'),
      });
    }
  };

  const createParcel = async (data: TrackingInfoFormData) => {
    try {
      await createOrderParcelMutation({
        input: {
          orderId: orderId,
          trackingInfo: {
            trackingCourierSlug: data.trackingCourierSlug,
            trackingNumber: data.trackingNumber,
          },
        },
      });

      queryClient.invalidateQueries({
        queryKey: ['order', { orderId: orderId }],
      });

      toast.success(t('order.tracking.tracking_added'));
    } catch (error) {
      toast.error(getTrackingMessageFromError(error, t));
    }
  };

  const groupParcels = async () => {
    try {
      await groupOrderParcelMutation({
        input: {
          orderId: orderId,
          trackingInfo: {
            trackingCourierSlug: form.getValues('trackingCourierSlug'),
            trackingNumber: form.getValues('trackingNumber') ?? '',
          },
        },
      });

      setModalOpen(false);

      queryClient.invalidateQueries({
        queryKey: ['order', { orderId: orderId }],
      });

      toast.success(t('order.tracking.tracking_added'));
    } catch (error) {
      toast.error(getTrackingMessageFromError(error, t));
    }
  };

  const isFetching =
    isDetectCouriersFetching ||
    isOrdersByTrackingFetching ||
    createOrderParcelLoading;
  let appearance: 'warning' | 'danger' | undefined = undefined;
  if (
    getDetectCourierError(
      form.getValues('trackingCourierSlug'),
      detectCouriersData
    )
  ) {
    appearance = 'warning';
  }
  if (createOrderParcelError) {
    appearance = 'danger';
  }

  const submitButton =
    detectCouriersData !== undefined ? (
      <Button
        type="button"
        appearance="primary"
        className="justify-center"
        disabled={
          !isScopeAuthorized(user, 'scope.brand.order.parcel.write') ||
          isFetching ||
          !!createOrderParcelError
        }
        onClick={() => checkOrdersByTracking()}
      >
        {t('order.tracking.confirm_add_tracking_number')}
      </Button>
    ) : (
      <Button
        type="submit"
        appearance="primary"
        className="justify-center"
        disabled={
          !isScopeAuthorized(user, 'scope.brand.order.parcel.write') ||
          isFetching ||
          !!createOrderParcelError
        }
      >
        {t('order.tracking.add_tracking_number')}
      </Button>
    );

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col space-y-4"
      >
        <div className="flex items-center justify-between">
          <div className="text-sm text-gray-700">
            {t('order.announced_delivery')}{' '}
            <EstimatedDeliveryDates
              history={shipping?.deliveryDateRangeHistory}
            />
          </div>
        </div>

        <TrackingNumberFormFields
          appearance={appearance}
          detectedCouriers={detectCouriersData}
          onReset={() => {
            resetCreateOrderParcel();
            resetGroupOrderParcel();
          }}
        />

        {isFetching ? (
          <div className="flex items-center justify-center space-x-2 p-2 text-sm">
            <Loader className="size-4" />
            <span className="text-gray-700">
              {t('order.tracking.check_compatibility')}
            </span>
          </div>
        ) : (
          submitButton
        )}
        <Modal open={modalOpen} onOpenChange={() => setModalOpen(false)}>
          <ModalContent scrollable>
            {ordersByTrackingData?.every(
              (o) =>
                o.customerId === order?.customerId &&
                o.recipient.id === order?.recipient.id
            ) ? (
              <ModalAddTrackingAlreadyAssignedSameRecipient
                detectedOrders={ordersByTrackingData}
                isPending={groupOrderParcelLoading}
                onConfirm={() => groupParcels()}
                trackingInfo={{
                  orderId: orderId,
                  trackingNumber: form.getValues('trackingNumber'),
                  courier: selectedCourier,
                }}
              />
            ) : (
              <ModalAddTrackingAlreadyAssignedDifferentRecipient
                detectedOrders={ordersByTrackingData ?? []}
              />
            )}
          </ModalContent>
        </Modal>
      </form>
    </Form>
  );
};
