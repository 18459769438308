import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@appchoose/button';
import { Modal, ModalContent, ModalTrigger } from '@appchoose/modal';
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from '@appchoose/tooltip';
import { useAuth0 } from '@auth0/auth0-react';

import { useSupportChat } from '../../hooks/use-support-chat';
import type { OrderQuery } from '../../types/generated-new';
import { track } from '../../utils/analytics';
import { isScopeAuthorized } from '../../utils/auth';
import { ModalAddReturnSlip } from './modal-add-return-slip';
import { ModalConfirmRefund } from './modal-confirm-refund';
import { ModalConfirmReturn } from './modal-confirm-return';
import { ModalDigitalCodeAlreadySolved } from './modal-digital-code-already-solved';
import { ModalNewDigitalCode } from './modal-new-digital-code';

export type ClaimActionsProps = {
  claim: OrderQuery['order']['claims'][0];
  isFullDigital: OrderQuery['order']['isFullDigital'];
  orderId: OrderQuery['order']['id'];
  order?: OrderQuery['order'];
};

export const ClaimActions: React.FC<ClaimActionsProps> = ({
  claim,
  isFullDigital,
  orderId,
  order,
}: ClaimActionsProps) => {
  const { user } = useAuth0();
  const { t } = useTranslation();
  const { openSupportChatWithPrePopulatedContent } = useSupportChat();

  const [displayModalReturn, setDisplayModalReturn] = useState(false);
  const [displayModalRefund, setDisplayModalRefund] = useState(false);
  const [displayModalReturnSlip, setDisplayModalReturnSlip] = useState(false);
  const [displayModalNewDigitalCode, setDisplayModalNewDigitalCode] =
    useState(false);
  const [
    displayModalDigitalCodeAlreadySolved,
    setDisplayModalDigitalCodeAlreadySolved,
  ] = useState(false);

  const openModalRefund = () => {
    track('OpenModalRefund', {});
  };

  const openModalReturn = () => {
    track('OpenModalProductReturn', {});
  };

  const openModalReturnSlip = () => {
    track('OpenModalProductReturnSlip', {});
  };

  const openModalNewDigitalCode = () => {
    track('OpenModalNewDigitalCode', {});
  };

  const openModalDigitalCodeAlreadySolved = () => {
    track('OpenModalDigitalCodeAlreadySolved', {});
  };

  const onOpenChat = () => {
    track('OpenHelpChat', {
      from: 'ShippingComponent',
    });
    openSupportChatWithPrePopulatedContent(
      t('support_chat.intercom_message_prefill', {
        orderId,
        customer: order?.recipient.fullName,
      })
    );
  };

  return (
    <div className="mt-4 flex flex-col border-t border-gray-300 pt-4">
      <h3 className="mb-2 text-sm font-semibold text-gray-900">
        {t('order.solutions_proposed')}
      </h3>
      {!isFullDigital ? (
        <div className="flex flex-col gap-4">
          <Modal open={displayModalReturn} onOpenChange={setDisplayModalReturn}>
            <ModalTrigger asChild>
              <Button
                onClick={openModalReturn}
                type="button"
                appearance="primary"
                className="justify-center"
                disabled={
                  !isScopeAuthorized(user, 'scope.brand.claims.write') ||
                  claim.reshipActionErrorReasons.length !== 0
                }
              >
                {t('order.actions_return_product', {
                  count: claim.items.length,
                })}
              </Button>
            </ModalTrigger>
            <ModalContent>
              <ModalConfirmReturn
                claim={claim}
                orderId={orderId}
                setIsOpen={setDisplayModalReturn}
              />
            </ModalContent>
          </Modal>

          <Modal open={displayModalRefund} onOpenChange={setDisplayModalRefund}>
            <ModalTrigger asChild>
              <Button
                type="button"
                appearance="outline"
                className="justify-center"
                disabled={!isScopeAuthorized(user, 'scope.brand.claims.write')}
                onClick={openModalRefund}
              >
                {t('order.actions_refund_product', {
                  count: claim.items.length,
                })}
              </Button>
            </ModalTrigger>
            <ModalContent>
              <ModalConfirmRefund
                claim={claim}
                orderId={orderId}
                order={order}
                setIsOpen={setDisplayModalRefund}
              />
            </ModalContent>
          </Modal>

          {order?.returnSlips.every(
            (returnSlip) => returnSlip.claimId !== claim.id
          ) &&
          order.returnParcels.every(
            (returnSlip) => returnSlip.claimId !== claim.id
          ) ? (
            <>
              <div className="flex items-center gap-6 lowercase">
                <hr className="h-px w-full border-none bg-gray-300" />
                <span className="text-xs text-gray-500">{t('or')}</span>
                <hr className="h-px w-full border-none bg-gray-300" />
              </div>

              <div className="text-center text-xs text-gray-900">
                {t('order.require_return')}
              </div>

              {order?.returnParcels.length > 0 ||
              order?.returnSlips.length > 0 ? (
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      onClick={openModalReturnSlip}
                      type="button"
                      appearance="outline"
                      className="justify-center"
                      disabled={true}
                    >
                      {t('order.send_return_slip')}
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <span>{t('order.return_already_exists')}</span>
                    <TooltipArrow />
                  </TooltipContent>
                </Tooltip>
              ) : (
                <Modal
                  open={displayModalReturnSlip}
                  onOpenChange={setDisplayModalReturnSlip}
                >
                  <ModalTrigger asChild>
                    <Button
                      onClick={openModalReturnSlip}
                      type="button"
                      appearance="outline"
                      className="justify-center"
                      disabled={
                        !isScopeAuthorized(user, 'scope.brand.claims.write')
                      }
                    >
                      {t('order.send_return_slip')}
                    </Button>
                  </ModalTrigger>
                  <ModalContent
                    scrollable
                    className="max-w-[55rem]"
                    onOpenAutoFocus={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <ModalAddReturnSlip
                      claim={claim}
                      orderId={orderId}
                      setIsOpen={setDisplayModalReturnSlip}
                    />
                  </ModalContent>
                </Modal>
              )}
            </>
          ) : null}
        </div>
      ) : (
        <div className="flex flex-col gap-4">
          <Modal
            open={displayModalNewDigitalCode}
            onOpenChange={setDisplayModalNewDigitalCode}
          >
            <ModalTrigger asChild>
              <Button
                onClick={openModalNewDigitalCode}
                type="button"
                appearance="primary"
                className="justify-center"
                disabled={!isScopeAuthorized(user, 'scope.brand.claims.write')}
              >
                {t('order.actions_generate_new_digital_code')}
              </Button>
            </ModalTrigger>
            <ModalContent scrollable>
              <ModalNewDigitalCode
                claim={claim}
                orderId={order?.id}
                setIsOpen={setDisplayModalNewDigitalCode}
              />
            </ModalContent>
          </Modal>

          <Modal
            open={displayModalDigitalCodeAlreadySolved}
            onOpenChange={setDisplayModalDigitalCodeAlreadySolved}
          >
            <ModalTrigger asChild>
              <Button
                onClick={openModalDigitalCodeAlreadySolved}
                type="button"
                appearance="outline"
                className="justify-center"
                disabled={!isScopeAuthorized(user, 'scope.brand.claims.write')}
              >
                {t('order.actions_digital_code_already_solved')}
              </Button>
            </ModalTrigger>
            <ModalContent scrollable>
              <ModalDigitalCodeAlreadySolved
                claim={claim}
                orderId={order?.id}
                setIsOpen={setDisplayModalDigitalCodeAlreadySolved}
              />
            </ModalContent>
          </Modal>

          <Modal open={displayModalRefund} onOpenChange={setDisplayModalRefund}>
            <ModalTrigger asChild>
              <Button
                onClick={openModalRefund}
                type="button"
                appearance="outline"
                className="justify-center"
                disabled={!isScopeAuthorized(user, 'scope.brand.claims.write')}
              >
                {t('order.actions_refund_product', {
                  count: claim.items.length,
                })}
              </Button>
            </ModalTrigger>
            <ModalContent>
              <ModalConfirmRefund
                claim={claim}
                order={order}
                orderId={order?.id}
                setIsOpen={setDisplayModalRefund}
              />
            </ModalContent>
          </Modal>
        </div>
      )}

      <div className="mt-4 border-t border-gray-300 pt-4 text-sm">
        <span className="text-gray-900">{t('order.an_issue')}</span>{' '}
        <button
          type="button"
          onClick={onOpenChat}
          className="font-semibold text-green-900"
        >
          {t('contact_us')}
        </button>
      </div>
    </div>
  );
};
