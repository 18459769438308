/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import type { Address } from '@appchoose/address';
import AddressFormFields from '@appchoose/address-form-fields';
import Button from '@appchoose/button';
import cn from '@appchoose/cn';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@appchoose/command';
import { Form, FormControl, FormField, FormItem } from '@appchoose/form';
import Icon from '@appchoose/icon';
import { Popover, PopoverContent, PopoverTrigger } from '@appchoose/popover';
import i18n from 'i18next';
import { useAtom } from 'jotai';
import { matchSorter } from 'match-sorter';

import { brandState } from '../../stores/brand';
import { StoreRegion, useUpdateSellerMutation } from '../../types/generated';
import { getDefaultFiscalCountry } from '../../utils/address';
import { getCountryOptions } from '../../utils/address-options';
import { removeAllSpaces } from '../../utils/string';
import { isInUE } from '../../utils/utils';
import { IbanFormFields } from '../brand-info/iban-form-fields';
import { LegalInfoFormFields } from '../brand-info/legal-info-form-fields';
import type { OnboardingStepBillingInfoForm } from '../onboarding/onboarding-step-billing-info';
import { TaxFormFields } from '../tax-form-fields/tax-form-fields';
import {
  UsBillingFormFields,
  accountTypeOptions,
} from '../us-billing-form-fields/us-billing-form-fields';

type SettingsBillingProps = {
  onPostSave: () => void;
};

type SettingsBillingFormData = {
  iban: string;
  beneficiaryName: string;
  accountType: string;
  accountNumber: string;
  routingCode: string;
  swiftCode: string;
  billingAddress: Address;
  bankAddress: Address;
} & Omit<
  OnboardingStepBillingInfoForm,
  'street' | 'street2' | 'bp' | 'province' | 'city' | 'country' | 'countryCode'
>;

export const SettingsBilling: React.FC<SettingsBillingProps> = ({
  onPostSave,
}) => {
  const [brand, setBrand] = useAtom(brandState);
  const { t } = useTranslation();

  const { mutateAsync: updateSellerMutation } = useUpdateSellerMutation();

  const defaultFiscalCountry = getDefaultFiscalCountry(
    brand?.billing_address?.countryCode ?? 'FR'
  );
  const countryOptions = getCountryOptions();

  const [open, setOpen] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');
  const listRef = useRef<HTMLDivElement>(null);
  const scrollId = useRef<number>();

  const countryMatches = useMemo(
    () => matchSorter(countryOptions, searchQuery, { keys: ['label'] }),
    [countryOptions, searchQuery]
  );

  const form = useForm<SettingsBillingFormData>({
    mode: 'onTouched',
    defaultValues: {
      fiscalCountryCode: defaultFiscalCountry?.code || '',
      legalEntityName: brand?.billing_address?.name ?? '',
      isNotSubjectVat: (brand && brand.is_autoentrepreneur) ?? false,
      siret: removeAllSpaces(brand?.siret ?? ''),
      taxId: brand?.tax_id ?? undefined,
      billingAddress: {
        street: brand?.billing_address?.street ?? '',
        street2: brand?.billing_address?.street2 ?? '',
        bp: brand?.billing_address?.bp ?? '',
        province: brand?.billing_address?.province ?? '',
        city: brand?.billing_address?.city ?? '',
        country: brand?.billing_address?.country ?? '',
        countryCode: brand?.billing_address?.countryCode ?? '',
      },
      bankAddress: {
        name: brand?.bank_address?.name ?? '',
        street: brand?.bank_address?.street ?? '',
        street2: brand?.bank_address?.street2 ?? '',
        bp: brand?.bank_address?.bp ?? '',
        city: brand?.bank_address?.city ?? '',
        province: brand?.bank_address?.province ?? '',
        country: brand?.bank_address?.country ?? '',
        countryCode: brand?.bank_address?.countryCode ?? '',
      },
      intracommunityVat: brand?.intracommunity_vat ?? undefined,
      iban: removeAllSpaces(brand?.iban ?? ''),
      beneficiaryName: brand?.beneficiary_name ?? '',
      accountType:
        brand && brand.routing_code && !brand.swift_code
          ? accountTypeOptions[1].value
          : accountTypeOptions[0].value,
      accountNumber: brand?.account_number ?? '',
      routingCode: brand?.routing_code ?? '',
      swiftCode: brand?.swift_code ?? '',
    },
  });

  const onSubmit = (data: SettingsBillingFormData) => {
    if (!brand) return;
    const bankAddress: Address = {
      name: data.bankAddress.name?.trim(),
      street: data.bankAddress.street.trim(),
      street2: data.bankAddress.street2?.trim(),
      bp: data.bankAddress.bp.trim(),
      city: data.bankAddress.city.trim(),
      province: data.bankAddress.province?.trim(),
      country: data.bankAddress.country.trim(),
      countryCode: data.bankAddress.countryCode.trim(),
    };
    const billingAddress: Address = {
      name: data.legalEntityName.trim(),
      street: data.billingAddress.street.trim(),
      street2: data.billingAddress.street2?.trim(),
      bp: data.billingAddress.bp.trim(),
      city: data.billingAddress.city.trim(),
      province: data.billingAddress.province?.trim(),
      country: data.billingAddress.country.trim(),
      countryCode: data.billingAddress.countryCode.trim(),
    };
    setBrand({
      ...brand,
      billing_address: billingAddress,
      bank_address: bankAddress,
      beneficiary_name: data.beneficiaryName,
      account_number: data.accountNumber,
      routing_code: data.routingCode,
      swift_code: data.swiftCode,
      iban: data.iban,
      is_autoentrepreneur: data.isNotSubjectVat,
      tax_id: data.taxId ?? '',
      intracommunity_vat: data.intracommunityVat,
      siret: data.siret ?? '',
    });

    updateSellerMutation({
      updateSeller: {
        iban: data.iban,
        siret: data.siret,
        address: billingAddress,
        bank_address: bankAddress,
        is_autoentrepreneur: data.isNotSubjectVat,
        tax_id: data.taxId,
        intracommunity_vat: data.intracommunityVat,
        beneficiary_name: data.beneficiaryName,
        account_number: data.accountNumber,
        routing_code: data.routingCode,
        swift_code: data.swiftCode,
      },
    });
    onPostSave();
  };

  form.watch('fiscalCountryCode');

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="mb-10 max-w-2.5xl space-y-14">
          {brand && brand.store === StoreRegion.Us && !brand.iban ? (
            <div className="flex flex-col gap-8">
              <h3 className="mb-8 text-2xl font-bold text-gray-900">
                {t('brand_info.legal_info_form_fields.bank_details.label')}
              </h3>
              <div className="flex flex-col gap-10">
                <UsBillingFormFields
                  autoFocus={true}
                  formPrefix="bankAddress"
                />
              </div>
            </div>
          ) : null}

          <div className="flex flex-col gap-8">
            <h3 className="text-2xl font-bold text-gray-900">
              {t('brand_info.legal_info_form_fields.fiscal_country.label')}
            </h3>
            <FormField
              control={form.control}
              name="fiscalCountryCode"
              render={({ field }) => {
                const selectedCountryOption = countryOptions.find(
                  (country) => country.value === field.value
                );
                return (
                  <FormItem>
                    <Popover open={open} onOpenChange={setOpen}>
                      <PopoverTrigger asChild>
                        <FormControl>
                          <button
                            type="button"
                            role="combobox"
                            aria-expanded={open}
                            className='form-input flex items-center justify-between rounded border-gray-500 text-sm leading-5.5 text-gray-900 transition duration-300 hover:border-gray-700 focus:border-gray-700 focus:ring-1 focus:ring-gray-700 aria-[invalid="true"]:border-red-600 aria-[invalid="true"]:ring-red-600'
                          >
                            <div className="flex items-center truncate">
                              {field.value
                                ? selectedCountryOption?.leftSection
                                : null}
                              <span className="truncate">
                                {field.value
                                  ? selectedCountryOption?.label
                                  : t('address.fields.country.empty_field')}
                              </span>
                            </div>
                            <Icon
                              icon={open ? 'close' : 'arrowDown'}
                              className="ml-2 size-4 shrink-0 opacity-50"
                            />
                          </button>
                        </FormControl>
                      </PopoverTrigger>
                      <PopoverContent className="p-0" align="start">
                        <Command shouldFilter={false}>
                          <CommandInput
                            placeholder={t(
                              'address.fields.country.placeholder_search'
                            )}
                            onValueChange={(value) => {
                              setSearchQuery(value);

                              //#region scroll list to top when typing
                              // https://github.com/pacocoursey/cmdk/issues/234
                              // https://github.com/pacocoursey/cmdk/issues/233

                              // clear pending scroll
                              if (scrollId.current)
                                cancelAnimationFrame(scrollId.current);

                              // the setTimeout is used to create a new task
                              // this is to make sure that we don't scroll until the user is done typing
                              // you can tweak the timeout duration ofc
                              scrollId.current = requestAnimationFrame(() => {
                                // inside your list select the first group and scroll to the top
                                listRef.current?.scrollTo({ top: 0 });
                              });

                              //#endregion
                            }}
                            value={searchQuery}
                          />
                          <CommandList ref={listRef}>
                            <CommandEmpty>
                              {t('address.fields.country.no_results')}
                            </CommandEmpty>

                            <CommandGroup>
                              {countryMatches.map((country) => (
                                <CommandItem
                                  key={country.value}
                                  value={country.value}
                                  keywords={[country.label]}
                                  onSelect={(currentValue) => {
                                    const newValue =
                                      currentValue === field.value
                                        ? ''
                                        : currentValue;
                                    field.onChange(newValue);
                                    form.setValue(
                                      'billingAddress.country',
                                      countryOptions.find(
                                        (option) => option.value === newValue
                                      )?.label ?? '',
                                      {
                                        shouldValidate: true,
                                      }
                                    );
                                    form.setValue(
                                      'billingAddress.countryCode',
                                      newValue
                                    );
                                    setOpen(false);
                                  }}
                                  className={cn(
                                    'flex items-center justify-between',
                                    {
                                      'font-semibold text-green-900':
                                        field.value === country.value,
                                    }
                                  )}
                                >
                                  <div className="flex items-center truncate">
                                    {country.leftSection}

                                    <span className="truncate">
                                      {country.label}
                                    </span>
                                  </div>
                                  <Icon
                                    icon="check"
                                    className={cn(
                                      'ml-2 size-4 shrink-0',
                                      field.value === country.value
                                        ? 'opacity-100'
                                        : 'opacity-0'
                                    )}
                                  />
                                </CommandItem>
                              ))}
                            </CommandGroup>
                          </CommandList>
                        </Command>
                      </PopoverContent>
                    </Popover>
                  </FormItem>
                );
              }}
            />
          </div>

          {brand?.store === StoreRegion.Fr ||
          (brand?.store === StoreRegion.Us && brand?.iban) ? (
            <div className="flex flex-col gap-8">
              <h3 className="text-2xl font-bold text-gray-900">
                {t('settings.billing_tabs.banking_info')}
              </h3>
              <IbanFormFields
                countryCode={form.getValues('fiscalCountryCode') ?? ''}
              />
            </div>
          ) : null}

          <div className="flex flex-col gap-8">
            <h3 className="text-2xl font-bold text-gray-900">
              {t('settings.billing_tabs.legal_info')}
            </h3>
            <div>
              <LegalInfoFormFields
                hasSiret={
                  form.getValues('fiscalCountryCode') === 'FR' &&
                  brand?.store === StoreRegion.Fr
                }
                hasTaxId={false}
              />
            </div>
          </div>
          {isInUE(form.getValues('fiscalCountryCode')) &&
          brand?.store === StoreRegion.Fr ? (
            <div className="flex flex-col gap-8">
              <h3 className="text-2xl font-bold text-gray-900">
                {t('settings.billing_tabs.tax_info')}
              </h3>
              <TaxFormFields
                countryCode={form.getValues('fiscalCountryCode') ?? ''}
              />
            </div>
          ) : null}
          <div className="flex flex-col gap-8">
            <h3 className="text-2xl font-bold text-gray-900">
              {t('settings.billing_tabs.address_info')}
            </h3>
            <AddressFormFields
              googleMapsApiKey={
                import.meta.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? ''
              }
              locale={i18n.language}
              showCompany={false}
              showName={false}
              showPhone={false}
              disabledCountry
              countryRestrictions={form.getValues('fiscalCountryCode')}
              translations={{
                suggestions: t('suggestions'),
                fieldsNameValidationErrorsRequired: t(
                  'address.fields.name.validation_errors.required'
                ),
                fieldsNameValidationErrorsMaxLength: t(
                  'address.fields.name.validation_errors.maxLength',
                  {
                    maxLength: '200',
                  }
                ),
                fieldsFirstnameValidationErrorsRequired: t(
                  'address.fields.firstname.validation_errors.required'
                ),
                fieldsFirstnameValidationErrorsMaxLength: t(
                  'address.fields.firstname.validation_errors.maxLength',
                  {
                    maxLength: '200',
                  }
                ),
                fieldsLastnameValidationErrorsRequired: t(
                  'address.fields.lastname.validation_errors.required'
                ),
                fieldsLastnameValidationErrorsMaxLength: t(
                  'address.fields.lastname.validation_errors.maxLength',
                  {
                    maxLength: '200',
                  }
                ),
                fieldsPhoneValidationErrorsRequired: t(
                  'address.fields.phone.validation_errors.required'
                ),
                fieldsPhoneValidationErrorsMaxLength: t(
                  'address.fields.phone.validation_errors.maxLength',
                  {
                    maxLength: '200',
                  }
                ),
                fieldsStreetValidationErrorsRequired: t(
                  'address.fields.street.validation_errors.required'
                ),
                fieldsStreetValidationErrorsMaxLength: t(
                  'address.fields.street.validation_errors.maxLength',
                  {
                    maxLength: '200',
                  }
                ),
                fieldsStreet2ValidationErrorsMaxLength: t(
                  'address.fields.street2.validation_errors.maxLength',
                  {
                    maxLength: '200',
                  }
                ),
                fieldsBpValidationErrorsRequired: t(
                  'address.fields.bp.validation_errors.required'
                ),
                fieldsBpValidationErrorsMaxLength: t(
                  'address.fields.bp.validation_errors.maxLength',
                  {
                    maxLength: '200',
                  }
                ),
                fieldsCityValidationErrorsRequired: t(
                  'address.fields.city.validation_errors.required'
                ),
                fieldsCityValidationErrorsMaxLength: t(
                  'address.fields.city.validation_errors.maxLength',
                  {
                    maxLength: '200',
                  }
                ),
                fieldsProvinceValidationErrorsRequired: t(
                  'address.fields.province.validation_errors.required'
                ),
                fieldsProvinceValidationErrorsMaxLength: t(
                  'address.fields.province.validation_errors.maxLength',
                  {
                    maxLength: '200',
                  }
                ),
                fieldsCountryValidationErrorsRequired: t(
                  'address.fields.country.validation_errors.required'
                ),
                fieldsNameLabel: t('address.fields.name.label_return'),
                fieldsNamePlaceholder: t('address.fields.name.placeholder'),
                fieldsFirstnameLabel: t('address.fields.firstname.label'),
                fieldsFirstnamePlaceholder: t(
                  'address.fields.firstname.placeholder'
                ),
                fieldsLastnameLabel: t('address.fields.lastname.label'),
                fieldsLastnamePlaceholder: t(
                  'address.fields.lastname.placeholder'
                ),
                fieldsPhoneLabel: t('address.fields.phone.label'),
                fieldsPhonePlaceholder: t('address.fields.phone.placeholder'),
                fieldsStreetLabel: t('address.fields.street.label'),
                fieldsStreetPlaceholder: t('address.fields.street.placeholder'),
                fieldsStreet2Label: t('address.fields.street2.label'),
                fieldsStreet2Placeholder: t(
                  'address.fields.street2.placeholder'
                ),
                fieldsBpLabel: t('address.fields.bp.label'),
                fieldsBpPlaceholder: '',
                fieldsCityLabel: t('address.fields.city.label'),
                fieldsCityPlaceholder: '',
                fieldsProvinceLabel: t('address.fields.province.label'),
                fieldsProvincePlaceholder: '',
                fieldsProvinceEmptyField: t(
                  'address.fields.province.empty_field'
                ),
                fieldsCountryLabel: t('address.fields.country.label'),
                fieldsCountryPlaceholder: t(
                  'address.fields.country.placeholder'
                ),
                fieldsCountryPlaceholderSearch: t(
                  'address.fields.country.placeholder_search'
                ),
                fieldsCountryEmptyField: t(
                  'address.fields.country.empty_field'
                ),
                fieldsCountryNoResults: t('address.fields.country.no_results'),
              }}
              formPrefix="billingAddress"
            />
          </div>
        </div>
        <Button type="submit">{t('save')}</Button>
      </form>
    </Form>
  );
};

SettingsBilling.displayName = 'SettingsBilling';
