import { useTranslation } from 'react-i18next';

import cn from '@appchoose/cn';
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from '@appchoose/tooltip';

import { Admin } from '../../components/icons/admin';

type AdminWithTooltipProps = {
  className?: string;
};

export const AdminWithTooltip: React.FC<AdminWithTooltipProps> = ({
  className,
}: AdminWithTooltipProps) => {
  const { t } = useTranslation();
  return (
    <Tooltip>
      <TooltipTrigger
        type="button"
        className={cn('cursor-default', className)}
        tabIndex={-1}
      >
        <Admin className="text-beige-600" />
      </TooltipTrigger>
      <TooltipContent>
        <div className="px-1.5 py-3">{t('invoice.tooltip_admin_only')}</div>
        <TooltipArrow />
      </TooltipContent>
    </Tooltip>
  );
};
