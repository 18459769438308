import { useTranslation } from 'react-i18next';

import { Clock } from '../../components/icons/clock';

export const InvoiceLockedSaleEndedWaitingWithdrawal: React.FC = () => {
  const { t } = useTranslation();

  return (
    <section className="flex flex-col gap-6 rounded border border-gray-100 bg-[#FBFBFB] p-6 md:flex-row md:items-center md:gap-8">
      <div className="flex flex-col gap-6 md:flex-row md:items-center">
        <Clock className="size-10 shrink-0 text-gray-900" />
        <div className="flex flex-col gap-1 text-gray-700">
          <h4 className="text-sm font-bold">
            {t(
              'invoice.invoice_locked.sale_ended_waiting_withdrawal.done_all_the_work'
            )}
          </h4>
          <p className="text-sm leading-5.5">
            {t(
              'invoice.invoice_locked.sale_ended_waiting_withdrawal.invoice_available_after_withdrawal_period'
            )}
          </p>
        </div>
      </div>
    </section>
  );
};
