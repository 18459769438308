import React from 'react';
import type { FileRejection } from 'react-dropzone';
import { useDropzone } from 'react-dropzone';
import type { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Icon from '@appchoose/icon';

import { Pdf } from '../icons/pdf';

const MAX_FILE_SIZE_MB = 20;

type ReturnSlipFileUploadStateProps = {
  file?: File;
  fileRejections: FileRejection[];
  isDragReject: boolean;
  isDragActive: boolean;
  onValueChange: (file: File | null) => void;
  fieldError?: FieldError;
};

const ReturnSlipFileUploadState: React.FC<ReturnSlipFileUploadStateProps> = ({
  file,
  fileRejections,
  isDragActive,
  onValueChange,
  fieldError,
}) => {
  const { t } = useTranslation();

  if (file) {
    return (
      <div className="flex flex-col items-center gap-2">
        <div className="flex flex-col items-center gap-4">
          <Pdf />
          <p className="text-balance text-center text-sm font-semibold text-gray-900">
            {file.name}
          </p>
        </div>
        <button
          type="button"
          className="text-sm font-bold text-green-900"
          onClick={(e) => {
            e.stopPropagation();
            onValueChange(null);
          }}
        >
          {t('orders.modals.import.remove')}
        </button>
      </div>
    );
  }

  return (
    <>
      <div className="space-y-2 text-center">
        <div className="text-gray-900">
          {t(
            'order.modals.modal_add_return_slip.file_upload.drag_and_drop_return_slip'
          )}{' '}
          <b className="cursor-pointer text-green-900">
            {t('order.modals.modal_add_return_slip.file_upload.select_file')}
          </b>
        </div>
        <div className="text-sm text-[#767A7C]">
          {t('order.modals.modal_add_return_slip.file_upload.requirements', {
            maxFileSize: MAX_FILE_SIZE_MB,
          })}
        </div>
      </div>
      {!isDragActive && (fileRejections.length > 0 || fieldError) ? (
        <ul className="space-y-2">
          {fieldError && fieldError.type === 'required' ? (
            <li className="flex items-center gap-x-2 text-sm text-red-600">
              <Icon icon="information" />
              <span>{t('invoice.file_upload.errors.file_required')}</span>
            </li>
          ) : null}
          {fileRejections
            .flatMap((rejections) => rejections.errors)
            .map((error) => (
              <li
                key={error.code}
                className="flex items-center gap-x-2 text-sm text-red-600"
              >
                <Icon icon="information" />
                {error.code === 'file-invalid-type' ? (
                  <span>
                    {t('invoice.file_upload.errors.file_invalid_type')}
                  </span>
                ) : null}
                {error.code === 'file-too-large' ? (
                  <span>{t('invoice.file_upload.errors.file_too_large')}</span>
                ) : null}
                {error.code === 'file-too-small' ? (
                  <span>{t('invoice.file_upload.errors.file_too_small')}</span>
                ) : null}
                {error.code === 'too-many-files' ? (
                  <span>{t('invoice.file_upload.errors.too_many_files')}</span>
                ) : null}
              </li>
            ))}
        </ul>
      ) : null}
    </>
  );
};

type ReturnSlipFileUploadProps = {
  onValueChange: (file: File | null) => void;
  value?: File;
  fieldError?: FieldError;
};

const ReturnSlipFileUpload: React.FC<ReturnSlipFileUploadProps> = ({
  onValueChange,
  value,
  fieldError,
}) => {
  const {
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
  } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
    },
    maxFiles: 1,
    maxSize: 1024 * 1024 * MAX_FILE_SIZE_MB,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length && acceptedFiles[0]) {
        onValueChange(acceptedFiles[0]);
      }
    },
  });

  return (
    <div
      className="group flex min-h-[15.5625rem] cursor-pointer flex-col items-center justify-center space-y-4 rounded-lg border border-dashed border-gray-300 bg-[#FBFBFB] transition-colors hover:border-green-900 data-[active=true]:border-green-900 data-[rejected=true]:border-red-600 data-[active=true]:bg-green-300"
      data-active={isDragActive}
      data-rejected={isDragReject || fileRejections.length > 0 || !!fieldError}
      {...getRootProps()}
    >
      <input {...getInputProps()} hidden />

      <div className="flex flex-col items-center space-y-6 py-11">
        <ReturnSlipFileUploadState
          file={value}
          fileRejections={fileRejections}
          fieldError={fieldError}
          isDragReject={isDragReject}
          isDragActive={isDragActive}
          onValueChange={onValueChange}
        />
      </div>
    </div>
  );
};

export { ReturnSlipFileUpload };
