import { atom, useAtom } from 'jotai';

import { getSearchParam } from '../../utils/url';

type PaginationState = {
  beforeCursor: string | undefined;
  afterCursor: string | undefined;
};

export const paginationState = atom<PaginationState | undefined>({
  beforeCursor: getSearchParam('before'),
  afterCursor: getSearchParam('after'),
});

export const usePagination = () => {
  const [pagination, setPagination] = useAtom(paginationState);

  const resetPagination = () => {
    setPagination({
      afterCursor: undefined,
      beforeCursor: undefined,
    });
  };

  return { pagination, resetPagination, setPagination } as const;
};
