import {
  UseMutationOptions,
  UseQueryOptions,
  UseSuspenseQueryOptions,
  useMutation,
  useQuery,
  useSuspenseQuery,
} from '@tanstack/react-query';

import { useFetchData } from '../hooks/use-fetcher-new';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  BaseUrl: { input: any; output: any };
  Date: { input: string; output: string };
  DateTime: { input: string; output: string };
  FloatPercentage: { input: number; output: number };
  NonEmptyString: { input: string; output: string };
  PositiveInteger: { input: number; output: number };
  URL: { input: string; output: string };
  Void: { input: any; output: any };
};

/** **Accept order claim with refund input** */
export type AcceptOrderClaimWithRefundInput = {
  /** **Claim identifier** */
  claimId: Scalars['ID']['input'];
  /** **Order identifier** */
  orderId: Scalars['ID']['input'];
};

/** **Accept order claim with reship input** */
export type AcceptOrderClaimWithReshipInput = {
  /** **Claim identifier** */
  claimId: Scalars['ID']['input'];
  /** **Order identifier** */
  orderId: Scalars['ID']['input'];
};

/** **Accept order claim with voucher replacement codes input** */
export type AcceptOrderClaimWithVoucherReplacementCodesInput = {
  /** **Claim identifier** */
  claimId: Scalars['ID']['input'];
  /** **Order identifier** */
  orderId: Scalars['ID']['input'];
  /** **Voucher replacement codes** */
  voucherReplacementCodes: Array<VoucherReplacementCodeInput>;
};

/** **Accept order claim with voucher replacement message input** */
export type AcceptOrderClaimWithVoucherReplacementMessageInput = {
  /** **Claim identifier** */
  claimId: Scalars['ID']['input'];
  /** **Order identifier** */
  orderId: Scalars['ID']['input'];
  /** **Voucher replacement message** */
  voucherReplacementMessage: Scalars['String']['input'];
};

/** **Rule concerning the activation date of the down payment** */
export type ActivationDateRule = {
  __typename?: 'ActivationDateRule';
  /** **The expected activation date for the down payment** */
  activationDate: Scalars['DateTime']['output'];
  /** **Indicates whether the activation date rule is met** */
  isValidated: Scalars['Boolean']['output'];
};

/** **Address** */
export type Address = {
  __typename?: 'Address';
  /** **City** */
  city: Scalars['String']['output'];
  /** **Country** */
  country: Scalars['String']['output'];
  /** **Country code** */
  countryCode: Scalars['String']['output'];
  /** **County** */
  county?: Maybe<Scalars['String']['output']>;
  /** **Letters indicating which state the address is from** */
  state?: Maybe<Scalars['String']['output']>;
  /** **Street** */
  street: Scalars['String']['output'];
  /** **Street additional** */
  streetAdditional?: Maybe<Scalars['String']['output']>;
  /** **Zip code** */
  zipCode: Scalars['String']['output'];
};

export type BasicUserInfo = {
  firstname: Scalars['String']['input'];
  lastname: Scalars['String']['input'];
  preferredLanguage?: InputMaybe<Scalars['String']['input']>;
};

export type BillingByVatV2 = {
  __typename?: 'BillingByVatV2';
  total_billing_ht: Scalars['Int']['output'];
  total_billing_ttc: Scalars['Int']['output'];
  total_commission_ht: Scalars['Int']['output'];
  total_commission_ttc: Scalars['Int']['output'];
  total_operation_ht: Scalars['Int']['output'];
  total_operation_ttc: Scalars['Int']['output'];
  total_vat: Scalars['Int']['output'];
  vat_rate: Scalars['String']['output'];
};

/** **Billing export** */
export type BillingExport = {
  __typename?: 'BillingExport';
  /** **File url** */
  fileUrl: Scalars['URL']['output'];
};

/** **Billing export input** */
export type BillingExportInput = {
  /** **File extension** */
  fileExtension: FileExtension;
};

export type BillingPaymentLine = {
  __typename?: 'BillingPaymentLine';
  amountVatExcludedInCents: Scalars['Int']['output'];
  amountVatInCents: Scalars['Int']['output'];
  amountVatIncludedInCents: Scalars['Int']['output'];
  invoiceId: Scalars['ID']['output'];
  paidAt?: Maybe<Scalars['DateTime']['output']>;
};

/** **Error information of the billing edition** */
export type BillingStateError = {
  __typename?: 'BillingStateError';
  /** **Error code of this issue** */
  code: BillingStateErrorCode;
  /** **Identifiant of order | return | claim that have an issue** */
  id: Scalars['String']['output'];
};

/** **List of errors type that lock billing edition** */
export enum BillingStateErrorCode {
  /** **Claim is unfulfilled** */
  ClaimUnfulfilled = 'CLAIM_UNFULFILLED',
  /** **Order is not yet delivered** */
  OrderNotDelivered = 'ORDER_NOT_DELIVERED',
  /** **Return is not yet validated** */
  ReturnNotValidated = 'RETURN_NOT_VALIDATED',
}

/** **Information about state of billing** */
export type BillingStateV2 = {
  __typename?: 'BillingStateV2';
  /** **All claims have been fulfilled by the brand ?** */
  allClaimsFulfilled: Scalars['Boolean']['output'];
  /** **All orders have been received by the customer ?** */
  allOrdersDelivered: Scalars['Boolean']['output'];
  /** **All returns have been validated by the brand / bot auto ?** */
  allReturnsValidated: Scalars['Boolean']['output'];
  /** **Billing can be edited ?** */
  canEdit: Scalars['Boolean']['output'];
  /**
   * **Date that the billing will be accessible and editable**
   *
   * - > null if there are somes errors => see 'errors'
   */
  editableAt?: Maybe<Scalars['DateTime']['output']>;
  /** **List of errors that lock billing's edition** */
  errors: Array<BillingStateError>;
  /**
   * **Billing can be edited with errors ?**
   *
   * - > true if financial team accept to display billing to brand even though has errors
   */
  forceEdit: Scalars['Boolean']['output'];
  /** **Date that the sale will be closed** */
  saleEndsAt: Scalars['DateTime']['output'];
  /** **Sale status** */
  saleStatus: SaleStatus;
  /** **Customer withdrawal period (in days)** */
  withdrawalPeriod: Scalars['Int']['output'];
};

export type BillingV2 = {
  __typename?: 'BillingV2';
  agg_by_vat_rate: Array<BillingByVatV2>;
  down_payment?: Maybe<DownPaymentV2>;
  errors: Array<ProductSoldErrorV2>;
  has_error: Scalars['Boolean']['output'];
  is_full_paid: Scalars['Boolean']['output'];
  is_subject_to_vat: Scalars['Boolean']['output'];
  payments: Array<BillingPaymentLine>;
  total_billing_ht: Scalars['Int']['output'];
  total_billing_ttc: Scalars['Int']['output'];
  total_commission_ht: Scalars['Int']['output'];
  total_commission_ttc: Scalars['Int']['output'];
  total_delivery_ht: Scalars['Int']['output'];
  total_delivery_ttc: Scalars['Int']['output'];
  total_operation_ht: Scalars['Int']['output'];
  total_operation_ttc: Scalars['Int']['output'];
  total_refund_ht: Scalars['Int']['output'];
  total_refund_ttc: Scalars['Int']['output'];
  total_remaining_ht: Scalars['Int']['output'];
  total_remaining_ttc: Scalars['Int']['output'];
  total_turnover_ht: Scalars['Int']['output'];
  total_turnover_ttc: Scalars['Int']['output'];
};

export type BrandAccess = {
  __typename?: 'BrandAccess';
  brandId: Scalars['String']['output'];
  brandName: Scalars['String']['output'];
  role: Scalars['String']['output'];
  store: Store;
};

export type BrandInfo = {
  __typename?: 'BrandInfo';
  acceptContract: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  nbUsers: Scalars['Int']['output'];
  store: Store;
};

export enum BrandRole {
  Admin = 'ADMIN',
  Member = 'MEMBER',
}

/** **The buyer is the person who has been billed for the order** */
export type Buyer = {
  __typename?: 'Buyer';
  /** **Address** */
  address: Address;
  /** **First name** */
  firstName: Scalars['String']['output'];
  /** **Full name ("first_name last_name")** */
  fullName: Scalars['String']['output'];
  /** **Last name** */
  lastName: Scalars['String']['output'];
  /** **Previous buyer if it has been updated** */
  previous?: Maybe<Buyer>;
};

/** **Claim** */
export type Claim = {
  __typename?: 'Claim';
  /** **Message written by the seller when it accept the claim** */
  acceptedMessage?: Maybe<Scalars['String']['output']>;
  /** **Action chose by the seller when it has accepted the claim** */
  actionFromSeller?: Maybe<ClaimSellerAction>;
  /** **Date of the creation** */
  createdAt: Scalars['DateTime']['output'];
  /**
   * **Message written by the seller when it decline the claim**
   * @deprecated Never been used
   */
  declinedMessage?: Maybe<Scalars['String']['output']>;
  /** **ID** */
  id: Scalars['ID']['output'];
  /** **Is accepted ?** */
  isAccepted: Scalars['Boolean']['output'];
  /**
   * **Is declined ?**
   * @deprecated Never been used
   */
  isDeclined: Scalars['Boolean']['output'];
  /** **Was created by automatic flow ?** */
  isOpenedByBot: Scalars['Boolean']['output'];
  /** **Items** */
  items: Array<Item>;
  /** **Message written by the support team to the seller** */
  messageFromSupport?: Maybe<Scalars['String']['output']>;
  /**
   * **Other reason label written by the support team when the reason is "OTHER"**
   * @deprecated Never been used
   */
  otherReasonLabel?: Maybe<Scalars['String']['output']>;
  /** **Proof URLs** */
  proofUrls: Array<Scalars['String']['output']>;
  /** **Reason** */
  reason: ClaimReason;
  /** **Reship action error reasons if the action is not available** */
  reshipActionErrorReasons: Array<ClaimReshipActionErrorReason>;
  /** **Sworn statement URLs** */
  swornStatementUrls: Array<Scalars['String']['output']>;
  /** **Latest update date** */
  updatedAt: Scalars['DateTime']['output'];
};

/** **Claim Reason** */
export enum ClaimReason {
  /** **The customer has received the parcel but is damaged** */
  DeliveredButDamaged = 'DELIVERED_BUT_DAMAGED',
  /** **The customer has received the parcel but is incomplete** */
  DeliveredButIncomplete = 'DELIVERED_BUT_INCOMPLETE',
  /** **The customer has received the parcel but is wrong (wrong or missing items)** */
  DeliveredButWrong = 'DELIVERED_BUT_WRONG',
  /** **The customer has received a digital coupon code but is not working** */
  DigitalCouponCodeNotWorking = 'DIGITAL_COUPON_CODE_NOT_WORKING',
  /** **The customer has not received the parcel and has been returned to the sender** */
  NotDeliveredAndReturnedToSender = 'NOT_DELIVERED_AND_RETURNED_TO_SENDER',
  /** **The customer has not received the parcel and the tracking is blocked** */
  NotDeliveredAndTrackingBlocked = 'NOT_DELIVERED_AND_TRACKING_BLOCKED',
  /** **The customer has not received the parcel and the tracking is delivered** */
  NotDeliveredAndTrackingDelivered = 'NOT_DELIVERED_AND_TRACKING_DELIVERED',
  /** **Other** */
  Other = 'OTHER',
}

/** **Claim reship action error reason** */
export enum ClaimReshipActionErrorReason {
  /** **There is no items to reship** */
  NoItems = 'NO_ITEMS',
  /** **The order has already been reshipped** */
  OrderAlreadyReshipped = 'ORDER_ALREADY_RESHIPPED',
  /** **The order is cancelled** */
  OrderCancelled = 'ORDER_CANCELLED',
  /** **Some of the items to reship are digital** */
  SomeItemsDigital = 'SOME_ITEMS_DIGITAL',
}

/** **Claim seller action (action chose by the seller when it has accepted the claim)** */
export enum ClaimSellerAction {
  /** **The seller has directy handled the claim with the customer** */
  DirectlyHandledWithTheCustomer = 'DIRECTLY_HANDLED_WITH_THE_CUSTOMER',
  /** **The seller decided to resend a new digital coupon code for the items** */
  ItemsDigitalCouponCodesResent = 'ITEMS_DIGITAL_COUPON_CODES_RESENT',
  /** **The seller decided to refund the items** */
  ItemsRefunded = 'ITEMS_REFUNDED',
  /** **The seller decided to reship the items** */
  ItemsReshipped = 'ITEMS_RESHIPPED',
}

/** **Courier** */
export type Courier = {
  __typename?: 'Courier';
  /** **Alternative name of the courier** */
  alternativeName?: Maybe<Scalars['String']['output']>;
  /** **Contact phone number of the courier** */
  contactPhoneNumber?: Maybe<Scalars['String']['output']>;
  /** **Default language of the courier** */
  defaultLanguage: Scalars['String']['output'];
  /** **Logo url of the courier** */
  logoUrl: Scalars['String']['output'];
  /** **Name of the courier** */
  name: Scalars['String']['output'];
  /** **Slug of the courier** */
  slug: Scalars['String']['output'];
  /** **Supported countries of the courier** */
  supportedCountries: Array<Scalars['String']['output']>;
  /** **Supported languages of the courier** */
  supportedLanguages: Array<Scalars['String']['output']>;
  /** **Web url of the courier** */
  webUrl?: Maybe<Scalars['String']['output']>;
};

/** **Create order parcel input** */
export type CreateOrderParcelInput = {
  /** **Order id** */
  orderId: Scalars['ID']['input'];
  /** **Tracking info** */
  trackingInfo: TrackingInfoInput;
};

/** **Currency ISO code** */
export enum CurrencyIso {
  /** **Euro** */
  Eur = 'eur',
  /** **US Dollar** */
  Usd = 'usd',
}

/** **Represents a range of date and time when the parcel is expected to arrive at the customer's location** */
export type DeliveryDateRange = {
  __typename?: 'DeliveryDateRange';
  /** **The creation date** */
  createdAt: Scalars['DateTime']['output'];
  /** **The latest delivery date and time when the parcel is expected to arrive at the customer's location** */
  end: Scalars['Date']['output'];
  /** **The earliest delivery date and time when the parcel is expected to arrive at the customer's location** */
  start: Scalars['Date']['output'];
};

/** **Detect couriers input** */
export type DetectCouriersInput = {
  /** **Identifier of the order** */
  orderId: Scalars['ID']['input'];
  /** **Tracking number** */
  trackingNumber: Scalars['String']['input'];
};

export type DownPaymentClassic = {
  __typename?: 'DownPaymentClassic';
  /** **Financial detail** */
  financialDetail: FinancialBilling;
  /** **Predefined value for the down payment** */
  preset: DownPaymentPreset;
  /** **Applicable down payment rate (as a percentage)** */
  rate: Scalars['Int']['output'];
  /** **Set of rules governing the down payment thresholds and validations** */
  rules: Array<DownPaymentRules>;
};

export type DownPaymentExceptional = {
  __typename?: 'DownPaymentExceptional';
  /** **Predefined value for the down payment** */
  preset: DownPaymentPreset;
};

/** **Enum representing predefined down payment values** */
export enum DownPaymentPreset {
  Classic = 'CLASSIC',
  Exceptional = 'EXCEPTIONAL',
}

export type DownPaymentRules =
  | ActivationDateRule
  | FulfilledOrderPercentageRule
  | MinimumAmountInCentsRule;

export type DownPaymentV2 = DownPaymentClassic | DownPaymentExceptional;

/** **File extension** */
export enum FileExtension {
  /** **CSV** */
  Csv = 'CSV',
  /** **XLSX (excel)** */
  Xlsx = 'XLSX',
}

export type FinancialBilling = {
  __typename?: 'FinancialBilling';
  agg_by_vat_rate: Array<BillingByVatV2>;
  total_billing_ht: Scalars['Int']['output'];
  total_billing_ttc: Scalars['Int']['output'];
  total_commission_ht: Scalars['Int']['output'];
  total_commission_ttc: Scalars['Int']['output'];
  total_delivery_ht: Scalars['Int']['output'];
  total_delivery_ttc: Scalars['Int']['output'];
  total_operation_ht: Scalars['Int']['output'];
  total_operation_ttc: Scalars['Int']['output'];
  total_refund_ht: Scalars['Int']['output'];
  total_refund_ttc: Scalars['Int']['output'];
  total_remaining_ht: Scalars['Int']['output'];
  total_remaining_ttc: Scalars['Int']['output'];
  total_turnover_ht: Scalars['Int']['output'];
  total_turnover_ttc: Scalars['Int']['output'];
};

/** **Rule concerning the percentage of order fulfillment** */
export type FulfilledOrderPercentageRule = {
  __typename?: 'FulfilledOrderPercentageRule';
  /** **The current percentage of the order fulfillment achieved** */
  currentValue: Scalars['Int']['output'];
  /** **Indicates whether the order fulfillment percentage rule is met** */
  isValidated: Scalars['Boolean']['output'];
  /** **The required percentage of order fulfillment to meet the condition** */
  targetValue: Scalars['Int']['output'];
};

/** **Invoice for customs info** */
export type GenerateInvoiceCustoms = {
  __typename?: 'GenerateInvoiceCustoms';
  /** **Url of invoice pdf** */
  url: Scalars['String']['output'];
};

/** **Purchase order info** */
export type GeneratePurchaseOrder = {
  __typename?: 'GeneratePurchaseOrder';
  /** **Url of purchase order pdf** */
  url: Scalars['String']['output'];
};

/** **Group order parcel input** */
export type GroupOrderParcelInput = {
  /** **Order id** */
  orderId: Scalars['ID']['input'];
  /** **Order ids excluded** */
  orderIdsExcluded?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** **Tracking info** */
  trackingInfo: TrackingInfoInput;
};

/** **Invoice** */
export type Invoice = {
  __typename?: 'Invoice';
  /** **Creation date of the invoice** */
  createdAt: Scalars['DateTime']['output'];
  /** **File name of the invoice file** */
  fileName: Scalars['String']['output'];
  /** **Url of the invoice file** */
  fileUrl: Scalars['URL']['output'];
  /** **Identifier of the invoice** */
  id: Scalars['ID']['output'];
  /** **Status of the invoice** */
  status: InvoiceStatus;
  /** **Type of the invoice** */
  type: InvoiceType;
};

/** **Invoice status** */
export enum InvoiceStatus {
  BotError = 'bot_error',
  BotProcessing = 'bot_processing',
  Paid = 'paid',
  Received = 'received',
  Rejected = 'rejected',
  ToBePaid = 'to_be_paid',
  Validated = 'validated',
}

/** **Invoice types** */
export enum InvoiceType {
  CreditNote = 'credit_note',
  DownPayment = 'down_payment',
  FinalBalance = 'final_balance',
  Invoice = 'invoice',
  Proforma = 'proforma',
  StatementOfAccount = 'statement_of_account',
}

/** **Item** */
export type Item = {
  __typename?: 'Item';
  /** **Digital coupon code** */
  digitalCouponCode?: Maybe<Scalars['String']['output']>;
  /** **GTIN** */
  gtin?: Maybe<Scalars['String']['output']>;
  /** **ID** */
  id: Scalars['ID']['output'];
  /** **Image URL** */
  imageUrl: Scalars['String']['output'];
  /** **Is cancellable ?** */
  isCancellable: Scalars['Boolean']['output'];
  /** **Is cancelled ?** */
  isCancelled: Scalars['Boolean']['output'];
  /** **Is digital ?** */
  isDigital: Scalars['Boolean']['output'];
  /** **Is offered ?** */
  isOffered: Scalars['Boolean']['output'];
  /** **Is reshipped ?** */
  isReshipped: Scalars['Boolean']['output'];
  /** **A return has been generated for this item ?** */
  isReturnGenerated: Scalars['Boolean']['output'];
  /** **A return has been validated for this item ?** */
  isReturnValidated: Scalars['Boolean']['output'];
  /** **Is returnable ?** */
  isReturnable: Scalars['Boolean']['output'];
  /** **Name** */
  name: Scalars['String']['output'];
  /** **New digital coupon code** */
  newDigitalCouponCode?: Maybe<Scalars['String']['output']>;
  /** **Price bought by Choose** */
  priceBoughtByChoose: Price;
  /** **Price sold by Choose to the customer (in cents)** */
  priceSold: Price;
  /** **Product ID** */
  productId: Scalars['String']['output'];
  /** **Product variant ID** */
  productVariantId: Scalars['String']['output'];
  /** **Refund summary** */
  refundSummary: OrderLineRefundSummary;
  /** **Return reason code** */
  returnReasonCode?: Maybe<ItemReturnReasonCode>;
  /** **Return sub reason code** */
  returnSubReasonCode?: Maybe<ItemReturnSubReasonCode>;
  /** **Notifications sent to the customer about the item** */
  sentNotifications: Array<ItemSentNotification>;
  /** **Size** */
  size?: Maybe<Scalars['String']['output']>;
  /** **SKU** */
  sku?: Maybe<Scalars['String']['output']>;
  /** **Voucher expiration date if the item is digital** */
  voucherExpiresAt?: Maybe<Scalars['DateTime']['output']>;
  /** **Voucher marked as used date if the item is digital** */
  voucherMarkedAsUsedAt?: Maybe<Scalars['DateTime']['output']>;
};

/** **Item return reason code** */
export enum ItemReturnReasonCode {
  /** **Better price** */
  BetterPrice = 'BETTER_PRICE',
  /** **Claim** */
  Claim = 'CLAIM',
  /** **Damaged product** */
  DamagedProduct = 'DAMAGED_PRODUCT',
  /** **Damaged shipping** */
  DamagedShipping = 'DAMAGED_SHIPPING',
  /** **Delay** */
  Delay = 'DELAY',
  /** **Description** */
  Description = 'DESCRIPTION',
  /** **Other** */
  Other = 'OTHER',
  /** **Picture** */
  Picture = 'PICTURE',
  /** **Quality** */
  Quality = 'QUALITY',
  /** **Size** */
  Size = 'SIZE',
  /** **Wrong article** */
  WrongArticle = 'WRONG_ARTICLE',
}

/** **Item return sub reason code** */
export enum ItemReturnSubReasonCode {
  /** **bad shape** */
  BadShape = 'BAD_SHAPE',
  /** **multi size bought** */
  MultiSize = 'MULTI_SIZE',
  /** **Other** */
  Other = 'OTHER',
  /** **too large** */
  TooLarge = 'TOO_LARGE',
  /** **too long** */
  TooLong = 'TOO_LONG',
  /** **too short** */
  TooShort = 'TOO_SHORT',
  /** **too tight** */
  TooTight = 'TOO_TIGHT',
}

/** **Item sent notification** */
export enum ItemSentNotification {
  /** **Voucher expires in 7 days** */
  VoucherExpiresIn_7Days = 'VOUCHER_EXPIRES_IN_7_DAYS',
  /** **Voucher expires in 30 days** */
  VoucherExpiresIn_30Days = 'VOUCHER_EXPIRES_IN_30_DAYS',
}

/** **Join order return label for claim input** */
export type JoinOrderReturnLabelForClaimInput = {
  /** **Claim identifier** */
  claimId: Scalars['ID']['input'];
  /** **Item identifiers** */
  itemIds: Array<Scalars['ID']['input']>;
  /** **Label file identifier** */
  labelFileId: Scalars['ID']['input'];
  /** **Order identifier** */
  orderId: Scalars['ID']['input'];
  /** **Carrier slug** */
  trackingCarrierSlug: Scalars['String']['input'];
  /** **Tracking number** */
  trackingNumber: Scalars['String']['input'];
};

/** **Logistician** */
export enum Logistician {
  /** **Bigblue** */
  Bigblue = 'BIGBLUE',
  /** **Boxtal** */
  Boxtal = 'BOXTAL',
  /** **Coliship** */
  Coliship = 'COLISHIP',
  /** **Cubyn** */
  Cubyn = 'CUBYN',
  /** **Generic all items on same row** */
  GenericColumn = 'GENERIC_COLUMN',
  /** **Generic by item row** */
  GenericRow = 'GENERIC_ROW',
  /** **Sendcloud** */
  Sendcloud = 'SENDCLOUD',
  /** **Shipping bo** */
  Shippingbo = 'SHIPPINGBO',
  /** **Transporterapp** */
  Transporterapp = 'TRANSPORTERAPP',
}

export type Member = {
  __typename?: 'Member';
  email: Scalars['String']['output'];
  firstname: Scalars['String']['output'];
  lastname: Scalars['String']['output'];
  picture: Scalars['String']['output'];
  role: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

/** **Rule concerning the minimum amount required in cents** */
export type MinimumAmountInCentsRule = {
  __typename?: 'MinimumAmountInCentsRule';
  /** **The current down payment amount in cents** */
  currentValue: Scalars['Int']['output'];
  /** **Indicates whether the minimum amount rule is met** */
  isValidated: Scalars['Boolean']['output'];
  /** **The minimum amount that the down payment must meet (in cents)** */
  targetValue: Scalars['Int']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** **Accepts an order claim with refund** */
  acceptOrderClaimWithRefund?: Maybe<Scalars['Void']['output']>;
  /** **Accepts an order claim with reship** */
  acceptOrderClaimWithReship?: Maybe<Scalars['Void']['output']>;
  /** **Accepts an order claim with voucher replacement codes** */
  acceptOrderClaimWithVoucherReplacementCodes?: Maybe<
    Scalars['Void']['output']
  >;
  /** **Accepts an order claim with voucher replacement message** */
  acceptOrderClaimWithVoucherReplacementMessage?: Maybe<
    Scalars['Void']['output']
  >;
  changePassword?: Maybe<Scalars['String']['output']>;
  /** **Creates an order parcel with the tracking info** */
  createOrderParcel?: Maybe<Scalars['Void']['output']>;
  createPasswordConnection?: Maybe<Scalars['String']['output']>;
  detachBrandUser?: Maybe<Scalars['String']['output']>;
  editBasicUserInfo?: Maybe<Scalars['String']['output']>;
  /** **Generate Invoice for customs** */
  generateInvoiceCustoms: GenerateInvoiceCustoms;
  /** **Generates an url to upload an invoice for a sale** */
  generateInvoiceUploadUrl: Upload;
  generateProfilePictureUploadUrl: Upload;
  /** **Generate purchase order** */
  generatePurchaseOrder: GeneratePurchaseOrder;
  /** **Groups the order parcel and the related order parcels with the tracking info** */
  groupOrderParcel?: Maybe<Scalars['Void']['output']>;
  joinBrand?: Maybe<Scalars['String']['output']>;
  /** **Join an order return label for a claim** */
  joinOrderReturnLabelForClaim?: Maybe<Scalars['Void']['output']>;
  /** **Mark user onboarding survey as viewed** */
  markUserOnboardingSurveyAsViewed?: Maybe<Scalars['Void']['output']>;
  postRegister?: Maybe<Scalars['String']['output']>;
  /** **Set brand role to user** */
  setUserRole?: Maybe<Scalars['Void']['output']>;
  /** **Submits a down payment invoice for a sale to be paid by Choose** */
  submitDownPayment?: Maybe<Scalars['Void']['output']>;
  /** **Submits an invoice for a sale to be paid by Choose** */
  submitInvoice?: Maybe<Scalars['Void']['output']>;
  /** **Swaps the order parcel and the related order parcels with the tracking info** */
  swapOrderParcel?: Maybe<Scalars['Void']['output']>;
  updateInviteCode: Scalars['String']['output'];
  /** **Updates the order parcel and the related order parcels with the tracking info** */
  updateOrderParcel?: Maybe<Scalars['Void']['output']>;
  updateProfilePicture?: Maybe<Scalars['Void']['output']>;
  updateUserLanguage?: Maybe<Scalars['String']['output']>;
};

export type MutationAcceptOrderClaimWithRefundArgs = {
  input: AcceptOrderClaimWithRefundInput;
};

export type MutationAcceptOrderClaimWithReshipArgs = {
  input: AcceptOrderClaimWithReshipInput;
};

export type MutationAcceptOrderClaimWithVoucherReplacementCodesArgs = {
  input: AcceptOrderClaimWithVoucherReplacementCodesInput;
};

export type MutationAcceptOrderClaimWithVoucherReplacementMessageArgs = {
  input: AcceptOrderClaimWithVoucherReplacementMessageInput;
};

export type MutationChangePasswordArgs = {
  newPassword: Scalars['String']['input'];
};

export type MutationCreateOrderParcelArgs = {
  input: CreateOrderParcelInput;
};

export type MutationCreatePasswordConnectionArgs = {
  userInfo: UserPasswordConnection;
};

export type MutationDetachBrandUserArgs = {
  userId: Scalars['String']['input'];
};

export type MutationEditBasicUserInfoArgs = {
  basicUserInfo: BasicUserInfo;
};

export type MutationGenerateInvoiceCustomsArgs = {
  orderId: Scalars['ID']['input'];
};

export type MutationGenerateInvoiceUploadUrlArgs = {
  saleId: Scalars['ID']['input'];
  upload: UploadInput;
};

export type MutationGenerateProfilePictureUploadUrlArgs = {
  upload: UploadInput;
};

export type MutationGeneratePurchaseOrderArgs = {
  orderId: Scalars['ID']['input'];
};

export type MutationGroupOrderParcelArgs = {
  input: GroupOrderParcelInput;
};

export type MutationJoinBrandArgs = {
  brandId: Scalars['String']['input'];
  inviteCode: Scalars['String']['input'];
};

export type MutationJoinOrderReturnLabelForClaimArgs = {
  input: JoinOrderReturnLabelForClaimInput;
};

export type MutationPostRegisterArgs = {
  config: PostRegisterConfig;
};

export type MutationSetUserRoleArgs = {
  role: BrandRole;
  userId: Scalars['ID']['input'];
};

export type MutationSubmitDownPaymentArgs = {
  input: SubmitDownPaymentInput;
  saleId: Scalars['ID']['input'];
};

export type MutationSubmitInvoiceArgs = {
  input: SubmitInvoiceInput;
  saleId: Scalars['ID']['input'];
};

export type MutationSwapOrderParcelArgs = {
  input: SwapOrderParcelInput;
};

export type MutationUpdateOrderParcelArgs = {
  input: UpdateOrderParcelInput;
};

export type MutationUpdateProfilePictureArgs = {
  url: Scalars['BaseUrl']['input'];
};

export type MutationUpdateUserLanguageArgs = {
  preferredLanguage: Scalars['String']['input'];
};

/** **Operation type** */
export enum OperationType {
  /** **Choose with StockAZ clears its stock ** */
  Clearance = 'CLEARANCE',
  /** **The seller sell its products and ship on its own** */
  DropShipping = 'DROP_SHIPPING',
  /** **Choose has ordered the products before the operation and ship on its own** */
  FirmPurchase = 'FIRM_PURCHASE',
  /** **Choose order the products sold after the operation and ship it on its own** */
  PostOperationPurchase = 'POST_OPERATION_PURCHASE',
  /** **The reseller sell products from another seller => Not used anymore** */
  Reseller = 'RESELLER',
}

/** **Order** */
export type Order = {
  __typename?: 'Order';
  /** **Billing ID** */
  billingId: Scalars['ID']['output'];
  /** **The buyer is the person who has been billed for the order** */
  buyer: Buyer;
  /** **Claims** */
  claims: Array<Claim>;
  /** **Date of the creation** */
  createdAt: Scalars['DateTime']['output'];
  /** **Customer ID** */
  customerId: Scalars['ID']['output'];
  /** **Has been exported by the seller ?  (using a CSV from the dashboard in order to not process it twice)** */
  hasBeenExported: Scalars['Boolean']['output'];
  /** **Has seller error ?** */
  hasSellerError: Scalars['Boolean']['output'];
  /** **Has synchronization error ?** */
  hasSynchronizationError: Scalars['Boolean']['output'];
  /** **ID** */
  id: Scalars['ID']['output'];
  /** **Initial order if this is a reship order** */
  initialOrder?: Maybe<Order>;
  /** **ID of the initial order if this is a reship order** */
  initialOrderId?: Maybe<Scalars['ID']['output']>;
  /** **Integration** */
  integration: OrderIntegration;
  /** **Is cancelled ?** */
  isCancelled: Scalars['Boolean']['output'];
  /** **Does the order include only digital items ?** */
  isFullDigital: Scalars['Boolean']['output'];
  /** **Is shipping estimated time exceeded ?** */
  isShippingEstimatedTimeExceeded: Scalars['Boolean']['output'];
  /** **Items ordered by the customer** */
  items: Array<Item>;
  /** **Lines refunds** */
  linesRefunds: Array<OrderLineRefund>;
  /** **Operation type** */
  operationType: OperationType;
  /** **Parcels** */
  parcels: Array<Parcel>;
  /** **Payment infos** */
  payment: OrderPayment;
  /** **Rating** */
  rating?: Maybe<OrderRating>;
  /** **The recipient is the person who will receive the order** */
  recipient: Recipient;
  /** **Reship order if this is an initial order reshipped** */
  reshipOrder?: Maybe<Order>;
  /** **ID of the reship order if this is an initial order reshipped** */
  reshipOrderId?: Maybe<Scalars['ID']['output']>;
  /** **Return parcels** */
  returnParcels: Array<ReturnParcel>;
  /** **Return slips** */
  returnSlips: Array<ReturnSlip>;
  /** **Sale ID** */
  saleId: Scalars['ID']['output'];
  /** **Seller ID** */
  sellerId: Scalars['ID']['output'];
  /** **Shipping infos** */
  shipping: OrderShipping;
  /** **Split action error reasons if the action is not available** */
  splitActionErrorReasons: Array<OrderSplitActionErrorReason>;
  /** **Tags** */
  tags: OrderTags;
  /** **Total price sold (items) by Choose to the customer (in cents)** */
  totalPriceSold: Price;
};

/** **Order integration** */
export type OrderIntegration = {
  __typename?: 'OrderIntegration';
  /** **Error message from the integration (Shopify, StockAZ)** */
  errorMessage?: Maybe<Scalars['String']['output']>;
  /** **ID of the order from the integration (Shopify, StockAZ)** */
  orderId?: Maybe<Scalars['ID']['output']>;
  /** **Shopify integration** */
  shopify?: Maybe<ShopifyOrderIntegration>;
};

/** **Order line refund** */
export type OrderLineRefund = {
  __typename?: 'OrderLineRefund';
  /** **Amount in cents of the refund** */
  amountInCents: Scalars['PositiveInteger']['output'];
  /** **Cover party that has covered the refund** */
  coveredBy: OrderLineRefundCover;
  /** **Creation date of the refund** */
  createdAt: Scalars['DateTime']['output'];
  /** **Identifier of the refund** */
  id: Scalars['ID']['output'];
  /** **Internal note associated to the refund** */
  internalNote?: Maybe<Scalars['String']['output']>;
  /** **Identifier of the line (item id for "ITEM" and order id for "SHIPPING" and "LEGACY")** */
  lineId: Scalars['ID']['output'];
  /** **Line type ("ITEM" or "SHIPPING")** */
  lineType: OrderLineType;
  /** **Identifier of the plan (refunds made at the same time have the same plan id)** */
  planId: Scalars['ID']['output'];
  /** **Reason of the refund** */
  reason: OrderLineRefundReason;
};

/** **Order line refund cover** */
export enum OrderLineRefundCover {
  /** **Choose has covered the refund** */
  Choose = 'CHOOSE',
  /** **The supplier has covered the refund** */
  Supplier = 'SUPPLIER',
}

/** **Order line refund reason** */
export enum OrderLineRefundReason {
  /** **A claim has been accepted** */
  ClaimAccepted = 'CLAIM_ACCEPTED',
  /** **A refund has been done directly** */
  Direct = 'DIRECT',
  /** **A refund has been done directly because a claim has the deadline exceeded** */
  DirectClaimDeadlineExceeded = 'DIRECT_CLAIM_DEADLINE_EXCEEDED',
  /** **A refund has been done directly because a claim has been refused** */
  DirectClaimRefused = 'DIRECT_CLAIM_REFUSED',
  /** **A refund has been done directly to make a commercial gesture** */
  DirectCommercialGesture = 'DIRECT_COMMERCIAL_GESTURE',
  /** **A refund has been done directly because an item has a wrong image** */
  DirectItemWrongImage = 'DIRECT_ITEM_WRONG_IMAGE',
  /** **A refund has been done directly because an item has a wrong price** */
  DirectItemWrongPrice = 'DIRECT_ITEM_WRONG_PRICE',
  /** **A refund has been done directly because a return has the deadline exceeded** */
  DirectReturnDeadlineExceeded = 'DIRECT_RETURN_DEADLINE_EXCEEDED',
  /** **A refund has been done directly because a return has been locked** */
  DirectReturnLocked = 'DIRECT_RETURN_LOCKED',
  /** **A refund has been done directly because a return has been refused** */
  DirectReturnRefused = 'DIRECT_RETURN_REFUSED',
  /** **An item has been canceled** */
  ItemCanceled = 'ITEM_CANCELED',
  /** **An item has been canceled because of out of stock** */
  ItemCanceledOutOfStock = 'ITEM_CANCELED_OUT_OF_STOCK',
  /** **An order has been canceled** */
  OrderCanceled = 'ORDER_CANCELED',
  /** **A return has been accepted** */
  ReturnAccepted = 'RETURN_ACCEPTED',
  /** **An unknown reason (legacy)** */
  Unknown = 'UNKNOWN',
}

/** **Order line refund summary** */
export type OrderLineRefundSummary = {
  __typename?: 'OrderLineRefundSummary';
  /**
   * **Is refundable ?**
   *
   * Is not offered and not removed and has a price greater than 0
   */
  isRefundable: Scalars['Boolean']['output'];
  /**
   * **Is totally refunded ?**
   *
   * Has at least one refund and the refunded amount is equal to the price
   */
  isTotallyRefunded: Scalars['Boolean']['output'];
  /** **Refunded amount of the price in cents** */
  refundedAmountInCents: Scalars['PositiveInteger']['output'];
  /**
   * **Refunded amount of the price in percentage**
   *
   * Is between `0` and `100`
   */
  refundedAmountInPercentage: Scalars['FloatPercentage']['output'];
  /**
   * **Refunded amount of the price in percentage covered by Choose**
   *
   * Is between `0` and `100`
   */
  refundedAmountInPercentageCoveredByChoose: Scalars['FloatPercentage']['output'];
  /**
   * **Refunded amount of the price in percentage covered by the supplier**
   *
   * Is between `0` and `100`
   */
  refundedAmountInPercentageCoveredBySupplier: Scalars['FloatPercentage']['output'];
  /** **Remaining to refund amount of the price in cents** */
  remainingToRefundAmountInCents: Scalars['PositiveInteger']['output'];
  /**
   * **Remaining to refund amount of the price in percentage**
   *
   * Is between `0` and `100`
   */
  remainingToRefundAmountInPercentage: Scalars['FloatPercentage']['output'];
};

/** **Order line type** */
export enum OrderLineType {
  /** **Item** */
  Item = 'ITEM',
  /** **Legacy (not associated to an item or a shipping)** */
  Legacy = 'LEGACY',
  /** **Shipping** */
  Shipping = 'SHIPPING',
}

/** **Order payment** */
export type OrderPayment = {
  __typename?: 'OrderPayment';
  /** **Processor used for the payment** */
  processor: PaymentProcessor;
  /** **Processor detailed (eg: "stripe (ios Apple Pay)")** */
  processorDetailed: Scalars['String']['output'];
  /** **Processor transaction id** */
  processorTransactionId: Scalars['String']['output'];
  /** **Url of the transaction in the processor dashboard** */
  processorTransactionUrl: Scalars['String']['output'];
  /** **Sub processor used for the payment** */
  subProcessor?: Maybe<PaymentSubProcessor>;
  /** **Currency used for the payment transaction** */
  transactionCurrency: CurrencyIso;
};

/** **Order rating** */
export type OrderRating = {
  __typename?: 'OrderRating';
  /** **Message** */
  message?: Maybe<Scalars['String']['output']>;
  /** **Rate (between 1 and 5)** */
  rate: Scalars['Int']['output'];
};

/** **Order return label upload URL input** */
export type OrderReturnLabelUploadUrlInput = {
  /** **Order identifier** */
  orderId: Scalars['ID']['input'];
};

/** **Order shipping** */
export type OrderShipping = {
  __typename?: 'OrderShipping';
  /** **Delivery date range history (ordered by createdAt desc)** */
  deliveryDateRangeHistory: Array<DeliveryDateRange>;
  /** **Estimated time in days** */
  estimatedTime: Scalars['Int']['output'];
  /** **Fees (in cents)** */
  fees: Scalars['Int']['output'];
  /** **Fees sold by Choose to the customer (in cents)** */
  feesSold: Scalars['Int']['output'];
  /** **Pickup point** */
  pickupPoint?: Maybe<PickupPoint>;
  /** **Policy ID** */
  policyId?: Maybe<Scalars['ID']['output']>;
  /** **Refund summary** */
  refundSummary: OrderLineRefundSummary;
};

/** **Order split action error reason** */
export enum OrderSplitActionErrorReason {
  /** **The order has all the items digital** */
  AllItemsDigital = 'ALL_ITEMS_DIGITAL',
  /** **The order is cancelled** */
  OrderCancelled = 'ORDER_CANCELLED',
  /** **The order has been reshipped** */
  OrderReshipped = 'ORDER_RESHIPPED',
  /** **The order has some claims pending** */
  SomeClaimsPending = 'SOME_CLAIMS_PENDING',
  /** **The order has some refunds** */
  SomeRefunds = 'SOME_REFUNDS',
  /** **The order has some return parcels** */
  SomeReturnParcels = 'SOME_RETURN_PARCELS',
  /** **The order has some return slips** */
  SomeReturnSlips = 'SOME_RETURN_SLIPS',
}

/** **Order tag brand** */
export enum OrderTagBrand {
  /** **The order is available for the brand and requires an action** */
  ActionRequired = 'ACTION_REQUIRED',
  /** **The order is available for the brand and requires an action because claim** */
  ActionRequiredClaim = 'ACTION_REQUIRED_CLAIM',
  /** **The order is available for the brand and requires an action because return** */
  ActionRequiredReturn = 'ACTION_REQUIRED_RETURN',
  /** **The order is available for the brand and requires an action because shipment incident** */
  ActionRequiredShipmentIncident = 'ACTION_REQUIRED_SHIPMENT_INCIDENT',
  /** **The order is available for the brand and requires an action because unfulfilled** */
  ActionRequiredUnfulfilled = 'ACTION_REQUIRED_UNFULFILLED',
  /** **The order is available for the brand** */
  Available = 'AVAILABLE',
}

/** **Order tag claim** */
export enum OrderTagClaim {
  /** **The order has some closed claims** */
  Closed = 'CLOSED',
  /** **The order has no claim** */
  None = 'NONE',
  /** **The order has some opened claims** */
  Opened = 'OPENED',
}

/** **Order tag claim reason** */
export enum OrderTagClaimReason {
  /** **The order has some claims with delivered but damaged reason** */
  DeliveredButDamaged = 'DELIVERED_BUT_DAMAGED',
  /** **The order has some claims with delivered but incomplete reason** */
  DeliveredButIncomplete = 'DELIVERED_BUT_INCOMPLETE',
  /** **The order has some claims with delivered but wrong reason** */
  DeliveredButWrong = 'DELIVERED_BUT_WRONG',
  /** **The order has no claim** */
  None = 'NONE',
  /** **The order has some claims with not delivered and returned to sender reason** */
  NotDeliveredAndReturnedToSender = 'NOT_DELIVERED_AND_RETURNED_TO_SENDER',
  /** **The order has some claims with not delivered and tracking blocked reason** */
  NotDeliveredAndTrackingBlocked = 'NOT_DELIVERED_AND_TRACKING_BLOCKED',
  /** **The order has some claims with not delivered and tracking delivered reason** */
  NotDeliveredAndTrackingDelivered = 'NOT_DELIVERED_AND_TRACKING_DELIVERED',
  /** **The order has some claims with other reason** */
  Other = 'OTHER',
  /** **The order has some claims with voucher not working reason** */
  VoucherNotWorking = 'VOUCHER_NOT_WORKING',
}

/** **Order tag fulfillment** */
export enum OrderTagFulfillment {
  /** **The order has all the items that are eligible to shipment in a shipment** */
  Fulfilled = 'FULFILLED',
  /** **The order has none of the items that are eligible to shipment in a shipment** */
  Unfulfilled = 'UNFULFILLED',
}

/** **Order tag refund** */
export enum OrderTagRefund {
  /** **The order has no refund** */
  None = 'NONE',
  /** **The order has some refunds but not totally refunded** */
  PartiallyRefunded = 'PARTIALLY_REFUNDED',
  /** **The order has some refunds and totally refunded** */
  TotallyRefunded = 'TOTALLY_REFUNDED',
}

/** **Order tag reship** */
export enum OrderTagReship {
  /** **The order has no reship** */
  None = 'NONE',
  /** **The order has some reships** */
  Reshipped = 'RESHIPPED',
}

/** **Order tag return** */
export enum OrderTagReturn {
  /** **The order has some canceled returns** */
  Canceled = 'CANCELED',
  /** **The order has some delivered returns** */
  Delivered = 'DELIVERED',
  /** **The order has some in progress returns** */
  InProgress = 'IN_PROGRESS',
  /** **The order has no return** */
  None = 'NONE',
  /** **The order has some requested returns** */
  Requested = 'REQUESTED',
  /** **The order has some validated returns** */
  Validated = 'VALIDATED',
}

/** **Order tag shipment** */
export enum OrderTagShipment {
  /** **The order has some attempt fail shipments** */
  AttemptFail = 'ATTEMPT_FAIL',
  /** **The order has some available for pickup shipments** */
  AvailableForPickup = 'AVAILABLE_FOR_PICKUP',
  /** **The order has some delivered shipments** */
  Delivered = 'DELIVERED',
  /** **The order has some exception shipments** */
  Exception = 'EXCEPTION',
  /** **The order has some expired shipments** */
  Expired = 'EXPIRED',
  /** **The order has some info received shipments** */
  InfoReceived = 'INFO_RECEIVED',
  /** **The order has some in transit shipments** */
  InTransit = 'IN_TRANSIT',
  /** **The order has no shipment** */
  None = 'NONE',
  /** **The order has some out for delivery shipments** */
  OutForDelivery = 'OUT_FOR_DELIVERY',
  /** **The order has some pending shipments** */
  Pending = 'PENDING',
}

/** **Order tag shipping mode** */
export enum OrderTagShippingMode {
  /** **The shipments will be shipped at the recipient's home** */
  Home = 'HOME',
  /** **The order will not be shipped (digital items)** */
  None = 'NONE',
  /** **The shipments will be shipped at a pickup point** */
  PickupPoint = 'PICKUP_POINT',
}

/** **Order tag shopify** */
export enum OrderTagShopify {
  /** **The order has no shopify** */
  None = 'NONE',
  /** **The order has a shopify and is synchronized** */
  Synchronized = 'SYNCHRONIZED',
  /** **The order has a shopify and is not synchronized** */
  Unsynchronized = 'UNSYNCHRONIZED',
}

/** **Order tag status** */
export enum OrderTagStatus {
  /** **The order has been canceled by the customer or by a member of the support team** */
  Canceled = 'CANCELED',
  /** **The order has been placed less than 10 minutes ago** */
  PendingValidation = 'PENDING_VALIDATION',
  /** **The order has been placed more than 10 minutes ago** */
  Validated = 'VALIDATED',
}

/** **Order tag type** */
export enum OrderTagType {
  /** **The order has only digital items** */
  Digital = 'DIGITAL',
  /** **The order has only physical items** */
  Physical = 'PHYSICAL',
}

/** **Order tags** */
export type OrderTags = {
  __typename?: 'OrderTags';
  /** **Brand tags** */
  brand: Array<OrderTagBrand>;
  /** **Claim tags** */
  claim: Array<OrderTagClaim>;
  /** **Claim reason tags** */
  claimReason: Array<OrderTagClaimReason>;
  /** **Fulfillment tag** */
  fulfillment: OrderTagFulfillment;
  /** **Refund tag** */
  refund: OrderTagRefund;
  /** **Reship tag** */
  reship: OrderTagReship;
  /** **Return tags** */
  return: Array<OrderTagReturn>;
  /** **Shipment tags** */
  shipment: Array<OrderTagShipment>;
  /** **Shipping mode tag** */
  shippingMode: OrderTagShippingMode;
  /** **Shopify tag** */
  shopify: OrderTagShopify;
  /** **Status tag** */
  status: OrderTagStatus;
  /** **Type tag** */
  type: OrderTagType;
};

/** **Orders** */
export type Orders = {
  __typename?: 'Orders';
  /** **Nodes** */
  nodes: Array<Order>;
  /** **Paging** */
  paging: Paging;
  /** **Total count** */
  totalCount: Scalars['PositiveInteger']['output'];
};

/** **Orders by tracking input** */
export type OrdersByTrackingInput = {
  /** **Identifier of the sale** */
  saleId: Scalars['ID']['input'];
  /** **Tracking courier slug** */
  trackingCourierSlug: Scalars['String']['input'];
  /** **Tracking number** */
  trackingNumber: Scalars['String']['input'];
};

/** **Orders counters** */
export type OrdersCounters = {
  __typename?: 'OrdersCounters';
  /** **Tagging counters** */
  tagging: OrdersTaggingCounters;
};

/** **Orders export** */
export type OrdersExport = {
  __typename?: 'OrdersExport';
  /** **File URL** */
  fileUrl?: Maybe<Scalars['String']['output']>;
};

/** **Orders export file extension** */
export enum OrdersExportFileExtension {
  /** **CSV** */
  Csv = 'CSV',
  /** **XLSX (excel)** */
  Xlsx = 'XLSX',
}

/** **Orders export input** */
export type OrdersExportInput = {
  /** **File extension** */
  fileExtension: OrdersExportFileExtension;
  /** **File format (only available for the Choose team)** */
  fileFormat?: InputMaybe<Logistician>;
  /** **Filter** */
  filter?: InputMaybe<OrdersFilterInput>;
  /** **Search** */
  search?: InputMaybe<OrdersSearchInput>;
  /** **Sort** */
  sort: OrdersSortInput;
};

/** **Orders filter creation date input** */
export type OrdersFilterCreationDateInput = {
  /** **Filter from this creation date** */
  from?: InputMaybe<Scalars['DateTime']['input']>;
  /** **Filter to this creation date** */
  to?: InputMaybe<Scalars['DateTime']['input']>;
};

/** **Orders filter input** */
export type OrdersFilterInput = {
  /** **Filter with creation date** */
  creationDate?: InputMaybe<OrdersFilterCreationDateInput>;
  /** **Filter with item SKU** */
  itemSku?: InputMaybe<Scalars['String']['input']>;
  /** **Filter with tagging** */
  tagging?: InputMaybe<OrdersFilterTaggingInput>;
};

/** **Orders filter tagging input** */
export type OrdersFilterTaggingInput = {
  /** **Filter on brand tags** */
  brand?: InputMaybe<Array<OrderTagBrand>>;
  /** **Filter on claim tags** */
  claim?: InputMaybe<Array<OrderTagClaim>>;
  /** **Filter on claim reason tags** */
  claimReason?: InputMaybe<Array<OrderTagClaimReason>>;
  /** **Filter on fulfillment tags** */
  fulfillment?: InputMaybe<Array<OrderTagFulfillment>>;
  /** **Filter on refund tags** */
  refund?: InputMaybe<Array<OrderTagRefund>>;
  /** **Filter on reship tags** */
  reship?: InputMaybe<Array<OrderTagReship>>;
  /** **Filter on return tags** */
  return?: InputMaybe<Array<OrderTagReturn>>;
  /** **Filter on shipment tags** */
  shipment?: InputMaybe<Array<OrderTagShipment>>;
  /** **Filter on shipping mode tags** */
  shippingMode?: InputMaybe<Array<OrderTagShippingMode>>;
  /** **Filter on shopify tags** */
  shopify?: InputMaybe<Array<OrderTagShopify>>;
  /** **Filter on status tags** */
  status?: InputMaybe<Array<OrderTagStatus>>;
  /** **Filter on type tags** */
  type?: InputMaybe<Array<OrderTagType>>;
};

/** **Orders input** */
export type OrdersInput = {
  /** **Filter** */
  filter?: InputMaybe<OrdersFilterInput>;
  /** **Paging** */
  paging: PagingInput;
  /** **Search** */
  search?: InputMaybe<OrdersSearchInput>;
  /** **Sort** */
  sort: OrdersSortInput;
};

/** **Orders filter search field input** */
export enum OrdersSearchFieldInput {
  /** **Search in all available fields (order identifier, recipient full name and item SKU)** */
  All = 'ALL',
}

/** **Orders search input** */
export type OrdersSearchInput = {
  /** **Field to search into** */
  field: OrdersSearchFieldInput;
  /** **Term to search** */
  term: Scalars['NonEmptyString']['input'];
};

/** **Orders sort field input** */
export enum OrdersSortFieldInput {
  /** **Sort on claim creation date** */
  ClaimCreationDate = 'CLAIM_CREATION_DATE',
  /** **Sort on creation date** */
  CreationDate = 'CREATION_DATE',
  /** **Sort on recipient full name** */
  RecipientFullName = 'RECIPIENT_FULL_NAME',
  /** **Sort on brand tag** */
  TagBrand = 'TAG_BRAND',
  /** **Sort on claim tag** */
  TagClaim = 'TAG_CLAIM',
  /** **Sort on claim reason tag** */
  TagClaimReason = 'TAG_CLAIM_REASON',
  /** **Sort on fulfillment tag** */
  TagFulfillment = 'TAG_FULFILLMENT',
  /** **Sort on refund tag** */
  TagRefund = 'TAG_REFUND',
  /** **Sort on reship tag** */
  TagReship = 'TAG_RESHIP',
  /** **Sort on return tag** */
  TagReturn = 'TAG_RETURN',
  /** **Sort on shipment tag** */
  TagShipment = 'TAG_SHIPMENT',
  /** **Sort on shipping mode tag** */
  TagShippingMode = 'TAG_SHIPPING_MODE',
  /** **Sort on shopify tag** */
  TagShopify = 'TAG_SHOPIFY',
  /** **Sort on status tag** */
  TagStatus = 'TAG_STATUS',
  /** **Sort on type tag** */
  TagType = 'TAG_TYPE',
  /** **Sort on total price sold** */
  TotalPriceSold = 'TOTAL_PRICE_SOLD',
}

/** **Orders sort input** */
export type OrdersSortInput = {
  /** **Field to sort** */
  field: OrdersSortFieldInput;
  /** **Order to sort** */
  order: OrdersSortOrderInput;
};

/** **Orders sort order input** */
export enum OrdersSortOrderInput {
  /** **Sort in ascending order** */
  Ascending = 'ASCENDING',
  /** **Sort in descending order** */
  Descending = 'DESCENDING',
}

/** **Orders tag brand counters** */
export type OrdersTagBrandCounters = {
  __typename?: 'OrdersTagBrandCounters';
  actionRequired: Scalars['PositiveInteger']['output'];
  actionRequiredClaim: Scalars['PositiveInteger']['output'];
  actionRequiredReturn: Scalars['PositiveInteger']['output'];
  actionRequiredShipmentIncident: Scalars['PositiveInteger']['output'];
  actionRequiredUnfulfilled: Scalars['PositiveInteger']['output'];
  available: Scalars['PositiveInteger']['output'];
};

/** **Orders tag claim counters** */
export type OrdersTagClaimCounters = {
  __typename?: 'OrdersTagClaimCounters';
  closed: Scalars['PositiveInteger']['output'];
  none: Scalars['PositiveInteger']['output'];
  opened: Scalars['PositiveInteger']['output'];
};

/** **Orders tag claim reason counters** */
export type OrdersTagClaimReasonCounters = {
  __typename?: 'OrdersTagClaimReasonCounters';
  deliveredButDamaged: Scalars['PositiveInteger']['output'];
  deliveredButIncomplete: Scalars['PositiveInteger']['output'];
  deliveredButWrong: Scalars['PositiveInteger']['output'];
  none: Scalars['PositiveInteger']['output'];
  notDeliveredAndReturnedToSender: Scalars['PositiveInteger']['output'];
  notDeliveredAndTrackingBlocked: Scalars['PositiveInteger']['output'];
  notDeliveredAndTrackingDelivered: Scalars['PositiveInteger']['output'];
  other: Scalars['PositiveInteger']['output'];
  voucherNotWorking: Scalars['PositiveInteger']['output'];
};

/** **Orders tag fulfillment counters** */
export type OrdersTagFulfillmentCounters = {
  __typename?: 'OrdersTagFulfillmentCounters';
  fulfilled: Scalars['PositiveInteger']['output'];
  unfulfilled: Scalars['PositiveInteger']['output'];
};

/** **Orders tag refund counters** */
export type OrdersTagRefundCounters = {
  __typename?: 'OrdersTagRefundCounters';
  none: Scalars['PositiveInteger']['output'];
  partiallyRefunded: Scalars['PositiveInteger']['output'];
  totallyRefunded: Scalars['PositiveInteger']['output'];
};

/** **Orders tag reship counters** */
export type OrdersTagReshipCounters = {
  __typename?: 'OrdersTagReshipCounters';
  none: Scalars['PositiveInteger']['output'];
  reshipped: Scalars['PositiveInteger']['output'];
};

/** **Orders tag return counters** */
export type OrdersTagReturnCounters = {
  __typename?: 'OrdersTagReturnCounters';
  canceled: Scalars['PositiveInteger']['output'];
  delivered: Scalars['PositiveInteger']['output'];
  inProgress: Scalars['PositiveInteger']['output'];
  none: Scalars['PositiveInteger']['output'];
  requested: Scalars['PositiveInteger']['output'];
  validated: Scalars['PositiveInteger']['output'];
};

/** **Orders tag shipment counters** */
export type OrdersTagShipmentCounters = {
  __typename?: 'OrdersTagShipmentCounters';
  attemptFail: Scalars['PositiveInteger']['output'];
  availableForPickup: Scalars['PositiveInteger']['output'];
  delivered: Scalars['PositiveInteger']['output'];
  exception: Scalars['PositiveInteger']['output'];
  expired: Scalars['PositiveInteger']['output'];
  inTransit: Scalars['PositiveInteger']['output'];
  infoReceived: Scalars['PositiveInteger']['output'];
  none: Scalars['PositiveInteger']['output'];
  outForDelivery: Scalars['PositiveInteger']['output'];
  pending: Scalars['PositiveInteger']['output'];
};

/** **Orders tag shipping mode counters** */
export type OrdersTagShippingModeCounters = {
  __typename?: 'OrdersTagShippingModeCounters';
  home: Scalars['PositiveInteger']['output'];
  none: Scalars['PositiveInteger']['output'];
  pickupPoint: Scalars['PositiveInteger']['output'];
};

/** **Orders tag shopify counters** */
export type OrdersTagShopifyCounters = {
  __typename?: 'OrdersTagShopifyCounters';
  none: Scalars['PositiveInteger']['output'];
  synchronized: Scalars['PositiveInteger']['output'];
  unsynchronized: Scalars['PositiveInteger']['output'];
};

/** **Orders tag status counters** */
export type OrdersTagStatusCounters = {
  __typename?: 'OrdersTagStatusCounters';
  canceled: Scalars['PositiveInteger']['output'];
  pendingValidation: Scalars['PositiveInteger']['output'];
  validated: Scalars['PositiveInteger']['output'];
};

/** **Orders tag type counters** */
export type OrdersTagTypeCounters = {
  __typename?: 'OrdersTagTypeCounters';
  digital: Scalars['PositiveInteger']['output'];
  physical: Scalars['PositiveInteger']['output'];
};

/** **Orders tagging counters** */
export type OrdersTaggingCounters = {
  __typename?: 'OrdersTaggingCounters';
  /** **Tag brand counters** */
  brand: OrdersTagBrandCounters;
  /** **Tag claim counters** */
  claim: OrdersTagClaimCounters;
  /** **Tag claim reason counters** */
  claimReason: OrdersTagClaimReasonCounters;
  /** **Tag fulfillment counters** */
  fulfillment: OrdersTagFulfillmentCounters;
  /** **Tag refund counters** */
  refund: OrdersTagRefundCounters;
  /** **Tag reship counters** */
  reship: OrdersTagReshipCounters;
  /** **Tag return counters** */
  return: OrdersTagReturnCounters;
  /** **Tag shipment counters** */
  shipment: OrdersTagShipmentCounters;
  /** **Tag shipping mode counters** */
  shippingMode: OrdersTagShippingModeCounters;
  /** **Tag shopify counters** */
  shopify: OrdersTagShopifyCounters;
  /** **Tag status counters** */
  status: OrdersTagStatusCounters;
  /** **Tag type counters** */
  type: OrdersTagTypeCounters;
};

/** **Orders unfulfilled export input** */
export type OrdersUnfulfilledExportInput = {
  /** **File extension** */
  fileExtension: OrdersExportFileExtension;
  /** **File format** */
  fileFormat?: InputMaybe<Logistician>;
};

/** **Paging** */
export type Paging = {
  __typename?: 'Paging';
  /** **End cursor** */
  endCursor?: Maybe<Scalars['ID']['output']>;
  /** **Has next page ?** */
  hasNextPage: Scalars['Boolean']['output'];
  /** **Has previous page ?** */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** **Page size** */
  pageSize: Scalars['PositiveInteger']['output'];
  /** **Start cursor** */
  startCursor?: Maybe<Scalars['ID']['output']>;
};

/** **Paging input** */
export type PagingInput = {
  /** **After cursor** */
  afterCursor?: InputMaybe<Scalars['ID']['input']>;
  /** **Before cursor** */
  beforeCursor?: InputMaybe<Scalars['ID']['input']>;
  /** **Page size** */
  pageSize: Scalars['PositiveInteger']['input'];
};

/** **Parcel** */
export type Parcel = {
  __typename?: 'Parcel';
  /** **Estimated delivery date range** */
  estimatedDeliveryDateRange?: Maybe<ParcelEstimatedDeliveryDateRange>;
  /**
   * **Does the parcel has its tracking stucked or not ?**
   *
   * - only if more than 3 days in 'pending' for now
   */
  hasTrackingStucked: Scalars['Boolean']['output'];
  /** **ID** */
  id: Scalars['ID']['output'];
  /** **Items** */
  items: Array<Item>;
  /** **Recipient** */
  recipient: Recipient;
  /** **Date when the parcel was picked up by the carrier** */
  trackingCarrierPickedUpAt?: Maybe<Scalars['DateTime']['output']>;
  /** **Tracking carrier slug** */
  trackingCarrierSlug: Scalars['String']['output'];
  /** **Tracking courier** */
  trackingCourier: Courier;
  /** **Date when the parcel was delivered** */
  trackingDeliveredAt?: Maybe<Scalars['DateTime']['output']>;
  /** **Tracking number** */
  trackingNumber: Scalars['String']['output'];
  /** **Tracking status** */
  trackingStatus: TrackingStatus;
  /** **Tracking sub status** */
  trackingSubStatus?: Maybe<TrackingSubStatus>;
  /** **Date of the last tracking update** */
  trackingUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** **Tracking URL** */
  trackingUrl: Scalars['String']['output'];
};

/** **Parcel estimated delivery date range** */
export type ParcelEstimatedDeliveryDateRange = {
  __typename?: 'ParcelEstimatedDeliveryDateRange';
  /** **Max** */
  max: Scalars['DateTime']['output'];
  /** **Min** */
  min: Scalars['DateTime']['output'];
};

/** **Payment processor** */
export enum PaymentProcessor {
  /** **Alma** */
  Alma = 'Alma',
  /** **Braintree** */
  Braintree = 'Braintree',
  /** **Choose** */
  Choose = 'Choose',
  /** **Primer** */
  Primer = 'Primer',
  /** **Stripe** */
  Stripe = 'Stripe',
}

/** **Payment Sub-processor - Stripe Specific ** */
export enum PaymentSubProcessor {
  /** **Bancontact** */
  Bancontact = 'Bancontact',
  /** **Klarna** */
  Klarna = 'Klarna',
  /** **PayPal** */
  PayPal = 'PayPal',
}

/** **Pickup point address** */
export type PickupAddress = {
  __typename?: 'PickupAddress';
  /** **city** */
  city: Scalars['String']['output'];
  /** **Country code** */
  countryCode: Scalars['String']['output'];
  /** **name** */
  name: Scalars['String']['output'];
  /** **street** */
  street: Scalars['String']['output'];
  /** **streetAdditional** */
  streetAdditional?: Maybe<Scalars['String']['output']>;
  /** **zipCode** */
  zipCode: Scalars['String']['output'];
};

/** **Pickup point** */
export type PickupPoint = {
  __typename?: 'PickupPoint';
  /** **Address** */
  address?: Maybe<PickupAddress>;
  /** **Country code** */
  countryCode: Scalars['String']['output'];
  /** **Id** */
  id: Scalars['ID']['output'];
  /** **Network** */
  network: PickupPointNetwork;
};

/** **Pickup point network** */
export enum PickupPointNetwork {
  /** **Colissimo** */
  Colissimo = 'COLISSIMO',
  /** **Mondial relay** */
  MondialRelay = 'MONDIAL_RELAY',
  /** **Shop2Shop** */
  Shop2Shop = 'SHOP2SHOP',
}

export type PostRegisterConfig = {
  preferredLanguage?: InputMaybe<Scalars['String']['input']>;
};

/** **Price** */
export type Price = {
  __typename?: 'Price';
  /** **Currency ISO code** */
  currency: CurrencyIso;
  /** **Value with VAT (in cents)** */
  valueWithVat: Scalars['Int']['output'];
  /** **Value without VAT (in cents)** */
  valueWithoutVat: Scalars['Int']['output'];
};

export type ProductSoldErrorV2 = {
  __typename?: 'ProductSoldErrorV2';
  code: Scalars['String']['output'];
  id: Scalars['String']['output'];
  order_id: Scalars['String']['output'];
  product_id: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  /** **Billing export** */
  billingExport: BillingExport;
  /** **Get information about state of billing** */
  billingStateV2: BillingStateV2;
  /** **Billing** */
  billingV2: BillingV2;
  brandInfo?: Maybe<BrandInfo>;
  /** **Get current authenticated user** */
  currentUser: User;
  /** **Detects the couriers using the input (order id and tracking number)** */
  detectCouriers: Array<Courier>;
  getBrandsAccess: Array<Maybe<BrandAccess>>;
  getInviteCode: Scalars['String']['output'];
  /** **Gets the system information** */
  info: SystemInfo;
  /** **Gets the invoices for a sale** */
  invoices: Array<Invoice>;
  members: Array<Member>;
  /** **Gets the order** */
  order: Order;
  /** **Returns the upload URL for an order return label** */
  orderReturnLabelUploadUrl: Upload;
  /** **Gets the orders** */
  orders: Orders;
  /** **Gets the orders using the tracking input (sale id, tracking courier slug and tracking number)** */
  ordersByTracking: Array<Order>;
  /** **Orders counters** */
  ordersCounters: OrdersCounters;
  /** **Orders export** */
  ordersExport: OrdersExport;
  /** **Orders unfulfilled export** */
  ordersUnfulfilledExport: OrdersExport;
  /** **Searches the couriers using their name** */
  searchCouriers: Array<Courier>;
  /** **Searches all couriers by supported countries** */
  searchCouriersBySupportedCountries: Array<Courier>;
  /** **Stocks export** */
  stocksExport: StocksExport;
  /** **Gets the supplier billing state** */
  supplierBillingState: SupplierBillingState;
  /** **Couriers used by the brand to ship orders** */
  usedCouriers: Array<Courier>;
};

export type QueryBillingExportArgs = {
  input: BillingExportInput;
  saleId: Scalars['ID']['input'];
};

export type QueryBillingStateV2Args = {
  saleId: Scalars['String']['input'];
};

export type QueryBillingV2Args = {
  saleId: Scalars['ID']['input'];
};

export type QueryBrandInfoArgs = {
  brandId: Scalars['String']['input'];
};

export type QueryDetectCouriersArgs = {
  input: DetectCouriersInput;
};

export type QueryInvoicesArgs = {
  saleId: Scalars['ID']['input'];
};

export type QueryOrderArgs = {
  id: Scalars['ID']['input'];
};

export type QueryOrderReturnLabelUploadUrlArgs = {
  input: OrderReturnLabelUploadUrlInput;
};

export type QueryOrdersArgs = {
  input: OrdersInput;
  saleId: Scalars['ID']['input'];
};

export type QueryOrdersByTrackingArgs = {
  input: OrdersByTrackingInput;
};

export type QueryOrdersCountersArgs = {
  saleId: Scalars['ID']['input'];
  sellerId: Scalars['ID']['input'];
};

export type QueryOrdersExportArgs = {
  input: OrdersExportInput;
  saleId: Scalars['ID']['input'];
  sellerId: Scalars['ID']['input'];
};

export type QueryOrdersUnfulfilledExportArgs = {
  input: OrdersUnfulfilledExportInput;
  saleId: Scalars['ID']['input'];
  sellerId: Scalars['ID']['input'];
};

export type QuerySearchCouriersArgs = {
  input: SearchCouriersInput;
};

export type QuerySearchCouriersBySupportedCountriesArgs = {
  input: SearchCouriersBySupportedCountries;
};

export type QueryStocksExportArgs = {
  input: StocksExportInput;
  saleId: Scalars['ID']['input'];
};

export type QuerySupplierBillingStateArgs = {
  saleId: Scalars['ID']['input'];
};

/** **The recipient is the person who will receive the order** */
export type Recipient = {
  __typename?: 'Recipient';
  /** **Address** */
  address: Address;
  /** **Email** */
  email: Scalars['String']['output'];
  /** **First name** */
  firstName: Scalars['String']['output'];
  /** **Full name ("first_name last_name")** */
  fullName: Scalars['String']['output'];
  /** **Identifier** */
  id: Scalars['ID']['output'];
  /** **Last name** */
  lastName: Scalars['String']['output'];
  /** **Phone number** */
  phoneNumber: Scalars['String']['output'];
  /** **Previous recipient if it has been updated** */
  previous?: Maybe<Recipient>;
};

/** **ReturnParcel** */
export type ReturnParcel = {
  __typename?: 'ReturnParcel';
  /** **Claim id** */
  claimId?: Maybe<Scalars['ID']['output']>;
  /** **ID** */
  id: Scalars['ID']['output'];
  /** **Items** */
  items: Array<Item>;
  /** **Return slip id** */
  returnSlipId: Scalars['ID']['output'];
  /** **Tracking carrier slug** */
  trackingCarrierSlug: Scalars['String']['output'];
  /** **Tracking courier** */
  trackingCourier: Courier;
  /** **Date when the parcel was delivered** */
  trackingDeliveredAt?: Maybe<Scalars['DateTime']['output']>;
  /** **Tracking number** */
  trackingNumber: Scalars['String']['output'];
  /** **Tracking status** */
  trackingStatus: TrackingStatus;
  /** **Date of the last tracking update** */
  trackingUpdatedAt: Scalars['DateTime']['output'];
  /** **Tracking URL** */
  trackingUrl: Scalars['String']['output'];
};

/** **Return slip** */
export type ReturnSlip = {
  __typename?: 'ReturnSlip';
  /** **Claim id** */
  claimId?: Maybe<Scalars['ID']['output']>;
  /** **Created at** */
  createdAt: Scalars['DateTime']['output'];
  /** **ID** */
  id: Scalars['ID']['output'];
  /** **Items** */
  items: Array<Item>;
  /** **Tracking carrier slug** */
  trackingCarrierSlug: Scalars['String']['output'];
  /** **Tracking courier** */
  trackingCourier: Courier;
  /** **Tracking number** */
  trackingNumber: Scalars['String']['output'];
  /** **Tracking URL** */
  trackingUrl: Scalars['String']['output'];
};

/** **Sale status** */
export enum SaleStatus {
  /** **Sale is ended** */
  Ended = 'ENDED',
  /** **Sale is ongoing** */
  Ongoing = 'ONGOING',
  /** **Sale is upcoming** */
  Upcoming = 'UPCOMING',
}

/** **Search couriers by supported countries input** */
export type SearchCouriersBySupportedCountries = {
  /** **Country code iso3** */
  countryCodeIso3: Scalars['String']['input'];
};

/** **Search couriers input** */
export type SearchCouriersInput = {
  /** **Name of the courier** */
  name: Scalars['String']['input'];
};

/** **Shopify order integration** */
export type ShopifyOrderIntegration = {
  __typename?: 'ShopifyOrderIntegration';
  /** **Count ID of the order from Shopify** */
  orderCountId?: Maybe<Scalars['ID']['output']>;
  /** **ID of the order from Shopify** */
  orderId: Scalars['ID']['output'];
  /** **URL of the order from Shopify** */
  orderUrl: Scalars['String']['output'];
};

/** **Stocks export** */
export type StocksExport = {
  __typename?: 'StocksExport';
  /** **File URL** */
  fileUrl: Scalars['URL']['output'];
};

/** **Stocks export input** */
export type StocksExportInput = {
  /** **File extension** */
  fileExtension: FileExtension;
};

export enum Store {
  Fr = 'fr',
  Us = 'us',
}

/** **Submit down payment invoice input** */
export type SubmitDownPaymentInput = {
  /** **Invoice storage file key** */
  invoiceStorageFileKey: Scalars['String']['input'];
};

/** **Submit invoice input** */
export type SubmitInvoiceInput = {
  /** **Invoice storage file key** */
  invoiceStorageFileKey: Scalars['String']['input'];
};

/** **Supplier billing state** */
export type SupplierBillingState = {
  __typename?: 'SupplierBillingState';
  /** **Whether the supplier can access to the supplier billing** */
  canAccess: Scalars['Boolean']['output'];
  /** **Whether the supplier can access to the supplier billing using a bypass (set by the Choose team)** */
  canAccessBypassed: Scalars['Boolean']['output'];
  /** **Whether the supplier billing is pending claim closing** */
  isPendingClaimClosing: Scalars['Boolean']['output'];
  /** **Whether the supplier billing is pending order delivery** */
  isPendingOrderDelivery: Scalars['Boolean']['output'];
  /** **Whether the supplier billing is pending return validation** */
  isPendingReturnValidation: Scalars['Boolean']['output'];
  /** **Whether the supplier billing is pending withdrawal period** */
  isPendingWithdrawalPeriod: Scalars['Boolean']['output'];
  /** **Order identifiers that are pending claim closing** */
  pendingClaimClosingOrderIds: Array<Scalars['ID']['output']>;
  /** **Order identifiers that are pending order delivery** */
  pendingOrderDeliveryOrderIds: Array<Scalars['ID']['output']>;
  /** **Order identifiers that are pending return validation** */
  pendingReturnValidationOrderIds: Array<Scalars['ID']['output']>;
  /** **Sale status** */
  saleStatus: SupplierBillingStateSaleStatus;
  /** **Date when the withdrawal period is passed** */
  withdrawalPeriodEndDate: Scalars['DateTime']['output'];
  /** **Duration in days of the withdrawal period** */
  withdrawalPeriodInDays: Scalars['PositiveInteger']['output'];
};

/** **Supplier billing state sale status** */
export enum SupplierBillingStateSaleStatus {
  /** **Sale is ended** */
  Ended = 'ENDED',
  /** **Sale is ongoing** */
  Ongoing = 'ONGOING',
  /** **Sale is upcoming** */
  Upcoming = 'UPCOMING',
}

/** **Swap order parcel input** */
export type SwapOrderParcelInput = {
  /** **Order id** */
  orderId: Scalars['ID']['input'];
  /** **Tracking info** */
  trackingInfo: TrackingInfoInput;
};

/** **System information** */
export type SystemInfo = {
  __typename?: 'SystemInfo';
  /**
   * **System build version**
   * @deprecated Use `version` instead
   */
  buildVersion: Scalars['String']['output'];
  /** **System environment** */
  environment: Scalars['String']['output'];
  /** **System version** */
  version: Scalars['String']['output'];
};

/** **Tracking info input** */
export type TrackingInfoInput = {
  /** **Tracking courier slug** */
  trackingCourierSlug: Scalars['String']['input'];
  /** **Tracking number** */
  trackingNumber: Scalars['String']['input'];
};

/** **Tracking status** */
export enum TrackingStatus {
  /** **Attempt fail** */
  AttemptFail = 'ATTEMPT_FAIL',
  /** **Available for pickup** */
  AvailableForPickup = 'AVAILABLE_FOR_PICKUP',
  /** **Delivered** */
  Delivered = 'DELIVERED',
  /** **Exception** */
  Exception = 'EXCEPTION',
  /** **Expired** */
  Expired = 'EXPIRED',
  /** **Info received** */
  InfoReceived = 'INFO_RECEIVED',
  /** **In transit** */
  InTransit = 'IN_TRANSIT',
  /** **Out for delivery** */
  OutForDelivery = 'OUT_FOR_DELIVERY',
  /** **Pending** */
  Pending = 'PENDING',
}

/** **Tracking sub status** */
export enum TrackingSubStatus {
  /** **Business is closed at the time of delivery (AttemptFail_003)** */
  AttemptFailBusinessClosed = 'ATTEMPT_FAIL_BUSINESS_CLOSED',
  /** **The delivery of the package failed due to some reason. Courier usually leaves a notice and will try to deliver again (AttemptFail_001)** */
  AttemptFailDueToSomeReason = 'ATTEMPT_FAIL_DUE_TO_SOME_REASON',
  /** **Recipient not available at the given address (AttemptFail_002)** */
  AttemptFailRecipientNotAvailable = 'ATTEMPT_FAIL_RECIPIENT_NOT_AVAILABLE',
  /** **The package arrived at a pickup point near you and is available for pickup (AvailableForPickup_001)** */
  AvailableForPickup = 'AVAILABLE_FOR_PICKUP',
  /** **Package delivered to the recipient and cash collected on delivery (Delivered_004)** */
  DeliveredAndCashCollected = 'DELIVERED_AND_CASH_COLLECTED',
  /** **Package delivered to and signed by the recipient (Delivered_003)** */
  DeliveredAndSignedByRecipient = 'DELIVERED_AND_SIGNED_BY_RECIPIENT',
  /** **CUSTOM: Marked delivered by a bot (Delivered_Mark_By_Bot)** */
  DeliveredMarkedByBot = 'DELIVERED_MARKED_BY_BOT',
  /** **CUSTOM: Marked delivered by the customer (Delivered_Mark_By_Customer)** */
  DeliveredMarkedByCustomer = 'DELIVERED_MARKED_BY_CUSTOMER',
  /** **CUSTOM: Marked delivered by a member of the support team (Delivered_Mark_By_Support)** */
  DeliveredMarkedBySupportTeam = 'DELIVERED_MARKED_BY_SUPPORT_TEAM',
  /** **Package picked up by the recipient (Delivered_002)** */
  DeliveredPickedUpByRecipient = 'DELIVERED_PICKED_UP_BY_RECIPIENT',
  /** **Shipment delivered successfully (Delivered_001)** */
  DeliveredSuccessfully = 'DELIVERED_SUCCESSFULLY',
  /** **The package is on its way back to the sender (Exception_010)** */
  ExceptionBackToSender = 'EXCEPTION_BACK_TO_SENDER',
  /** **The return package has been successfully received by the sender (Exception_011)** */
  ExceptionBackToSenderReceived = 'EXCEPTION_BACK_TO_SENDER_RECEIVED',
  /** **Shipment damaged (Exception_012)** */
  ExceptionDamaged = 'EXCEPTION_DAMAGED',
  /** **Package delayed due to some issues during the customs clearance (Exception_004)** */
  ExceptionDelayedCustomsClearance = 'EXCEPTION_DELAYED_CUSTOMS_CLEARANCE',
  /** **Package not delivered due to incorrect recipient address (Exception_007)** */
  ExceptionIncorrectRecipientAddress = 'EXCEPTION_INCORRECT_RECIPIENT_ADDRESS',
  /** **Delivery of the package failed as it got lost (Exception_013)** */
  ExceptionLost = 'EXCEPTION_LOST',
  /** **The package being held due to pending payment from the recipient's end (Exception_006)** */
  ExceptionPendingPayment = 'EXCEPTION_PENDING_PAYMENT',
  /** **Package available for the pickup but not collected by the recipient (Exception_008)** */
  ExceptionPickupNotCollectedByRecipient = 'EXCEPTION_PICKUP_NOT_COLLECTED_BY_RECIPIENT',
  /** **Delivery of the package failed as the recipient refused to take the package due to some reason (Exception_003)** */
  ExceptionRecipientRefused = 'EXCEPTION_RECIPIENT_REFUSED',
  /** **Delivery of the package failed as the recipient relocated (Exception_002)** */
  ExceptionRecipientRelocated = 'EXCEPTION_RECIPIENT_RELOCATED',
  /** **Package rejected by the carrier due to noncompliance with its guidelines (Exception_009)** */
  ExceptionRejectedByCarrier = 'EXCEPTION_REJECTED_BY_CARRIER',
  /** **Delivery of the package failed due to some shipping exception (Exception_001)** */
  ExceptionShippingException = 'EXCEPTION_SHIPPING_EXCEPTION',
  /** **Package delayed due to some unforeseen reasons (Exception_005)** */
  ExceptionUnforseenReason = 'EXCEPTION_UNFORSEEN_REASON',
  /** **No tracking information of the shipment, from the last 30 days (Expired_001)** */
  ExpiredNoTrackingInformation = 'EXPIRED_NO_TRACKING_INFORMATION',
  /** **The carrier received a request from the shipper and is about to pick up the shipment (InfoReceived_001)** */
  InfoReceivedAboutToPickup = 'INFO_RECEIVED_ABOUT_TO_PICKUP',
  /** **Shipment accepted by the carrier (InTransit_002)** */
  InTransitAcceptedByCarrier = 'IN_TRANSIT_ACCEPTED_BY_CARRIER',
  /** **Shipment arrived at a hub or sorting center (InTransit_003)** */
  InTransitArrivedAtAHubOrSortingCenter = 'IN_TRANSIT_ARRIVED_AT_A_HUB_OR_SORTING_CENTER',
  /** **International shipment arrived at the destination country / region (InTransit_004)** */
  InTransitArrivedAtDestinationCountryOrRegion = 'IN_TRANSIT_ARRIVED_AT_DESTINATION_COUNTRY_OR_REGION',
  /** **Customs clearance completed (InTransit_005)** */
  InTransitCustomsClearanceCompleted = 'IN_TRANSIT_CUSTOMS_CLEARANCE_COMPLETED',
  /** **Package departed from the facility (InTransit_007)** */
  InTransitDepartedFromFacility = 'IN_TRANSIT_DEPARTED_FROM_FACILITY',
  /** **Shipment forwarded to a different delivery address (InTransit_009)** */
  InTransitForwardedToADifferentDeliveryAddress = 'IN_TRANSIT_FORWARDED_TO_A_DIFFERENT_DELIVERY_ADDRESS',
  /** **Package handed over to customs for clearance (InTransit_006)** */
  InTransitHandedOverToCustomsForClearance = 'IN_TRANSIT_HANDED_OVER_TO_CUSTOMS_FOR_CLEARANCE',
  /** **Shipment on the way (InTransit_001)** */
  InTransitOnTheWay = 'IN_TRANSIT_ON_THE_WAY',
  /** **Problem resolved and shipment in transit (InTransit_008)** */
  InTransitProblemResolved = 'IN_TRANSIT_PROBLEM_RESOLVED',
  /** **The package is out for delivery (OutForDelivery_001)** */
  OutForDelivery = 'OUT_FOR_DELIVERY',
  /** **A delivery appointment is scheduled (OutForDelivery_004)** */
  OutForDeliveryAppointmentScheduled = 'OUT_FOR_DELIVERY_APPOINTMENT_SCHEDULED',
  /** **The recipient is contacted before the final delivery (OutForDelivery_003)** */
  OutForDeliveryRecipientContacted = 'OUT_FOR_DELIVERY_RECIPIENT_CONTACTED',
  /** **Can’t track this type of shipment as the carrier is unrecognized (Pending_006)** */
  PendingCarrierUnrecognized = 'PENDING_CARRIER_UNRECOGNIZED',
  /** **The order has been processed / packaged, but not scanned at a shipping location yet (Pending_003)** */
  PendingNotScanned = 'PENDING_NOT_SCANNED',
  /** **It represents the shipments are pending due to no connection with carrier accounts (Pending_002)** */
  PendingNoConnectionWithCarrier = 'PENDING_NO_CONNECTION_WITH_CARRIER',
  /** **No information available on the carrier website or the tracking number is yet to be tracked (Pending_001)** */
  PendingNoInformationAvailable = 'PENDING_NO_INFORMATION_AVAILABLE',
  /** **There have been no new tracking updates in the last 120 days (Pending_005)** */
  PendingNoUpdate = 'PENDING_NO_UPDATE',
  /** **There is no tracking info available because the carrier is wrong (Pending_004)** */
  PendingWrongCarrier = 'PENDING_WRONG_CARRIER',
}

/** **Update order parcel input** */
export type UpdateOrderParcelInput = {
  /** **Order id** */
  orderId: Scalars['ID']['input'];
  /** **Order ids excluded** */
  orderIdsExcluded?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** **Tracking info** */
  trackingInfo: TrackingInfoInput;
};

export type Upload = {
  __typename?: 'Upload';
  expiresAt: Scalars['DateTime']['output'];
  key: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type UploadInput = {
  fileMimeType: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  fileSize: Scalars['Int']['input'];
};

/** **User of brand dashboard** */
export type User = {
  __typename?: 'User';
  email: Scalars['String']['output'];
  firstname: Scalars['String']['output'];
  intercomUserHash: Scalars['ID']['output'];
  lastname: Scalars['String']['output'];
  picture: Scalars['String']['output'];
  preferredLanguage: Scalars['String']['output'];
  userId: Scalars['ID']['output'];
};

export type UserPasswordConnection = {
  firstname: Scalars['String']['input'];
  lastname: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

/** **Voucher replacement code input** */
export type VoucherReplacementCodeInput = {
  /** **Original code** */
  originalCode: Scalars['String']['input'];
  /** **Replacement code** */
  replacementCode: Scalars['String']['input'];
};

export type PartialCourierFragment = {
  __typename?: 'Courier';
  logoUrl: string;
  name: string;
  slug: string;
};

export type CurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserQuery = {
  __typename?: 'Query';
  currentUser: {
    __typename?: 'User';
    userId: string;
    intercomUserHash: string;
  };
};

export type BillingQueryVariables = Exact<{
  saleId: Scalars['ID']['input'];
}>;

export type BillingQuery = {
  __typename?: 'Query';
  billingV2: {
    __typename?: 'BillingV2';
    total_operation_ht: number;
    total_operation_ttc: number;
    total_refund_ht: number;
    total_refund_ttc: number;
    total_commission_ht: number;
    total_commission_ttc: number;
    total_delivery_ht: number;
    total_delivery_ttc: number;
    total_billing_ht: number;
    total_billing_ttc: number;
    total_remaining_ht: number;
    total_remaining_ttc: number;
    is_subject_to_vat: boolean;
    has_error: boolean;
    is_full_paid: boolean;
    agg_by_vat_rate: Array<{
      __typename?: 'BillingByVatV2';
      vat_rate: string;
      total_operation_ht: number;
      total_operation_ttc: number;
      total_commission_ht: number;
      total_commission_ttc: number;
      total_billing_ht: number;
      total_billing_ttc: number;
      total_vat: number;
    }>;
    errors: Array<{
      __typename: 'ProductSoldErrorV2';
      id: string;
      order_id: string;
      product_id: string;
      code: string;
    }>;
    payments: Array<{
      __typename?: 'BillingPaymentLine';
      amountVatExcludedInCents: number;
      amountVatInCents: number;
      amountVatIncludedInCents: number;
      invoiceId: string;
      paidAt?: string | null;
    }>;
    down_payment?:
      | {
          __typename: 'DownPaymentClassic';
          preset: DownPaymentPreset;
          rate: number;
          financialDetail: {
            __typename?: 'FinancialBilling';
            total_billing_ht: number;
            total_billing_ttc: number;
            total_commission_ht: number;
            total_commission_ttc: number;
            total_delivery_ht: number;
            total_delivery_ttc: number;
            total_operation_ht: number;
            total_operation_ttc: number;
            total_refund_ht: number;
            total_refund_ttc: number;
            total_remaining_ht: number;
            total_remaining_ttc: number;
            agg_by_vat_rate: Array<{
              __typename?: 'BillingByVatV2';
              vat_rate: string;
              total_operation_ht: number;
              total_operation_ttc: number;
              total_commission_ht: number;
              total_commission_ttc: number;
              total_billing_ht: number;
              total_billing_ttc: number;
              total_vat: number;
            }>;
          };
          rules: Array<
            | {
                __typename: 'ActivationDateRule';
                activationDate: string;
                isValidated: boolean;
              }
            | {
                __typename: 'FulfilledOrderPercentageRule';
                currentValue: number;
                isValidated: boolean;
                targetValue: number;
              }
            | {
                __typename: 'MinimumAmountInCentsRule';
                currentValue: number;
                isValidated: boolean;
                targetValue: number;
              }
          >;
        }
      | { __typename: 'DownPaymentExceptional'; preset: DownPaymentPreset }
      | null;
  };
};

export type BillingExportQueryVariables = Exact<{
  saleId: Scalars['ID']['input'];
  input: BillingExportInput;
}>;

export type BillingExportQuery = {
  __typename?: 'Query';
  billingExport: { __typename?: 'BillingExport'; fileUrl: string };
};

export type BillingStateQueryVariables = Exact<{
  saleId: Scalars['ID']['input'];
}>;

export type BillingStateQuery = {
  __typename?: 'Query';
  supplierBillingState: {
    __typename?: 'SupplierBillingState';
    canAccess: boolean;
    canAccessBypassed: boolean;
    isPendingClaimClosing: boolean;
    isPendingOrderDelivery: boolean;
    isPendingReturnValidation: boolean;
    isPendingWithdrawalPeriod: boolean;
    pendingClaimClosingOrderIds: Array<string>;
    pendingOrderDeliveryOrderIds: Array<string>;
    pendingReturnValidationOrderIds: Array<string>;
    saleStatus: SupplierBillingStateSaleStatus;
    withdrawalPeriodEndDate: string;
    withdrawalPeriodInDays: number;
  };
};

export type BrandInfoQueryVariables = Exact<{
  brandId: Scalars['String']['input'];
}>;

export type BrandInfoQuery = {
  __typename?: 'Query';
  brandInfo?: {
    __typename?: 'BrandInfo';
    name: string;
    acceptContract: boolean;
    nbUsers: number;
  } | null;
};

export type BrandsAccessQueryVariables = Exact<{ [key: string]: never }>;

export type BrandsAccessQuery = {
  __typename?: 'Query';
  getBrandsAccess: Array<{
    __typename?: 'BrandAccess';
    brandId: string;
    brandName: string;
    role: string;
    store: Store;
  } | null>;
};

export type SearchCouriersQueryVariables = Exact<{
  input: SearchCouriersInput;
}>;

export type SearchCouriersQuery = {
  __typename?: 'Query';
  searchCouriers: Array<{
    __typename?: 'Courier';
    supportedCountries: Array<string>;
    logoUrl: string;
    name: string;
    slug: string;
  }>;
};

export type SearchCouriersBySupportedCountriesQueryVariables = Exact<{
  input: SearchCouriersBySupportedCountries;
}>;

export type SearchCouriersBySupportedCountriesQuery = {
  __typename?: 'Query';
  searchCouriersBySupportedCountries: Array<{
    __typename?: 'Courier';
    supportedCountries: Array<string>;
    logoUrl: string;
    name: string;
    slug: string;
  }>;
};

export type DetectCouriersQueryVariables = Exact<{
  input: DetectCouriersInput;
}>;

export type DetectCouriersQuery = {
  __typename?: 'Query';
  detectCouriers: Array<{
    __typename?: 'Courier';
    logoUrl: string;
    name: string;
    slug: string;
  }>;
};

export type InviteCodeQueryVariables = Exact<{ [key: string]: never }>;

export type InviteCodeQuery = { __typename?: 'Query'; getInviteCode: string };

export type InfoQueryVariables = Exact<{ [key: string]: never }>;

export type InfoQuery = {
  __typename?: 'Query';
  info: { __typename?: 'SystemInfo'; version: string; environment: string };
};

export type InvoicesQueryVariables = Exact<{
  saleId: Scalars['ID']['input'];
}>;

export type InvoicesQuery = {
  __typename?: 'Query';
  invoices: Array<{
    __typename?: 'Invoice';
    createdAt: string;
    fileName: string;
    fileUrl: string;
    id: string;
    status: InvoiceStatus;
    type: InvoiceType;
  }>;
};

export type JoinOrderReturnLabelForClaimMutationVariables = Exact<{
  input: JoinOrderReturnLabelForClaimInput;
}>;

export type JoinOrderReturnLabelForClaimMutation = {
  __typename?: 'Mutation';
  joinOrderReturnLabelForClaim?: any | null;
};

export type MembersQueryVariables = Exact<{ [key: string]: never }>;

export type MembersQuery = {
  __typename?: 'Query';
  members: Array<{
    __typename?: 'Member';
    userId: string;
    email: string;
    role: string;
    picture: string;
    firstname: string;
    lastname: string;
  }>;
};

export type OrderQueryVariables = Exact<{
  orderId: Scalars['ID']['input'];
}>;

export type OrderQuery = {
  __typename?: 'Query';
  order: {
    __typename?: 'Order';
    id: string;
    billingId: string;
    sellerId: string;
    saleId: string;
    customerId: string;
    initialOrderId?: string | null;
    reshipOrderId?: string | null;
    hasBeenExported: boolean;
    isShippingEstimatedTimeExceeded: boolean;
    isCancelled: boolean;
    isFullDigital: boolean;
    createdAt: string;
    reshipOrder?: { __typename?: 'Order'; createdAt: string } | null;
    recipient: {
      __typename?: 'Recipient';
      id: string;
      fullName: string;
      firstName: string;
      lastName: string;
      email: string;
      phoneNumber: string;
      address: {
        __typename?: 'Address';
        street: string;
        streetAdditional?: string | null;
        zipCode: string;
        city: string;
        county?: string | null;
        state?: string | null;
        country: string;
        countryCode: string;
      };
      previous?: {
        __typename?: 'Recipient';
        fullName: string;
        firstName: string;
        lastName: string;
        email: string;
        phoneNumber: string;
        address: {
          __typename?: 'Address';
          street: string;
          streetAdditional?: string | null;
          zipCode: string;
          city: string;
          county?: string | null;
          state?: string | null;
          country: string;
          countryCode: string;
        };
      } | null;
    };
    shipping: {
      __typename?: 'OrderShipping';
      fees: number;
      feesSold: number;
      policyId?: string | null;
      deliveryDateRangeHistory: Array<{
        __typename?: 'DeliveryDateRange';
        start: string;
        end: string;
      }>;
      pickupPoint?: {
        __typename?: 'PickupPoint';
        id: string;
        countryCode: string;
        network: PickupPointNetwork;
        address?: {
          __typename?: 'PickupAddress';
          name: string;
          street: string;
          streetAdditional?: string | null;
          zipCode: string;
          city: string;
          countryCode: string;
        } | null;
      } | null;
    };
    items: Array<{
      __typename?: 'Item';
      id: string;
      productId: string;
      productVariantId: string;
      name: string;
      imageUrl: string;
      size?: string | null;
      sku?: string | null;
      gtin?: string | null;
      isDigital: boolean;
      digitalCouponCode?: string | null;
      newDigitalCouponCode?: string | null;
      isReturnGenerated: boolean;
      isReturnValidated: boolean;
      isOffered: boolean;
      returnReasonCode?: ItemReturnReasonCode | null;
      isCancelled: boolean;
      priceSold: {
        __typename?: 'Price';
        currency: CurrencyIso;
        valueWithVat: number;
        valueWithoutVat: number;
      };
      priceBoughtByChoose: {
        __typename?: 'Price';
        currency: CurrencyIso;
        valueWithVat: number;
        valueWithoutVat: number;
      };
      refundSummary: {
        __typename?: 'OrderLineRefundSummary';
        isTotallyRefunded: boolean;
        refundedAmountInPercentage: number;
        refundedAmountInPercentageCoveredByChoose: number;
        refundedAmountInPercentageCoveredBySupplier: number;
      };
    }>;
    totalPriceSold: {
      __typename?: 'Price';
      currency: CurrencyIso;
      valueWithVat: number;
      valueWithoutVat: number;
    };
    parcels: Array<{
      __typename?: 'Parcel';
      hasTrackingStucked: boolean;
      id: string;
      trackingNumber: string;
      trackingStatus: TrackingStatus;
      trackingSubStatus?: TrackingSubStatus | null;
      trackingUrl: string;
      trackingUpdatedAt?: string | null;
      trackingDeliveredAt?: string | null;
      items: Array<{
        __typename?: 'Item';
        id: string;
        productId: string;
        productVariantId: string;
        name: string;
        imageUrl: string;
        size?: string | null;
        sku?: string | null;
        gtin?: string | null;
        isDigital: boolean;
        digitalCouponCode?: string | null;
        newDigitalCouponCode?: string | null;
        isReturnGenerated: boolean;
        isOffered: boolean;
        priceSold: {
          __typename?: 'Price';
          currency: CurrencyIso;
          valueWithVat: number;
          valueWithoutVat: number;
        };
        priceBoughtByChoose: {
          __typename?: 'Price';
          currency: CurrencyIso;
          valueWithVat: number;
          valueWithoutVat: number;
        };
      }>;
      recipient: {
        __typename?: 'Recipient';
        fullName: string;
        firstName: string;
        lastName: string;
        email: string;
        phoneNumber: string;
        address: {
          __typename?: 'Address';
          street: string;
          streetAdditional?: string | null;
          zipCode: string;
          city: string;
          county?: string | null;
          state?: string | null;
          country: string;
          countryCode: string;
        };
      };
      trackingCourier: {
        __typename?: 'Courier';
        logoUrl: string;
        name: string;
        slug: string;
      };
    }>;
    returnParcels: Array<{
      __typename?: 'ReturnParcel';
      claimId?: string | null;
      id: string;
      trackingNumber: string;
      trackingStatus: TrackingStatus;
      trackingUrl: string;
      trackingUpdatedAt: string;
      trackingDeliveredAt?: string | null;
      items: Array<{
        __typename?: 'Item';
        id: string;
        productId: string;
        productVariantId: string;
        name: string;
        imageUrl: string;
        size?: string | null;
        sku?: string | null;
        gtin?: string | null;
        isDigital: boolean;
        digitalCouponCode?: string | null;
        newDigitalCouponCode?: string | null;
        isReturnGenerated: boolean;
        isOffered: boolean;
        priceSold: {
          __typename?: 'Price';
          currency: CurrencyIso;
          valueWithVat: number;
          valueWithoutVat: number;
        };
        priceBoughtByChoose: {
          __typename?: 'Price';
          currency: CurrencyIso;
          valueWithVat: number;
          valueWithoutVat: number;
        };
      }>;
      trackingCourier: {
        __typename?: 'Courier';
        logoUrl: string;
        name: string;
        slug: string;
      };
    }>;
    returnSlips: Array<{
      __typename?: 'ReturnSlip';
      claimId?: string | null;
      createdAt: string;
      id: string;
    }>;
    claims: Array<{
      __typename?: 'Claim';
      id: string;
      reason: ClaimReason;
      otherReasonLabel?: string | null;
      messageFromSupport?: string | null;
      proofUrls: Array<string>;
      swornStatementUrls: Array<string>;
      isAccepted: boolean;
      isDeclined: boolean;
      actionFromSeller?: ClaimSellerAction | null;
      acceptedMessage?: string | null;
      reshipActionErrorReasons: Array<ClaimReshipActionErrorReason>;
      createdAt: string;
      items: Array<{
        __typename?: 'Item';
        id: string;
        productId: string;
        productVariantId: string;
        name: string;
        imageUrl: string;
        size?: string | null;
        sku?: string | null;
        gtin?: string | null;
        isDigital: boolean;
        digitalCouponCode?: string | null;
        newDigitalCouponCode?: string | null;
        isReturnable: boolean;
        isReturnGenerated: boolean;
        isOffered: boolean;
        priceSold: {
          __typename?: 'Price';
          currency: CurrencyIso;
          valueWithVat: number;
          valueWithoutVat: number;
        };
        priceBoughtByChoose: {
          __typename?: 'Price';
          currency: CurrencyIso;
          valueWithVat: number;
          valueWithoutVat: number;
        };
      }>;
    }>;
    linesRefunds: Array<{
      __typename?: 'OrderLineRefund';
      amountInCents: number;
      coveredBy: OrderLineRefundCover;
      createdAt: string;
      id: string;
      lineId: string;
      lineType: OrderLineType;
      reason: OrderLineRefundReason;
    }>;
    integration: {
      __typename?: 'OrderIntegration';
      errorMessage?: string | null;
      shopify?: {
        __typename?: 'ShopifyOrderIntegration';
        orderCountId?: string | null;
        orderId: string;
        orderUrl: string;
      } | null;
    };
    rating?: {
      __typename?: 'OrderRating';
      rate: number;
      message?: string | null;
    } | null;
    tags: {
      __typename?: 'OrderTags';
      claim: Array<OrderTagClaim>;
      fulfillment: OrderTagFulfillment;
      refund: OrderTagRefund;
      reship: OrderTagReship;
      return: Array<OrderTagReturn>;
      shipment: Array<OrderTagShipment>;
    };
  };
};

export type OrderReturnLabelUploadUrlQueryVariables = Exact<{
  input: OrderReturnLabelUploadUrlInput;
}>;

export type OrderReturnLabelUploadUrlQuery = {
  __typename?: 'Query';
  orderReturnLabelUploadUrl: {
    __typename?: 'Upload';
    expiresAt: string;
    key: string;
    url: string;
  };
};

export type OrdersByTrackingQueryVariables = Exact<{
  input: OrdersByTrackingInput;
}>;

export type OrdersByTrackingQuery = {
  __typename?: 'Query';
  ordersByTracking: Array<{
    __typename?: 'Order';
    id: string;
    saleId: string;
    customerId: string;
    recipient: {
      __typename?: 'Recipient';
      id: string;
      email: string;
      fullName: string;
    };
    parcels: Array<{
      __typename?: 'Parcel';
      trackingNumber: string;
      trackingCourier: {
        __typename?: 'Courier';
        logoUrl: string;
        name: string;
        slug: string;
      };
    }>;
  }>;
};

export type OrdersLinkedQueryVariables = Exact<{
  input: OrdersByTrackingInput;
}>;

export type OrdersLinkedQuery = {
  __typename?: 'Query';
  ordersByTracking: Array<{ __typename?: 'Order'; id: string }>;
};

export type PartialOrderFragment = {
  __typename?: 'Order';
  id: string;
  hasSynchronizationError: boolean;
  createdAt: string;
  recipient: { __typename?: 'Recipient'; fullName: string };
  totalPriceSold: {
    __typename?: 'Price';
    valueWithVat: number;
    valueWithoutVat: number;
    currency: CurrencyIso;
  };
  items: Array<{ __typename?: 'Item'; imageUrl: string }>;
  parcels: Array<{
    __typename?: 'Parcel';
    hasTrackingStucked: boolean;
    trackingSubStatus?: TrackingSubStatus | null;
  }>;
  claims: Array<{ __typename?: 'Claim'; createdAt: string }>;
  tags: {
    __typename?: 'OrderTags';
    brand: Array<OrderTagBrand>;
    claim: Array<OrderTagClaim>;
    claimReason: Array<OrderTagClaimReason>;
    fulfillment: OrderTagFulfillment;
    refund: OrderTagRefund;
    reship: OrderTagReship;
    return: Array<OrderTagReturn>;
    shipment: Array<OrderTagShipment>;
    shopify: OrderTagShopify;
  };
};

export type PartialOrderQueryVariables = Exact<{
  orderId: Scalars['ID']['input'];
}>;

export type PartialOrderQuery = {
  __typename?: 'Query';
  order: {
    __typename?: 'Order';
    id: string;
    hasSynchronizationError: boolean;
    createdAt: string;
    recipient: { __typename?: 'Recipient'; fullName: string };
    totalPriceSold: {
      __typename?: 'Price';
      valueWithVat: number;
      valueWithoutVat: number;
      currency: CurrencyIso;
    };
    items: Array<{ __typename?: 'Item'; imageUrl: string }>;
    parcels: Array<{
      __typename?: 'Parcel';
      hasTrackingStucked: boolean;
      trackingSubStatus?: TrackingSubStatus | null;
    }>;
    claims: Array<{ __typename?: 'Claim'; createdAt: string }>;
    tags: {
      __typename?: 'OrderTags';
      brand: Array<OrderTagBrand>;
      claim: Array<OrderTagClaim>;
      claimReason: Array<OrderTagClaimReason>;
      fulfillment: OrderTagFulfillment;
      refund: OrderTagRefund;
      reship: OrderTagReship;
      return: Array<OrderTagReturn>;
      shipment: Array<OrderTagShipment>;
      shopify: OrderTagShopify;
    };
  };
};

export type OrdersQueryVariables = Exact<{
  saleId: Scalars['ID']['input'];
  input: OrdersInput;
}>;

export type OrdersQuery = {
  __typename?: 'Query';
  orders: {
    __typename?: 'Orders';
    totalCount: number;
    nodes: Array<{
      __typename?: 'Order';
      id: string;
      hasSynchronizationError: boolean;
      createdAt: string;
      recipient: { __typename?: 'Recipient'; fullName: string };
      totalPriceSold: {
        __typename?: 'Price';
        valueWithVat: number;
        valueWithoutVat: number;
        currency: CurrencyIso;
      };
      items: Array<{ __typename?: 'Item'; imageUrl: string }>;
      parcels: Array<{
        __typename?: 'Parcel';
        hasTrackingStucked: boolean;
        trackingSubStatus?: TrackingSubStatus | null;
      }>;
      claims: Array<{ __typename?: 'Claim'; createdAt: string }>;
      tags: {
        __typename?: 'OrderTags';
        brand: Array<OrderTagBrand>;
        claim: Array<OrderTagClaim>;
        claimReason: Array<OrderTagClaimReason>;
        fulfillment: OrderTagFulfillment;
        refund: OrderTagRefund;
        reship: OrderTagReship;
        return: Array<OrderTagReturn>;
        shipment: Array<OrderTagShipment>;
        shopify: OrderTagShopify;
      };
    }>;
    paging: {
      __typename?: 'Paging';
      startCursor?: string | null;
      endCursor?: string | null;
      hasPreviousPage: boolean;
      hasNextPage: boolean;
      pageSize: number;
    };
  };
};

export type OrdersUnfulfilledDesyncShopifyQueryVariables = Exact<{
  saleId: Scalars['ID']['input'];
  input: OrdersInput;
}>;

export type OrdersUnfulfilledDesyncShopifyQuery = {
  __typename?: 'Query';
  orders: {
    __typename?: 'Orders';
    totalCount: number;
    nodes: Array<{
      __typename?: 'Order';
      integration: {
        __typename?: 'OrderIntegration';
        errorMessage?: string | null;
      };
    }>;
    paging: {
      __typename?: 'Paging';
      startCursor?: string | null;
      endCursor?: string | null;
      hasPreviousPage: boolean;
      hasNextPage: boolean;
      pageSize: number;
    };
  };
};

export type OrdersCountersQueryVariables = Exact<{
  sellerId: Scalars['ID']['input'];
  saleId: Scalars['ID']['input'];
}>;

export type OrdersCountersQuery = {
  __typename?: 'Query';
  ordersCounters: {
    __typename?: 'OrdersCounters';
    tagging: {
      __typename?: 'OrdersTaggingCounters';
      brand: {
        __typename?: 'OrdersTagBrandCounters';
        actionRequiredUnfulfilled: number;
        actionRequiredShipmentIncident: number;
        actionRequiredClaim: number;
        actionRequiredReturn: number;
        actionRequired: number;
        available: number;
      };
      type: {
        __typename?: 'OrdersTagTypeCounters';
        digital: number;
        physical: number;
      };
      status: {
        __typename?: 'OrdersTagStatusCounters';
        canceled: number;
        pendingValidation: number;
        validated: number;
      };
      shopify: {
        __typename?: 'OrdersTagShopifyCounters';
        none: number;
        unsynchronized: number;
        synchronized: number;
      };
      shippingMode: {
        __typename?: 'OrdersTagShippingModeCounters';
        none: number;
        pickupPoint: number;
        home: number;
      };
      fulfillment: {
        __typename?: 'OrdersTagFulfillmentCounters';
        unfulfilled: number;
        fulfilled: number;
      };
      shipment: {
        __typename?: 'OrdersTagShipmentCounters';
        none: number;
        pending: number;
        infoReceived: number;
        inTransit: number;
        outForDelivery: number;
        attemptFail: number;
        availableForPickup: number;
        delivered: number;
        exception: number;
        expired: number;
      };
      claim: {
        __typename?: 'OrdersTagClaimCounters';
        none: number;
        opened: number;
        closed: number;
      };
      claimReason: {
        __typename?: 'OrdersTagClaimReasonCounters';
        none: number;
        deliveredButDamaged: number;
        deliveredButIncomplete: number;
        deliveredButWrong: number;
        notDeliveredAndReturnedToSender: number;
        notDeliveredAndTrackingBlocked: number;
        notDeliveredAndTrackingDelivered: number;
        voucherNotWorking: number;
        other: number;
      };
      refund: {
        __typename?: 'OrdersTagRefundCounters';
        none: number;
        partiallyRefunded: number;
        totallyRefunded: number;
      };
      reship: {
        __typename?: 'OrdersTagReshipCounters';
        none: number;
        reshipped: number;
      };
      return: {
        __typename?: 'OrdersTagReturnCounters';
        none: number;
        canceled: number;
        requested: number;
        inProgress: number;
        delivered: number;
        validated: number;
      };
    };
  };
};

export type OrdersExportQueryVariables = Exact<{
  sellerId: Scalars['ID']['input'];
  saleId: Scalars['ID']['input'];
  input: OrdersExportInput;
}>;

export type OrdersExportQuery = {
  __typename?: 'Query';
  ordersExport: { __typename?: 'OrdersExport'; fileUrl?: string | null };
};

export type OrdersUnfulfilledExportQueryVariables = Exact<{
  sellerId: Scalars['ID']['input'];
  saleId: Scalars['ID']['input'];
  input: OrdersUnfulfilledExportInput;
}>;

export type OrdersUnfulfilledExportQuery = {
  __typename?: 'Query';
  ordersUnfulfilledExport: {
    __typename?: 'OrdersExport';
    fileUrl?: string | null;
  };
};

export type StocksExportQueryVariables = Exact<{
  saleId: Scalars['ID']['input'];
  input: StocksExportInput;
}>;

export type StocksExportQuery = {
  __typename?: 'Query';
  stocksExport: { __typename?: 'StocksExport'; fileUrl: string };
};

export type UsedCouriersQueryVariables = Exact<{ [key: string]: never }>;

export type UsedCouriersQuery = {
  __typename?: 'Query';
  usedCouriers: Array<{
    __typename?: 'Courier';
    supportedCountries: Array<string>;
    logoUrl: string;
    name: string;
    slug: string;
  }>;
};

export type AcceptOrderClaimWithRefundMutationVariables = Exact<{
  input: AcceptOrderClaimWithRefundInput;
}>;

export type AcceptOrderClaimWithRefundMutation = {
  __typename?: 'Mutation';
  acceptOrderClaimWithRefund?: any | null;
};

export type AcceptOrderClaimWithReshipMutationVariables = Exact<{
  input: AcceptOrderClaimWithReshipInput;
}>;

export type AcceptOrderClaimWithReshipMutation = {
  __typename?: 'Mutation';
  acceptOrderClaimWithReship?: any | null;
};

export type AcceptOrderClaimWithVoucherReplacementCodesMutationVariables =
  Exact<{
    input: AcceptOrderClaimWithVoucherReplacementCodesInput;
  }>;

export type AcceptOrderClaimWithVoucherReplacementCodesMutation = {
  __typename?: 'Mutation';
  acceptOrderClaimWithVoucherReplacementCodes?: any | null;
};

export type AcceptOrderClaimWithVoucherReplacementMessageMutationVariables =
  Exact<{
    input: AcceptOrderClaimWithVoucherReplacementMessageInput;
  }>;

export type AcceptOrderClaimWithVoucherReplacementMessageMutation = {
  __typename?: 'Mutation';
  acceptOrderClaimWithVoucherReplacementMessage?: any | null;
};

export type ChangePasswordMutationVariables = Exact<{
  newPassword: Scalars['String']['input'];
}>;

export type ChangePasswordMutation = {
  __typename?: 'Mutation';
  changePassword?: string | null;
};

export type CreateOrderParcelMutationVariables = Exact<{
  input: CreateOrderParcelInput;
}>;

export type CreateOrderParcelMutation = {
  __typename?: 'Mutation';
  createOrderParcel?: any | null;
};

export type CreatePasswordConnectionMutationVariables = Exact<{
  userInfo: UserPasswordConnection;
}>;

export type CreatePasswordConnectionMutation = {
  __typename?: 'Mutation';
  createPasswordConnection?: string | null;
};

export type DetachBrandUserMutationVariables = Exact<{
  userId: Scalars['String']['input'];
}>;

export type DetachBrandUserMutation = {
  __typename?: 'Mutation';
  detachBrandUser?: string | null;
};

export type EditBasicUserInfoMutationVariables = Exact<{
  basicUserInfo: BasicUserInfo;
}>;

export type EditBasicUserInfoMutation = {
  __typename?: 'Mutation';
  editBasicUserInfo?: string | null;
};

export type GenerateInvoiceUploadUrlMutationVariables = Exact<{
  saleId: Scalars['ID']['input'];
  upload: UploadInput;
}>;

export type GenerateInvoiceUploadUrlMutation = {
  __typename?: 'Mutation';
  generateInvoiceUploadUrl: {
    __typename?: 'Upload';
    expiresAt: string;
    key: string;
    url: string;
  };
};

export type GenerateProfilePictureUploadUrlMutationVariables = Exact<{
  upload: UploadInput;
}>;

export type GenerateProfilePictureUploadUrlMutation = {
  __typename?: 'Mutation';
  generateProfilePictureUploadUrl: {
    __typename?: 'Upload';
    expiresAt: string;
    key: string;
    url: string;
  };
};

export type GroupOrderParcelMutationVariables = Exact<{
  input: GroupOrderParcelInput;
}>;

export type GroupOrderParcelMutation = {
  __typename?: 'Mutation';
  groupOrderParcel?: any | null;
};

export type GenerateInvoiceCustomsMutationVariables = Exact<{
  orderId: Scalars['ID']['input'];
}>;

export type GenerateInvoiceCustomsMutation = {
  __typename?: 'Mutation';
  generateInvoiceCustoms: {
    __typename?: 'GenerateInvoiceCustoms';
    url: string;
  };
};

export type GeneratePurchaseOrderMutationVariables = Exact<{
  orderId: Scalars['ID']['input'];
}>;

export type GeneratePurchaseOrderMutation = {
  __typename?: 'Mutation';
  generatePurchaseOrder: { __typename?: 'GeneratePurchaseOrder'; url: string };
};

export type JoinBrandMutationVariables = Exact<{
  brandId: Scalars['String']['input'];
  inviteCode: Scalars['String']['input'];
}>;

export type JoinBrandMutation = {
  __typename?: 'Mutation';
  joinBrand?: string | null;
};

export type PostRegisterMutationVariables = Exact<{
  config: PostRegisterConfig;
}>;

export type PostRegisterMutation = {
  __typename?: 'Mutation';
  postRegister?: string | null;
};

export type SetUserRoleMutationVariables = Exact<{
  userId: Scalars['ID']['input'];
  role: BrandRole;
}>;

export type SetUserRoleMutation = {
  __typename?: 'Mutation';
  setUserRole?: any | null;
};

export type SubmitDownPaymentMutationVariables = Exact<{
  input: SubmitDownPaymentInput;
  saleId: Scalars['ID']['input'];
}>;

export type SubmitDownPaymentMutation = {
  __typename?: 'Mutation';
  submitDownPayment?: any | null;
};

export type SubmitInvoiceMutationVariables = Exact<{
  input: SubmitInvoiceInput;
  saleId: Scalars['ID']['input'];
}>;

export type SubmitInvoiceMutation = {
  __typename?: 'Mutation';
  submitInvoice?: any | null;
};

export type SwapOrderParcelMutationVariables = Exact<{
  input: SwapOrderParcelInput;
}>;

export type SwapOrderParcelMutation = {
  __typename?: 'Mutation';
  swapOrderParcel?: any | null;
};

export type UpdateInviteCodeMutationVariables = Exact<{ [key: string]: never }>;

export type UpdateInviteCodeMutation = {
  __typename?: 'Mutation';
  updateInviteCode: string;
};

export type UpdateOrderParcelMutationVariables = Exact<{
  input: UpdateOrderParcelInput;
}>;

export type UpdateOrderParcelMutation = {
  __typename?: 'Mutation';
  updateOrderParcel?: any | null;
};

export type UpdateProfilePictureMutationVariables = Exact<{
  url: Scalars['BaseUrl']['input'];
}>;

export type UpdateProfilePictureMutation = {
  __typename?: 'Mutation';
  updateProfilePicture?: any | null;
};

export type UpdateUserLanguageMutationVariables = Exact<{
  preferredLanguage: Scalars['String']['input'];
}>;

export type UpdateUserLanguageMutation = {
  __typename?: 'Mutation';
  updateUserLanguage?: string | null;
};

export const PartialCourierFragmentDoc = `
    fragment PartialCourier on Courier {
  logoUrl
  name
  slug
}
    `;
export const PartialOrderFragmentDoc = `
    fragment PartialOrder on Order {
  id
  recipient {
    fullName
  }
  totalPriceSold {
    valueWithVat
    valueWithoutVat
    currency
  }
  items {
    imageUrl
  }
  parcels {
    hasTrackingStucked
    trackingSubStatus
  }
  claims {
    createdAt
  }
  hasSynchronizationError
  tags {
    brand
    claim
    claimReason
    fulfillment
    refund
    reship
    return
    shipment
    shopify
  }
  createdAt
}
    `;
export const CurrentUserDocument = `
    query currentUser {
  currentUser {
    userId
    intercomUserHash
  }
}
    `;

export const useCurrentUserQuery = <TData = CurrentUserQuery, TError = unknown>(
  variables?: CurrentUserQueryVariables,
  options?: Omit<
    UseQueryOptions<CurrentUserQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<CurrentUserQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<CurrentUserQuery, TError, TData>({
    queryKey:
      variables === undefined ? ['currentUser'] : ['currentUser', variables],
    queryFn: useFetchData<CurrentUserQuery, CurrentUserQueryVariables>(
      CurrentUserDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useSuspenseCurrentUserQuery = <
  TData = CurrentUserQuery,
  TError = unknown,
>(
  variables?: CurrentUserQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<CurrentUserQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      CurrentUserQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useSuspenseQuery<CurrentUserQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['currentUserSuspense']
        : ['currentUserSuspense', variables],
    queryFn: useFetchData<CurrentUserQuery, CurrentUserQueryVariables>(
      CurrentUserDocument
    ).bind(null, variables),
    ...options,
  });
};

export const BillingDocument = `
    query billing($saleId: ID!) {
  billingV2(saleId: $saleId) {
    total_operation_ht
    total_operation_ttc
    total_refund_ht
    total_refund_ttc
    total_commission_ht
    total_commission_ttc
    total_delivery_ht
    total_delivery_ttc
    total_billing_ht
    total_billing_ttc
    total_remaining_ht
    total_remaining_ttc
    agg_by_vat_rate {
      vat_rate
      total_operation_ht
      total_operation_ttc
      total_commission_ht
      total_commission_ttc
      total_billing_ht
      total_billing_ttc
      total_vat
    }
    is_subject_to_vat
    has_error
    errors {
      __typename
      id
      order_id
      product_id
      code
    }
    is_full_paid
    payments {
      amountVatExcludedInCents
      amountVatInCents
      amountVatIncludedInCents
      invoiceId
      paidAt
    }
    down_payment {
      __typename
      ... on DownPaymentClassic {
        financialDetail {
          agg_by_vat_rate {
            vat_rate
            total_operation_ht
            total_operation_ttc
            total_commission_ht
            total_commission_ttc
            total_billing_ht
            total_billing_ttc
            total_vat
          }
          total_billing_ht
          total_billing_ttc
          total_commission_ht
          total_commission_ttc
          total_delivery_ht
          total_delivery_ttc
          total_operation_ht
          total_operation_ttc
          total_refund_ht
          total_refund_ttc
          total_remaining_ht
          total_remaining_ttc
        }
        preset
        rate
        rules {
          __typename
          ... on ActivationDateRule {
            activationDate
            isValidated
          }
          ... on FulfilledOrderPercentageRule {
            currentValue
            isValidated
            targetValue
          }
          ... on MinimumAmountInCentsRule {
            currentValue
            isValidated
            targetValue
          }
        }
      }
      ... on DownPaymentExceptional {
        preset
      }
    }
  }
}
    `;

export const useBillingQuery = <TData = BillingQuery, TError = unknown>(
  variables: BillingQueryVariables,
  options?: Omit<UseQueryOptions<BillingQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<BillingQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<BillingQuery, TError, TData>({
    queryKey: ['billing', variables],
    queryFn: useFetchData<BillingQuery, BillingQueryVariables>(
      BillingDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useSuspenseBillingQuery = <TData = BillingQuery, TError = unknown>(
  variables: BillingQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<BillingQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<BillingQuery, TError, TData>['queryKey'];
  }
) => {
  return useSuspenseQuery<BillingQuery, TError, TData>({
    queryKey: ['billingSuspense', variables],
    queryFn: useFetchData<BillingQuery, BillingQueryVariables>(
      BillingDocument
    ).bind(null, variables),
    ...options,
  });
};

export const BillingExportDocument = `
    query billingExport($saleId: ID!, $input: BillingExportInput!) {
  billingExport(saleId: $saleId, input: $input) {
    fileUrl
  }
}
    `;

export const useBillingExportQuery = <
  TData = BillingExportQuery,
  TError = unknown,
>(
  variables: BillingExportQueryVariables,
  options?: Omit<
    UseQueryOptions<BillingExportQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<BillingExportQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<BillingExportQuery, TError, TData>({
    queryKey: ['billingExport', variables],
    queryFn: useFetchData<BillingExportQuery, BillingExportQueryVariables>(
      BillingExportDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useSuspenseBillingExportQuery = <
  TData = BillingExportQuery,
  TError = unknown,
>(
  variables: BillingExportQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<BillingExportQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      BillingExportQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useSuspenseQuery<BillingExportQuery, TError, TData>({
    queryKey: ['billingExportSuspense', variables],
    queryFn: useFetchData<BillingExportQuery, BillingExportQueryVariables>(
      BillingExportDocument
    ).bind(null, variables),
    ...options,
  });
};

export const BillingStateDocument = `
    query billingState($saleId: ID!) {
  supplierBillingState(saleId: $saleId) {
    canAccess
    canAccessBypassed
    isPendingClaimClosing
    isPendingOrderDelivery
    isPendingReturnValidation
    isPendingWithdrawalPeriod
    pendingClaimClosingOrderIds
    pendingOrderDeliveryOrderIds
    pendingReturnValidationOrderIds
    saleStatus
    withdrawalPeriodEndDate
    withdrawalPeriodInDays
  }
}
    `;

export const useBillingStateQuery = <
  TData = BillingStateQuery,
  TError = unknown,
>(
  variables: BillingStateQueryVariables,
  options?: Omit<
    UseQueryOptions<BillingStateQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<BillingStateQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<BillingStateQuery, TError, TData>({
    queryKey: ['billingState', variables],
    queryFn: useFetchData<BillingStateQuery, BillingStateQueryVariables>(
      BillingStateDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useSuspenseBillingStateQuery = <
  TData = BillingStateQuery,
  TError = unknown,
>(
  variables: BillingStateQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<BillingStateQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      BillingStateQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useSuspenseQuery<BillingStateQuery, TError, TData>({
    queryKey: ['billingStateSuspense', variables],
    queryFn: useFetchData<BillingStateQuery, BillingStateQueryVariables>(
      BillingStateDocument
    ).bind(null, variables),
    ...options,
  });
};

export const BrandInfoDocument = `
    query brandInfo($brandId: String!) {
  brandInfo(brandId: $brandId) {
    name
    acceptContract
    nbUsers
  }
}
    `;

export const useBrandInfoQuery = <TData = BrandInfoQuery, TError = unknown>(
  variables: BrandInfoQueryVariables,
  options?: Omit<UseQueryOptions<BrandInfoQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<BrandInfoQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<BrandInfoQuery, TError, TData>({
    queryKey: ['brandInfo', variables],
    queryFn: useFetchData<BrandInfoQuery, BrandInfoQueryVariables>(
      BrandInfoDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useSuspenseBrandInfoQuery = <
  TData = BrandInfoQuery,
  TError = unknown,
>(
  variables: BrandInfoQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<BrandInfoQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      BrandInfoQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useSuspenseQuery<BrandInfoQuery, TError, TData>({
    queryKey: ['brandInfoSuspense', variables],
    queryFn: useFetchData<BrandInfoQuery, BrandInfoQueryVariables>(
      BrandInfoDocument
    ).bind(null, variables),
    ...options,
  });
};

export const BrandsAccessDocument = `
    query brandsAccess {
  getBrandsAccess {
    brandId
    brandName
    role
    store
  }
}
    `;

export const useBrandsAccessQuery = <
  TData = BrandsAccessQuery,
  TError = unknown,
>(
  variables?: BrandsAccessQueryVariables,
  options?: Omit<
    UseQueryOptions<BrandsAccessQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<BrandsAccessQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<BrandsAccessQuery, TError, TData>({
    queryKey:
      variables === undefined ? ['brandsAccess'] : ['brandsAccess', variables],
    queryFn: useFetchData<BrandsAccessQuery, BrandsAccessQueryVariables>(
      BrandsAccessDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useSuspenseBrandsAccessQuery = <
  TData = BrandsAccessQuery,
  TError = unknown,
>(
  variables?: BrandsAccessQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<BrandsAccessQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      BrandsAccessQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useSuspenseQuery<BrandsAccessQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['brandsAccessSuspense']
        : ['brandsAccessSuspense', variables],
    queryFn: useFetchData<BrandsAccessQuery, BrandsAccessQueryVariables>(
      BrandsAccessDocument
    ).bind(null, variables),
    ...options,
  });
};

export const SearchCouriersDocument = `
    query searchCouriers($input: SearchCouriersInput!) {
  searchCouriers(input: $input) {
    ...PartialCourier
    supportedCountries
  }
}
    ${PartialCourierFragmentDoc}`;

export const useSearchCouriersQuery = <
  TData = SearchCouriersQuery,
  TError = unknown,
>(
  variables: SearchCouriersQueryVariables,
  options?: Omit<
    UseQueryOptions<SearchCouriersQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<SearchCouriersQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<SearchCouriersQuery, TError, TData>({
    queryKey: ['searchCouriers', variables],
    queryFn: useFetchData<SearchCouriersQuery, SearchCouriersQueryVariables>(
      SearchCouriersDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useSuspenseSearchCouriersQuery = <
  TData = SearchCouriersQuery,
  TError = unknown,
>(
  variables: SearchCouriersQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<SearchCouriersQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      SearchCouriersQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useSuspenseQuery<SearchCouriersQuery, TError, TData>({
    queryKey: ['searchCouriersSuspense', variables],
    queryFn: useFetchData<SearchCouriersQuery, SearchCouriersQueryVariables>(
      SearchCouriersDocument
    ).bind(null, variables),
    ...options,
  });
};

export const SearchCouriersBySupportedCountriesDocument = `
    query searchCouriersBySupportedCountries($input: SearchCouriersBySupportedCountries!) {
  searchCouriersBySupportedCountries(input: $input) {
    ...PartialCourier
    supportedCountries
  }
}
    ${PartialCourierFragmentDoc}`;

export const useSearchCouriersBySupportedCountriesQuery = <
  TData = SearchCouriersBySupportedCountriesQuery,
  TError = unknown,
>(
  variables: SearchCouriersBySupportedCountriesQueryVariables,
  options?: Omit<
    UseQueryOptions<SearchCouriersBySupportedCountriesQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      SearchCouriersBySupportedCountriesQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useQuery<SearchCouriersBySupportedCountriesQuery, TError, TData>({
    queryKey: ['searchCouriersBySupportedCountries', variables],
    queryFn: useFetchData<
      SearchCouriersBySupportedCountriesQuery,
      SearchCouriersBySupportedCountriesQueryVariables
    >(SearchCouriersBySupportedCountriesDocument).bind(null, variables),
    ...options,
  });
};

export const useSuspenseSearchCouriersBySupportedCountriesQuery = <
  TData = SearchCouriersBySupportedCountriesQuery,
  TError = unknown,
>(
  variables: SearchCouriersBySupportedCountriesQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<
      SearchCouriersBySupportedCountriesQuery,
      TError,
      TData
    >,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      SearchCouriersBySupportedCountriesQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useSuspenseQuery<
    SearchCouriersBySupportedCountriesQuery,
    TError,
    TData
  >({
    queryKey: ['searchCouriersBySupportedCountriesSuspense', variables],
    queryFn: useFetchData<
      SearchCouriersBySupportedCountriesQuery,
      SearchCouriersBySupportedCountriesQueryVariables
    >(SearchCouriersBySupportedCountriesDocument).bind(null, variables),
    ...options,
  });
};

export const DetectCouriersDocument = `
    query detectCouriers($input: DetectCouriersInput!) {
  detectCouriers(input: $input) {
    logoUrl
    name
    slug
  }
}
    `;

export const useDetectCouriersQuery = <
  TData = DetectCouriersQuery,
  TError = unknown,
>(
  variables: DetectCouriersQueryVariables,
  options?: Omit<
    UseQueryOptions<DetectCouriersQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<DetectCouriersQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<DetectCouriersQuery, TError, TData>({
    queryKey: ['detectCouriers', variables],
    queryFn: useFetchData<DetectCouriersQuery, DetectCouriersQueryVariables>(
      DetectCouriersDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useSuspenseDetectCouriersQuery = <
  TData = DetectCouriersQuery,
  TError = unknown,
>(
  variables: DetectCouriersQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<DetectCouriersQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      DetectCouriersQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useSuspenseQuery<DetectCouriersQuery, TError, TData>({
    queryKey: ['detectCouriersSuspense', variables],
    queryFn: useFetchData<DetectCouriersQuery, DetectCouriersQueryVariables>(
      DetectCouriersDocument
    ).bind(null, variables),
    ...options,
  });
};

export const InviteCodeDocument = `
    query inviteCode {
  getInviteCode
}
    `;

export const useInviteCodeQuery = <TData = InviteCodeQuery, TError = unknown>(
  variables?: InviteCodeQueryVariables,
  options?: Omit<
    UseQueryOptions<InviteCodeQuery, TError, TData>,
    'queryKey'
  > & { queryKey?: UseQueryOptions<InviteCodeQuery, TError, TData>['queryKey'] }
) => {
  return useQuery<InviteCodeQuery, TError, TData>({
    queryKey:
      variables === undefined ? ['inviteCode'] : ['inviteCode', variables],
    queryFn: useFetchData<InviteCodeQuery, InviteCodeQueryVariables>(
      InviteCodeDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useSuspenseInviteCodeQuery = <
  TData = InviteCodeQuery,
  TError = unknown,
>(
  variables?: InviteCodeQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<InviteCodeQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      InviteCodeQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useSuspenseQuery<InviteCodeQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['inviteCodeSuspense']
        : ['inviteCodeSuspense', variables],
    queryFn: useFetchData<InviteCodeQuery, InviteCodeQueryVariables>(
      InviteCodeDocument
    ).bind(null, variables),
    ...options,
  });
};

export const InfoDocument = `
    query info {
  info {
    version
    environment
  }
}
    `;

export const useInfoQuery = <TData = InfoQuery, TError = unknown>(
  variables?: InfoQueryVariables,
  options?: Omit<UseQueryOptions<InfoQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<InfoQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<InfoQuery, TError, TData>({
    queryKey: variables === undefined ? ['info'] : ['info', variables],
    queryFn: useFetchData<InfoQuery, InfoQueryVariables>(InfoDocument).bind(
      null,
      variables
    ),
    ...options,
  });
};

export const useSuspenseInfoQuery = <TData = InfoQuery, TError = unknown>(
  variables?: InfoQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<InfoQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<InfoQuery, TError, TData>['queryKey'];
  }
) => {
  return useSuspenseQuery<InfoQuery, TError, TData>({
    queryKey:
      variables === undefined ? ['infoSuspense'] : ['infoSuspense', variables],
    queryFn: useFetchData<InfoQuery, InfoQueryVariables>(InfoDocument).bind(
      null,
      variables
    ),
    ...options,
  });
};

export const InvoicesDocument = `
    query invoices($saleId: ID!) {
  invoices(saleId: $saleId) {
    createdAt
    fileName
    fileUrl
    id
    status
    type
  }
}
    `;

export const useInvoicesQuery = <TData = InvoicesQuery, TError = unknown>(
  variables: InvoicesQueryVariables,
  options?: Omit<UseQueryOptions<InvoicesQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<InvoicesQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<InvoicesQuery, TError, TData>({
    queryKey: ['invoices', variables],
    queryFn: useFetchData<InvoicesQuery, InvoicesQueryVariables>(
      InvoicesDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useSuspenseInvoicesQuery = <
  TData = InvoicesQuery,
  TError = unknown,
>(
  variables: InvoicesQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<InvoicesQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      InvoicesQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useSuspenseQuery<InvoicesQuery, TError, TData>({
    queryKey: ['invoicesSuspense', variables],
    queryFn: useFetchData<InvoicesQuery, InvoicesQueryVariables>(
      InvoicesDocument
    ).bind(null, variables),
    ...options,
  });
};

export const JoinOrderReturnLabelForClaimDocument = `
    mutation joinOrderReturnLabelForClaim($input: JoinOrderReturnLabelForClaimInput!) {
  joinOrderReturnLabelForClaim(input: $input)
}
    `;

export const useJoinOrderReturnLabelForClaimMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    JoinOrderReturnLabelForClaimMutation,
    TError,
    JoinOrderReturnLabelForClaimMutationVariables,
    TContext
  >
) => {
  return useMutation<
    JoinOrderReturnLabelForClaimMutation,
    TError,
    JoinOrderReturnLabelForClaimMutationVariables,
    TContext
  >({
    mutationKey: ['joinOrderReturnLabelForClaim'],
    mutationFn: useFetchData<
      JoinOrderReturnLabelForClaimMutation,
      JoinOrderReturnLabelForClaimMutationVariables
    >(JoinOrderReturnLabelForClaimDocument),
    ...options,
  });
};

export const MembersDocument = `
    query members {
  members {
    userId
    email
    role
    picture
    firstname
    lastname
  }
}
    `;

export const useMembersQuery = <TData = MembersQuery, TError = unknown>(
  variables?: MembersQueryVariables,
  options?: Omit<UseQueryOptions<MembersQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<MembersQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<MembersQuery, TError, TData>({
    queryKey: variables === undefined ? ['members'] : ['members', variables],
    queryFn: useFetchData<MembersQuery, MembersQueryVariables>(
      MembersDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useSuspenseMembersQuery = <TData = MembersQuery, TError = unknown>(
  variables?: MembersQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<MembersQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<MembersQuery, TError, TData>['queryKey'];
  }
) => {
  return useSuspenseQuery<MembersQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['membersSuspense']
        : ['membersSuspense', variables],
    queryFn: useFetchData<MembersQuery, MembersQueryVariables>(
      MembersDocument
    ).bind(null, variables),
    ...options,
  });
};

export const OrderDocument = `
    query order($orderId: ID!) {
  order(id: $orderId) {
    id
    billingId
    sellerId
    saleId
    customerId
    initialOrderId
    reshipOrderId
    reshipOrder {
      createdAt
    }
    recipient {
      id
      fullName
      firstName
      lastName
      email
      phoneNumber
      address {
        street
        streetAdditional
        zipCode
        city
        county
        state
        country
        countryCode
      }
      previous {
        fullName
        firstName
        lastName
        email
        phoneNumber
        address {
          street
          streetAdditional
          zipCode
          city
          county
          state
          country
          countryCode
        }
      }
    }
    shipping {
      fees
      feesSold
      deliveryDateRangeHistory {
        start
        end
      }
      policyId
      pickupPoint {
        id
        countryCode
        network
        address {
          name
          street
          streetAdditional
          zipCode
          city
          countryCode
        }
      }
    }
    items {
      id
      productId
      productVariantId
      name
      imageUrl
      size
      sku
      gtin
      priceSold {
        currency
        valueWithVat
        valueWithoutVat
      }
      priceBoughtByChoose {
        currency
        valueWithVat
        valueWithoutVat
      }
      isDigital
      digitalCouponCode
      newDigitalCouponCode
      isReturnGenerated
      isReturnValidated
      isOffered
      refundSummary {
        isTotallyRefunded
        refundedAmountInPercentage
        refundedAmountInPercentageCoveredByChoose
        refundedAmountInPercentageCoveredBySupplier
      }
      returnReasonCode
      isCancelled
    }
    totalPriceSold {
      currency
      valueWithVat
      valueWithoutVat
    }
    parcels {
      hasTrackingStucked
      id
      items {
        id
        productId
        productVariantId
        name
        imageUrl
        size
        sku
        gtin
        priceSold {
          currency
          valueWithVat
          valueWithoutVat
        }
        priceBoughtByChoose {
          currency
          valueWithVat
          valueWithoutVat
        }
        isDigital
        digitalCouponCode
        newDigitalCouponCode
        isReturnGenerated
        isOffered
      }
      recipient {
        fullName
        firstName
        lastName
        email
        phoneNumber
        address {
          street
          streetAdditional
          zipCode
          city
          county
          state
          country
          countryCode
        }
      }
      trackingCourier {
        ...PartialCourier
      }
      trackingNumber
      trackingStatus
      trackingSubStatus
      trackingUrl
      trackingUpdatedAt
      trackingDeliveredAt
    }
    returnParcels {
      claimId
      id
      items {
        id
        productId
        productVariantId
        name
        imageUrl
        size
        sku
        gtin
        priceSold {
          currency
          valueWithVat
          valueWithoutVat
        }
        priceBoughtByChoose {
          currency
          valueWithVat
          valueWithoutVat
        }
        isDigital
        digitalCouponCode
        newDigitalCouponCode
        isReturnGenerated
        isOffered
      }
      trackingCourier {
        ...PartialCourier
      }
      trackingNumber
      trackingStatus
      trackingUrl
      trackingUpdatedAt
      trackingDeliveredAt
    }
    returnSlips {
      claimId
      createdAt
      id
    }
    claims {
      id
      reason
      otherReasonLabel
      messageFromSupport
      items {
        id
        productId
        productVariantId
        name
        imageUrl
        size
        sku
        gtin
        priceSold {
          currency
          valueWithVat
          valueWithoutVat
        }
        priceSold {
          currency
          valueWithVat
          valueWithoutVat
        }
        priceBoughtByChoose {
          currency
          valueWithVat
          valueWithoutVat
        }
        isDigital
        digitalCouponCode
        newDigitalCouponCode
        isReturnable
        isReturnGenerated
        isOffered
      }
      proofUrls
      swornStatementUrls
      isAccepted
      isDeclined
      actionFromSeller
      acceptedMessage
      reshipActionErrorReasons
      createdAt
    }
    linesRefunds {
      amountInCents
      coveredBy
      createdAt
      id
      lineId
      lineType
      reason
    }
    hasBeenExported
    isShippingEstimatedTimeExceeded
    integration {
      errorMessage
      shopify {
        orderCountId
        orderId
        orderUrl
      }
    }
    rating {
      rate
      message
    }
    isCancelled
    isFullDigital
    tags {
      claim
      fulfillment
      refund
      reship
      return
      shipment
    }
    createdAt
  }
}
    ${PartialCourierFragmentDoc}`;

export const useOrderQuery = <TData = OrderQuery, TError = unknown>(
  variables: OrderQueryVariables,
  options?: Omit<UseQueryOptions<OrderQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<OrderQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<OrderQuery, TError, TData>({
    queryKey: ['order', variables],
    queryFn: useFetchData<OrderQuery, OrderQueryVariables>(OrderDocument).bind(
      null,
      variables
    ),
    ...options,
  });
};

export const useSuspenseOrderQuery = <TData = OrderQuery, TError = unknown>(
  variables: OrderQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<OrderQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<OrderQuery, TError, TData>['queryKey'];
  }
) => {
  return useSuspenseQuery<OrderQuery, TError, TData>({
    queryKey: ['orderSuspense', variables],
    queryFn: useFetchData<OrderQuery, OrderQueryVariables>(OrderDocument).bind(
      null,
      variables
    ),
    ...options,
  });
};

export const OrderReturnLabelUploadUrlDocument = `
    query orderReturnLabelUploadUrl($input: OrderReturnLabelUploadUrlInput!) {
  orderReturnLabelUploadUrl(input: $input) {
    expiresAt
    key
    url
  }
}
    `;

export const useOrderReturnLabelUploadUrlQuery = <
  TData = OrderReturnLabelUploadUrlQuery,
  TError = unknown,
>(
  variables: OrderReturnLabelUploadUrlQueryVariables,
  options?: Omit<
    UseQueryOptions<OrderReturnLabelUploadUrlQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      OrderReturnLabelUploadUrlQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useQuery<OrderReturnLabelUploadUrlQuery, TError, TData>({
    queryKey: ['orderReturnLabelUploadUrl', variables],
    queryFn: useFetchData<
      OrderReturnLabelUploadUrlQuery,
      OrderReturnLabelUploadUrlQueryVariables
    >(OrderReturnLabelUploadUrlDocument).bind(null, variables),
    ...options,
  });
};

export const useSuspenseOrderReturnLabelUploadUrlQuery = <
  TData = OrderReturnLabelUploadUrlQuery,
  TError = unknown,
>(
  variables: OrderReturnLabelUploadUrlQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<OrderReturnLabelUploadUrlQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      OrderReturnLabelUploadUrlQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useSuspenseQuery<OrderReturnLabelUploadUrlQuery, TError, TData>({
    queryKey: ['orderReturnLabelUploadUrlSuspense', variables],
    queryFn: useFetchData<
      OrderReturnLabelUploadUrlQuery,
      OrderReturnLabelUploadUrlQueryVariables
    >(OrderReturnLabelUploadUrlDocument).bind(null, variables),
    ...options,
  });
};

export const OrdersByTrackingDocument = `
    query ordersByTracking($input: OrdersByTrackingInput!) {
  ordersByTracking(input: $input) {
    id
    saleId
    customerId
    recipient {
      id
      email
      fullName
    }
    parcels {
      trackingCourier {
        ...PartialCourier
      }
      trackingNumber
    }
  }
}
    ${PartialCourierFragmentDoc}`;

export const useOrdersByTrackingQuery = <
  TData = OrdersByTrackingQuery,
  TError = unknown,
>(
  variables: OrdersByTrackingQueryVariables,
  options?: Omit<
    UseQueryOptions<OrdersByTrackingQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      OrdersByTrackingQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useQuery<OrdersByTrackingQuery, TError, TData>({
    queryKey: ['ordersByTracking', variables],
    queryFn: useFetchData<
      OrdersByTrackingQuery,
      OrdersByTrackingQueryVariables
    >(OrdersByTrackingDocument).bind(null, variables),
    ...options,
  });
};

export const useSuspenseOrdersByTrackingQuery = <
  TData = OrdersByTrackingQuery,
  TError = unknown,
>(
  variables: OrdersByTrackingQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<OrdersByTrackingQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      OrdersByTrackingQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useSuspenseQuery<OrdersByTrackingQuery, TError, TData>({
    queryKey: ['ordersByTrackingSuspense', variables],
    queryFn: useFetchData<
      OrdersByTrackingQuery,
      OrdersByTrackingQueryVariables
    >(OrdersByTrackingDocument).bind(null, variables),
    ...options,
  });
};

export const OrdersLinkedDocument = `
    query ordersLinked($input: OrdersByTrackingInput!) {
  ordersByTracking(input: $input) {
    id
  }
}
    `;

export const useOrdersLinkedQuery = <
  TData = OrdersLinkedQuery,
  TError = unknown,
>(
  variables: OrdersLinkedQueryVariables,
  options?: Omit<
    UseQueryOptions<OrdersLinkedQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<OrdersLinkedQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<OrdersLinkedQuery, TError, TData>({
    queryKey: ['ordersLinked', variables],
    queryFn: useFetchData<OrdersLinkedQuery, OrdersLinkedQueryVariables>(
      OrdersLinkedDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useSuspenseOrdersLinkedQuery = <
  TData = OrdersLinkedQuery,
  TError = unknown,
>(
  variables: OrdersLinkedQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<OrdersLinkedQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      OrdersLinkedQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useSuspenseQuery<OrdersLinkedQuery, TError, TData>({
    queryKey: ['ordersLinkedSuspense', variables],
    queryFn: useFetchData<OrdersLinkedQuery, OrdersLinkedQueryVariables>(
      OrdersLinkedDocument
    ).bind(null, variables),
    ...options,
  });
};

export const PartialOrderDocument = `
    query partialOrder($orderId: ID!) {
  order(id: $orderId) {
    ...PartialOrder
  }
}
    ${PartialOrderFragmentDoc}`;

export const usePartialOrderQuery = <
  TData = PartialOrderQuery,
  TError = unknown,
>(
  variables: PartialOrderQueryVariables,
  options?: Omit<
    UseQueryOptions<PartialOrderQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<PartialOrderQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<PartialOrderQuery, TError, TData>({
    queryKey: ['partialOrder', variables],
    queryFn: useFetchData<PartialOrderQuery, PartialOrderQueryVariables>(
      PartialOrderDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useSuspensePartialOrderQuery = <
  TData = PartialOrderQuery,
  TError = unknown,
>(
  variables: PartialOrderQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<PartialOrderQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      PartialOrderQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useSuspenseQuery<PartialOrderQuery, TError, TData>({
    queryKey: ['partialOrderSuspense', variables],
    queryFn: useFetchData<PartialOrderQuery, PartialOrderQueryVariables>(
      PartialOrderDocument
    ).bind(null, variables),
    ...options,
  });
};

export const OrdersDocument = `
    query orders($saleId: ID!, $input: OrdersInput!) {
  orders(saleId: $saleId, input: $input) {
    nodes {
      ...PartialOrder
    }
    totalCount
    paging {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
      pageSize
    }
  }
}
    ${PartialOrderFragmentDoc}`;

export const useOrdersQuery = <TData = OrdersQuery, TError = unknown>(
  variables: OrdersQueryVariables,
  options?: Omit<UseQueryOptions<OrdersQuery, TError, TData>, 'queryKey'> & {
    queryKey?: UseQueryOptions<OrdersQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<OrdersQuery, TError, TData>({
    queryKey: ['orders', variables],
    queryFn: useFetchData<OrdersQuery, OrdersQueryVariables>(
      OrdersDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useSuspenseOrdersQuery = <TData = OrdersQuery, TError = unknown>(
  variables: OrdersQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<OrdersQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<OrdersQuery, TError, TData>['queryKey'];
  }
) => {
  return useSuspenseQuery<OrdersQuery, TError, TData>({
    queryKey: ['ordersSuspense', variables],
    queryFn: useFetchData<OrdersQuery, OrdersQueryVariables>(
      OrdersDocument
    ).bind(null, variables),
    ...options,
  });
};

export const OrdersUnfulfilledDesyncShopifyDocument = `
    query ordersUnfulfilledDesyncShopify($saleId: ID!, $input: OrdersInput!) {
  orders(saleId: $saleId, input: $input) {
    nodes {
      integration {
        errorMessage
      }
    }
    totalCount
    paging {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
      pageSize
    }
  }
}
    `;

export const useOrdersUnfulfilledDesyncShopifyQuery = <
  TData = OrdersUnfulfilledDesyncShopifyQuery,
  TError = unknown,
>(
  variables: OrdersUnfulfilledDesyncShopifyQueryVariables,
  options?: Omit<
    UseQueryOptions<OrdersUnfulfilledDesyncShopifyQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      OrdersUnfulfilledDesyncShopifyQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useQuery<OrdersUnfulfilledDesyncShopifyQuery, TError, TData>({
    queryKey: ['ordersUnfulfilledDesyncShopify', variables],
    queryFn: useFetchData<
      OrdersUnfulfilledDesyncShopifyQuery,
      OrdersUnfulfilledDesyncShopifyQueryVariables
    >(OrdersUnfulfilledDesyncShopifyDocument).bind(null, variables),
    ...options,
  });
};

export const useSuspenseOrdersUnfulfilledDesyncShopifyQuery = <
  TData = OrdersUnfulfilledDesyncShopifyQuery,
  TError = unknown,
>(
  variables: OrdersUnfulfilledDesyncShopifyQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<OrdersUnfulfilledDesyncShopifyQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      OrdersUnfulfilledDesyncShopifyQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useSuspenseQuery<OrdersUnfulfilledDesyncShopifyQuery, TError, TData>({
    queryKey: ['ordersUnfulfilledDesyncShopifySuspense', variables],
    queryFn: useFetchData<
      OrdersUnfulfilledDesyncShopifyQuery,
      OrdersUnfulfilledDesyncShopifyQueryVariables
    >(OrdersUnfulfilledDesyncShopifyDocument).bind(null, variables),
    ...options,
  });
};

export const OrdersCountersDocument = `
    query ordersCounters($sellerId: ID!, $saleId: ID!) {
  ordersCounters(sellerId: $sellerId, saleId: $saleId) {
    tagging {
      brand {
        actionRequiredUnfulfilled
        actionRequiredShipmentIncident
        actionRequiredClaim
        actionRequiredReturn
        actionRequired
        available
      }
      type {
        digital
        physical
      }
      status {
        canceled
        pendingValidation
        validated
      }
      shopify {
        none
        unsynchronized
        synchronized
      }
      shippingMode {
        none
        pickupPoint
        home
      }
      fulfillment {
        unfulfilled
        fulfilled
      }
      shipment {
        none
        pending
        infoReceived
        inTransit
        outForDelivery
        attemptFail
        availableForPickup
        delivered
        exception
        expired
      }
      claim {
        none
        opened
        closed
      }
      claimReason {
        none
        deliveredButDamaged
        deliveredButIncomplete
        deliveredButWrong
        notDeliveredAndReturnedToSender
        notDeliveredAndTrackingBlocked
        notDeliveredAndTrackingDelivered
        voucherNotWorking
        other
      }
      refund {
        none
        partiallyRefunded
        totallyRefunded
      }
      reship {
        none
        reshipped
      }
      return {
        none
        canceled
        requested
        inProgress
        delivered
        validated
      }
    }
  }
}
    `;

export const useOrdersCountersQuery = <
  TData = OrdersCountersQuery,
  TError = unknown,
>(
  variables: OrdersCountersQueryVariables,
  options?: Omit<
    UseQueryOptions<OrdersCountersQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<OrdersCountersQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<OrdersCountersQuery, TError, TData>({
    queryKey: ['ordersCounters', variables],
    queryFn: useFetchData<OrdersCountersQuery, OrdersCountersQueryVariables>(
      OrdersCountersDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useSuspenseOrdersCountersQuery = <
  TData = OrdersCountersQuery,
  TError = unknown,
>(
  variables: OrdersCountersQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<OrdersCountersQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      OrdersCountersQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useSuspenseQuery<OrdersCountersQuery, TError, TData>({
    queryKey: ['ordersCountersSuspense', variables],
    queryFn: useFetchData<OrdersCountersQuery, OrdersCountersQueryVariables>(
      OrdersCountersDocument
    ).bind(null, variables),
    ...options,
  });
};

export const OrdersExportDocument = `
    query ordersExport($sellerId: ID!, $saleId: ID!, $input: OrdersExportInput!) {
  ordersExport(sellerId: $sellerId, saleId: $saleId, input: $input) {
    fileUrl
  }
}
    `;

export const useOrdersExportQuery = <
  TData = OrdersExportQuery,
  TError = unknown,
>(
  variables: OrdersExportQueryVariables,
  options?: Omit<
    UseQueryOptions<OrdersExportQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<OrdersExportQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<OrdersExportQuery, TError, TData>({
    queryKey: ['ordersExport', variables],
    queryFn: useFetchData<OrdersExportQuery, OrdersExportQueryVariables>(
      OrdersExportDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useSuspenseOrdersExportQuery = <
  TData = OrdersExportQuery,
  TError = unknown,
>(
  variables: OrdersExportQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<OrdersExportQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      OrdersExportQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useSuspenseQuery<OrdersExportQuery, TError, TData>({
    queryKey: ['ordersExportSuspense', variables],
    queryFn: useFetchData<OrdersExportQuery, OrdersExportQueryVariables>(
      OrdersExportDocument
    ).bind(null, variables),
    ...options,
  });
};

export const OrdersUnfulfilledExportDocument = `
    query ordersUnfulfilledExport($sellerId: ID!, $saleId: ID!, $input: OrdersUnfulfilledExportInput!) {
  ordersUnfulfilledExport(sellerId: $sellerId, saleId: $saleId, input: $input) {
    fileUrl
  }
}
    `;

export const useOrdersUnfulfilledExportQuery = <
  TData = OrdersUnfulfilledExportQuery,
  TError = unknown,
>(
  variables: OrdersUnfulfilledExportQueryVariables,
  options?: Omit<
    UseQueryOptions<OrdersUnfulfilledExportQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<
      OrdersUnfulfilledExportQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useQuery<OrdersUnfulfilledExportQuery, TError, TData>({
    queryKey: ['ordersUnfulfilledExport', variables],
    queryFn: useFetchData<
      OrdersUnfulfilledExportQuery,
      OrdersUnfulfilledExportQueryVariables
    >(OrdersUnfulfilledExportDocument).bind(null, variables),
    ...options,
  });
};

export const useSuspenseOrdersUnfulfilledExportQuery = <
  TData = OrdersUnfulfilledExportQuery,
  TError = unknown,
>(
  variables: OrdersUnfulfilledExportQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<OrdersUnfulfilledExportQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      OrdersUnfulfilledExportQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useSuspenseQuery<OrdersUnfulfilledExportQuery, TError, TData>({
    queryKey: ['ordersUnfulfilledExportSuspense', variables],
    queryFn: useFetchData<
      OrdersUnfulfilledExportQuery,
      OrdersUnfulfilledExportQueryVariables
    >(OrdersUnfulfilledExportDocument).bind(null, variables),
    ...options,
  });
};

export const StocksExportDocument = `
    query stocksExport($saleId: ID!, $input: StocksExportInput!) {
  stocksExport(saleId: $saleId, input: $input) {
    fileUrl
  }
}
    `;

export const useStocksExportQuery = <
  TData = StocksExportQuery,
  TError = unknown,
>(
  variables: StocksExportQueryVariables,
  options?: Omit<
    UseQueryOptions<StocksExportQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<StocksExportQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<StocksExportQuery, TError, TData>({
    queryKey: ['stocksExport', variables],
    queryFn: useFetchData<StocksExportQuery, StocksExportQueryVariables>(
      StocksExportDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useSuspenseStocksExportQuery = <
  TData = StocksExportQuery,
  TError = unknown,
>(
  variables: StocksExportQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<StocksExportQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      StocksExportQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useSuspenseQuery<StocksExportQuery, TError, TData>({
    queryKey: ['stocksExportSuspense', variables],
    queryFn: useFetchData<StocksExportQuery, StocksExportQueryVariables>(
      StocksExportDocument
    ).bind(null, variables),
    ...options,
  });
};

export const UsedCouriersDocument = `
    query usedCouriers {
  usedCouriers {
    ...PartialCourier
    supportedCountries
  }
}
    ${PartialCourierFragmentDoc}`;

export const useUsedCouriersQuery = <
  TData = UsedCouriersQuery,
  TError = unknown,
>(
  variables?: UsedCouriersQueryVariables,
  options?: Omit<
    UseQueryOptions<UsedCouriersQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseQueryOptions<UsedCouriersQuery, TError, TData>['queryKey'];
  }
) => {
  return useQuery<UsedCouriersQuery, TError, TData>({
    queryKey:
      variables === undefined ? ['usedCouriers'] : ['usedCouriers', variables],
    queryFn: useFetchData<UsedCouriersQuery, UsedCouriersQueryVariables>(
      UsedCouriersDocument
    ).bind(null, variables),
    ...options,
  });
};

export const useSuspenseUsedCouriersQuery = <
  TData = UsedCouriersQuery,
  TError = unknown,
>(
  variables?: UsedCouriersQueryVariables,
  options?: Omit<
    UseSuspenseQueryOptions<UsedCouriersQuery, TError, TData>,
    'queryKey'
  > & {
    queryKey?: UseSuspenseQueryOptions<
      UsedCouriersQuery,
      TError,
      TData
    >['queryKey'];
  }
) => {
  return useSuspenseQuery<UsedCouriersQuery, TError, TData>({
    queryKey:
      variables === undefined
        ? ['usedCouriersSuspense']
        : ['usedCouriersSuspense', variables],
    queryFn: useFetchData<UsedCouriersQuery, UsedCouriersQueryVariables>(
      UsedCouriersDocument
    ).bind(null, variables),
    ...options,
  });
};

export const AcceptOrderClaimWithRefundDocument = `
    mutation acceptOrderClaimWithRefund($input: AcceptOrderClaimWithRefundInput!) {
  acceptOrderClaimWithRefund(input: $input)
}
    `;

export const useAcceptOrderClaimWithRefundMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    AcceptOrderClaimWithRefundMutation,
    TError,
    AcceptOrderClaimWithRefundMutationVariables,
    TContext
  >
) => {
  return useMutation<
    AcceptOrderClaimWithRefundMutation,
    TError,
    AcceptOrderClaimWithRefundMutationVariables,
    TContext
  >({
    mutationKey: ['acceptOrderClaimWithRefund'],
    mutationFn: useFetchData<
      AcceptOrderClaimWithRefundMutation,
      AcceptOrderClaimWithRefundMutationVariables
    >(AcceptOrderClaimWithRefundDocument),
    ...options,
  });
};

export const AcceptOrderClaimWithReshipDocument = `
    mutation acceptOrderClaimWithReship($input: AcceptOrderClaimWithReshipInput!) {
  acceptOrderClaimWithReship(input: $input)
}
    `;

export const useAcceptOrderClaimWithReshipMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    AcceptOrderClaimWithReshipMutation,
    TError,
    AcceptOrderClaimWithReshipMutationVariables,
    TContext
  >
) => {
  return useMutation<
    AcceptOrderClaimWithReshipMutation,
    TError,
    AcceptOrderClaimWithReshipMutationVariables,
    TContext
  >({
    mutationKey: ['acceptOrderClaimWithReship'],
    mutationFn: useFetchData<
      AcceptOrderClaimWithReshipMutation,
      AcceptOrderClaimWithReshipMutationVariables
    >(AcceptOrderClaimWithReshipDocument),
    ...options,
  });
};

export const AcceptOrderClaimWithVoucherReplacementCodesDocument = `
    mutation acceptOrderClaimWithVoucherReplacementCodes($input: AcceptOrderClaimWithVoucherReplacementCodesInput!) {
  acceptOrderClaimWithVoucherReplacementCodes(input: $input)
}
    `;

export const useAcceptOrderClaimWithVoucherReplacementCodesMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    AcceptOrderClaimWithVoucherReplacementCodesMutation,
    TError,
    AcceptOrderClaimWithVoucherReplacementCodesMutationVariables,
    TContext
  >
) => {
  return useMutation<
    AcceptOrderClaimWithVoucherReplacementCodesMutation,
    TError,
    AcceptOrderClaimWithVoucherReplacementCodesMutationVariables,
    TContext
  >({
    mutationKey: ['acceptOrderClaimWithVoucherReplacementCodes'],
    mutationFn: useFetchData<
      AcceptOrderClaimWithVoucherReplacementCodesMutation,
      AcceptOrderClaimWithVoucherReplacementCodesMutationVariables
    >(AcceptOrderClaimWithVoucherReplacementCodesDocument),
    ...options,
  });
};

export const AcceptOrderClaimWithVoucherReplacementMessageDocument = `
    mutation acceptOrderClaimWithVoucherReplacementMessage($input: AcceptOrderClaimWithVoucherReplacementMessageInput!) {
  acceptOrderClaimWithVoucherReplacementMessage(input: $input)
}
    `;

export const useAcceptOrderClaimWithVoucherReplacementMessageMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    AcceptOrderClaimWithVoucherReplacementMessageMutation,
    TError,
    AcceptOrderClaimWithVoucherReplacementMessageMutationVariables,
    TContext
  >
) => {
  return useMutation<
    AcceptOrderClaimWithVoucherReplacementMessageMutation,
    TError,
    AcceptOrderClaimWithVoucherReplacementMessageMutationVariables,
    TContext
  >({
    mutationKey: ['acceptOrderClaimWithVoucherReplacementMessage'],
    mutationFn: useFetchData<
      AcceptOrderClaimWithVoucherReplacementMessageMutation,
      AcceptOrderClaimWithVoucherReplacementMessageMutationVariables
    >(AcceptOrderClaimWithVoucherReplacementMessageDocument),
    ...options,
  });
};

export const ChangePasswordDocument = `
    mutation changePassword($newPassword: String!) {
  changePassword(newPassword: $newPassword)
}
    `;

export const useChangePasswordMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    ChangePasswordMutation,
    TError,
    ChangePasswordMutationVariables,
    TContext
  >
) => {
  return useMutation<
    ChangePasswordMutation,
    TError,
    ChangePasswordMutationVariables,
    TContext
  >({
    mutationKey: ['changePassword'],
    mutationFn: useFetchData<
      ChangePasswordMutation,
      ChangePasswordMutationVariables
    >(ChangePasswordDocument),
    ...options,
  });
};

export const CreateOrderParcelDocument = `
    mutation createOrderParcel($input: CreateOrderParcelInput!) {
  createOrderParcel(input: $input)
}
    `;

export const useCreateOrderParcelMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    CreateOrderParcelMutation,
    TError,
    CreateOrderParcelMutationVariables,
    TContext
  >
) => {
  return useMutation<
    CreateOrderParcelMutation,
    TError,
    CreateOrderParcelMutationVariables,
    TContext
  >({
    mutationKey: ['createOrderParcel'],
    mutationFn: useFetchData<
      CreateOrderParcelMutation,
      CreateOrderParcelMutationVariables
    >(CreateOrderParcelDocument),
    ...options,
  });
};

export const CreatePasswordConnectionDocument = `
    mutation createPasswordConnection($userInfo: UserPasswordConnection!) {
  createPasswordConnection(userInfo: $userInfo)
}
    `;

export const useCreatePasswordConnectionMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    CreatePasswordConnectionMutation,
    TError,
    CreatePasswordConnectionMutationVariables,
    TContext
  >
) => {
  return useMutation<
    CreatePasswordConnectionMutation,
    TError,
    CreatePasswordConnectionMutationVariables,
    TContext
  >({
    mutationKey: ['createPasswordConnection'],
    mutationFn: useFetchData<
      CreatePasswordConnectionMutation,
      CreatePasswordConnectionMutationVariables
    >(CreatePasswordConnectionDocument),
    ...options,
  });
};

export const DetachBrandUserDocument = `
    mutation detachBrandUser($userId: String!) {
  detachBrandUser(userId: $userId)
}
    `;

export const useDetachBrandUserMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    DetachBrandUserMutation,
    TError,
    DetachBrandUserMutationVariables,
    TContext
  >
) => {
  return useMutation<
    DetachBrandUserMutation,
    TError,
    DetachBrandUserMutationVariables,
    TContext
  >({
    mutationKey: ['detachBrandUser'],
    mutationFn: useFetchData<
      DetachBrandUserMutation,
      DetachBrandUserMutationVariables
    >(DetachBrandUserDocument),
    ...options,
  });
};

export const EditBasicUserInfoDocument = `
    mutation editBasicUserInfo($basicUserInfo: BasicUserInfo!) {
  editBasicUserInfo(basicUserInfo: $basicUserInfo)
}
    `;

export const useEditBasicUserInfoMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    EditBasicUserInfoMutation,
    TError,
    EditBasicUserInfoMutationVariables,
    TContext
  >
) => {
  return useMutation<
    EditBasicUserInfoMutation,
    TError,
    EditBasicUserInfoMutationVariables,
    TContext
  >({
    mutationKey: ['editBasicUserInfo'],
    mutationFn: useFetchData<
      EditBasicUserInfoMutation,
      EditBasicUserInfoMutationVariables
    >(EditBasicUserInfoDocument),
    ...options,
  });
};

export const GenerateInvoiceUploadUrlDocument = `
    mutation generateInvoiceUploadUrl($saleId: ID!, $upload: UploadInput!) {
  generateInvoiceUploadUrl(saleId: $saleId, upload: $upload) {
    expiresAt
    key
    url
  }
}
    `;

export const useGenerateInvoiceUploadUrlMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    GenerateInvoiceUploadUrlMutation,
    TError,
    GenerateInvoiceUploadUrlMutationVariables,
    TContext
  >
) => {
  return useMutation<
    GenerateInvoiceUploadUrlMutation,
    TError,
    GenerateInvoiceUploadUrlMutationVariables,
    TContext
  >({
    mutationKey: ['generateInvoiceUploadUrl'],
    mutationFn: useFetchData<
      GenerateInvoiceUploadUrlMutation,
      GenerateInvoiceUploadUrlMutationVariables
    >(GenerateInvoiceUploadUrlDocument),
    ...options,
  });
};

export const GenerateProfilePictureUploadUrlDocument = `
    mutation generateProfilePictureUploadUrl($upload: UploadInput!) {
  generateProfilePictureUploadUrl(upload: $upload) {
    expiresAt
    key
    url
  }
}
    `;

export const useGenerateProfilePictureUploadUrlMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    GenerateProfilePictureUploadUrlMutation,
    TError,
    GenerateProfilePictureUploadUrlMutationVariables,
    TContext
  >
) => {
  return useMutation<
    GenerateProfilePictureUploadUrlMutation,
    TError,
    GenerateProfilePictureUploadUrlMutationVariables,
    TContext
  >({
    mutationKey: ['generateProfilePictureUploadUrl'],
    mutationFn: useFetchData<
      GenerateProfilePictureUploadUrlMutation,
      GenerateProfilePictureUploadUrlMutationVariables
    >(GenerateProfilePictureUploadUrlDocument),
    ...options,
  });
};

export const GroupOrderParcelDocument = `
    mutation groupOrderParcel($input: GroupOrderParcelInput!) {
  groupOrderParcel(input: $input)
}
    `;

export const useGroupOrderParcelMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    GroupOrderParcelMutation,
    TError,
    GroupOrderParcelMutationVariables,
    TContext
  >
) => {
  return useMutation<
    GroupOrderParcelMutation,
    TError,
    GroupOrderParcelMutationVariables,
    TContext
  >({
    mutationKey: ['groupOrderParcel'],
    mutationFn: useFetchData<
      GroupOrderParcelMutation,
      GroupOrderParcelMutationVariables
    >(GroupOrderParcelDocument),
    ...options,
  });
};

export const GenerateInvoiceCustomsDocument = `
    mutation generateInvoiceCustoms($orderId: ID!) {
  generateInvoiceCustoms(orderId: $orderId) {
    url
  }
}
    `;

export const useGenerateInvoiceCustomsMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    GenerateInvoiceCustomsMutation,
    TError,
    GenerateInvoiceCustomsMutationVariables,
    TContext
  >
) => {
  return useMutation<
    GenerateInvoiceCustomsMutation,
    TError,
    GenerateInvoiceCustomsMutationVariables,
    TContext
  >({
    mutationKey: ['generateInvoiceCustoms'],
    mutationFn: useFetchData<
      GenerateInvoiceCustomsMutation,
      GenerateInvoiceCustomsMutationVariables
    >(GenerateInvoiceCustomsDocument),
    ...options,
  });
};

export const GeneratePurchaseOrderDocument = `
    mutation generatePurchaseOrder($orderId: ID!) {
  generatePurchaseOrder(orderId: $orderId) {
    url
  }
}
    `;

export const useGeneratePurchaseOrderMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    GeneratePurchaseOrderMutation,
    TError,
    GeneratePurchaseOrderMutationVariables,
    TContext
  >
) => {
  return useMutation<
    GeneratePurchaseOrderMutation,
    TError,
    GeneratePurchaseOrderMutationVariables,
    TContext
  >({
    mutationKey: ['generatePurchaseOrder'],
    mutationFn: useFetchData<
      GeneratePurchaseOrderMutation,
      GeneratePurchaseOrderMutationVariables
    >(GeneratePurchaseOrderDocument),
    ...options,
  });
};

export const JoinBrandDocument = `
    mutation joinBrand($brandId: String!, $inviteCode: String!) {
  joinBrand(brandId: $brandId, inviteCode: $inviteCode)
}
    `;

export const useJoinBrandMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    JoinBrandMutation,
    TError,
    JoinBrandMutationVariables,
    TContext
  >
) => {
  return useMutation<
    JoinBrandMutation,
    TError,
    JoinBrandMutationVariables,
    TContext
  >({
    mutationKey: ['joinBrand'],
    mutationFn: useFetchData<JoinBrandMutation, JoinBrandMutationVariables>(
      JoinBrandDocument
    ),
    ...options,
  });
};

export const PostRegisterDocument = `
    mutation postRegister($config: PostRegisterConfig!) {
  postRegister(config: $config)
}
    `;

export const usePostRegisterMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    PostRegisterMutation,
    TError,
    PostRegisterMutationVariables,
    TContext
  >
) => {
  return useMutation<
    PostRegisterMutation,
    TError,
    PostRegisterMutationVariables,
    TContext
  >({
    mutationKey: ['postRegister'],
    mutationFn: useFetchData<
      PostRegisterMutation,
      PostRegisterMutationVariables
    >(PostRegisterDocument),
    ...options,
  });
};

export const SetUserRoleDocument = `
    mutation setUserRole($userId: ID!, $role: BrandRole!) {
  setUserRole(userId: $userId, role: $role)
}
    `;

export const useSetUserRoleMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    SetUserRoleMutation,
    TError,
    SetUserRoleMutationVariables,
    TContext
  >
) => {
  return useMutation<
    SetUserRoleMutation,
    TError,
    SetUserRoleMutationVariables,
    TContext
  >({
    mutationKey: ['setUserRole'],
    mutationFn: useFetchData<SetUserRoleMutation, SetUserRoleMutationVariables>(
      SetUserRoleDocument
    ),
    ...options,
  });
};

export const SubmitDownPaymentDocument = `
    mutation submitDownPayment($input: SubmitDownPaymentInput!, $saleId: ID!) {
  submitDownPayment(input: $input, saleId: $saleId)
}
    `;

export const useSubmitDownPaymentMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    SubmitDownPaymentMutation,
    TError,
    SubmitDownPaymentMutationVariables,
    TContext
  >
) => {
  return useMutation<
    SubmitDownPaymentMutation,
    TError,
    SubmitDownPaymentMutationVariables,
    TContext
  >({
    mutationKey: ['submitDownPayment'],
    mutationFn: useFetchData<
      SubmitDownPaymentMutation,
      SubmitDownPaymentMutationVariables
    >(SubmitDownPaymentDocument),
    ...options,
  });
};

export const SubmitInvoiceDocument = `
    mutation submitInvoice($input: SubmitInvoiceInput!, $saleId: ID!) {
  submitInvoice(input: $input, saleId: $saleId)
}
    `;

export const useSubmitInvoiceMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    SubmitInvoiceMutation,
    TError,
    SubmitInvoiceMutationVariables,
    TContext
  >
) => {
  return useMutation<
    SubmitInvoiceMutation,
    TError,
    SubmitInvoiceMutationVariables,
    TContext
  >({
    mutationKey: ['submitInvoice'],
    mutationFn: useFetchData<
      SubmitInvoiceMutation,
      SubmitInvoiceMutationVariables
    >(SubmitInvoiceDocument),
    ...options,
  });
};

export const SwapOrderParcelDocument = `
    mutation swapOrderParcel($input: SwapOrderParcelInput!) {
  swapOrderParcel(input: $input)
}
    `;

export const useSwapOrderParcelMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    SwapOrderParcelMutation,
    TError,
    SwapOrderParcelMutationVariables,
    TContext
  >
) => {
  return useMutation<
    SwapOrderParcelMutation,
    TError,
    SwapOrderParcelMutationVariables,
    TContext
  >({
    mutationKey: ['swapOrderParcel'],
    mutationFn: useFetchData<
      SwapOrderParcelMutation,
      SwapOrderParcelMutationVariables
    >(SwapOrderParcelDocument),
    ...options,
  });
};

export const UpdateInviteCodeDocument = `
    mutation updateInviteCode {
  updateInviteCode
}
    `;

export const useUpdateInviteCodeMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateInviteCodeMutation,
    TError,
    UpdateInviteCodeMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateInviteCodeMutation,
    TError,
    UpdateInviteCodeMutationVariables,
    TContext
  >({
    mutationKey: ['updateInviteCode'],
    mutationFn: useFetchData<
      UpdateInviteCodeMutation,
      UpdateInviteCodeMutationVariables
    >(UpdateInviteCodeDocument),
    ...options,
  });
};

export const UpdateOrderParcelDocument = `
    mutation updateOrderParcel($input: UpdateOrderParcelInput!) {
  updateOrderParcel(input: $input)
}
    `;

export const useUpdateOrderParcelMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateOrderParcelMutation,
    TError,
    UpdateOrderParcelMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateOrderParcelMutation,
    TError,
    UpdateOrderParcelMutationVariables,
    TContext
  >({
    mutationKey: ['updateOrderParcel'],
    mutationFn: useFetchData<
      UpdateOrderParcelMutation,
      UpdateOrderParcelMutationVariables
    >(UpdateOrderParcelDocument),
    ...options,
  });
};

export const UpdateProfilePictureDocument = `
    mutation updateProfilePicture($url: BaseUrl!) {
  updateProfilePicture(url: $url)
}
    `;

export const useUpdateProfilePictureMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateProfilePictureMutation,
    TError,
    UpdateProfilePictureMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateProfilePictureMutation,
    TError,
    UpdateProfilePictureMutationVariables,
    TContext
  >({
    mutationKey: ['updateProfilePicture'],
    mutationFn: useFetchData<
      UpdateProfilePictureMutation,
      UpdateProfilePictureMutationVariables
    >(UpdateProfilePictureDocument),
    ...options,
  });
};

export const UpdateUserLanguageDocument = `
    mutation updateUserLanguage($preferredLanguage: String!) {
  updateUserLanguage(preferredLanguage: $preferredLanguage)
}
    `;

export const useUpdateUserLanguageMutation = <
  TError = unknown,
  TContext = unknown,
>(
  options?: UseMutationOptions<
    UpdateUserLanguageMutation,
    TError,
    UpdateUserLanguageMutationVariables,
    TContext
  >
) => {
  return useMutation<
    UpdateUserLanguageMutation,
    TError,
    UpdateUserLanguageMutationVariables,
    TContext
  >({
    mutationKey: ['updateUserLanguage'],
    mutationFn: useFetchData<
      UpdateUserLanguageMutation,
      UpdateUserLanguageMutationVariables
    >(UpdateUserLanguageDocument),
    ...options,
  });
};
