import React from 'react';

import Flag from '@appchoose/flag';
import Icon from '@appchoose/icon';
import i18n, { t } from 'i18next';
import { useAtomValue } from 'jotai';

import { brandState } from '../../stores/brand';
import { type Sale, StoreRegion } from '../../types/generated';
import { transformDateSale, transformDateSimple } from '../../utils/date';

const CurrentSale = ({ sale }: { sale: Sale }) => {
  const brand = useAtomValue(brandState);
  return (
    <>
      <p className="leading-4 group-hover:hidden">
        <span className="text-sm font-bold text-green-900">
          {sale.selection_name ??
            transformDateSale(
              new Date(sale.start_at ?? ''),
              i18n.language === 'fr' ? 'fr' : 'en',
              brand?.timezone ?? undefined
            )}
        </span>
      </p>
      <p className="hidden leading-4 group-hover:block">
        <span className="text-sm text-green-900">
          {transformDateSimple(
            new Date(sale.start_at ?? ''),
            i18n.language === 'fr' ? 'fr' : 'en',
            true,
            brand?.timezone ?? undefined
          )}{' '}
          →{' '}
          {sale.end_at
            ? transformDateSimple(
                new Date(sale.end_at),
                i18n.language === 'fr' ? 'fr' : 'en',
                true,
                brand?.timezone ?? undefined
              )
            : ''}
        </span>
      </p>
    </>
  );
};

const NoSale = () => {
  return (
    <p className="leading-4">
      <span className="text-sm font-bold text-green-900">
        {t('selector.choose_a_sale')}
      </span>
    </p>
  );
};

type SalesSelectorCurrentSaleProps = {
  sale?: Sale;
  showFlag: boolean;
};

export const SalesSelectorCurrentSale: React.FC<
  SalesSelectorCurrentSaleProps
> = ({ sale, showFlag }) => {
  return (
    <div className="group relative box-border flex w-full cursor-pointer flex-col items-start gap-2">
      <p className="flex items-center gap-2">
        <span className="text-xs font-semibold uppercase tracking-wider text-gray-700">
          {t('selector.campaign')}
        </span>
        {showFlag && sale ? (
          <Flag
            flag={sale.store === StoreRegion.Us ? 'US' : 'EU'}
            viewBox="0 0 24 24"
            className="h-4 w-[18px]"
          />
        ) : null}
      </p>
      {sale ? <CurrentSale sale={sale} /> : <NoSale />}
      <Icon
        icon="selector"
        className="absolute right-0 top-2.5 text-gray-500"
      />
    </div>
  );
};
