import { useTranslation } from 'react-i18next';

import Button from '@appchoose/button';
import cn from '@appchoose/cn';
import Icon from '@appchoose/icon';
import { useAtomValue } from 'jotai';

import { brandState } from '../../stores/brand';
import type { Order } from '../../types/generated-new';
import { OrderTagFulfillment } from '../../types/generated-new';
import {
  getProcShopifyUrl,
  getShopifyAdminUrl,
  getShopifyErrors,
  getShopifyLabelFromError,
} from '../../utils/shopify';
import { useResyncShopifyOrders } from '../../views/orders-page/use-resync-shopify-orders';

type OrderShopifyDesyncAlertProps = {
  exportedErrorCode: string;
  fulfillment: Order['tags']['fulfillment'];
};

export const OrderShopifyDesyncAlert: React.FC<
  OrderShopifyDesyncAlertProps
> = ({ exportedErrorCode, fulfillment }: OrderShopifyDesyncAlertProps) => {
  const { i18n, t } = useTranslation();
  const { mutateAsync: onResyncShopifyOrders, isPending } =
    useResyncShopifyOrders();

  const brand = useAtomValue(brandState);

  const shopifyError = getShopifyErrors(exportedErrorCode);

  return (
    <div
      className={cn(
        'flex flex-col justify-between gap-x-8 gap-y-6 rounded border border-gray-300 bg-[#FBFBFB] p-6',
        {
          'border-red-350 bg-[#F9E7E7]':
            fulfillment === OrderTagFulfillment.Unfulfilled,
        }
      )}
    >
      <div className="flex flex-row space-x-4">
        <Icon
          icon="shopifyError"
          className={cn('size-6 shrink-0 text-gray-700', {
            'text-red-600': fulfillment === OrderTagFulfillment.Unfulfilled,
          })}
        />
        <div className="space-y-2.5">
          <div className="space-y-1">
            <div className="text-sm font-bold text-gray-700">
              {t('order.shopify_desync.desync_orders')}
            </div>
            <ul className="list-inside list-disc text-sm text-gray-700">
              <li>{getShopifyLabelFromError(shopifyError, t)}</li>
            </ul>
          </div>
          <a
            href={getProcShopifyUrl(i18n.language)}
            target="_blank"
            rel="noreferrer"
            className="inline-flex items-center space-x-0.5 text-xs font-semibold text-green-900"
          >
            <span>{t('orders.shopify_desync.view_procedure')}</span>
            <Icon icon="externalLinkSimple" />
          </a>
        </div>
      </div>
      <div className="flex shrink-0 flex-col gap-4">
        <a
          href={getShopifyAdminUrl(brand?.shopify?.shop ?? '')}
          target="_blank"
          rel="noreferrer"
          className={cn(
            'flex items-center justify-center space-x-2 rounded-lg border-2 border-gray-900 bg-white px-4 py-2 text-sm font-bold text-gray-900',
            {
              'border-[#E79E9E]':
                fulfillment === OrderTagFulfillment.Unfulfilled,
            }
          )}
        >
          <Icon icon="externalLinkDefault" className="size-5 shrink-0" />
          <span>{t('orders.shopify_desync.rectify_on_shopify')}</span>
        </a>
        <Button
          appearance="primary"
          type="button"
          className="justify-center space-x-2"
          onClick={onResyncShopifyOrders}
          disabled={isPending}
        >
          <Icon icon="refresh" className="size-5 shrink-0" />
          <span>{t('orders.shopify_desync.sync_orders')}</span>
        </Button>
      </div>
    </div>
  );
};
