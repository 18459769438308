import { useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { useQueryClient } from '@tanstack/react-query';
import { useAtom, useAtomValue } from 'jotai';

import { activeSaleState, salesState } from '../stores/sales';
import type { WidgetUpdatedSubscription } from '../types/generated';
import { WidgetUpdatedDocument } from '../types/generated';
import { getSubscriptionClient } from './subscription-client';

export const useWidgetUpdatedSubscription = () => {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();
  const activeSale = useAtomValue(activeSaleState);
  const [sales, setSales] = useAtom(salesState);

  useEffect(() => {
    const client = getSubscriptionClient(getAccessTokenSilently);
    const subscribe = () => {
      client
        .request({
          query: WidgetUpdatedDocument,
        })
        .subscribe({
          next(response) {
            if (!activeSale) return;

            const data = response.data as WidgetUpdatedSubscription;

            if (!data.widgetUpdated) return;

            setSales([
              ...(sales?.filter((sale) => sale.id !== activeSale.id) ?? []),
              {
                ...activeSale,
                widgets: [
                  ...(activeSale.widgets
                    ?.filter((widget) => !!widget)
                    .filter((widget) => widget.id !== data.widgetUpdated?.id) ??
                    []),
                  data.widgetUpdated ?? [],
                ],
              },
            ]);
          },
        });
    };
    subscribe();
    return () => {
      client.close();
    };
  }, [queryClient, activeSale, sales, setSales]);
};
