import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from '@appchoose/button';
import { Form } from '@appchoose/form';
import { useQueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';

import { brandState } from '../../../stores/brand';
import { useUpdateSellerMutation } from '../../../types/generated';
import type { UsedCouriersQuery } from '../../../types/generated-new';
import { ShippingServiceFields } from '../../shipping-service-fields/shipping-service-fields';
import { useVisibleCouriers } from './use-default-couriers';

export type SelectedCourier = UsedCouriersQuery['usedCouriers'][number] & {
  selected: boolean;
};

export type OnboardingStepShippingDeliveryServiceProps = {
  goToNextSubStepOrStep: () => void;
};

export type OnboardingStepShippingDeliveryServiceForm = {
  services: SelectedCourier[];
};

export const OnboardingStepShippingDeliveryService: React.FC<
  OnboardingStepShippingDeliveryServiceProps
> = ({ goToNextSubStepOrStep }: OnboardingStepShippingDeliveryServiceProps) => {
  const brand = useAtomValue(brandState);
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { getVisibleCouriers } = useVisibleCouriers();

  const { mutateAsync: updateSellerMutation } = useUpdateSellerMutation();

  const form = useForm<OnboardingStepShippingDeliveryServiceForm>({
    defaultValues: async () => {
      return {
        services: await getVisibleCouriers(),
      };
    },
  });

  if (!brand) return;

  const onSubmit = async (data: OnboardingStepShippingDeliveryServiceForm) => {
    await updateSellerMutation({
      updateSeller: {
        used_couriers: data.services
          .filter((service) => service.selected === true)
          .map((service) => service.slug),
      },
    });
    queryClient.invalidateQueries({
      queryKey: ['usedCouriers'],
    });
    goToNextSubStepOrStep();
  };

  return (
    <>
      <div className="mb-10 flex flex-col gap-10 sm:gap-2">
        <h2 className="mt-10 text-2xl font-bold sm:mt-20 sm:text-3.5xl">
          {t('onboarding.shipping.sub_steps.2.title')}
        </h2>
        <p className="text-sm leading-5.5 text-gray-700">
          {t('onboarding.shipping.sub_steps.2.subtitle')}
        </p>
      </div>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-col gap-10"
        >
          <ShippingServiceFields
            shippingCountryCode={brand.shipping_country ?? 'FR'}
          />

          <Button type="submit" size="large" className="self-start">
            {t('continue')}
          </Button>
        </form>
      </Form>
    </>
  );
};

OnboardingStepShippingDeliveryService.displayName =
  'OnboardingStepShippingDeliveryService';
