import { useTranslation } from 'react-i18next';

import cn from '@appchoose/cn';

import type { OrderQuery } from '../../types/generated-new';

export type ProofDetailsProps = {
  claim?: OrderQuery['order']['claims'][0];
};

export const ProofDetails: React.FC<ProofDetailsProps> = ({
  claim,
}: ProofDetailsProps) => {
  const { t } = useTranslation();

  if (!claim || claim.proofUrls.length === 0) return null;

  return (
    <div
      className={cn('flex flex-col gap-2 pt-4', {
        'mt-4 border-t border-gray-300': !claim.isAccepted && !claim.isDeclined,
      })}
    >
      <h5 className="text-sm font-semibold text-gray-900">
        {t('order.proof')}
      </h5>
      <ul className="space-y-2">
        {claim.proofUrls.map((imgUrl, idx) => (
          <li key={idx} className="flex items-center space-x-2">
            <a
              href={imgUrl}
              target="_blank"
              rel="noreferrer"
              className="text-xs font-semibold text-green-900"
            >
              <img
                src={imgUrl}
                className="size-10 rounded border-0.5 border-gray-300 object-cover"
              />
            </a>
            <a
              href={imgUrl}
              target="_blank"
              rel="noreferrer"
              className="text-xs font-semibold text-green-900"
            >
              {t('order.see_proof')}
              {idx + 1}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};
