import { useTranslation } from 'react-i18next';

import Icon from '@appchoose/icon';
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from '@appchoose/tooltip';

import { InvoiceStatus } from '../../types/generated-new';

type InvoiceBadgeProps = {
  invoiceStatus: InvoiceStatus;
};

export const InvoiceBadge: React.FC<InvoiceBadgeProps> = ({
  invoiceStatus,
}) => {
  const { t } = useTranslation();

  if (invoiceStatus === InvoiceStatus.Paid)
    return (
      <Tooltip>
        <TooltipTrigger className="flex shrink-0 cursor-pointer items-center space-x-1 rounded-sm bg-[#ECF7F1] px-2 py-1 text-xs font-semibold text-green-600">
          <span>{t('invoice.invoice_information.status.paid')}</span>
          <Icon icon="information" className="h-3.5 w-3.5" />
        </TooltipTrigger>
        <TooltipContent sideOffset={8}>
          <div className="p-1">
            {t('invoice.invoice_information.status_explanations.paid')}
          </div>
          <TooltipArrow />
        </TooltipContent>
      </Tooltip>
    );
  if (
    invoiceStatus === InvoiceStatus.BotProcessing ||
    invoiceStatus === InvoiceStatus.Received
  )
    return (
      <Tooltip>
        <TooltipTrigger className="flex shrink-0 cursor-pointer items-center space-x-1 rounded-sm bg-gray-50 px-2 py-1 text-xs font-semibold text-gray-700">
          <span>
            {t('invoice.invoice_information.status.pending_validation')}
          </span>
          <Icon icon="information" className="h-3.5 w-3.5" />
        </TooltipTrigger>
        <TooltipContent sideOffset={8}>
          <div className="p-1">
            {t(
              'invoice.invoice_information.status_explanations.pending_validation'
            )}
          </div>
          <TooltipArrow />
        </TooltipContent>
      </Tooltip>
    );
  if (invoiceStatus === InvoiceStatus.Rejected)
    return (
      <Tooltip>
        <TooltipTrigger className="flex shrink-0 cursor-pointer items-center space-x-1 rounded-sm bg-red-300 px-2 py-1 text-xs font-semibold text-red-600">
          <span>{t('invoice.invoice_information.status.rejected')}</span>
          <Icon icon="information" className="h-3.5 w-3.5" />
        </TooltipTrigger>
        <TooltipContent sideOffset={8}>
          <div className="p-1">
            {t('invoice.invoice_information.status_explanations.rejected')}
          </div>
          <TooltipArrow />
        </TooltipContent>
      </Tooltip>
    );
  if (
    invoiceStatus === InvoiceStatus.ToBePaid ||
    invoiceStatus === InvoiceStatus.Validated ||
    invoiceStatus === InvoiceStatus.BotError
  )
    return (
      <Tooltip>
        <TooltipTrigger className="flex shrink-0 cursor-pointer items-center space-x-1 rounded-sm bg-[#ECF7F1] px-2 py-1 text-xs font-semibold text-green-600">
          <span>{t('invoice.invoice_information.status.validated')}</span>
          <Icon icon="information" className="h-3.5 w-3.5" />
        </TooltipTrigger>
        <TooltipContent sideOffset={8}>
          <div className="p-1">
            {t('invoice.invoice_information.status_explanations.validated')}
          </div>
          <TooltipArrow />
        </TooltipContent>
      </Tooltip>
    );

  return null;
};
