import { atom, useAtomValue } from 'jotai';

import { activeSaleIdState } from '../../stores/sales';
import type { BillingStateQuery } from '../../types/generated-new';
import { useBillingStateQuery } from '../../types/generated-new';

export const billingStateState = atom<
  BillingStateQuery['supplierBillingState'] | undefined
>(undefined);

export const useBillingState = () => {
  const activeSaleId = useAtomValue(activeSaleIdState);

  const billingState = useBillingStateQuery(
    {
      saleId: activeSaleId ?? '',
    },
    {
      enabled: !!activeSaleId,
      refetchOnWindowFocus: false,
      select: (data) => data.supplierBillingState,
      staleTime: 1000,
    }
  );

  return billingState;
};
