import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import cn from '@appchoose/cn';
import { useAtomValue } from 'jotai';

import { brandState } from '../../stores/brand';
import type { SortedVariant } from '../../stores/product';
import { stockUpdateOpenIdState } from './stock-state';
import { StockUpdate } from './stock-update';

type StockProductListItemProps = {
  title: string;
  variant: SortedVariant;
  productType: string; //'coupon' | 'object';
  productImage: string | undefined;
};

export const StockProductListItem: React.FC<StockProductListItemProps> = ({
  title,
  variant,
  productType,
  productImage,
}: StockProductListItemProps) => {
  const { t } = useTranslation();
  const brand = useAtomValue(brandState);
  const stockUpdateOpenId = useAtomValue(stockUpdateOpenIdState);

  const options =
    Array.isArray(variant.options) && variant.options
      ? variant.options.map((option: string) => option)
      : [];
  const isShopifySynced = brand?.shopify?.shop && brand?.shopify.isStockLive;

  return (
    <div
      className={cn(
        'stock-product-list-item relative grid border-b border-gray-300 py-4 transition-colors duration-150 hover:bg-gray-50/30',
        {
          'bg-gray-50': stockUpdateOpenId === variant.stock_id,
        }
      )}
    >
      <div data-column="product" className="flex space-x-4">
        <div className="shrink-0">
          <img
            src={variant.images?.[0] ?? productImage}
            alt=""
            className="size-14 rounded border-0.5 border-gray-300 object-cover"
          />
        </div>

        <div className="flex items-center">
          <div className="space-y-1">
            <p className="line-clamp-2 text-sm text-gray-900">{title}</p>
            {options.length > 0 ? (
              <p className="space-x-1 text-xs text-gray-700">
                {options.map((option, index) => (
                  <Fragment key={option}>
                    <span>{option}</span>
                    {index < options.length - 1 ? (
                      <span className="text-gray-500">•</span>
                    ) : null}
                  </Fragment>
                ))}
              </p>
            ) : null}
          </div>
        </div>
      </div>

      <div data-column="sku" className="flex items-center">
        {variant.sku ? (
          <p className="text-sm text-gray-700">{variant.sku}</p>
        ) : (
          <p className="text-sm text-gray-500">{t('no_sku')}</p>
        )}
      </div>

      <StockUpdate
        stock={variant.stock}
        stock_id={variant.stock_id}
        canEdit={!isShopifySynced && productType === 'object'}
      />
    </div>
  );
};
