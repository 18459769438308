import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import cn from '@appchoose/cn';
import Icon from '@appchoose/icon';
import { toast } from '@appchoose/toast';
import { useQueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';

import { activeSaleIdState } from '../../stores/sales';
import { useResyncShopifyOrdersMutation } from '../../types/generated';

export const useResyncShopifyOrders = () => {
  const queryClient = useQueryClient();
  const { orderId } = useParams();
  const { t } = useTranslation();

  const activeSaleId = useAtomValue(activeSaleIdState);

  const { mutateAsync: resyncShopifyOrdersMutation, isPending } =
    useResyncShopifyOrdersMutation();

  const onResyncShopifyOrders = async () => {
    const loadingToast = toast.custom(
      (toast) => (
        <div
          className={cn(
            'pointer-events-auto z-9999 flex max-w-[600px] rounded-lg bg-gray-100 px-4 py-2 text-sm font-semibold',
            toast.visible ? 'animate-enter' : 'animate-leave'
          )}
        >
          <div
            className="flex flex-auto items-center justify-center gap-x-2 break-words"
            style={{ overflowWrap: 'anywhere' }}
            {...toast.ariaProps}
          >
            <div>
              <Icon icon="refresh" />
            </div>
            {t('orders.shopify_desync.sync_in_progress')}
          </div>
        </div>
      ),
      {
        duration: Infinity,
      }
    );
    try {
      const { resyncShopifyOrders } = await resyncShopifyOrdersMutation({
        saleId: activeSaleId ?? '',
      });

      if (!resyncShopifyOrders) throw new Error('No orders to sync');

      toast.success(t('orders.shopify_desync.sync_success'));
    } catch {
      toast.error(t('orders.shopify_desync.sync_error'));
    } finally {
      toast.dismiss(loadingToast);
      queryClient.invalidateQueries({
        queryKey: ['ordersUnfulfilledDesyncShopify'],
      });
      if (orderId) {
        queryClient.invalidateQueries({
          queryKey: ['order', { orderId }],
        });
      }
    }
  };

  return { mutateAsync: onResyncShopifyOrders, isPending };
};
