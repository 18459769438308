import { useBrandsAccessQuery } from '../types/generated-new';

export const useBrandsAccess = () => {
  const brandsAccess = useBrandsAccessQuery(undefined, {
    select: (data) =>
      data.getBrandsAccess.filter((brandAccess) => !!brandAccess),
    staleTime: 1000,
  });

  return brandsAccess;
};
