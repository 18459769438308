import { getCountryFromCountryName } from '@appchoose/address';
import i18n from 'i18next';

import { StoreRegion } from '../types/generated';
import { ItemReturnReasonCode, TrackingStatus } from '../types/generated-new';

/**
 * Regex pour la validation d'URL
 */
export const getUrlPattern = () => {
  return new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
};

export const getCorrectStep = (step: string | undefined, maxSteps: number) => {
  const initialStep = Number.parseInt(step ?? '');
  return !Number.isNaN(initialStep) &&
    initialStep >= 0 &&
    initialStep <= maxSteps
    ? initialStep
    : 0;
};

const UE_COUNTRY_CODES = [
  'AT', // Autriche
  'BE', // Belgique
  'BG', // Bulgarie
  'CY', // Chypre
  'CZ', // République tchèque
  'DE', // Allemagne
  'DK', // Danemark
  'EE', // Estonie
  'ES', // Espagne
  'FI', // Finlande
  'FR', // France
  'GR', // Grèce
  'HR', // Croatie
  'HU', // Hongrie
  'IE', // Irlande
  'IT', // Italie
  'LT', // Lituanie
  'LU', // Luxembourg
  'LV', // Lettonie
  'MT', // Malte
  'NL', // Pays-Bas
  'PL', // Pologne
  'PT', // Portugal
  'RO', // Roumanie
  'SE', // Suède
  'SI', // Slovénie
  'SK', // Slovaquie
];

export const isCountryNameInUE = (countryName: string) => {
  const countryCode = getCountryFromCountryName(countryName)?.code;
  return isInUE(countryCode);
};

export const isInUE = (countryCode: string | undefined) => {
  if (!countryCode) {
    return false;
  }

  return UE_COUNTRY_CODES.includes(countryCode);
};

export enum OrderLabelExtended {
  ClaimProcessed = 'CLAIM_PROCESSED',
}

export const getParcelStatusInfos = (
  trackingStatus: TrackingStatus
): {
  text: string;
  appearance: 'success' | 'warning' | 'default' | 'error';
} => {
  switch (trackingStatus) {
    case TrackingStatus.Pending:
    case TrackingStatus.InfoReceived:
      return {
        text: i18n.t('status.waiting_for_shipping'),
        appearance: 'success',
      };
    case TrackingStatus.InTransit:
    case TrackingStatus.OutForDelivery:
    case TrackingStatus.AvailableForPickup:
      return {
        text: i18n.t('status.shipped'),
        appearance: 'success',
      };
    case TrackingStatus.Delivered:
      return {
        text: i18n.t('status.delivered'),
        appearance: 'success',
      };
    case TrackingStatus.Exception:
    case TrackingStatus.Expired:
    case TrackingStatus.AttemptFail:
      return {
        text: i18n.t('status.error'),
        appearance: 'error',
      };
  }
};

export const getReturnParcelStatusInfos = (
  trackingStatus: TrackingStatus
): {
  text: string;
  appearance: 'success' | 'warning' | 'default' | 'error';
} => {
  switch (trackingStatus) {
    case TrackingStatus.Pending:
    case TrackingStatus.InfoReceived:
      return {
        text: i18n.t('status.return_ready_to_process'),
        appearance: 'warning',
      };
    case TrackingStatus.InTransit:
    case TrackingStatus.OutForDelivery:
    case TrackingStatus.AvailableForPickup:
      return {
        text: i18n.t('status.return_shipped'),
        appearance: 'success',
      };
    case TrackingStatus.Delivered:
      return {
        text: i18n.t('status.return_delivered'),
        appearance: 'success',
      };
    case TrackingStatus.Exception:
    case TrackingStatus.Expired:
    case TrackingStatus.AttemptFail:
      return {
        text: i18n.t('status.return_error'),
        appearance: 'error',
      };
  }
};

export const getReturnReasonLabel = (reasonCode: ItemReturnReasonCode) => {
  switch (reasonCode) {
    case ItemReturnReasonCode.BetterPrice:
      return i18n.t('return_reason.better_price');
    case ItemReturnReasonCode.DamagedProduct:
      return i18n.t('return_reason.damaged_product');
    case ItemReturnReasonCode.DamagedShipping:
      return i18n.t('return_reason.damaged_shipping');
    case ItemReturnReasonCode.Delay:
      return i18n.t('return_reason.delay');
    case ItemReturnReasonCode.Description:
      return i18n.t('return_reason.description');
    case ItemReturnReasonCode.Other:
      return i18n.t('return_reason.other');
    case ItemReturnReasonCode.Picture:
      return i18n.t('return_reason.picture');
    case ItemReturnReasonCode.Quality:
      return i18n.t('return_reason.quality');
    case ItemReturnReasonCode.Size:
      return i18n.t('return_reason.size');
    case ItemReturnReasonCode.WrongArticle:
      return i18n.t('return_reason.wrong_article');
    case ItemReturnReasonCode.Claim:
      return i18n.t('return_reason.claim');
    default:
      return i18n.t('return_reason.no_reason');
  }
};

export const uniqueBy = <T>(arr: T[], fn: (...item: T[]) => boolean) =>
  arr.reduce<T[]>((acc, v) => {
    if (!acc.some((x: T) => fn(v, x))) acc.push(v);
    return acc;
  }, []);

export const getInvoiceEmail = (store: StoreRegion) => {
  return store === StoreRegion.Fr
    ? 'facture@appchoose.io'
    : 'invoice@appchoose.io';
};
