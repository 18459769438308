import { useDebouncedEffect } from '@react-hookz/web';
import { atom, useAtom } from 'jotai';

import { track } from '../../utils/analytics';
import { getSearchParam } from '../../utils/url';

const searchTermState = atom<string | undefined>(getSearchParam('q'));

const debouncedSearchTermState = atom<string | undefined>(getSearchParam('q'));

const isSearchActiveState = atom<boolean>(!!getSearchParam('q'));

export const useSearch = () => {
  const [searchTerm, setSearchTerm] = useAtom(searchTermState);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useAtom(
    debouncedSearchTermState
  );
  useDebouncedEffect(
    () => setDebouncedSearchTerm(searchTerm),
    [searchTerm],
    150
  );

  const [isSearchActive, setIsSearchActive] = useAtom(isSearchActiveState);

  const resetSearch = () => {
    setSearchTerm(undefined);
    setIsSearchActive(false);
  };

  const trackOpenSearch = () => {
    track('GoToSearchOrder', {});
  };

  return {
    debouncedSearchTerm,
    isSearchActive,
    isSearchEmpty: isSearchActive && !searchTerm,
    resetSearch,
    searchTerm,
    setIsSearchActive,
    setSearchTerm,
    trackOpenSearch,
  } as const;
};
