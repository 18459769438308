import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';
import { useUpdateEffect } from '@react-hookz/web';
import { useQueryClient } from '@tanstack/react-query';
import { atom, useSetAtom } from 'jotai';
import { useAtomValue } from 'jotai';

import { useFilters } from '../components/order-table/use-filters';
import { useSorting } from '../components/order-table/use-sorting';
import { activeSaleState } from '../stores/sales';
import type { OrderUpdatedSubscription } from '../types/generated';
import { OrderUpdatedDocument } from '../types/generated';
import type { OrdersQuery } from '../types/generated-new';
import { usePartialOrderQuery } from '../types/generated-new';
import {
  PAGE_SIZE,
  getDateFiltersFromTableFilters,
  getFiltersFromTableFilters,
  getSearchFromTableSearch,
  getSortFromTableSort,
  getTaggingFromTabEntry,
} from '../views/orders-page/orders-page';
import { usePagination } from '../views/orders-page/use-pagination';
import { useSearch } from '../views/orders-page/use-search';
import { useTabs } from '../views/orders-page/use-tabs';
import { getSubscriptionClient } from './subscription-client';

export const orderDetailUpdatedIdState = atom<string | null>(null);

export const useOrderUpdatedSubscription = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { orderId } = useParams();
  const queryClient = useQueryClient();

  const setOrderDetailUpdatedId = useSetAtom(orderDetailUpdatedIdState);
  const [updatedOrderId, setUpdatedOrderId] = useState<string | undefined>(
    undefined
  );

  const activeSale = useAtomValue(activeSaleState);
  const { filters } = useFilters();
  const { pagination } = usePagination();
  const { debouncedSearchTerm } = useSearch();
  const { sorting } = useSorting();
  const { selectedTab } = useTabs();

  const { data: order } = usePartialOrderQuery(
    {
      orderId: updatedOrderId ?? '',
    },
    {
      enabled: !!updatedOrderId,
      refetchOnWindowFocus: false,
      select: (result) => result.order,
    }
  );

  useUpdateEffect(() => {
    if (!order) return;

    queryClient.setQueryData(
      [
        'orders',
        {
          saleId: activeSale?.id ?? '',
          input: {
            filter: {
              creationDate: getDateFiltersFromTableFilters(filters),
              tagging: {
                brand: getTaggingFromTabEntry(selectedTab),
                ...getFiltersFromTableFilters(filters),
              },
            },
            ...getSearchFromTableSearch(debouncedSearchTerm),
            sort: getSortFromTableSort(sorting),
            paging: {
              pageSize: PAGE_SIZE,
              beforeCursor: pagination?.beforeCursor,
              afterCursor: pagination?.afterCursor,
            },
          },
        },
      ],
      (oldData: OrdersQuery) => {
        if (!oldData?.orders) return oldData;

        const newNodes = [...oldData.orders.nodes];
        const orderIndex = oldData.orders.nodes.findIndex(
          (o) => o.id === order.id
        );
        if (orderIndex !== -1) {
          newNodes[orderIndex] = order;
        }
        return {
          orders: {
            ...oldData.orders,
            nodes: newNodes,
          },
        };
      }
    );
  }, [order]);

  useEffect(() => {
    const client = getSubscriptionClient(getAccessTokenSilently);
    const subscribe = () => {
      client
        .request({
          query: OrderUpdatedDocument,
        })
        .subscribe({
          next(response) {
            if (!activeSale) return;

            const data = response.data as OrderUpdatedSubscription;

            if (!data.orderUpdated) return;

            queryClient.invalidateQueries({
              queryKey: ['ordersCounters'],
            });

            setUpdatedOrderId(data.orderUpdated.id);
            if (orderId) {
              setOrderDetailUpdatedId(data.orderUpdated.id);
            }
          },
        });
    };
    subscribe();
    return () => {
      client.close();
    };
  }, [queryClient, activeSale]);
};
