import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import cn from '@appchoose/cn';
import { Form, FormControl, FormField, FormItem } from '@appchoose/form';
import Icon from '@appchoose/icon';
import { toast } from '@appchoose/toast';
import { useUpdateEffect } from '@react-hookz/web';
import { useQueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';

import { useInvoiceUploadMutation } from '../../api/graphql';
import { InvoiceFileUpload } from '../../components/invoice-file-upload';
import { useCopyToClipboard } from '../../hooks/use-copy-to-clipboard';
import { useInvoiceCreatedSubscription } from '../../hooks/use-invoice-created-subscription';
import { useInvoiceUpdatedSubscription } from '../../hooks/use-invoice-updated-subscription';
import { brandState } from '../../stores/brand';
import { activeSaleState } from '../../stores/sales';
import { StoreRegion } from '../../types/generated';
import type { InvoicesQuery } from '../../types/generated-new';
import { InvoiceStatus } from '../../types/generated-new';
import { track } from '../../utils/analytics';
import { formatPrice } from '../../utils/currency';
import { getInvoiceEmail } from '../../utils/utils';
import { useBilling } from './use-billing';
import { useInvoices } from './use-invoices';

type InvoiceFormData = {
  file: File;
};

type InvoiceUploadProps = {
  isSubjectVat: boolean;
};

export const InvoiceUpload: React.FC<InvoiceUploadProps> = ({
  isSubjectVat,
}) => {
  const { data: billing } = useBilling();
  const { data: invoices } = useInvoices();
  const { i18n, t } = useTranslation();
  const queryClient = useQueryClient();

  const activeSale = useAtomValue(activeSaleState);
  const brand = useAtomValue(brandState);

  const form = useForm<InvoiceFormData>({
    mode: 'onTouched',
  });
  const [invoiceUploadMutation, { loading, progress }] =
    useInvoiceUploadMutation();
  const [clipboardState, copyToClipboard] = useCopyToClipboard();

  useUpdateEffect(() => {
    //TODO: handle errors
    toast.success(t('clipboard.email_copied'));
  }, [clipboardState]);

  useInvoiceCreatedSubscription();
  useInvoiceUpdatedSubscription();

  const onSubmit = async (data: InvoiceFormData) => {
    try {
      await invoiceUploadMutation({
        file: data.file,
        saleId: activeSale?.id ?? '',
      });

      toast.success(t('invoice.invoice_information.upload_success'));

      form.reset();

      queryClient.setQueryData(
        ['invoices', { saleId: activeSale?.id ?? '' }],
        (oldData: InvoicesQuery) => ({
          invoices: [
            ...(oldData?.invoices ?? []),
            {
              __typename: 'Invoice',
              createdAt: new Date().toISOString(),
              fileName: data.file.name,
              fileUrl: '',
              id: self.crypto.randomUUID(),
              status: InvoiceStatus.BotProcessing,
            },
          ],
        })
      );
    } catch {
      toast.error(t('invoice.invoice_information.upload_error'));
      form.reset();
    }
  };

  const remainingAmount = isSubjectVat
    ? (billing?.total_remaining_ttc ?? 0)
    : (billing?.total_remaining_ht ?? 0);
  const hasInvoices = (invoices?.length ?? 0) > 0;
  const invoiceEmail = getInvoiceEmail(brand?.store ?? StoreRegion.Fr);

  form.watch('file');

  return (
    <section className="flex flex-col gap-4 rounded-lg border border-gray-100 p-6">
      <div
        className={cn({
          'opacity-50': loading,
        })}
      >
        <div className="flex justify-between">
          <h2 className="text-lg font-bold text-gray-900">
            {t('invoice.invoice_information.import_an_invoice')}
          </h2>
          <p className="text-3.5xl font-bold text-gray-900">
            {formatPrice(
              remainingAmount / 100,
              i18n.language === 'fr' ? 'fr' : 'en',
              activeSale?.currency.toString().toUpperCase() ?? 'EUR'
            )}
          </p>
        </div>
        <div className="flex justify-between">
          <div className="flex space-x-1 text-sm text-gray-700">
            <span>{t('invoice.invoice_information.need_help')}</span>
            <span>{t('invoice.invoice_information.contact_us_at')}</span>
            <button
              type="button"
              className="flex items-center space-x-1 font-semibold text-green-900"
              onClick={() => {
                copyToClipboard(invoiceEmail);
                track('CopyToClipboard', {
                  label: 'billing_email',
                });
              }}
            >
              <span>{invoiceEmail}</span>
              <Icon icon="copy" className="size-3.5 shrink-0" />
            </button>
          </div>
          <div className="text-sm text-gray-700">
            {isSubjectVat
              ? t('invoice.invoice_information.remainder_be_invoiced_ttc')
              : t('invoice.invoice_information.remainder_be_invoiced_ht')}
          </div>
        </div>
      </div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
          <FormField
            control={form.control}
            name="file"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <InvoiceFileUpload
                    isUploading={loading}
                    progress={progress}
                    value={field.value}
                    onValueChange={(file) => {
                      field.onChange(file);
                      if (file) form.handleSubmit(onSubmit)();
                    }}
                    type="invoice"
                    variant={hasInvoices ? 'small' : 'default'}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        </form>
      </Form>
    </section>
  );
};
