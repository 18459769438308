import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

import Button from '@appchoose/button';
import cn from '@appchoose/cn';
import Icon from '@appchoose/icon';
import { Sidebar, SidebarContent } from '@appchoose/sidebar';
import { useAtomValue } from 'jotai';

import { Send } from '../../components/icons/send';
import { ShopifyPluggedIcon } from '../../components/icons/shopify-plugged/shopify-plugged';
import { ShopifyModal } from '../../components/shopify-plugin/shopify-modal';
import { brandState } from '../../stores/brand';
import { track } from '../../utils/analytics';

export const SaleConfiguration: React.FC = () => {
  const { t } = useTranslation();
  const brand = useAtomValue(brandState);
  const { search } = useLocation();

  const [isOpen, setIsOpen] = useState(false);

  if (!brand) {
    return null;
  }

  const hasActionToBeTaken = brand.has_shopify && !brand.shopify?.shop;

  return (
    <section className="flex flex-col gap-6 rounded-lg border border-gray-100 bg-white px-[1.4375rem] py-6">
      <div className="flex items-center gap-3">
        <div
          className={cn('rounded border p-1', {
            'border-[#F3D9B3] bg-[#FBF2E6] text-orange-600': hasActionToBeTaken,
            'border-gray-100 bg-[#FBFBFB] text-gray-900': !hasActionToBeTaken,
          })}
        >
          <Icon icon="settings" className="size-6" />
        </div>
        <span className="text-sm font-bold text-gray-900">
          {t('home.sale_configuration.sale_configuration')}
        </span>
      </div>
      <div className="divide-y divide-gray-100 rounded-md border border-gray-100">
        {brand.has_shopify ? (
          <div className="flex items-center gap-4 p-6">
            <ShopifyPluggedIcon title="Shopify" className="size-8 shrink-0" />
            <span className="flex-1 text-sm font-semibold text-gray-900">
              {t('home.sale_configuration.integrate_shopify')}
            </span>
            {brand.shopify?.shop ? (
              <button
                type="button"
                onClick={() => {
                  setIsOpen(true);
                  track('OpenModalShopifyIntegration', {
                    from: 'Settings',
                    mode: 'Edit',
                  });
                }}
                className="flex items-center rounded border border-gray-100 bg-[#FBFBFB] px-4 py-2 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus-visible:ring-2 focus-visible:ring-gray-100 disabled:bg-gray-100 disabled:text-[#9DA0A1]"
              >
                {t('home.sale_configuration.view_settings')}
              </button>
            ) : (
              <Button
                type="button"
                onClick={() => {
                  setIsOpen(true);
                  track('OpenModalShopifyIntegration', {
                    from: 'Home',
                    mode: 'Edit',
                  });
                }}
              >
                {t('home.sale_configuration.integrate')}
              </Button>
            )}
          </div>
        ) : null}

        <div className="flex items-center gap-4 p-6">
          <Send className="size-8 shrink-0 text-gray-700" />
          <span className="flex-1 text-sm font-semibold text-gray-900">
            {t('home.sale_configuration.invite_other_members')}
          </span>
          <NavLink
            to={`/${brand.id}/settings/member${search}`}
            className="flex items-center rounded border border-gray-100 bg-[#FBFBFB] px-4 py-2 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus-visible:ring-2 focus-visible:ring-gray-100 disabled:bg-gray-100 disabled:text-[#9DA0A1]"
          >
            {t('home.sale_configuration.view_settings')}
          </NavLink>
        </div>
      </div>
      <Sidebar
        open={isOpen}
        onOpenChange={(isOpen) => {
          setIsOpen(isOpen);
        }}
      >
        <SidebarContent className="mt-18 sm:!w-[37.5rem] lg:mt-0">
          <ShopifyModal setIsOpen={setIsOpen} />
        </SidebarContent>
      </Sidebar>
    </section>
  );
};
