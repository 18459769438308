/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useAtomValue } from 'jotai';

import { brandState } from '../../stores/brand';
import type { OrderQuery } from '../../types/generated-new';
import { OrderShopifyDesyncAlert } from './order-shopify-desync-alert';
import { OrderShopifyNotConfiguredAlert } from './order-shopify-not-configured-alert';

type ModalOrderShopifyProps = {
  order?: OrderQuery['order'];
};

export const ModalOrderShopify: React.FC<ModalOrderShopifyProps> = ({
  order,
}) => {
  const brand = useAtomValue(brandState);

  if (brand?.plugins?.shopify && !brand.plugins.shopify.token) {
    return <OrderShopifyNotConfiguredAlert />;
  }
  if (brand?.plugins?.shopify && order?.integration.errorMessage) {
    return (
      <OrderShopifyDesyncAlert
        exportedErrorCode={order.integration.errorMessage}
        fulfillment={order.tags.fulfillment}
      />
    );
  }
  return null;
};
