import { Fragment } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import cn from '@appchoose/cn';
import type { Row } from '@tanstack/react-table';
import { flexRender } from '@tanstack/react-table';
import { useAtomValue } from 'jotai';

import { brandState } from '../../stores/brand';
import type { OrderData } from './order-table';

export const OrderCardRow: React.FC<{
  row: Row<OrderData>;
}> = ({ row }) => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const brand = useAtomValue(brandState);

  return (
    <li
      onClick={() =>
        navigate(`/${brand?.id ?? ''}/orders/${row.original.id}${search}`)
      }
      className={cn(
        'cursor-pointer space-y-3 rounded border border-gray-100 p-4',
        {
          'bg-white': row.original.requiredAction,
          'bg-[#FBFBFB]': !row.original.requiredAction,
        }
      )}
    >
      {/* Table row */}
      <div className="flex items-center gap-x-2">
        <div className="flex items-center whitespace-nowrap">
          {row.getVisibleCells().map((cell) =>
            cell.getContext().column.id === 'createdAt' ? (
              <Fragment key={cell.id}>
                {/* Table cell */}
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </Fragment>
            ) : null
          )}
        </div>
        <span className="text-xs text-[#9DA0A1]">•</span>
        <div className="truncate">
          {row.getVisibleCells().map((cell) =>
            cell.getContext().column.id === 'id' ? (
              <Fragment key={cell.id}>
                {/* Table cell */}
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </Fragment>
            ) : null
          )}
        </div>
      </div>
      {row.getVisibleCells().map((cell) =>
        cell.getContext().column.id === 'recipientName' ? (
          <Fragment key={cell.id}>
            {/* Table cell */}
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </Fragment>
        ) : null
      )}
      <div className="flex flex-wrap gap-2">
        {row.getVisibleCells().map((cell) =>
          cell.getContext().column.id === 'fulfillment' ||
          cell.getContext().column.id === 'delivery' ||
          cell.getContext().column.id === 'claim' ? (
            <Fragment key={cell.id}>
              {/* Table cell */}
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </Fragment>
          ) : null
        )}
      </div>
      <div className="flex items-center justify-between gap-4">
        {row.getVisibleCells().map((cell) =>
          cell.getContext().column.id === 'items' ? (
            <Fragment key={cell.id}>
              {/* Table cell */}
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </Fragment>
          ) : null
        )}
        {row.getVisibleCells().map((cell) =>
          cell.getContext().column.id === 'total' ? (
            <Fragment key={cell.id}>
              {/* Table cell */}
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </Fragment>
          ) : null
        )}
      </div>
    </li>
  );
};
